import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { paginatedGql, toStandardResult } from "../util/graphql.js";

const interventionVoucherSchema = `
  id
  voucherCode
  companyCode
  amount
  expirationDate
  reference
  used
  usedAt
  downloadable
  downloaded
  downloadedAt
  intervention {
    id
    locationKind
  }
`;

export function useInterventionVoucherCreate() {
  const [mutation, result] = useMutation(
    gql`
      mutation SEND_INTERVENTION_VOUCHER_CREATE($amount: Float!, $data: InterventionVoucherDataPrivateInput) {
        private {
          interventions {
            success: createInterventionVoucher(amount: $amount, data: $data) {
              ${interventionVoucherSchema}
            }
          }
        }
      }
    `
  );
  return [
    ({ amount, ...data }) => mutation({ variables: { amount, data } }),
    ...toStandardResult(result, "private.interventions.success"),
  ];
}

export function useInterventionVouchers(filter, pagination) {
  const result = useQuery(
    gql`
      query GET_INTERVENTION_VOUCHERS($filter: InterventionVoucherPrivateFilterInput, $pagination: PaginationInput) {
        private {
          interventions {
            interventionVouchers: findInterventionVouchers(
              filter: $filter
              pagination: $pagination
            ) {
              ${paginatedGql(interventionVoucherSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        filter,
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.interventions.interventionVouchers");
}

export function useUpdateDownloadedInterventionVoucher(voucherCode) {
  const [mutation, result] = useMutation(
    gql`
      mutation ($voucherCode: String!) {
        private {
          interventions {
            interventionVoucher: updateDownloadedInterventionVoucher(
              voucherCode: $voucherCode
            ) {
              id
              downloaded
              downloadedAt
            }
          }
        }
      }
    `,
    { variables: { voucherCode } }
  );

  return [
    () => mutation({}),
    ...toStandardResult(result, "private.interventions.interventionVoucher"),
  ];
}
