import { Redirect, useParams } from "react-router-dom";

import ContentLoading from "../components/ContentLoading.jsx";
import { useExternalChallenge } from "../services/externalChallenges.js";
import { paths } from "../services/router.js";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { styled } from "../util/style.js";

const FullSizeIframe = styled.iframe`
  border: 0;
  flex: 1;
`;

export default function ExternalChallengeDetails() {
  const { challenge: alias } = useParams();

  const [externalChallenge, { ready }] = useExternalChallenge(alias);

  if (ready && (!externalChallenge || !externalChallenge.isPlayable))
    return <Redirect to={paths.challenges} />;
  return (
    <LoggedInTemplate
      title={externalChallenge ? externalChallenge.title : "Challenge"}
    >
      {!externalChallenge ? (
        <ContentLoading />
      ) : (
        <FullSizeIframe src={externalChallenge.button.url} />
      )}
    </LoggedInTemplate>
  );
}
