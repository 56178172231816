import { useState } from "react";

import Icon from "../../components/Icon.jsx";
import Link from "../../components/Link.jsx";
import { paths } from "../../services/router.js";
import {
  cnSuffix,
  color,
  context,
  font,
  isnot,
  styled,
  theme,
  z,
} from "../../util/style.js";
import Container from "./Container.jsx";

const COPYRIGHT = `Copyright © Daikin`;

export const SimpleFooter = styled(function({ className }) {
  return (
    <footer className={className}>
      <span className="SimpleFooter-copyright">{COPYRIGHT}</span>
      <Social />
    </footer>
  );
})`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 32px;

  .SimpleFooter-copyright {
    ${font("mini")};
    color: white;
    font-weight: ${theme("font-weight-bold")};
  }
`;

const FooterNavItem = styled(function({ to, icon, label, alt, className }) {
  return (
    <li className={className}>
      {to ? (
        <Link className="FooterNavItem-link" to={to} alt={alt}>
          {Boolean(icon) && (
            <Icon name={icon} size={20} variant="gold" hoverVariant="blue" />
          )}
          {label}
        </Link>
      ) : (
        <strong>{label}</strong>
      )}
    </li>
  );
})`
  ${font("mini")};
  padding: 8px;
  .FooterNavItem-link {
    color: ${color("text-white")};
    transition: color 0.2s ease-out;
    text-decoration: none;
    &:hover {
      color: ${color("secondary")};
    }
  }
`;

const Social = styled(function({ className }) {
  return (
    <ul className={className}>
      <FooterNavItem
        to={paths.facebook}
        icon="facebook"
        alt="Page Facebook de Daikin"
      />
      <FooterNavItem
        to={paths.instagram}
        icon="instagram"
        alt="Compte Instagram de Daikin"
      />
      <FooterNavItem
        to={paths.linkedin}
        icon="linkedin"
        alt="Profil LinkedIn de Daikin"
      />
      <FooterNavItem
        to={paths.youtube}
        icon="youtube"
        alt="Page Youtube de Daikin"
      />
    </ul>
  );
})`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0 -4px;
`;

const FooterNavSecondary = styled(function({ className }) {
  return (
    <ul className={className}>
      <FooterNavItem label={COPYRIGHT} />
      <FooterNavItem to={paths.legal} label="Mentions légales" />
      <FooterNavItem to={paths.rules} label="Règlement du programme" />
      <FooterNavItem
        to={paths.policy}
        label="Politique de protection des données"
      />
      <FooterNavItem to={paths.cookies} label="Cookies" />
    </ul>
  );
})`
  color: ${color("text-white")};
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -8px;
  margin-right: -8px;

  ${context("for-tablet-landscape")`
    flex-direction: column;
  `}
`;

const FooterNavPrimaryItem = styled(function({ to, label, className }) {
  return (
    <li className={className}>
      <a href={to} className="FooterNavPrimary-link">
        {label}
      </a>
    </li>
  );
})`
  padding: 12px;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }

  .FooterNavPrimary-link {
    color: white;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.2s ease-out;
    &:hover {
      color: ${color("secondary")};
      transition: color 0.2s ease-in;
    }
  }
`;

const FooterNavPrimary = styled(function({ className }) {
  return (
    <ul className={className}>
      <FooterNavPrimaryItem
        to={paths.program}
        label="Mon programme DAIKIN ELITE"
      />
      <FooterNavPrimaryItem to={paths.faq} label="Consultez notre FAQ" />
      <FooterNavPrimaryItem to={paths.contact} label="Contactez-nous" />
    </ul>
  );
})`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
`;

const FooterNav = styled(function({ className, children }) {
  return (
    <div className={className}>
      <Container>{children}</Container>
    </div>
  );
})`
  background-color: ${color("bg-dark-light")};
  padding: 16px 0;

  ${context("for-tablet-landscape")`
    display: none;
  `}
`;

const FooterData = styled(function({ className, children }) {
  return <Container className={className}>{children}</Container>;
})`
  color: ${color("text")};
  ${font("paragraph")};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 24px;
  padding-top: 24px;
`;

const FooterButton = styled.button`
  background-color: ${color("yellow")};
  border: 0;
  border-radius: ${theme("border-radius")} ${theme("border-radius")} 0 0;
  bottom: 100%;
  color: ${color("white-grey")};
  margin: 0;
  padding: 8px 16px;
  position: absolute;
  right: 16px;
`;

export default styled(function({ className, mini }) {
  const [opened, setOpened] = useState(!mini);
  return (
    <footer className={cnSuffix(className, { "--closed": !opened })}>
      {!!mini && (
        <FooterButton onClick={() => setOpened(!opened)}>
          Pied de page
        </FooterButton>
      )}
      <div
        className={cnSuffix("content", {
          "--closed": !opened,
        })}
      >
        <FooterNav>
          <FooterNavPrimary />
        </FooterNav>

        <FooterData>
          <FooterNavSecondary />
          <Social />
        </FooterData>
      </div>
    </footer>
  );
})`
  background-color: ${color("bg-dark")};
  ${theme("big-border-top")};
  position: relative;
  z-index: ${z("footer")};

  .content {
    overflow: hidden;
    height: calc(100%);
  }
  .content--closed {
    height: 0;
  }
`;
