import { Headline, MainContent } from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { List, Paragraph } from "../components/typography.jsx";

import Container from "../templates/common/Container.jsx";

export default function Hotline() {
  return (
    <LoggedInTemplate title="Utilisation des cookies">
      <MainContent>
        <Headline title="Utilisation des cookies" />
        <Container style={{ maxWidth: 660 }}>
          <Paragraph justify>
            Le présent site Internet www.daikinelite.fr utilise des technologies
            permettant de suivre les visites (cookies, stockage local, etc.). Ce
            suivi a pour vocation de :
          </Paragraph>
          <List>
            <li>
              Conserver la connexion d'un utilisateur du site dans le cadre d'un
              accès sécurisé
            </li>
            <li>
              Mesurer de manière anonyme l'audience à des fins exclusives de
              suivi de performances et d'amélioration du site
            </li>
            <li>Mémoriser l'affichage de messages d'information ou d’alerte</li>
            <li>Sauvegarder les préférences de consentement</li>
          </List>
          <Paragraph justify>
            En dehors des fonctionnalités assurant le bon fonctionnement du site
            ou son amélioration, vous pouvez consentir ou non au suivi de vos
            visites et à l'utilisation de vos données de navigation.
          </Paragraph>
          <Paragraph justify>
            Ce choix vous est donné par un formulaire dès votre première visite,
            et vous pouvez revenir à tout moment sur votre décision.
          </Paragraph>
          <Paragraph justify>
            La durée de conservation de votre consentement n'excède pas 13 mois.
          </Paragraph>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
