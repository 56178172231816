import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

import { ButtonArrowAround } from "../components/typography.jsx";
import { useTrainingSelfData } from "../services/training.js";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { font, styled } from "../util/style.js";

const FullSizeIframe = styled.iframe`
  border: 0;
  flex: 1;
`;

const Overlay = styled.div`
  align-items: center;
  background: rgba(62, 62, 62, 0.75);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const OverlayText = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  ${font("headline4")};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 32px;
  max-width: 520px;
  text-align: center;

  h3 {
    ${font("headline4")};
    margin: 0;
    padding: 0;
  }
  p {
    margin-top: 1em;
    span {
      display: block;
      text-decoration: underline;
      padding: 0.5em 0;
    }
  }
`;

const STATUS_LOADING = "loading",
  STATUS_NEED_GTM = "need-gtm",
  STATUS_LOADED = "loaded",
  STATUS_FAILED = "failed";

export default function Formations() {
  const [data, { loading }] = useTrainingSelfData();
  const [status, setStatus] = useState(STATUS_LOADING);
  const [gtmActive, setGtmActive] = useState(false);
  const onMessage = useCallback(
    function(event) {
      switch (event.data) {
        case "gtm_scripts_active":
          setGtmActive(true);
          setStatus((status) =>
            status !== STATUS_LOADED ? STATUS_LOADING : STATUS_LOADED
          );
          break;
        case "gtm_scripts_loaded":
          setStatus(STATUS_LOADED);
          break;
      }
    },
    [setStatus, setGtmActive]
  );
  useEffect(
    // Si la page ne renvoie pas une information de chargement on bascule le statut pour indiquer qu'il y a potentiellement un bloqueur pub
    function() {
      const to = setTimeout(function() {
        if (!gtmActive) setStatus(STATUS_NEED_GTM);
      }, 3000); // 3 sec pour recevoir le premier message
      return () => clearTimeout(to);
    },
    [gtmActive, setStatus]
  );
  useEffect(
    // Si la page ne renvoie pas une information de chargement après un certain temps on considère que c'est un échec
    function() {
      const to = setTimeout(function() {
        if (status !== STATUS_LOADED) setStatus(STATUS_FAILED);
      }, 30000); // 30 sec
      return () => clearTimeout(to);
    },
    [status, setStatus]
  );
  useEffect(
    // On récupère les messages et on les traite
    function() {
      window.addEventListener("message", onMessage);
      return () => window.removeEventListener("message", onMessage);
    },
    [onMessage]
  );
  if (loading) return null;
  return (
    <LoggedInTemplate title="Formations" mini style={{ position: "relative" }}>
      <FullSizeIframe
        src={`https://daikin.mp-formation.fr/?imported_data=${encodeURIComponent(
          JSON.stringify(data)
        )}`}
      />
      {status !== STATUS_LOADED && (
        <Overlay>
          {status === STATUS_NEED_GTM ? (
            <OverlayText>
              <h3>Le chargement semble long…</h3>
              <p>
                <strong>Nous vous conseillons de :</strong>
                <span>
                  Désactiver votre bloqueur de publicités sur la plateforme
                  Daikin Elite
                </span>
                ou
                <span>Vérifier votre connexion</span>
              </p>
              {window && (
                <ButtonArrowAround
                  onClick={() =>
                    window.open(
                      `https://daikin.mp-formation.fr/?imported_data=${encodeURIComponent(
                        JSON.stringify(data)
                      )}`
                    )
                  }
                >
                  Ouvrir dans une autre fenêtre
                </ButtonArrowAround>
              )}
            </OverlayText>
          ) : status === STATUS_FAILED ? (
            <OverlayText>
              <h3>Échec du chargement de votre espace Formations</h3>
              <p>
                Vous pouvez utiliser l'Espace Formations depuis une nouvelle
                fenêtre
              </p>
              {window && (
                <ButtonArrowAround
                  onClick={() =>
                    window.open(
                      `https://daikin.mp-formation.fr/?imported_data=${encodeURIComponent(
                        JSON.stringify(data)
                      )}`
                    )
                  }
                >
                  Ouvrir dans une autre fenêtre
                </ButtonArrowAround>
              )}
            </OverlayText>
          ) : (
            <OverlayText>
              <h3>Chargement de votre espace Formations&hellip;</h3>
            </OverlayText>
          )}
        </Overlay>
      )}
    </LoggedInTemplate>
  );
}

// eslint-disable-next-line no-unused-vars
function ScriptToImportInOpal() {
  // Ici pour enregistrement seulement
  useEffect(function() {
    // Styles
    // <style type="text/css">
    //   body { background: white !important; }
    //   .mig-block-logo, .mig-block-TitrePage, #mig-layout-footer { display: none !important; }
    //   .mig-block-MenuPrincipal { width: 100% !important; }
    //   #mig-layout-header .mig-block-MenuPrincipal ul > li { margin-top: 0 !important; }
    //   @media screen and (max-width:1023px) {
    //     #mig-layout-header { position: relative !important; z-index: 9999 !important; }
    //     #mig-layout-header .mig-block-MenuPrincipal ul { margin-top: 42px !important; }
    //     #mig-layout-header .mig-block-MenuPrincipal ul > li { width: 100% !important; max-width: 320px !important; }
    //   }
    // </style>

    //--- start

    if (window.parent && window.parent.postMessage)
      window.parent.postMessage("gtm_scripts_active", "*");

    var urlParameters = new URLSearchParams(window.location.search);
    var importedData = urlParameters.get("imported_data");
    if (importedData) {
      sessionStorage.setItem("importedData", importedData);
    }

    jQuery(function($) {
      var dataRaw = sessionStorage.getItem("importedData");
      if (!dataRaw) {
        return;
      }
      var data = JSON.parse(dataRaw);
      switch (window.location.pathname) {
        case "/pre-inscription": // Page de connexion
          if (!data.company) {
            return;
          }
          var $fieldset = $("#mig-panier-type-client-fieldset");
          var loginWith = function(selection) {
            if (!selection || !selection.username || !selection.password)
              return;
            var $loginForm = $("form", $fieldset).first();
            $("input[name=_username]", $loginForm).val(selection.username);
            $("input[name=_password]", $loginForm).val(selection.password);
            $loginForm.submit();
          };
          if (data.company.sites && data.company.sites.length) {
            $fieldset.children("legend").html("Votre société :");
            $fieldset.children("div").hide();
            var sitesById = data.company.sites.reduce(function(obj, site) {
              obj[site.id] = site;
              return obj;
            }, {});
            var $content = $("<div><p>Choisissez votre société</p></div>");
            var $select = $("<select></select>");
            var $generalOption = $("<option></option>");
            $generalOption.html((data.company.name || data.company.id) + "...");
            $generalOption.attr("value", "");
            $select.append($generalOption);
            for (var i = 0; i < data.company.sites.length; i++) {
              var site = data.company.sites[i];
              var $option = $("<option></option>");
              $option.html(site.name || site.id);
              $option.attr("value", site.id);
              if (
                !site.opalLogin ||
                !site.opalLogin.username ||
                !site.opalLogin.password
              ) {
                $option.attr("disabled", true);
              }
              $select.append($option);
            }
            var $button = $('<button class="mig-bouton">Continuer</button>');
            $button.on("click", function(value) {
              var selectedId = $select.val();
              var selectedSite = sitesById[selectedId];
              if (!selectedSite) return;
              loginWith(selectedSite.opalLogin);
            });
            $select.css({
              padding: "9px",
              "line-height": "1.2em",
              "margin-right": "5px",
              "min-width": "16em",
            });
            $content.append($select, $button);
            $fieldset.append($content);
          } else {
            $fieldset.hide();
            loginWith(data.company.opalLogin);
          }
          return;
        case "/pre-inscription/13": // Page de récapitulatif
          if (!data.company || !data.company.budgets) {
            return;
          }
          var numberFormat = new Intl.NumberFormat("fr").format;
          var dateFormat = new Intl.DateTimeFormat("fr").format;
          var $blockBudgetsTotal = $(".mig-panier-recap-budgettotal");
          var budgetForThisTraining = parseFloat(
            $(".mig-panier-recap-budget-ht-val")
              .text()
              .replace(/\s+/gim, "")
              .replace(/[,.]/, ".") || "0"
          );
          var budgetBalance =
            data.company.budgets.formations.totalBalance.amount;
          var budgetCredits =
            data.company.budgets.formations.totalCredits.amount;
          var budgetReserved =
            data.company.budgets.formations.totalReserved.amount;
          var budgetReservedLastUpdateAt =
            data.company.budgets.formations.totalReserved.lastUpdatedAt;
          var $blockBudgetsBalance = $(
            '<div class="mig-panier-recap-budget-ht">' +
              '<span class="mig-panier-recap-budget-txt">Votre budget restant de formation :</span>' +
              '<span class="mig-panier-recap-budget-montant-ht">' +
              '<span class="mig-panier-recap-budget-ht-val" style="color:' +
              (budgetForThisTraining > budgetBalance ? "#bb5026" : "#45990c") +
              '">' +
              numberFormat(budgetBalance) +
              " €</span>" +
              '<span class="mig-panier-recap-budget-ht-txt"> HT</span>' +
              "</span>" +
              "</div>"
          );
          var $blockBudgetsReserved =
            budgetReserved > 0
              ? $(
                  '<div class="mig-panier-recap-budget-ht" style="color:#868181;font-style:italic">' +
                    '<span class="mig-panier-recap-budget-txt">dont réservé au ' +
                    dateFormat(budgetReservedLastUpdateAt) +
                    " :</span>" +
                    '<span class="mig-panier-recap-budget-montant-ht">' +
                    '<span class="mig-panier-recap-budget-ht-val">' +
                    numberFormat(budgetReserved) +
                    " €</span>" +
                    '<span class="mig-panier-recap-budget-ht-txt"> HT</span>' +
                    "</span>" +
                    "</div>"
                )
              : "";
          var $blockBudgetsCredits = $(
            '<div class="mig-panier-recap-budget-ht">' +
              '<span class="mig-panier-recap-budget-montant-ht">' +
              '<span class="mig-panier-recap-budget-ht-val"> / ' +
              numberFormat(budgetCredits) +
              " €</span>" +
              '<span class="mig-panier-recap-budget-ht-txt"> total</span>' +
              "</span>" +
              "</div>"
          );
          $blockBudgetsTotal.append(
            $blockBudgetsBalance,
            $blockBudgetsReserved,
            $blockBudgetsCredits
          );
          return;
      }
      if (window.parent && window.parent.postMessage)
        window.parent.postMessage("gtm_scripts_loaded", "*");
    });

    //--- end
  }, []);
  return null;
}
