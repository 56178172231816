import { color, font, styled } from "../../util/style.js";

const TextArea = styled.textarea`
  border: 0;
  color: ${color("grey-text")};
  font: ${font("paragraph")};
  height: 120px;
  width: 100%;
  padding: 8px;
`;

export default function TextQuestion({ onChange, value }) {
  return (
    <>
      <TextArea
        onChange={(evt) => onChange && onChange(evt.nativeEvent.target.value)}
        value={value}
        maxLength={400}
      />
      {/* <Char>{String(value || "").length}/400 caractères</Char> */}
    </>
  );
}

// const Char = styled.div`
//   margin-top: 8px;
//   font-size: 12px;
//   font-weight: normal;
//   line-height: 12px;
// `;
