import PostSection from "../components/PostSection.jsx";
import header from "../img/actualites.jpg";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export default function PostDetails() {
  return (
    <LoggedInTemplate title="Actualités">
      <ImageHeader image={header} icon="megaphone-line" label="Actualités" />
      <MainContent>
        <Headline title="Toutes les actualités à ne pas manquer" />
        <PostSection />
      </MainContent>
    </LoggedInTemplate>
  );
}
