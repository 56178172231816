/* eslint-disable */
import { useLazyQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { createContext, useContext, useEffect, useState } from "react";

const SessionContext = createContext();

export function SessionProvider({ session: providedSession, ...props }) {
  const cacheSession =
    typeof window !== "undefined" ? window.__SESSION__ : null;
  const [version, setVersion] = useState(cacheSession ? 0 : 1);
  const [session, setSession] = useState(providedSession || cacheSession);

  return (
    <SessionContext.Provider
      value={{ version, setVersion, session, setSession }}
      {...props}
    />
  );
}

export function useSessionData() {
  const { session } = useContext(SessionContext);
  return session?.data;
}

export function useSessionDataUpdate() {
  const { setVersion } = useContext(SessionContext);
  return () => setVersion((version) => version + 1);
}

export function useUserSession() {
  const { session: currentSession, version, setSession } = useContext(
    SessionContext
  );

  // On récupère les éléments de session si aucune n'est en cache
  const [
    fetchSession,
    { loading: sessionLoading, error: sessionError, data },
  ] = useLazyQuery(
    gql`
      query {
        private {
          session {
            expiresIn
            data
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      errorPolicy: "all", // Masque les erreurs d'authentification console
      ssr: false, // Pas nécessaire côté serveur
    }
  );

  useEffect(() => {
    if (version > 0) fetchSession();
  }, [version]);

  // Si on a des résultats de session, on les récupère et on les stocke
  const session = data?.private?.session || currentSession;
  const hasUnauthenticatedError = sessionError?.graphQLErrors?.find(
    (err) => err?.extensions?.code === "UNAUTHENTICATED"
  );

  useEffect(
    function() {
      if (sessionLoading) return;
      if (hasUnauthenticatedError) return setSession(null);
      setSession(session);
    },
    [sessionLoading, hasUnauthenticatedError, session]
  );

  return [
    session?.data,
    hasUnauthenticatedError
      ? null
      : sessionError?.graphQLErrors && sessionError?.graphQLErrors.length
      ? sessionError?.graphQLErrors
      : null,
    { loading: !session && sessionLoading, sessionLoading },
  ];
}
