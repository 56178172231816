import { useCallback, useEffect } from "react";

import { usePollNavigation } from "../services/polls.js";
import { paths } from "../services/router.js";
import Container from "../templates/common/Container.jsx";
import { getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import ContentLoading from "./ContentLoading.jsx";
import HtmlText from "./HtmlText.jsx";
import Question from "./questions/Question.jsx";
import Steps from "./Steps.jsx";
import { Button, ButtonArrowAround } from "./typography.jsx";

const PanelImage = styled.div`
  ${theme("content-box")}
  border-bottom: 0;
  padding: 25px;
`;

export default styled(function Poll({
  className,
  poll,
  onSubmit,
  loading,
  result,
}) {
  const [
    {
      currentQuestion,
      currentIndex,
      canGoNext,
      canSend,
      errors,
      errorsSoFar,
      nextQuestion,
      previousQuestion,
      currentAnswer,
      totalQuestions,
      questionsEnabled,
      answers,
    },
    { start, previous, next, setAnswer, restart, close },
  ] = usePollNavigation(poll);

  const send = useCallback(
    function () {
      onSubmit && onSubmit(answers);
    },
    [onSubmit, answers]
  );

  useEffect(
    function () {
      if (!result) return;
      // À chaque envoi de sondage
      close();
    },
    [result]
  );

  const image =
    (currentQuestion && currentQuestion.image) || (poll && poll.image);
  return (
    <Container className={className}>
      {Boolean(loading) ? (
        <ContentLoading />
      ) : !currentQuestion ? (
        <>
          {/* INTRODUCTION */}
          <div className="PollSection">
            <div className="PollSection-image">
              {Boolean(image) && (
                <PanelImage>
                  <img
                    src={getMediaUrl(image, "challenge-image")}
                    alt={poll.title}
                  />
                </PanelImage>
              )}
            </div>
            <div className="PollSection-content">
              {Boolean(poll.attempted) && (
                <Alert>
                  Votre entreprise a déjà participé au sondage.{" "}
                  {!poll.multiple &&
                    "Une seule participation par entreprise est acceptée."}
                </Alert>
              )}

              <HtmlText html={poll.introduction} />

              {Boolean(poll.button && poll.button.label) && (
                <ButtonsList center>
                  <ButtonArrowAround
                    download={Boolean(poll.document)}
                    to={poll.button.url || getMediaUrl(poll.document)}
                  >
                    {poll.button.label}
                  </ButtonArrowAround>
                </ButtonsList>
              )}

              <ButtonsList>
                <Button ghost to={paths.challenges}>
                  Retour à la liste
                </Button>
                {(!poll.attempted || poll.multiple) && (
                  <Button onClick={start}>Commencer le sondage</Button>
                )}
              </ButtonsList>
            </div>
          </div>
        </>
      ) : result ? (
        <>
          {/* CONCLUSION */}
          <Steps barOnly max={totalQuestions} current={totalQuestions} />
          <div className="PollSection">
            <div className="PollSection-image">
              {Boolean(image) && (
                <PanelImage>
                  <img
                    src={getMediaUrl(image, "challenge-image")}
                    alt={poll.title}
                  />
                </PanelImage>
              )}
            </div>
            <div className="PollSection-content">
              <HtmlText html={poll.conclusion} />
              <ButtonsList>
                <Button to={paths.challenges}>Retour à la liste</Button>
              </ButtonsList>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* QUESTIONS */}
          <Steps
            barOnly
            max={totalQuestions}
            current={currentIndex}
            colors={questionsEnabled.reduce(
              (all, active, index) => ({
                ...all,
                [index]: active ? "" : "disabled",
              }),
              {}
            )}
          />
          <div className="PollSection">
            <div className="PollSection-image">
              {Boolean(image) && (
                <PanelImage>
                  <img
                    src={getMediaUrl(image, "challenge-image")}
                    alt={poll.title}
                  />
                </PanelImage>
              )}
            </div>
            <div className="PollSection-content">
              <Question
                index={currentIndex}
                total={totalQuestions}
                {...currentQuestion}
                value={currentAnswer}
                onChange={setAnswer}
              />
              <ButtonsList>
                {previousQuestion ? (
                  <Button ghost onClick={previous}>
                    Précédent
                  </Button>
                ) : (
                  <Button ghost onClick={restart}>
                    Recommencer
                  </Button>
                )}
                {nextQuestion ? (
                  <Button onClick={next} disabled={!canGoNext}>
                    Suivant
                  </Button>
                ) : (
                  <Button onClick={send} disabled={!canSend}>
                    Envoyer
                  </Button>
                )}
              </ButtonsList>
            </div>
          </div>
        </>
      )}
    </Container>
  );
})`
  .PollSection {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    grid-gap: 56px;

    &-image {
      width: 360px;
    }
    &-content {
      flex: 1;
    }
  }

  ${context("for-tablet-landscape")`
    grid-gap: 32px;
    .QuizSection {
      &-image {
        width: 120px;
      }
    }
  `}
`;

const ButtonsList = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  ${is("center")`
    justify-content: center
  `}
`;

const Alert = styled.div`
  ${font("small")}
  color: ${color("grey-text")};
  margin-bottom: 24px;
`;
