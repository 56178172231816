import { useCallback } from "react";
import { useState } from "react";
import Joyride from "react-joyride";
import Popup from "reactjs-popup";
import { keyframes } from "styled-components";

import { usePermissions } from "../../services/permissions.js";
import { color, context, styled, theme, z } from "../../util/style.js";
import GdprDataPopup from "../GdprDataPopup.jsx";
import Icon from "../Icon.jsx";
import WidgetAccount from "./WidgetAccount.jsx";
import WidgetDaikin from "./WidgetDaikin.jsx";
import WidgetRevenues from "./WidgetRevenues.jsx";
import WidgetUsers from "./WidgetUsers.jsx";

const WIDGETS = [
  {
    id: "account",
    label: "Mon compte",
    icon: "user-line",
    component: WidgetAccount,
  },
  {
    id: "revenues",
    label: "Mon chiffre d'affaires",
    icon: "money",
    component: WidgetRevenues,
  },
  {
    id: "daikin",
    label: "Mes contacts Daikin",
    icon: "daikin",
    component: WidgetDaikin,
  },
  {
    id: "users",
    label: "Mes utilisateurs",
    icon: "users-multiple",
    permission: "users:*",
    component: WidgetUsers,
  },
];

const Widget = styled(function ({
  icon,
  label,
  active,
  className,
  onOpen,
  onClose,
  children,
}) {
  return (
    <WidgetPopup
      modal
      open={Boolean(active)}
      onClose={onClose}
      onOpen={onOpen}
      lockScroll
      closeOnDocumentClick
      trigger={
        <button
          aria-label={label}
          type="button"
          className={className + (active ? " Widget--active" : "")}
        >
          <span className="Widget-iconBorder">
            <Icon
              name={icon}
              size={20}
              variant="white"
              className="Widget-icon"
            />
          </span>
        </button>
      }
    >
      <div className="Widget-content">{children}</div>
    </WidgetPopup>
  );
})`
  background: ${color("bg-dark")};
  display: block;
  padding: 8px;
  position: relative;
  transition: background 0.3s ease;
  &::before {
    ${color("gradient-blue-widget")}
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    width: 100%;
  }
  &.Widget--active,
  &:hover {
    &::before {
      transform: translateX(0);
      transition: transform 0.3s ease;
    }
  }
  .Widget {
    &-iconBorder {
      border: 2px solid white;
      border-radius: 50px;
      display: block;
      padding: 4px;
      position: relative;
      z-index: 9;
    }
    &-icon {
      display: block;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const WidgetPopup = styled(Popup)`
  &-overlay {
    background-color: ${color("overlay")};
    z-index: ${z("side-widgets-popup")} !important;
    animation: ${fadeIn} 0.3s;
    overflow-y: auto;
    padding: 40px 0;
    display: flex;
    justify-content: flex-end;
    ${context("for-tablet-landscape")`
      padding: 0;
    `}
  }

  &-content {
    margin: 0 0 auto 0 !important;

    .Widget-content {
      ${theme("big-border")};
      background-color: white;
      border-radius: ${theme("border-radius")} 0 0 ${theme("border-radius")};
      max-width: 635px;
      padding: 0 105px 0 56px;
      animation: ${fadeIn} 0.3s;
      position: relative;
      overflow: auto;
      width: 100vw;
    }
  }

  ${context("for-tablet-landscape")`
    &-content {
      top: 0 !important;
      .Widget-content {
        border: 0;
        border-radius: 0;
        margin: 0;
        max-width: none !important;
        min-height: 100%;
        padding: 12px 60px 12px 12px;
      }
    }
  `}
`;

export default styled(function SideWidgets({ className }) {
  const [open, setOpen] = useState(null);
  const [lastOpenedWidget, setLastOpenedWidget] = useState(null);
  const [openUpdateSelfData, setOpenUpdateSelfData] = useState(false);
  const [hasPermission] = usePermissions();
  const hideWidgetUsersTourGuide =
    typeof localStorage !== "undefined" &&
    localStorage.getItem("widget-users-tour-guide");
  const onCloseWidgetUsersTourGuide = useCallback(function () {
    localStorage.setItem("widget-users-tour-guide", "1");
  }, []);
  const close = useCallback(function () {
    setLastOpenedWidget(null);
    setOpen(null);
  }, []);
  const onUpdateSelfData = useCallback(function () {
    setOpen(null);
    setOpenUpdateSelfData(true);
  }, []);
  const onCloseUpdateSelfData = useCallback(
    function () {
      setOpen(lastOpenedWidget);
      setOpenUpdateSelfData(false);
    },
    [lastOpenedWidget]
  );

  const widgetsWithPermissions = WIDGETS.filter(
    (widget) => !widget.permission || hasPermission(widget.permission)
  );

  return (
    <>
      <aside className={className}>
        {widgetsWithPermissions.map((widget) => (
          <Widget
            icon={widget.icon}
            label={widget.label}
            key={widget.id}
            className={`widget-${widget.id}`}
            onOpen={() => {
              setOpen(widget.id);
              setLastOpenedWidget(widget.id);
            }}
            onClose={close}
            active={open === widget.id}
          >
            <widget.component
              close={close}
              widget={widget}
              onUpdateSelfData={onUpdateSelfData}
            />
          </Widget>
        ))}
      </aside>
      <GdprDataPopup
        open={openUpdateSelfData}
        onClose={onCloseUpdateSelfData}
        onSubmit={onCloseUpdateSelfData}
      />
      {!hideWidgetUsersTourGuide && (
        <Joyride
          steps={[
            {
              target: ".widget-users",
              title: "Nouveau !",
              placement: "left",
              locale: {
                open: "Ouvrir le guide",
                close: "J'ai compris !",
              },
              content: (
                <div>
                  Vous avez désormais accès à la gestion des comptes
                  utilisateurs depuis votre plateforme DAIKIN ELITE. Rendez-vous
                  sur ce volet afin de demander la{" "}
                  <strong>
                    création d'accès spécifiques pour vos collaborateurs
                  </strong>
                   : service formation, boutiques ou technique, selon votre
                  choix et jusqu'à 3 nouveaux comptes.
                  <br />
                  Pour toute question, contactez votre commercial Daikin.
                </div>
              ),
            },
          ]}
          callback={({ status }) =>
            status === "finished" && onCloseWidgetUsersTourGuide()
          }
        />
      )}
    </>
  );
})`
  border-radius: ${theme("border-radius")} 0 0 ${theme("border-radius")};
  margin: 40px 0;
  overflow: hidden;
  position: fixed;
  top: 180px;
  right: 0;
  z-index: ${z("side-widgets")};
  ${context("for-tablet-landscape")`
    top: 80px;
  `}
`;
