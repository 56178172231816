import { Headline, MainContent } from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { Paragraph } from "../components/typography.jsx";

import Container from "../templates/common/Container.jsx";

export default function Hotline() {
  return (
    <LoggedInTemplate title="Mentions légales">
      <MainContent>
        <Headline title="Mentions légales" />
        <Container style={{ maxWidth: 660 }}>
          <Paragraph justify>
            Le présent site Internet www.daikinelite.fr (ci-après « le Site »)
            est édité par DAIKIN AIRCONDITIONING (ci-après DAIKIN
            AIRCONDITIONING), immatriculée au répertoire SIRENE sous le numéro
            967501065, ayant son siège social Z.A. du Petit Nanterre - 31, rue
            des Hautes Pâtures - Le Narval - Bât. B - 92737 Nanterre Cedex,
            France.
            <dl>
              <dt>Représenté par :</dt>
              <dd>Christophe MUTZ</dd>
              <dt>Téléphone :</dt> <dd>01 46 69 95 69</dd>
              <dt>Email :</dt> <dd>contact@daikin.fr</dd>
              <dt>N° TVA (CEE) :</dt> <dd>FR46967501065</dd>
              <dt>SIRET :</dt> <dd>967501 065 00338</dd>
              <dt>Directeur de la publication :</dt> <dd>Philipe GARYGA</dd>
            </dl>
          </Paragraph>

          <Paragraph justify>
            Le Site est hébergé par OVH, société par actions simplifiée
            immatriculée au RCS Lille Métropole sous le numéro B 424 761 419,
            ayant son siège social : 2 rue Kellermann - 59100 Roubaix, France.
            <dl>
              <dt>Site internet :</dt> <dd>www.ovh.com</dd>
              <dt>Téléphone :</dt> <dd>08 99 70 17 61</dd>
              <dt>Email :</dt> <dd>contact@groupe-manitoba.com</dd>
            </dl>
          </Paragraph>

          <Paragraph justify>
            Création réalisée par MANITOBA-PRAXIS COMMUNICATION, société par
            actions simplifiée immatriculée au RCS Nanterre sous le numéro 380
            899 377, ayant son siège social : 41, rue Périer - 92120 Montrouge,
            France.
            <dl>
              <dt>Site internet :</dt> <dd>www.manitoba.fr</dd>
              <dt>Téléphone :</dt> <dd>01 46 21 22 66</dd>
              <dt>Email :</dt> <dd>contact@groupe-manitoba.com</dd>
            </dl>
          </Paragraph>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
