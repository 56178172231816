import { SimpleFooter } from "./common/Footer.jsx";
import { HeaderTop } from "./common/Header.jsx";
import { Helmet } from "react-helmet";

import ScrollToTop from "../components/ScrollToTop.jsx";
import backgroundLogin from "../img/login_bg.jpg";
import Container from "./common/Container.jsx";
import { Title } from "../components/typography.jsx";
import { color, context, font, styled, theme } from "../util/style.js";

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background-image: url(${backgroundLogin});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Main = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Box = styled.div`
  ${theme("content-box")}
  margin: auto;
  max-width: 640px;
  width: 100%;
  padding: 24px 48px;
  text-align: center;

  ${context("for-tablet-portrait")`
    padding: 16px;
  `}

  ${Title} {
    ${font("headline4")}
    color : ${color("primary")};
    margin: 0;
  }
`;

export default function LoggedOutTemplate({ title, children }) {
  return (
    <>
      <ScrollToTop />
      {Boolean(title) && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <FullHeight>
        <HeaderTop />
        <Main>{children}</Main>
        <Container>
          <SimpleFooter />
        </Container>
      </FullHeight>
    </>
  );
}
