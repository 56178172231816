import { formatRevenue } from "../../../common/currency.js";
import { context, styled } from "../../util/style.js";
import { SVG_STEPS, getWidth } from "./data.js";

// Pourcentage de la jauge a partir duquel le texte devient blanc
const activeSteps = [114, 313, 505];

export default styled(function({ revenue, className, width }) {
  const jaugeWidth = getWidth(revenue / 1000);

  return (
    <svg
      version="1.1"
      id="GraphiqueCA"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 535 292"
      className={className}
      width={width}
      xmlSpace="preserve"
    >
      {/* <!-- MASK DE LA GAUGE  --> */}
      <defs>
        <clipPath id="gauge-mask">
          <rect
            x="0"
            y="125"
            fill="red"
            width="535"
            height="60"
            rx="30"
            ry="30"
          />
        </clipPath>
      </defs>
      {/* <!-- MASK DE LA GAUGE  --> */}

      {/* <!-- GAUGE POURCENTAGE  --> */}
      <rect
        id="gauge-rectangle"
        x="0"
        y="125"
        className="gauge-bg-color"
        width={jaugeWidth}
        height="60"
        rx="30"
        ry="30"
        clipPath="url(#gauge-mask)"
      />
      {/* <!-- /GAUGE POURCENTAGE  --> */}

      {/* <!-- BORD DE LA GAUGE  --> */}
      <path
        id="gauge-contour"
        fill="#707070"
        d="M505.9995117,186.3271332H29c-15.9907227,0-29-13.0097656-29-29v-4.8339844
	c0-15.9907227,13.0092773-29,29-29h476.9995117c15.9902344,0,29,13.0092773,29,29v4.8339844
	C534.9995117,173.3173676,521.9897461,186.3271332,505.9995117,186.3271332z M29,126.4931488c-14.3364258,0-26,11.6635742-26,26
	v4.8339844c0,14.3369141,11.6635742,26,26,26h476.9995117c14.3369141,0,26-11.6630859,26-26v-4.8339844
	c0-14.3364258-11.6630859-26-26-26H29z"
      />
      {/* <!-- /BORD DE LA GAUGE  --> */}

      {/* <!-- GAUGE : PALIER 1  --> */}
      <text dx="46" dy="114" className="titre-palier" textAnchor="start">
        PALIER 1
      </text>
      <text
        dx="23"
        dy="152"
        className={
          jaugeWidth > activeSteps[0]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="23"
        dy="167"
        className={
          jaugeWidth > activeSteps[0]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        2€ CADEAUX
      </text>
      {/* <!-- /GAUGE : PALIER 1  --> */}

      {/* <!-- GAUGE : PALIER 2  --> */}
      <text dx="245" dy="114" className="titre-palier" textAnchor="start">
        PALIER 2
      </text>
      <text
        dx="222"
        dy="152"
        className={
          jaugeWidth > activeSteps[1]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="222"
        dy="167"
        className={
          jaugeWidth > activeSteps[1]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        3€ CADEAUX
      </text>
      {/* <!-- /GAUGE : PALIER 2  --> */}

      {/* Regroupement 1 */}
      <svg x="10">
        <rect
          id="step1-ca-line"
          x="158"
          y="83"
          className="line-color"
          width="1"
          height="30"
        />

        <rect
          id="step1-cadeau-line"
          x="158"
          y="195"
          className="line-color"
          width="1"
          height="30"
        />

        {/* <!-- GAUGE : CERCLE FIN DE PALIER 1  --> */}
        <ellipse
          id="step1-gauge-cercle"
          fill="#575257"
          cx="158"
          cy="154"
          rx="18"
          ry="18"
        />
        <path
          id="step1-gauge-cercle-fleche"
          fill="#DAC783"
          d="M157.6159668,146.4464569
	c0.6062622,0.5689697,6.6150513,6.4673462,6.6150513,6.4673462c0.6392212,0.5712891,0.6638794,1.5206909,0.0551147,2.1206055
	c-0.0179443,0.0177002-0.0363159,0.0349731-0.0551147,0.0517578c0,0-6.0087891,5.9529419-6.6150513,6.4672852
	c-0.6630249,0.5953979-1.7080688,0.5953979-2.3710938,0c-0.6395264-0.5368042-0.6942139-1.4586182-0.1221313-2.0587158
	c0.0384521-0.0402832,0.0792236-0.0785522,0.1221313-0.114563l5.5116577-5.380188l-5.5116577-5.380127
	c-0.6395264-0.5368652-0.6942139-1.4586182-0.1221313-2.0587158c0.0384521-0.0403442,0.0792236-0.0785522,0.1221313-0.114624
	C155.9078979,145.851181,156.9529419,145.85112,157.6159668,146.4464569z"
        />
        {/* <!-- /GAUGE : CERCLE FIN DE PALIER 1  --> */}
        {/* <!-- CA PALIER 1  --> */}
        <text dx="170" dy="35" className="ca-valeur" textAnchor="end">
          {formatRevenue(SVG_STEPS[0].max, true, false)}
        </text>
        <text dx="170" dy="51" className="ca-label" textAnchor="end">
          CA
        </text>
        <path
          id="step1-ca-cercle"
          fill="#313131"
          d="M156.6937256,71.9999847c-6.6104736,0-13.1079102-1.8282471-18.8729248-5.3845215
	c-16.9090576-10.4310303-22.1685791-32.6556396-11.7248535-49.5428467
	c10.4442139-16.8892822,32.6967773-22.140625,49.605835-11.7102051c4.0791016,2.5164795,7.5631104,5.7657475,10.3566895,9.6584473
	c0.6481934,0.902832,0.4406738,2.15979-0.4637451,2.8071289c-0.9040527,0.6464844-2.1630859,0.4396973-2.810791-0.4631348
	c-2.4812012-3.4575195-5.5761719-6.3438721-9.1993408-8.5787354C166.3092041,4.29808,157.7210693,2.9079435,149.397583,4.872787
	c-8.3218994,1.9642334-15.3800049,7.0476079-19.8736572,14.3138428
	c-9.2766113,14.9996338-4.6048584,34.7403564,10.4136963,44.005249c7.2758789,4.4890137,15.8658447,5.8796387,24.1873779,3.9133301
	c8.3220215-1.9642334,15.3795166-7.0476074,19.873291-14.3139648c0.5847168-0.9449463,1.8275146-1.237793,2.7723389-0.6546631
	c0.9466553,0.5839844,1.2403564,1.8237305,0.6555176,2.7687988c-5.0592041,8.1809082-13.0050049,13.9042969-22.3745117,16.1160889
	C162.2772217,71.6762543,159.4748535,71.9999847,156.6937256,71.9999847z"
        />
        <path
          id="step1-ca-picto-euro"
          fill="#313131"
          d="M200.0770264,24.3535004
	c2.8334961-0.0013428,5.5759277,1.0119629,7.7414551,2.8601074l0.9085693,0.7773438v-4.2902832l-0.2481689-0.1660156
	c-7.0842285-4.6906738-16.5869141-2.6849365-21.2248535,4.4801025c-0.961792,1.4859619-1.6640625,3.1279297-2.0761719,4.8544922
	h-4.6312256l-0.8195801,2.2336426h5.0905762c-0.0400391,0.4615479-0.0640869,0.927124-0.0640869,1.3967285
	s0.0229492,0.9342041,0.0640869,1.3956299h-2.802002l-0.8195801,2.2337646h3.9818115
	c1.9869385,8.324707,10.2702637,13.4440918,18.5012207,11.4344482c1.7070312-0.4168701,3.3305664-1.1271973,4.7998047-2.0999756
	l0.2481689-0.164917V45.007309l-0.9085693,0.7772217c-5.076416,4.3242188-12.6575928,3.6674805-16.9331055-1.4666748
	c-1.0240479-1.2298584-1.7967529-2.6529541-2.2731934-4.1868896h10.8475342l0.8195801-2.2336426h-12.1274414
	c-0.1081543-0.9276123-0.1081543-1.8648682,0-2.7923584h13.1611328l0.819458-2.2337646h-13.5202637
	c1.5732422-5.065918,6.2147217-8.5135498,11.4648438-8.5157471V24.3535004z"
        />
        {/* <!-- /CA PALIER 1  --> */}
        {/* <!-- CADEAU PALIER 1  --> */}
        <text dx="194" dy="258" className="cadeau-txt" textAnchor="start">
          Bonus palier 1
        </text>
        <text dx="194" dy="276" className="cadeau-txt" textAnchor="start">
          +90€ cadeaux
        </text>
        <path
          id="step1-cadeau-cercle"
          fill="#313131"
          d="M157.2268066,291.0000305
	c-15.7147217,0-28.4997559-12.7851562-28.4997559-28.4995117c0-15.7154694,12.7850342-28.5005035,28.4997559-28.5005035
	c15.71521,0,28.5002441,12.7850342,28.5002441,28.5005035C185.7270508,278.2148743,172.9420166,291.0000305,157.2268066,291.0000305
	z M157.2268066,236.0124664c-14.6053467,0-26.4873047,11.8820801-26.4873047,26.4880524
	c0,14.6049805,11.881958,26.4870605,26.4873047,26.4870605s26.487793-11.8820801,26.487793-26.4870605
	C183.7145996,247.8945465,171.8321533,236.0124664,157.2268066,236.0124664z"
        />
        <path
          id="step1-cadeau-picto"
          fill="#313131"
          d="M171.7186279,257.1474915h-8.4453125
	c1.5393066-0.5262451,3.0634766-1.1179199,4.5670166-1.7812653c1.8543701-0.9588623,2.84021-3.3432617,2.2937012-5.5484619
	c-0.5928955-2.3902588-2.7073975-3.817749-5.6558838-3.817749c-4.0073242,0-5.6523438,3.9123535-6.3277588,7.1712646
	c-0.3924561-0.1098633-0.7983398-0.1875-1.2259521-0.1875c-0.4272461,0-0.8328857,0.0773926-1.2250977,0.1872559
	c-0.6756592-3.2589111-2.3209229-7.1710205-6.3289795-7.1710205c-2.9486084,0-5.0631104,1.4265137-5.6553955,3.8167725
	c-0.5471191,2.2042236,0.4396973,4.5905762,2.3516846,5.5767822c1.4715576,0.6474762,2.9786377,1.2315826,4.5079346,1.7539215
	h-7.8391113c-0.5568848,0-1.0084229,0.4462891-1.0084229,0.9978027v5.2142334c0,0.5515137,0.4515381,0.9978027,1.0084229,0.9978027
	h1.6264648v14.6439209c0,0.5515137,0.4515381,0.9978027,1.0085449,0.9978027h11.5490723
	c0.001709,0,0.0031738,0.0009766,0.0048828,0.0009766s0.0031738-0.0009766,0.0048828-0.0009766h12.1542969
	c0.5570068,0,1.0085449-0.4462891,1.0085449-0.9978027v-14.6439209h1.6264648c0.5568848,0,1.0084229-0.4462891,1.0084229-0.9978027
	v-5.2142334C172.7270508,257.5937805,172.2755127,257.1474915,171.7186279,257.1474915z M164.4781494,247.9956207
	c2.7205811,0,3.4842529,1.4401855,3.6970215,2.2977295c0.326416,1.3164062-0.230957,2.767334-1.211792,3.276001
	c-1.8986816,0.8363037-3.8592529,1.5615234-5.8516846,2.1785889c-0.2481689-0.5872803-0.6243896-1.1044922-1.0860596-1.5344238
	C160.4989014,251.4704742,161.6535645,247.9956207,164.4781494,247.9956207z M156.9244385,254.9793854
	c0.3214111,0,0.6260986,0.0657959,0.9091797,0.1749268c-0.0585938,0.5057373-0.0950928,0.9547272-0.1173096,1.3217926h-1.5826416
	c-0.0223389-0.3670654-0.0588379-0.8161774-0.1174316-1.3221588
	C156.2990723,255.0450592,156.6033936,254.9793854,156.9244385,254.9793854z M145.6737061,250.2923737
	c0.2127686-0.8575439,0.9755859-2.2967529,3.6966553-2.2967529c2.8251953,0,3.9801025,3.4748535,4.4536133,6.2174072
	c-0.461792,0.4299316-0.8382568,0.9468994-1.0865479,1.5341797c-1.9875488-0.6147461-3.9354248-1.3337402-5.7937012-2.1505127
	C145.9051514,253.0597076,145.34729,251.6087799,145.6737061,250.2923737z M143.7440186,259.143219h12.1719971v3.2185059
	h-10.5455322h-1.6264648V259.143219z M146.3789062,264.3573303h9.5371094v13.6459961h-9.5371094V264.3573303z
	 M168.0751953,278.0033264h-10.1422119v-13.6459961h10.1422119V278.0033264z M170.710083,262.3617249h-1.6264648h-11.1506348
	v-3.2185059h12.7770996V262.3617249z"
        />
        {/* <!-- /CADEAU PALIER 1  --> */}
      </svg>
      {/* Regroupement 1 */}

      {/* Regroupement 2 */}
      <svg x="-10">
        {/* <!-- GAUGE : CERCLE FIN DE PALIER 2  --> */}
        <circle
          id="step2-gauge-cercle"
          fill="#575257"
          cx="363"
          cy="154"
          r="18"
        />
        <path
          id="step2-gauge-cercle-fleche"
          fill="#DAC783"
          d="M361.2927246,147.0079193
	c0.5830078,0.5830078,6.361084,6.6270142,6.361084,6.6270142c0.614624,0.5853882,0.6384277,1.5582886,0.0529785,2.1729736
	c-0.0172119,0.0181274-0.0349121,0.0358276-0.0529785,0.0530396c0,0-5.7780762,6.0999756-6.361084,6.6270142
	c-0.6375732,0.6100464-1.6424561,0.6100464-2.2800293,0c-0.6149902-0.5501099-0.6674805-1.4946289-0.1174316-2.1095581
	c0.0369873-0.0413208,0.0761719-0.0805054,0.1174316-0.1174316l5.3000488-5.5130005l-5.3000488-5.5130005
	c-0.6149902-0.5501099-0.6674805-1.4946289-0.1174316-2.1095581c0.0369873-0.0413208,0.0761719-0.0805054,0.1174316-0.1174316
	C359.6502686,146.397934,360.6551514,146.3978729,361.2927246,147.0079193z"
        />
        {/* <!-- /GAUGE : CERCLE FIN DE PALIER 2  --> */}
        {/* <!-- CA PALIER 2  --> */}
        <text dx="375" dy="35" className="ca-valeur" textAnchor="end">
          {formatRevenue(SVG_STEPS[1].max, true, false)}
        </text>
        <text dx="375" dy="52" className="ca-label" textAnchor="end">
          CA
        </text>
        <path
          id="step2-ca-cercle"
          fill="#313131"
          d="M360.6333008,72.1137543c-6.5625,0-13.0136719-1.8173828-18.7373047-5.3525391
	c-16.7871094-10.3691406-22.0087891-32.4624023-11.6396484-49.2490234
	c10.3681641-16.7866211,32.4638672-22.0092773,49.2490234-11.640625c4.0507812,2.5024419,7.5097656,5.7324224,10.2822266,9.6010742
	c0.6435547,0.8979492,0.4365234,2.1474609-0.4609375,2.7910156c-0.8984375,0.6416016-2.1484375,0.4360352-2.7910156-0.4609375
	c-2.4619141-3.4365234-5.5341797-6.3056641-9.1318359-8.527832c-14.9111328-9.2099609-34.5341797-4.5708013-43.7451172,10.3393555
	c-9.2089844,14.9101562-4.5712891,34.5336914,10.3388672,43.7436523c7.2236328,4.4624023,15.7519531,5.8452148,24.0136719,3.8901367
	c8.2626953-1.9526367,15.2695312-7.0058594,19.7314453-14.2290039c0.5800781-0.9389648,1.8115234-1.2319336,2.7519531-0.6508789
	c0.9404297,0.5805664,1.2314453,1.8129883,0.6503906,2.7524414c-5.0224609,8.1323242-12.9111328,13.8217773-22.2128906,16.0205078
	C366.1772461,71.7919769,363.3950195,72.1137543,360.6333008,72.1137543z"
        />
        <path
          id="step2-ca-picto-euro"
          fill="#313131"
          d="M404.1877441,25.2169037
	c2.831543-0.0012207,5.5720215,0.9998779,7.7359619,2.8260498l0.9080811,0.7679443v-4.2390137l-0.2480469-0.1639404
	c-7.0793457-4.6346436-16.5755615-2.652832-21.2102051,4.4265137c-0.9611816,1.4681396-1.6629639,3.0905762-2.0748291,4.7965088
	h-4.6279297l-0.8190918,2.2069092h5.0870361c-0.0400391,0.4560547-0.0639648,0.9160156-0.0639648,1.3800049
	c0,0.4641113,0.0229492,0.9230957,0.0639648,1.3790283h-2.8000488l-0.8189697,2.2070312h3.9790039
	c1.9857178,8.2252197,10.2633057,13.2834473,18.4885254,11.2978516c1.7059326-0.4118652,3.3282471-1.1136475,4.7965088-2.0748291
	l0.2480469-0.1630859v-4.2399902l-0.9080811,0.7680664c-5.072876,4.2724609-12.6488037,3.6236572-16.9212646-1.4492188
	c-1.0234375-1.2150879-1.7955322-2.6212158-2.2717285-4.1368408h10.8399658l0.8190918-2.2070312H392.270752
	c-0.1081543-0.9163818-0.1081543-1.8424072,0-2.7589111h13.1519775l0.8189697-2.2070312h-13.5109863
	c1.5721436-5.0054932,6.2104492-8.4118652,11.4570312-8.4140625V25.2169037z"
        />
        {/* <!-- /CA PALIER 2  --> */}

        <rect
          id="step2-ca-line"
          x="363"
          y="83"
          className="line-color"
          width="1"
          height="30"
        />

        {/* <!-- CADEAU PALIER 2  --> */}
        <text dx="400" dy="258" className="cadeau-txt" textAnchor="start">
          Bonus palier 2
        </text>
        <text dx="400" dy="276" className="cadeau-txt" textAnchor="start">
          +250€ cadeaux
        </text>
        <path
          id="step2-cadeau-cercle"
          fill="#313131"
          d="M363.3745117,291.1406555
	c-15.6181641,0-28.3242188-12.7060547-28.3242188-28.3232422c0-15.6181793,12.7060547-28.324234,28.3242188-28.324234
	s28.3242188,12.7060547,28.3242188,28.324234C391.6987305,278.4346008,378.9926758,291.1406555,363.3745117,291.1406555z
	 M363.3745117,236.4931793c-14.515625,0-26.3242188,11.8085938-26.3242188,26.324234
	c0,14.5146484,11.8085938,26.3232422,26.3242188,26.3232422s26.3242188-11.8085938,26.3242188-26.3232422
	C389.6987305,248.3017731,377.8901367,236.4931793,363.3745117,236.4931793z"
        />
        <path
          id="step2-cadeau-picto"
          fill="#313131"
          d="M377.4985352,257.8945618h-8.3742676
	c1.5264893-0.5274658,3.0375977-1.1203613,4.5285645-1.7851562c1.8388672-0.9609528,2.8164062-3.3506012,2.2744141-5.5605621
	c-0.5878906-2.3955078-2.6845703-3.8261719-5.6083984-3.8261719c-3.973877,0-5.6049805,3.9208984-6.2746582,7.1870117
	c-0.3892822-0.1101074-0.791626-0.1879883-1.2155762-0.1879883c-0.4237061,0-0.8258057,0.0776367-1.2147217,0.1876221
	c-0.6699219-3.2659912-2.3013916-7.1866455-6.2764893-7.1866455c-2.9228516,0-5.0195312,1.4296875-5.6074219,3.8242188
	c-0.5419922,2.2099609,0.4365234,4.6015625,2.3330078,5.5888824c1.4584961,0.6494141,2.9526367,1.2351074,4.4692383,1.7587891
	h-7.7739258c-0.5527344,0-1,0.4472656-1,1v5.2255859c0,0.5527344,0.4472656,1,1,1h1.6132812v14.6757812c0,0.5527344,0.4472656,1,1,1
	h11.4521484c0.001709,0,0.0031738,0.0009766,0.0048828,0.0009766s0.0031738-0.0009766,0.0048828-0.0009766h12.0527344
	c0.5527344,0,1-0.4472656,1-1v-14.6757812h1.6123047c0.5527344,0,1-0.4472656,1-1v-5.2255859
	C378.4985352,258.3418274,378.0512695,257.8945618,377.4985352,257.8945618z M370.3188477,248.7226715
	c2.6982422,0,3.4550781,1.4433594,3.6660156,2.3027344c0.3242188,1.3193359-0.2285156,2.7734375-1.2011719,3.2832031
	c-1.8830566,0.8381348-3.8272705,1.5649414-5.8029785,2.1833649c-0.2460938-0.5886383-0.6191406-1.1067047-1.0769043-1.5376129
	C366.373291,252.2053375,367.5179443,248.7226715,370.3188477,248.7226715z M362.8286133,255.7216949
	c0.3188477,0,0.6208496,0.065918,0.9013672,0.1751709c-0.0579834,0.5069733-0.0942383,0.9569244-0.1162109,1.3248444h-1.5693359
	c-0.0222168-0.3679199-0.0581055-0.8179932-0.116333-1.3252106
	C362.208374,255.7874908,362.5101318,255.7216949,362.8286133,255.7216949z M351.6723633,251.0234528
	c0.2109375-0.859375,0.9677734-2.3007812,3.6650391-2.3007812c2.8015137,0,3.9470215,3.4820557,4.416748,6.230957
	c-0.4580078,0.4310303-0.8314209,0.9494629-1.0776367,1.5382233c-1.9709473-0.6167145-3.9020996-1.3372955-5.7443848-2.155899
	C351.9018555,253.7978668,351.3491211,252.3437653,351.6723633,251.0234528z M349.7583008,259.8945618h12.0703125v3.2255859
	H351.371582h-1.6132812V259.8945618z M352.371582,265.1201477h9.4570312v13.6757812h-9.4570312V265.1201477z
	 M373.8862305,278.795929h-10.0576172v-13.6757812h10.0576172V278.795929z M376.4985352,263.1201477h-1.6123047h-11.0576172
	v-3.2255859h12.6699219V263.1201477z"
        />
        {/* <!-- /CADEAU PALIER 2  --> */}

        <rect
          id="step-2-cadeau-line"
          x="363"
          y="196"
          className="line-color"
          width="1"
          height="30"
        />
      </svg>
      {/* Regroupement 2 */}

      {/* <!-- GAUGE : PALIER 3  --> */}
      <text dx="435" dy="114" className="titre-palier" textAnchor="start">
        PALIER 3
      </text>
      <text
        dx="413"
        dy="152"
        className={
          jaugeWidth > activeSteps[2]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="413"
        dy="167"
        className={
          jaugeWidth > activeSteps[2]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        4€ CADEAUX
      </text>
      {/* <!-- /GAUGE : PALIER 3  --> */}
    </svg>
  );
})`
  display: block;
  ${context("for-tablet-landscape")`
    display: none;
  `}

  .titre-palier {
    fill: #575257;
    font-weight: 500;
    font-size: 12px;
  }

  .ca-valeur {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 15px;
  }

  .ca-label {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 13px;
  }

  .cadeau-txt {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 12px;
  }

  .gauge-ca-txt-valeur {
    fill: #575257;
    font-weight: 600;
    font-size: 14px;
  }

  .gauge-ca-txt-valeur-active {
    fill: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }

  .gauge-ca-txt-cadeau {
    fill: #575257;
    font-weight: 700;
    font-size: 14px;
  }

  .gauge-ca-txt-cadeau-active {
    fill: #ffffff;
    font-weight: 700;
    font-size: 14px;
  }

  .gauge-bg-color {
    fill: #0d97e2;
  }

  .line-color {
    fill: #575257;
  }
`;
