import { Redirect } from "react-router-dom";

import { Button, Paragraph, Title } from "../components/typography.jsx";
import { useSessionData } from "../services/auth.js";
import { paths } from "../services/router.js";
import LoggedOutTemplate, { Box } from "../templates/LoggedOutTemplate.jsx";

export default function Login() {
  const sessionData = useSessionData();

  if (Boolean(sessionData)) return <Redirect to={paths.home} />;

  return (
    <LoggedOutTemplate title="Connexion">
      <Box>
        <Title>Bienvenue dans le programme DAIKIN&nbsp;ELITE</Title>
        <Paragraph>
          Connectez-vous avec vos identifiants extranet Daikin
        </Paragraph>
        <Button as="a" href={paths.ssoLogin} rel="nofollow">
          Se connecter
        </Button>
      </Box>
    </LoggedOutTemplate>
  );
}
