import _get from "lodash/get";
import { forwardRef, useCallback } from "react";
import Select from "react-select";

import {
  color,
  css,
  eq,
  font,
  ifelse,
  is,
  prop,
  styled,
  theme,
} from "../util/style.js";
import ContentLoading from "./ContentLoading.jsx";
import Icon from "./Icon.jsx";
import { Button } from "./typography.jsx";

export * from "./base-forms/forms.js";

const input = css`
  background-color: ${color("input")};
  ${is("white")`
    background-color: white;
  `}
  border: 1px solid transparent;
  color: ${color("input-text")};
  height: 40px;
  min-width: 0;
  width: 100%;
  padding: 8px;
  outline: 0;
  transition: border-color 0.3s ease;
  &::placeholder {
    color: ${color("text-dark")};
    font-weight: ${theme("font-weight-semi")};
    opacity: 0.4;
  }

  ${ifelse("disabled")`
    opacity: 0.3;
  ``
    &:hover,
    &:focus {
      border-color: ${color("input-focus")};
    }
  `}

  ${is("error")`
    border-color: ${color("input-error")} !important;
  `}
`;

const BaseInput = styled.input`
  ${input}
`;

const FormLoading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContent = styled.div`
  ${is("$loading")`
    opacity: 0.6;
    pointer-events: none;
  `}
`;

export const Form = styled(
  forwardRef(function Form(
    { loading, error, errors, errorName = "_global", children, ...props },
    ref
  ) {
    const errorMessage =
      error ||
      (errorName && errors ? _get(errors, errorName)?.message : null) ||
      null;

    return (
      <form ref={ref} {...props}>
        <FormContent $loading={loading}>{children}</FormContent>
        {!!loading && (
          <FormLoading>
            <ContentLoading />
          </FormLoading>
        )}
        {Boolean(errorMessage) && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </form>
    );
  })
)`
  position: relative;
`;

export const FormButton = styled(
  forwardRef(function FormButton({ className, ...props }, ref) {
    return (
      <div className={className}>
        <Button {...props} ref={ref} />
      </div>
    );
  })
)`
  display: inline-block;
  ${is("loading")`
    opacity: 0.7;
  `}
`;

const Label = styled.label`
  display: block;
  font-weight: ${theme("font-weight-bold")};
  margin-bottom: 6px;
`;

const Required = styled.label``;

const ErrorMessage = styled.p`
  ${font("small")}
  color: ${color("input-error")};
  display: block;
  margin: 0;
  padding: 0;
  margin-top: 4px;
`;

const InfoMessage = styled.p`
  ${font("small")}
  color: #666666;
  display: block;
  margin: 0;
  padding: 0;
  margin-top: 4px;
`;

export const FormLabel = styled(Label)``;

export const FormField = styled(function FormField({
  label,
  name,
  required,
  layout,
  error,
  errors,
  info,
  children,
  className,
  ...props
}) {
  const errorMessage =
    error || (name && errors ? _get(errors, name)?.message : null) || null;

  return (
    <div className={className} key={name}>
      <div className="FormField-field">
        {!!label && (
          <Label htmlFor={name}>
            {label}
            {!!required && <Required>*</Required>}
          </Label>
        )}
        <div className="FormField-input">
          {typeof children === "function"
            ? children({
                name,
                error: Boolean(errorMessage),
                required,
                ...props,
              })
            : children}
        </div>
      </div>
      <div className="FormField-message">
        {Boolean(errorMessage) ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : (
          Boolean(info) && <InfoMessage>{info}</InfoMessage>
        )}
      </div>
    </div>
  );
})`
  margin-bottom: 25px;
  position: relative;
  text-align: left;
  width: 100%;

  & &:not(:first-child) {
    margin-top: 8px;
  }

  ${is("layout")`
    & > .FormField-field  > .FormField-input {
      display: flex;
      flex-wrap: wrap;
      ${eq("layout", "column")`
        flex-direction: column;
      `}
      grid-column-gap: 24px;
      grid-row-gap: 12px;
    }
  `}
  .FormField-message {
    position: absolute;
  }
`;

export const FormInput = styled(BaseInput)``;

export const FormInputAppend = styled.span`
  display: inline-block;
  background-color: ${color("input")};
  ${is("white")`
    background-color: white;
  `}
  color: ${color("input-text")};
  height: 40px;
  min-width: 0;
  padding: 0 8px;
  line-height: 40px;
  vertical-align: bottom;
`;

export const FormTextarea = styled(FormInput).attrs({ as: "textarea" })`
  width: 100%;
  min-height: 160px;
  height: auto;
  outline: 0;
  display: block;
`;

export const FormUpload = styled(
  forwardRef(function FormUpload(
    {
      error,
      className,
      name,
      onChange,
      onBlur,
      value,
      placeholder,
      image,
      ...props
    },
    ref
  ) {
    const imageUrl = image && value ? URL.createObjectURL(value) : null;
    return (
      <label className={className} htmlFor={name}>
        <input
          type="file"
          onChange={(e) => onChange(e.target.files?.[0])}
          onBlur={onBlur}
          accept={image ? "image/*" : ""}
          {...props}
          ref={ref}
          id={name}
          name={name}
        />
        {imageUrl ? (
          <div
            className="BackgroundImage"
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          ></div>
        ) : (
          <Content>{value ? value.name : placeholder}</Content>
        )}
      </label>
    );
  })
)`
  cursor: pointer;
  display: inline-block;
  ${input}

  input {
    position: absolute;
    left: -9999px;
  }
  ${is("error")`
    border-color: ${color("input-error")};
  `}

  .BackgroundImage {
    flex: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
  }
`;

export const Checkbox = styled(
  forwardRef(function Checkbox({ error, white, className, ...props }, ref) {
    return (
      <div className={className}>
        <input type="checkbox" ref={ref} {...props} />
        <div className="fake">
          <Icon name="check" variant="white" size={16} />
        </div>
      </div>
    );
  })
)`
  input {
    position: absolute;
    left: -9999px;
  }
  .fake {
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    background-color: ${color("input")};
    ${is("white")`
      background-color: white;
    `}
    transition: all 0.3s ease;
    position: relative;

    ${Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input:focus + .fake {
    border-color: ${color("input-focus")};
  }

  ${is("error")`
    .fake {
      border-color: ${color("input-error")};
    }
  `}

  input:checked + .fake {
    background-color: ${color("input-focus")};
    ${Icon} {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  line-height: 1.4;
  margin-left: 8px;
  margin-right: 0;
  text-align: left;
`;

export const FormCheckbox = styled(
  forwardRef(function FormCheckbox(
    { className, name, children, ...props },
    ref
  ) {
    return (
      <label className={className} htmlFor={name}>
        <Checkbox {...props} ref={ref} id={name} name={name} />
        <Content>{children}</Content>
      </label>
    );
  })
)`
  display: flex;
  cursor: pointer;

  &:hover,
  &:focus {
    ${Checkbox} .fake {
      border-color: ${color("input-focus")};
    }
  }
`;

export const FormCheckboxes = styled(
  forwardRef(function FormCheckboxes(
    { className, options, name, ...props },
    ref
  ) {
    return (
      <>
        {options.map(({ value, label }) => (
          <label className={className} htmlFor={`${name}-${value}`} key={value}>
            <Checkbox
              {...props}
              ref={ref}
              id={`${name}-${value}`}
              name={name}
              value={value}
            />
            <Content>{label}</Content>
          </label>
        ))}
      </>
    );
  })
)`
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover,
  &:focus {
    ${Checkbox} .fake {
      border-color: ${color("input-focus")};
    }
  }
`;

const Radio = styled(
  forwardRef(function Radio({ error, className, ...props }, ref) {
    return (
      <div className={className}>
        <input type="radio" ref={ref} {...props} />
        <div className="fake">
          <Icon name="check" variant="white" size={16} />
        </div>
      </div>
    );
  })
)`
  input {
    position: absolute;
    left: -9999px;
  }
  .fake {
    width: 24px;
    height: 24px;
    border: 1px solid transparent;
    background: ${color("input")};
    transition: all 0.3s ease;
    position: relative;
    border-radius: 100%;

    ${Icon} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input:focus + .fake {
    border-color: ${color("input-focus")};
  }

  ${is("error")`
    .fake {
      border-color: ${color("input-error")};
    }
  `}

  input:checked + .fake {
    background-color: ${color("input-focus")};
    ${Icon} {
      opacity: 1;
    }
  }
`;

export const FormRadio = styled(
  forwardRef(function FormRadio({ className, children, ...props }, ref) {
    return (
      <label className={className}>
        <Radio {...props} ref={ref} />
        <Content>{children}</Content>
      </label>
    );
  })
)`
  display: flex;
  cursor: pointer;

  &:hover,
  &:focus {
    ${Radio} .fake {
      border-color: ${color("input-focus")};
    }
  }
`;

const DropdownIndicator = styled(function ({ className }) {
  return (
    <button className={className}>
      <Icon
        className={"DropdownIndicator-icon"}
        name="arrow-down"
        size={16}
        variant="white"
      />
    </button>
  );
})`
    background-color: ${color("primary")};
    height: 40px;
    width: 40px;
    margin: -1px;
    pointer-events: none;
    .DropdownIndicator-icon {
      display: block;
      margin: auto;
    }
  }
`;

export const FormSelect = styled(
  forwardRef(function FormSelect(
    { className, value, onChange, disabled, ...props },
    ref
  ) {
    const selectedOption =
      (props.options || []).find((option) => option.value === value) || null;
    const handleSelect = useCallback(
      (option) => (
        onChange && onChange(option ? option.value : null), [onChange]
      )
    );

    return (
      <div className={className}>
        <Select
          classNamePrefix="select"
          isDisabled={disabled}
          {...props}
          value={selectedOption}
          onChange={handleSelect}
          components={{ DropdownIndicator, IndicatorSeparator: false }}
          ref={ref}
        />
      </div>
    );
  })
)`
  .select {
    &__control {
      ${input}
      cursor: pointer;
      padding: 0;
      border-radius: 0;
      &:hover {
        border: 1px solid #f5f5f5;
      }
    }
    &__value-container {
      height: 36px;
      [class$="-Input"] {
        color: ${color("input-text")};
        margin: 0;
        padding: 0;
      }
    }
    &__placeholder {
      color: ${color("text-dark")};
      font-weight: ${theme("font-weight-semi")};
      opacity: 0.4;
      margin: 0;
    }
    &__indicators {
      align-selft: flex-start;
    }
  }
`;

export const FormSeparator = styled.hr`
  margin: 25px 0;
  border: 0;
  border-bottom: 1px solid ${color("separator")};
  display: inline-block;
  width: 100%;
`;
