import { ApolloProvider } from "@apollo/client";
import { useState } from "react";
//!!! This files is only imported on client-side !!!
// import { CookiesProvider, useCookies } from "react-cookie";
import ReactDOM from "react-dom";
// import { useSSR } from "react-i18next";
import { setLocale as yupSetLocale } from "yup";

import { setLocale } from "../common/date.js";
// import { setLocale } from "../common/date.js";
import App from "./App.jsx";
// import config from "./config.js";
import * as yupLocale from "./locales/yup.js";
import { Router } from "./router.jsx";
import apolloClient from "./services/apollo.js";
import { SessionProvider } from "./services/auth.js";

// import { i18n as i18next } from "./services/i18n.js";

// Keep Apollo & Router provider here, this file is not imported by the server-side!
function ClientApp({ apolloClient, i18n }) {
  // const [cookies] = useCookies(["i18next"]);
  // if (i18n) {
  //   useSSR({ [i18n.language]: i18n.data || {} }, i18n.language);
  // } else {
  //   i18next.changeLanguage(cookies["i18next"] || config.i18next.fallbackLng);
  // }
  // setLocale(i18next.language);
  setLocale("fr");
  yupSetLocale(yupLocale);
  const [userConfirmation, setUserConfirmation] = useState(null);
  return (
    // <CookiesProvider>
    <SessionProvider>
      <ApolloProvider client={apolloClient}>
        <Router onUserConfirmation={setUserConfirmation}>
          <App userConfirmation={userConfirmation} />
        </Router>
      </ApolloProvider>
    </SessionProvider>
    // </CookiesProvider>
  );
}

if (typeof document !== "undefined" && document.getElementById) {
  const root = document.getElementById("root");
  const i18n = typeof window !== "undefined" ? window.__I18N__ : null;
  ReactDOM[!!root.innerHtml ? "hydrate" : "render"](
    <ClientApp apolloClient={apolloClient} i18n={i18n} />,
    root
  );
} else {
  console.error("No wrapper for React app!");
}
