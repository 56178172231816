import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

export function useCompanyUsers() {
  const result = useQuery(
    gql`
      query {
        private {
          users {
            fetchUsers {
              id
              email
              lastname
              firstname
              phone
              status
              roles
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.users.fetchUsers");
}

export function useCompanyUserCreate() {
  const [mutation, result] = useMutation(
    gql`
      mutation($data: UserDataPrivateInput!) {
        private {
          users {
            createUser(data: $data) {
              id
              status
            }
          }
        }
      }
    `
  );

  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "private.users.createUser"),
  ];
}
