import _keyBy from "lodash/keyBy";
import _sumBy from "lodash/sumBy";
import _uniqBy from "lodash/uniqBy";
import { Fragment, useMemo } from "react";

import { formatCurrency, formatRevenue } from "../../../common/currency.js";
import { daysUntilNow, format } from "../../../common/date.js";
import { usePermissions } from "../../services/permissions.js";
import { useSelfMetaData } from "../../services/self.js";
import { useVouchers } from "../../services/vouchers.js";
import { color, font, is, styled, theme } from "../../util/style.js";
import { Form } from "../forms.jsx";
import RevenueGraph from "../revenue-graph/RevenueGraph.jsx";
import RevenueGraphMobile from "../revenue-graph/RevenueGraphMobile.jsx";
import Tooltip, {
  ArchivedTooltipContent,
  ExpiredTooltipContent,
  TrainingTooltipContent,
} from "../Tooltip.jsx";
import { Badge, Primary, Section, Title } from "./Components.jsx";

const BudgetSummary = styled.div`
  margin: 40px 0;
  padding-left: 24px;
  position: relative;
  &::before {
    background-color: ${color("list")};
    content: "";
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 10px;
  }

  ${is("disabled")`
    filter: grayscale(100%);
    opacity: 0.5;
  `}
`;

const BudgetTitle = styled.div`
  margin-top: 0;
  ${font("paragraph")}
  color: ${color("text-dark")};
  ${Primary} {
    ${font("headline5")}
  }
`;

const BudgetDate = styled.div`
  ${font("paragraph")}
  color: ${color("text-dark")};
  margin-top: 16px;
`;

const BudgetLine = styled(function ({ label, value, className }) {
  return (
    <div className={className}>
      <div className="BudgetLine-label">{label}</div>
      <hr className="BudgetLine-separator" />
      <div className="BudgetLine-value">{value}</div>
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 4px -24px;
  position: relative;

  ${is("primary")`
    color: ${color("primary")};
  `}

  ${is("danger")`
    color: ${color("red")};
  `}

  .BudgetLine {
    &-separator {
      flex: 1;
      margin: 0;
    }
    &-label,
    &-value {
      background-color: white;
      font-weight: ${theme("font-weight-bold")};
      padding: 0 24px;
    }
  }
`;

function singleToArray(item) {
  return Array.isArray(item) ? item : [item];
}

function budgetsByExpirationDate(budget) {
  if (!budget) return [];

  const expirationDates = _uniqBy(
    [...(budget.credits || []), ...(budget.expenses || [])],
    ({ expiresAt }) => format(expiresAt)
  );

  const creditsByExpirationDates = _keyBy(
    budget.credits || [],
    ({ expiresAt }) => format(expiresAt)
  );
  const expensesByExpirationDates = _keyBy(
    budget.expenses || [],
    ({ expiresAt }) => format(expiresAt)
  );
  const balancesByExpirationDates = _keyBy(
    budget.balances || [],
    ({ expiresAt }) => format(expiresAt)
  );

  return expirationDates.map(function ({ expiresAt }) {
    const formattedDate = format(expiresAt);

    const credits = _sumBy(
      singleToArray(creditsByExpirationDates[formattedDate] || []),
      "amount"
    );
    const expenses = _sumBy(
      singleToArray(expensesByExpirationDates[formattedDate] || []),
      "amount"
    );
    const balance = _sumBy(
      singleToArray(balancesByExpirationDates[formattedDate] || []),
      "amount"
    );
    return {
      date: expiresAt,
      budget: {
        credits,
        expenses,
        balance,
        reserved: budget.totalReserved.amount,
      },
    };
  });
}

export function useRevenuesData(company) {
  const [vouchers] = useVouchers();

  const budgets = company?.budgets;

  const giftsByExpiration = budgetsByExpirationDate(budgets?.gifts);
  const formationsByExpiration = budgetsByExpirationDate(budgets?.formations);
  const accessoriesByExpiration = budgetsByExpirationDate(budgets?.accessories);
  const interventionsByExpiration = budgetsByExpirationDate(
    budgets?.interventions
  );

  const vouchersActive = useMemo(
    () =>
      (vouchers || []).filter(
        (voucher) =>
          voucher?.used ||
          (voucher?.expirationDate &&
            new Date(voucher.expirationDate) > new Date())
      ),
    [vouchers]
  );

  const vouchersActiveUsed = useMemo(
    () => (vouchersActive || []).filter((voucher) => voucher.used),
    [vouchersActive]
  );

  return {
    budgets,
    giftsByExpiration,
    formationsByExpiration,
    accessoriesByExpiration,
    interventionsByExpiration,
    vouchers,
    vouchersActiveUsed,
    vouchersActive,
  };
}

export default function WidgetRevenues() {
  const [hasPermission] = usePermissions();
  const { isArchived, isSubscriptionExpired, isEliteProPlus, company } =
    useSelfMetaData();
  const {
    budgets,
    giftsByExpiration,
    formationsByExpiration,
    accessoriesByExpiration,
    interventionsByExpiration,
    vouchersActiveUsed,
    vouchersActive,
  } = useRevenuesData(company);

  const yearOfData = parseInt(
    format(company?.importedAt || new Date(), "yyyy"),
    10
  );
  const revenue =
    company?.revenues.find(({ year }) => year === yearOfData)?.total || 0;

  return (
    <Form>
      {hasPermission("manager:*") && (
        <Section>
          <Title tabIndex="0">
            Mon chiffre d'affaires <small>avec DAIKIN en {yearOfData}</small>
          </Title>
          <center>
            <Badge>{formatRevenue(revenue)}</Badge>
            {Boolean(company?.importedAt) && (
              <div>
                <small>Mis à jour le {format(company?.importedAt)}</small>
              </div>
            )}
          </center>
          <div style={{ marginTop: "24px" }}>
            <RevenueGraph revenue={revenue} width={"100%"} />
            <RevenueGraphMobile revenue={revenue} width={"100%"} />
          </div>
        </Section>
      )}
      <Section>
        <Title>Mes budgets</Title>
        <BudgetSummary>
          <BudgetTitle>
            <Primary>Budget cadeaux</Primary>
          </BudgetTitle>
          {hasPermission("stores:gifts") ? (
            <>
              {giftsByExpiration.map(({ date, budget }) => (
                <Fragment key={"gifts" + format(date)}>
                  <BudgetDate>
                    À dépenser jusqu'au <strong>{format(date)}</strong>
                  </BudgetDate>
                  <BudgetLine
                    label="Offert"
                    value={formatCurrency(budget.credits || 0, false)}
                  />
                  <BudgetLine
                    label="Dépensé"
                    value={formatCurrency(
                      -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                      false
                    )}
                  />
                  <BudgetLine
                    label="Solde"
                    value={formatCurrency(budget.balance || 0, false)}
                    danger={daysUntilNow(date) < 30}
                    primary={daysUntilNow(date) >= 30}
                  />
                </Fragment>
              ))}
              {Boolean(!giftsByExpiration?.length) && (
                <BudgetDate>Aucun budget disponible</BudgetDate>
              )}
              {isSubscriptionExpired &&
                Boolean(!budgets?.gifts?.totalBalance?.amount) && (
                  <Tooltip
                    align="left"
                    content={<ExpiredTooltipContent />}
                  ></Tooltip>
                )}
            </>
          ) : (
            <>
              <BudgetDate>Budget non disponible</BudgetDate>
              {isArchived && (
                <Tooltip
                  align="left"
                  content={<ArchivedTooltipContent />}
                ></Tooltip>
              )}
            </>
          )}
        </BudgetSummary>
        <BudgetSummary>
          <BudgetTitle>
            <Primary>Budget formations</Primary>
          </BudgetTitle>
          {hasPermission("training:order") ? (
            <>
              {!!budgets?.formations?.totalReserved?.amount && (
                <BudgetLine
                  label="Réservés"
                  value={formatCurrency(
                    budgets?.formations?.totalReserved?.amount || 0,
                    false
                  )}
                />
              )}

              {formationsByExpiration.map(({ date, budget }) => (
                <Fragment key={"formations" + format(date)}>
                  <BudgetDate>
                    À dépenser jusqu'au <strong>{format(date)}</strong>
                  </BudgetDate>
                  <BudgetLine
                    label="Offert"
                    value={formatCurrency(budget.credits || 0, false)}
                  />
                  <BudgetLine
                    label="Dépensé"
                    value={formatCurrency(
                      -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                      false
                    )}
                  />
                  <BudgetLine
                    label="Solde"
                    value={formatCurrency(budget.balance || 0, false)}
                    danger={daysUntilNow(date) < 30}
                    primary={daysUntilNow(date) >= 30}
                  />
                </Fragment>
              ))}
              {Boolean(!formationsByExpiration?.length) && (
                <BudgetDate>Aucun budget disponible</BudgetDate>
              )}
              <Tooltip
                align="left"
                content={
                  isSubscriptionExpired &&
                  !budgets?.formations?.totalBalance?.amount ? (
                    <ExpiredTooltipContent />
                  ) : (
                    <TrainingTooltipContent />
                  )
                }
              ></Tooltip>
            </>
          ) : (
            <>
              <BudgetDate>Budget non disponible</BudgetDate>
              {isArchived && (
                <Tooltip
                  align="left"
                  content={<ArchivedTooltipContent />}
                ></Tooltip>
              )}
            </>
          )}
        </BudgetSummary>
        <BudgetSummary>
          <BudgetTitle>
            <Primary>Budget accessoires</Primary>
          </BudgetTitle>
          {hasPermission("stores:clothing") ? (
            <>
              {accessoriesByExpiration.map(({ date, budget }) => (
                <Fragment key={"accessory" + format(date)}>
                  <BudgetDate>
                    À dépenser jusqu'au <strong>{format(date)}</strong>
                  </BudgetDate>
                  <BudgetLine
                    label="Offert"
                    value={formatCurrency(budget.credits || 0, false)}
                  />
                  <BudgetLine
                    label="Dépensé"
                    value={formatCurrency(
                      -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                      false
                    )}
                  />
                  <BudgetLine
                    label="Solde"
                    value={formatCurrency(budget.balance || 0, false)}
                    danger={daysUntilNow(date) < 30}
                    primary={daysUntilNow(date) >= 30}
                  />
                </Fragment>
              ))}
              {Boolean(!accessoriesByExpiration?.length) && (
                <BudgetDate>Aucun budget disponible</BudgetDate>
              )}
              {isSubscriptionExpired &&
                Boolean(!budgets?.accessories?.totalBalance?.amount) && (
                  <Tooltip
                    align="left"
                    content={<ExpiredTooltipContent />}
                  ></Tooltip>
                )}
            </>
          ) : (
            <>
              <BudgetDate>Budget non disponible</BudgetDate>
              {isArchived && (
                <Tooltip
                  align="left"
                  content={<ArchivedTooltipContent />}
                ></Tooltip>
              )}
            </>
          )}
        </BudgetSummary>
        <BudgetSummary disabled={!isEliteProPlus}>
          <BudgetTitle>
            <Primary>Budget interventions</Primary>
            {!isEliteProPlus && " • Avantage réservé aux Elite Pro +"}
          </BudgetTitle>

          {hasPermission("technical:*") ? (
            <>
              {!!budgets?.interventions?.totalReserved?.amount && (
                <BudgetLine
                  label="Réservés"
                  value={formatCurrency(
                    budgets?.interventions?.totalReserved?.amount || 0,
                    false
                  )}
                />
              )}

              {interventionsByExpiration.map(({ date, budget }) => (
                <Fragment key={"gifts" + format(date)}>
                  <BudgetDate>
                    À dépenser jusqu'au <strong>{format(date)}</strong>
                  </BudgetDate>
                  <BudgetLine
                    label="Offert"
                    value={formatCurrency(budget.credits || 0, false)}
                  />
                  <BudgetLine
                    label="Dépensé"
                    value={formatCurrency(
                      -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                      false
                    )}
                  />
                  <BudgetLine
                    label="Solde"
                    value={formatCurrency(budget.balance || 0, false)}
                    danger={daysUntilNow(date) < 30}
                    primary={daysUntilNow(date) >= 30}
                  />
                </Fragment>
              ))}
              {Boolean(!interventionsByExpiration?.length) && (
                <BudgetDate>Aucun budget disponible</BudgetDate>
              )}
            </>
          ) : (
            <>
              <BudgetDate>Budget non disponible</BudgetDate>
              {isArchived && (
                <Tooltip
                  align="left"
                  content={<ArchivedTooltipContent />}
                ></Tooltip>
              )}
            </>
          )}
        </BudgetSummary>
        <BudgetSummary disabled={!isEliteProPlus}>
          <BudgetTitle>
            <Primary>Offres de remboursement</Primary>
            {!isEliteProPlus && " • Avantage réservé aux Elite Pro +"}
          </BudgetTitle>

          {hasPermission("offers:*") ? (
            <>
              {Boolean(vouchersActive?.length) && (
                <>
                  {Boolean(vouchersActive[0]?.expirationDate) && (
                    <BudgetDate>
                      À dépenser jusqu'au{" "}
                      <strong>
                        {format(vouchersActive[0]?.expirationDate)}
                      </strong>
                    </BudgetDate>
                  )}
                  <BudgetLine
                    label="Offert"
                    value={`${vouchersActive?.length || 0} bon(s)`}
                  />
                  <BudgetLine
                    label="Téléchargés"
                    value={`${
                      vouchersActive?.filter((voucher) => voucher.downloaded)
                        .length || 0
                    } bon(s)`}
                  />
                  <BudgetLine
                    label="Utilisés"
                    value={`${vouchersActiveUsed?.length || 0} bon(s)`}
                  />
                  <BudgetLine
                    label="Solde"
                    value={`${
                      vouchersActive?.length - vouchersActiveUsed?.length || 0
                    } bon(s)`}
                    primary
                  />
                </>
              )}
              {Boolean(!vouchersActive?.length) && (
                <BudgetDate>Aucune offre disponible</BudgetDate>
              )}
            </>
          ) : (
            <>
              <BudgetDate>Budget non disponible</BudgetDate>
              {isArchived && (
                <Tooltip
                  align="left"
                  content={<ArchivedTooltipContent />}
                ></Tooltip>
              )}
            </>
          )}
        </BudgetSummary>
      </Section>
    </Form>
  );
}
