import { useChallenges } from "../services/challenges.js";
import { color, font, styled } from "../util/style.js";

export default styled(function ChallengesCount({ className }) {
  const [challengesResult] = useChallenges();
  const totalCount = challengesResult ? challengesResult.totalCount : null;

  if (!totalCount) return null;
  return <span className={className}>{totalCount}</span>;
})`
  ${font("mini")}
  background: ${color("red")};
  border-radius: 100%;
  color: white;
  float: right;
  height: 1.6em;
  padding: 0;
  margin-left: 0.5em;
  text-align: center;
  width: 1.6em;
`;
