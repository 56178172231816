import _orderBy from "lodash/orderBy";
import _uniq from "lodash/uniq";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useRouteMatch } from "react-router-dom";

import { buildPath } from "../../common/alias.js";
import { useLocationQuery } from "../util/router.js";
import { color, is, styled } from "../util/style.js";
import Icon from "./Icon.jsx";

function Link({ href, after, disabled, isActive, ...props }) {
  if (!href) return <span {...props} />;
  return <NavLink exact to={href} isActive={isActive} {...props} />;
}

const PaginationItem = styled(Link)`
  color: ${color("black")};
  transition: color 0.3s ease;
  text-decoration: none;

  ${is("disabled")`
    pointer-events: none;
    opacity: 0.3;
  `}

  &:hover {
    color: ${color("blue")};
    --icon-transition: opacity 0.3s ease;
    --icon-hover: 1;
  }
  &.active {
    color: ${color("blue")};
    font-weight: bold;
    &:hover {
      color: ${color("black")};
    }
  }
`;

export function usePagination(limit = 10, scrollToEl = true) {
  const query = useLocationQuery();
  const pageNumber = parseInt(query.get("p") || "1", 10);
  const pagination = {
    after: (pageNumber - 1) * limit,
    limit,
  };

  useEffect(() => {
    if (!query.get("p")) return;
    if (scrollToEl === undefined || scrollToEl === false) return;
    if (scrollToEl === true) window.scrollTo(0, 0);
    else scrollToEl.scrollIntoView();
  }, [scrollToEl, pageNumber]);

  return pagination;
}

export default styled(function Pagination({
  result: {
    totalCount,
    pageInfo: { endCursor, hasPreviousPage, hasNextPage },
  },
  pagination: { after, limit },
  className,
}) {
  const { url } = useRouteMatch();

  const totalPages = totalCount && limit ? Math.ceil(totalCount / limit) : 0;
  const currentPage =
    1 +
    (limit
      ? typeof after !== "undefined"
        ? Math.ceil(parseInt(after, 10) / limit)
        : Math.ceil(parseInt(endCursor, 10) / limit)
      : 0);

  const mandatoryPages = _orderBy(
    _uniq([
      // First pages
      1,
      2,
      3,
      // Pages around current
      currentPage - 1,
      currentPage,
      currentPage + 1,
      // Last pages
      totalPages - 2,
      totalPages - 1,
      totalPages,
    ])
  ).filter((page) => page >= 1 && page <= totalPages);

  const pages = mandatoryPages.reduce((pages, page, index) => {
    const previousPage = mandatoryPages[index - 1];

    const distance = page - previousPage;

    const previous =
      // display nothing if page just after previous (or no previous page)
      !previousPage || previousPage === "separator" || distance === 1
        ? null
        : // display page if there is just one page between pages
        distance === 2
        ? page - 1
        : // display separator if 2 pages or more between mandatory pages
          "separator";

    return [...pages, ...[previous, page].filter(Boolean)];
  }, []);

  const generatePagePath = (p) =>
    buildPath(url, undefined, { ...(p !== 1 && { p }) });

  return (
    <div className={className}>
      <Helmet>
        {Boolean(hasPreviousPage) && (
          <link rel="prev" href={generatePagePath(currentPage - 1)} />
        )}
        {Boolean(hasNextPage) && (
          <link rel="next" href={generatePagePath(currentPage + 1)} />
        )}
      </Helmet>
      <PaginationItem
        disabled={!hasPreviousPage}
        href={generatePagePath(currentPage - 1)}
        after={endCursor - limit}
        rel="prev"
        aria-label="Page précédente"
        isActive={() => false}
      >
        <Icon name="arrow-prev" size={22} variant="black" hoverVariant="blue" />
      </PaginationItem>
      {totalPages ? (
        pages.map((page, index) =>
          page === "separator" ? (
            <PaginationItem key={"separator" + index} disabled={true}>
              ...
            </PaginationItem>
          ) : (
            <PaginationItem
              key={"page" + page}
              href={generatePagePath(page)}
              isActive={(match) => match && page === currentPage}
              after={(page - 1) * limit}
              // active={page === currentPage}
            >
              {page}
            </PaginationItem>
          )
        )
      ) : (
        <PaginationItem isActive={(match) => match}>0</PaginationItem>
      )}
      <PaginationItem
        disabled={!hasNextPage}
        href={generatePagePath(currentPage + 1)}
        after={endCursor + 1}
        rel="next"
        aria-label="Page suivante"
        isActive={() => false}
      >
        <Icon name="arrow-next" size={22} variant="black" hoverVariant="blue" />
      </PaginationItem>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  & > * {
    align-items: center;
    border-radius: 50px;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 0 6px;
    width: 30px;
  }
`;
