export default function ReactVideoTile({ tile, options, className }) {
  return (
    <div
      className={`${className ||
        ""} tile-container tile-video tile--typography`}
    >
      <figure>
        <div className="iframe-container ratio-169">
          <iframe
            src={tile.videoUrlEmbed}
            width="100%"
            height="100%"
            title={("" + Math.random()).substr(2)}
          />
        </div>
        {!!tile.caption && <figcaption>{tile.caption}</figcaption>}
      </figure>
    </div>
  );
}
