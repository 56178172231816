import { context, styled } from "../util/style.js";
import Stat from "./Stat.jsx";

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;

  ${context("for-tablet-portrait")`
    display: grid;
    grid-gap: 8px 24px;
    grid-template:
      'pending declined'
      'validating archived'
      'accepted empty' / 1fr 1fr;
  `}
`;

export default styled(function TrainingSessionsStatHeader({
  stats,
  className,
}) {
  return (
    <div className={className}>
      <StatRow>
        <Stat
          style={{ gridArea: "pending" }}
          icon="training-pending"
          value={stats?.pending || 0}
          label={
            stats?.pending > 1 ? "sessions commandées" : "session commandée"
          }
          type="primary"
        />
        <Stat
          style={{ gridArea: "completed" }}
          icon="training-done"
          value={stats?.completed || 0}
          label={
            stats?.completed > 1 ? "sessions terminées" : "session terminée"
          }
          type="success"
        />
        <Stat
          style={{ gridArea: "canceled" }}
          icon="training-cancelled"
          value={stats?.canceled || 0}
          label={stats?.canceled > 1 ? "sessions annulées" : "session annulée"}
          type="warning"
        />
      </StatRow>
    </div>
  );
})`
  max-width: 396px;
  margin: 0 auto 72px;
  ${context("for-tablet-portrait")`
    padding: 0 32px;
    margin: 32px auto;
  `}
`;
