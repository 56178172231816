import fontkit from "@pdf-lib/fontkit";
import { PDFDocument, rgb } from "pdf-lib";
import React, { useState } from "react";

import { format } from "../../common/date.js";
import { useUpdateDownloadedVoucher } from "../services/vouchers.js";
import { useSafeEffect } from "../util/useSafe.js";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import Icon from "./Icon.jsx";

async function createPdf(voucher) {
  const modelPath = `${
    process.env.WEBSITE_URI
  }modeles/modele_voucher_${voucher.expirationDate.getFullYear()}_${
    voucher.amount
  }.pdf`;
  const fontPath = `${process.env.WEBSITE_URI}fonts/Poppins/poppins-v15-latin-500.ttf`;

  const [existingPdfBytes, defaultFontBytes] = await Promise.all([
    fetch(modelPath).then((res) => res.arrayBuffer()),
    fetch(fontPath).then((res) => res.arrayBuffer()),
  ]);

  let pdf;
  try {
    pdf = await PDFDocument.load(existingPdfBytes);
  } catch (e) {
    return;
  }

  pdf.registerFontkit(fontkit);
  const defaultFont = await pdf.embedFont(defaultFontBytes);

  const pages = pdf.getPages();
  const firstPage = pages[0];

  const { width: pageWidth, height: pageHeight } = firstPage.getSize();

  const voucherCodeTextOptions = {
    font: defaultFont,
    x: pageWidth - 146,
    y: pageHeight - 76,
    size: 12,
    color: rgb(0, 0, 0),
  };

  firstPage.drawText(voucher.voucherCode, voucherCodeTextOptions);

  return pdf;
}

async function getPdfUrl(pdf) {
  const pdfBytes = await pdf.save();
  return URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
}

export function DownloadPdfVoucher({ voucher }) {
  const [updateDownloadedVoucher] = useUpdateDownloadedVoucher(
    voucher.voucherCode
  );

  const [pdfUrl, setPdfUrl] = useState();
  const [invalidPdf, setInvalidPdf] = useState(false);

  async function onDownload() {
    await updateDownloadedVoucher();
  }

  useSafeEffect(
    (state) => {
      async function initializePdf() {
        const pdf = await createPdf(voucher);
        if (!pdf) return;
        const url = await getPdfUrl(pdf);
        if (!state.mounted) return;
        setPdfUrl(url);
      }
      initializePdf();
    },
    [voucher]
  );

  return (
    <div>
      {Boolean(pdfUrl) ? (
        <a
          href={pdfUrl}
          rel="nofollow"
          download={`Daikin-Bon-${voucher.voucherCode}-${format(
            new Date(),
            "ddMMyyyy"
          )}.pdf`}
        >
          <button className="Voucher-download" onClick={onDownload}>
            <Icon
              name="download"
              size={24}
              variant="black"
              hoverVariant="blue"
            />
          </button>
        </a>
      ) : (
        <div>
          <button
            onClick={() => setInvalidPdf(true)}
            className="Voucher-download"
          >
            <Icon
              name="download"
              size={24}
              variant="black"
              hoverVariant="blue"
            />
          </button>
          <ConfirmationPopup
            title={"Offre non disponible"}
            open={Boolean(invalidPdf)}
            onClose={() => setInvalidPdf(false)}
          >
            L'offre {voucher.voucherCode} n’a pas pu être téléchargé. Si le
            problème persiste, veuillez contacter nos équipes.
          </ConfirmationPopup>
        </div>
      )}
    </div>
  );
}
