import { useRouteMatch } from "react-router-dom";
import { color, font, styled, is, context, theme } from "../util/style.js";
import Link from "./Link.jsx";
import { paths } from "../services/router.js";
import { categories } from "../../common/categories.js";

const Category = styled(function({ title, to, alias = to, className }) {
  const routeMatch = useRouteMatch(to);
  const routeMatchAlias = useRouteMatch(alias);
  const active = (to && routeMatch?.isExact) || (alias && routeMatchAlias);

  const Component = to ? Link : "div";

  return (
    <div className={className + (active ? " Category--active" : "")}>
      <Component to={to} className="Category-title">
        {title}
      </Component>
    </div>
  );
})`
  .Category {
    &-title {
      ${font("headline5")}
      color: ${color("text-dark")};
      margin: 0;
      text-decoration: none;
      transition: color 0.3s ease;
    }
    
    &-summary {
      padding-left: 16px;
    }
  }
  ${is("to")`
    &.Category--active .Category-title, .Category-title:hover {
      color: ${color("primary")};
      transition: color 0.3s ease;
    }
  `}
  ${context("for-tablet-portrait")`
    .Category {
      &-title {
        font-size: 1.6rem;
      }
    }
  `}
`;

export default styled(function PostCategories({ className }) {
  return (
    <div className={className}>
      <div className="PostCategories-container">
        <h2 className="PostCategories-title">Sélectionnez une thématique</h2>

        <div className="PostCategories-list">
          {categories.map((category, index) => (
            <Category
              key={"category-" + index}
              to={
                category.id === "all"
                  ? paths.posts
                  : `${paths.posts}/${category.id}`
              }
              alias={`${paths.posts}/${category.id}`}
              title={category.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;
  grid-gap: 56px;
  ${theme("content-box")}
  position: relative;

  .PostCategories {
    &-container {
      padding: 24px;
    }
    &-list {
      padding: 0 40px;
      & > ${Category} {
        margin: 40px 0;
      }
    }
    
    &-title {
      ${font("headline3")}
      color: ${color("grey-text")};
      margin: 0;
      padding: 24px 40px;
      background: ${color("bg-page")};
    }
  }

  ${context("for-desktop")`
    width: auto;
    flex: 1;
    flex-direction: column;
    grid-gap: 32px;
    .PostCategories {
      &-title {
        ${font("headline4")}
        color: ${color("primary")};
        margin: 0;
        padding: 16px 20px;
      }
      &-list {
        padding: 0 20px;
        & > ${Category} {
          margin: 20px 0;
        }
      }
    }
  `}
`;
