import { paginatedGql, toStandardResult } from "../util/graphql.js";
import { useMutation, useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";

const interventionSchema = `
  id
  internalReference
  externalId
  context
  kind
  restrictedAccess
  attachments {
    id
    name
    version
  }
  company {
    name
  }
  location {
    id
    company
    street
    postalCode
    city
    cedex
    country
  }
  personInCharge {
    name
    position
    phone
    email
  }
  products {
    reference
    serialNumber
    quantity
  }
  amount
  date
  createdAt
  status
  statusDetails
  archived
`;

export function useInterventionRequest() {
  const [mutation, result] = useMutation(
    gql`
      mutation SEND_INTERVENTION_REQUEST($data: InterventionDataPrivateInput!) {
        private {
          interventions {
            success: sendInterventionRequest(data: $data) {
              ${interventionSchema}
            }
          }
        }
      }
    `
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "private.interventions.success"),
  ];
}

export function useCancelInterventionRequest(id) {
  const [mutation, result] = useMutation(
    gql`
      mutation CANCEL_INTERVENTION_REQUEST($id: ID!) {
        private {
          interventions {
            success: cancelInterventionRequest(id: $id) {
              id
              status
            }
          }
        }
      }
    `,
    { variables: { id } }
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "private.interventions.success"),
  ];
}

export function useArchiveInterventionRequest(id) {
  const [mutation, result] = useMutation(
    gql`
      mutation ARCHIVE_INTERVENTION_REQUEST($id: ID!) {
        private {
          interventions {
            success: archiveInterventionRequest(id: $id) {
              id
              status
              archived
            }
          }
        }
      }
    `,
    { variables: { id } }
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(result, "private.interventions.success"),
  ];
}

export function useInterventionsStats() {
  const result = useQuery(
    gql`
      query GET_STATS {
        private {
          interventions {
            countByStatus: getInterventionCountByStatus {
              status
              count
            }
            archived: getArchivedInterventionCount
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.interventions", {
    transformData: (data) =>
      data && {
        archived: data.archived,
        ...(data.countByStatus || []).reduce(
          (stats, { status, count }) => ({ ...stats, [status]: count }),
          {}
        ),
      },
  });
}

export function useInterventions({ status, search }, pagination) {
  const result = useQuery(
    gql`
      query GET_INTERVENTIONS($filter: InterventionPrivateFilterInput, $pagination: PaginationInput) {
        private {
          interventions {
            interventions: findInterventions(
              filter: $filter
              pagination: $pagination
            ) {
              ${paginatedGql(interventionSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        filter: { status, search },
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.interventions.interventions");
}
