import { Redirect, useParams } from "react-router-dom";

import ContentLoading from "../components/ContentLoading.jsx";
import Poll from "../components/Poll.jsx";
import header from "../img/challenge.jpg";
import { usePoll, usePollSubmit } from "../services/polls.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export default function PollDetails() {
  const { challenge: pollAlias } = useParams();

  const [poll, { ready }] = usePoll(pollAlias);

  const [doSubmit, result, { loading }] = usePollSubmit(poll);

  if (ready && (!poll || !poll.isPlayable))
    return <Redirect to={paths.challenges} />;

  const title = poll ? poll.title : "Sondage";

  return (
    <LoggedInTemplate title={title}>
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline title={title} />
        {ready ? (
          <Poll
            poll={poll}
            onSubmit={doSubmit}
            loading={loading}
            result={result}
          />
        ) : (
          <ContentLoading />
        )}
      </MainContent>
    </LoggedInTemplate>
  );
}
