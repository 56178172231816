import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import _merge from "lodash/merge";
import { fetch } from "whatwg-fetch";

// import { i18n } from "./i18n.js";

const JWT_ACCESS_TOKEN_USER_COOKIE =
  process.env.JWT_ACCESS_TOKEN_USER_COOKIE || "user-session";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        public: {
          merge: function(existing, importing) {
            return _merge({}, existing, importing);
          },
        },
        private: {
          merge: function(existing, importing) {
            return _merge({}, existing, importing);
          },
        },
      },
    },
  },
}).restore(typeof window !== "undefined" ? window.__APOLLO_STATE__ : {});

const client = new ApolloClient({
  cache,
  link: createUploadLink({
    uri: process.env.GRAPHQL_URI,
    credentials: "same-origin",
    fetch: (uri, options) => {
      const token = localStorage.getItem(JWT_ACCESS_TOKEN_USER_COOKIE);
      const headers = options.headers || {};
      // headers["Accept-Language"] = i18n.language;
      if (token) {
        // We use Authorization to bypass cookies restricted to a domain name
        headers["X-Access-Token"] = token;
      }
      options.headers = headers;
      return fetch(uri, options);
    },
  }),
  ssrForceFetchDelay: 100,
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
    query: {
      fetchPolicy: "cache-first",
    },
  },
});

export default client;
