import Icon from "./Icon.jsx";
import { styled, eq, color, font, context } from "../util/style.js";

export default styled(function Stat({
  icon,
  value,
  label,
  type,
  className,
  ...props
}) {
  return (
    <div className={className} {...props}>
      <div className="Stat-icon">
        <Icon name={icon} variant="black" size={48} />
      </div>
      <div className="Stat-value">{value}</div>
      <div className="Stat-label"> {label}</div>
    </div>
  );
})`
  text-align: center;
  max-width: 80px;

  .Stat-icon {
    border: 2px solid ${color("icon-black")};
    border-radius: 999px;
    display: block;
    margin: auto;
    width: 64px;
    height: 64px;
    text-align: center;
    padding: 6px;
    margin-bottom: 16px;
  }

  .Stat-value {
    ${font("headline3")}
    margin: 6px 0;
  }

  .Stat-label {
    ${font("small")}
  }

  .Stat-value, .Stat-label {
    ${eq("type", "primary")`
      color: ${color("blue")};
    `}
    ${eq("type", "warning")`
      color: ${color("red")};
    `}
    ${eq("type", "success")`
      color: ${color("green")};
    `}
  }

  ${context("for-tablet-portrait")`
    max-width: initial;
    text-align: left;
    display: flex;
    align-items: center;
    
    .Stat-icon {
      display: none;
    }
    .Stat-value, .Stat-label {
      display: inline-block;
    }
    .Stat-value {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 8px;
      min-width: 30px;
    }
    .Stat-label {
      flex: 1;
    }
  `}
`;
