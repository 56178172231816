import _pick from "lodash/pick";
import { useCallback, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

import { DownloadInterventionVoucherPdfButton } from "../components/DownloadPdfInterventionVoucher.jsx";
import {
  Form,
  FormField,
  FormInput,
  FormInputAppend,
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../components/forms.jsx";
import InterventionBudgetHeader from "../components/InterventionBudgetHeader.jsx";
import { Col, Row } from "../components/layout.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import { useInterventionVoucherCreate } from "../services/interventionVouchers.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, font, styled, theme } from "../util/style.js";

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
  hr {
    margin: 32px 0;
  }
`;

const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const PanelTitle = styled.div`
  ${font("headline5")}
  text-align: center;
`;

const PanelSubtitle = styled.div`
  ${font("paragraph")}
  text-align: center;
`;

const Buttons = styled.div`
  padding: 0 ${theme("padding-container")};
  display: flex;
  justify-content: space-between;
`;

function useInterventionVoucherForm(maxAmount) {
  return useForm({
    mode: "onBlur",
    defaultValues: {
      amount: 0,
      reference: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        amount: Yup.number().label("Montant").max(maxAmount),
        reference: Yup.string().label("Nom du site").nullable(),
      })
    ),
  });
}

export default function InterventionVoucherCreate() {
  const { isEliteProPlus, company } = useSelfMetaData();

  const budgetInterventions = company?.budgets?.interventions;

  const topScroll = useRef();
  const scrollToFormTop = useCallback(() => {
    if (!topScroll) return;
    topScroll.current.scrollIntoView();
  }, [topScroll]);

  const interventionVoucherAction = useInterventionVoucherCreate();

  const [success, setSuccess] = useState(false);
  const [voucher, setVoucher] = useState(null);

  const form = useInterventionVoucherForm(
    budgetInterventions?.totalBalance?.amount || 0
  );
  const {
    reset,
    register,
    formState: { errors },
  } = form;

  const [onRequest, { loading: loadingInterventionVoucher }] = useFormAction(
    form,
    interventionVoucherAction,
    {
      transformData: (data) => _pick(data, ["amount", "reference"]),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre bon d'achat intervention n'a pas pu être créé. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: (res) => {
        setSuccess(true);
        setVoucher(res);
        reset({});
        scrollToFormTop();
      },
    }
  );

  if (company && !isEliteProPlus) return <Redirect to={paths.interventions} />;

  return (
    <LoggedInTemplate title="Bons d'achat intervention">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Bons d'achat intervention"
      />
      <MainContent>
        <Headline title="Créer un bon d'achat intervention" />
        <Container>
          <InterventionBudgetHeader
            budget={budgetInterventions}
            budgetButton={null}
          />
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    Créez votre bon d'achat intervention afin d'utiliser votre
                    budget. N'oubliez pas de le joindre avec votre demande afin
                    qu'il soit déduit de votre facture.
                  </p>
                  <center>
                    <Button to={paths.interventionVouchers} ghost>
                      Retour à la liste des bons
                    </Button>
                  </center>
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />

              <Form onSubmit={onRequest} loading={loadingInterventionVoucher}>
                <Panel>
                  {success ? (
                    <div>
                      <p>
                        <strong>
                          Votre bon d'achat intervention a été créé
                        </strong>
                        . Joignez-le à votre demande afin de recevoir une
                        déduction du montant du bon.
                      </p>
                      {Boolean(voucher) && (
                        <p>
                          <DownloadInterventionVoucherPdfButton {...voucher} />
                        </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <PanelTitle>Votre bon d'achat intervention</PanelTitle>
                      <PanelSubtitle>
                        Le montant ne peut être supérieur à votre solde de bonus
                        de {budgetInterventions?.totalBalance?.amount || 0} €.
                      </PanelSubtitle>
                      <hr />

                      <FormField
                        label="Montant"
                        name="amount"
                        errors={errors}
                        layout="column"
                      >
                        {({ name, ...childProps }) => (
                          <span>
                            <span>
                              <FormInput
                                type="number"
                                {...register(name)}
                                {...childProps}
                                max={
                                  budgetInterventions?.totalBalance?.amount || 0
                                }
                                style={{ width: "auto", maxWidth: 200 }}
                              />
                            </span>
                            <FormInputAppend>€</FormInputAppend>
                          </span>
                        )}
                      </FormField>
                      <FormField
                        label="Nom du site"
                        name="reference"
                        errors={errors}
                        layout="column"
                      >
                        {({ name, ...childProps }) => (
                          <FormInput
                            placeholder="Référence client"
                            {...register(name)}
                            {...childProps}
                          />
                        )}
                      </FormField>
                    </>
                  )}
                </Panel>
                {Boolean(!success) && (
                  <Buttons>
                    <Button
                      type="submit"
                      disabled={budgetInterventions?.totalBalance?.amount <= 0}
                    >
                      Je créé mon bon
                    </Button>
                  </Buttons>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
