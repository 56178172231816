import { useForm } from "react-hook-form";

import { paths } from "../services/router.js";
import { useValidateUsage } from "../services/self.js";
import { color, styled, theme, z } from "../util/style.js";
import {
  Form,
  FormButton,
  FormCheckbox,
  FormField,
  Yup,
  useFormAction,
  yupResolver,
} from "./forms.jsx";
import Link from "./Link.jsx";
import Popup from "./Popup.jsx";

function useValidateForm() {
  return useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        cgu: Yup.boolean()
          .nullable()
          .oneOf([true], "Veuillez accepter les CGU"),
        dataUsage: Yup.boolean()
          .nullable()
          .oneOf([true], "Veuillez accepter le traitement de vos données"),
        rules: Yup.boolean()
          .nullable()
          .oneOf([true], "Veuillez accepter le règlement"),
      })
    ),
  });
}

export default styled(function ({ ...props }) {
  const form = useValidateForm();
  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const [cgu, dataUsage, rules] = watch(["cgu", "dataUsage", "rules"]);
  const canSubmit = Boolean(cgu && dataUsage && rules);

  const validateAction = useValidateUsage();

  const [onValidate, { loading: loadingValidate }] = useFormAction(
    form,
    validateAction,
    {
      transformData: (data) => ({
        dataUsage: data.cgu && data.dataUsage && data.rules,
        optIn: data.optIn,
      }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Vos préférences n'ont pas pu être enregistrées. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess() {
        window.location.reload();
      },
    }
  );

  return (
    <Popup
      {...props}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      {() => (
        <Form onSubmit={onValidate} loading={loadingValidate} errors={errors}>
          <header>Avant d'entrer dans le programme DAIKIN ELITE</header>
          <main>
            <FormField
              error={!!errors?.cgu}
              errors={errors}
              name="cgu"
              required
            >
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  J'accepte les{" "}
                  <Link external to={paths.legal}>
                    CGU
                  </Link>
                </FormCheckbox>
              )}
            </FormField>
            <FormField error={!!errors?.dataUsage} name="dataUsage" required>
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  J'accepte le traitement de mes données par Daikin
                </FormCheckbox>
              )}
            </FormField>
            <FormField error={!!errors?.rules} name="rules" required>
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  J'accepte le{" "}
                  <Link external to={paths.rules}>
                    règlement du programme
                  </Link>
                </FormCheckbox>
              )}
            </FormField>
            <FormField errors={errors} name="optIn">
              {({ name, ...childProps }) => (
                <FormCheckbox {...register(name)} {...childProps}>
                  Je souhaite recevoir les communications liées au programme
                  DAIKIN ELITE
                </FormCheckbox>
              )}
            </FormField>
            {!!(errors?.cgu || errors?.dataUsage || errors?.rules) && (
              <FormField
                error="Veuillez accepter les conditions"
                name="general"
              />
            )}
          </main>
          <footer>
            <FormButton type="submit" grey={!canSubmit}>
              Accéder au site
            </FormButton>
          </footer>
        </Form>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlay")};
    z-index: ${z("popin")};
  }

  &-content {
    ${theme("content-box")}
    ${theme("big-border")}
    max-width: 600px;
    padding: 24px 48px;
    width: 90%;
    text-align: center;

    header {
      border-bottom: 2px solid ${color("primary")};
      font-weight: ${theme("font-weight-bold")};
      display: inline-block;
      line-height: 1.4;
      padding-bottom: 8px;
      text-align: center;
      margin-bottom: 24px;
    }

    main {
      ${FormField} {
        margin-bottom: 12px;
      }
    }

    footer {
      margin-top: 24px;
    }
  }
`;
