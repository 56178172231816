import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { toStandardResult } from "../util/graphql.js";

export function useCarousel() {
  const result = useQuery(
    gql`
      query {
        private {
          carousels {
            fetchCarousel {
              id
              slides {
                image {
                  id
                  name
                  version
                }
                title
                text
                label
                url
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.carousels.fetchCarousel");
}
