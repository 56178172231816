import { useWorkforceParticipationRequestsStats } from "../services/interventions.js";
import { context, styled } from "../util/style.js";
import Stat from "./Stat.jsx";

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;

  ${context("for-tablet-portrait")`
    display: grid;
    grid-gap: 8px 24px;
    grid-template:
      'pending declined'
      'validating archived'
      'accepted empty' / 1fr 1fr;
  `}
`;

export default styled(function ParticipationsRequestsStatHeader({
  stats,
  className,
}) {
  const totalPending = stats?.pending + stats?.incomplete;
  return (
    <div className={className}>
      <StatRow>
        <Stat
          style={{ gridArea: "pending" }}
          icon="clipboard-reload"
          value={totalPending || 0}
          label={totalPending > 1 ? "demandes en cours" : "demande en cours"}
          type="primary"
        />
        <Stat
          style={{ gridArea: "validating" }}
          icon="hourglass"
          value={stats?.invoiceValidation || 0}
          label={
            stats?.invoiceValidation > 1
              ? "factures en attente"
              : "facture en attente"
          }
          type="warning"
        />
        <Stat
          style={{ gridArea: "accepted" }}
          icon="clipboard-valid"
          value={stats?.accepted || 0}
          label={
            stats?.archived > 1 ? "demandes acceptées" : "demande acceptée"
          }
          type="success"
        />
        <Stat
          style={{ gridArea: "declined" }}
          icon="clipboard-error"
          value={stats?.declined || 0}
          label={stats?.archived > 1 ? "demandes refusées" : "demande refusée"}
        />
        <Stat
          style={{ gridArea: "archived" }}
          icon="archive"
          value={stats?.archived || 0}
          label={
            stats?.archived > 1 ? "demandes archivées" : "demande archivée"
          }
        />
      </StatRow>
    </div>
  );
})`
  max-width: 660px;
  margin: 0 auto 72px;
  ${context("for-tablet-portrait")`
    padding: 0 32px;
    margin: 32px auto;
  `}
`;
