export function getWidth(value) {
  // Calcul de la jauge du SVG en utilisant https://www.dcode.fr/recherche-equation-fonction
  // Taille des arrondis début et fin de jauge : 30
  // taille totale de la jauge : 535
  // ordonnée : 60, 190, 330, 535
  // parabole/hyperbole par ajustement
  // −0.00094938 x2 +1.44515 x +51.6112
  return Math.min(-0.00094938 * value * value + 1.44515 * value + 51.6112, 535);
}
export function getHeight(value) {
  // Calcul de la jauge du SVG en utilisant https://www.dcode.fr/recherche-equation-fonction
  // Taille des arrondis début et fin de jauge : 30
  // taille totale de la jauge : 535
  // ordonnée : 60, 94, 208, 305
  // parabole/hyperbole par ajustement
  // −0.000313209 x2 +0.666271 x +51.9369
  return Math.min(
    -0.000313209 * value * value + 0.666271 * value + 51.9369,
    535
  );
}
export const SVG_STEPS = [{ max: 90000 }, { max: 250000 }, { max: 500000 }];
