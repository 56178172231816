import { color, font, styled } from "../../util/style.js";
import HtmlText from "../HtmlText.jsx";
import CheckboxesQuestion from "./CheckboxesQuestion.jsx";
import FileQuestion from "./FileQuestion.jsx";
import InputQuestion from "./InputQuestion.jsx";
import ListQuestion from "./ListQuestion.jsx";
import PercentageQuestion from "./PercentageQuestion.jsx";
import RadioQuestion from "./RadioQuestion.jsx";
import RatingQuestion from "./RatingQuestion.jsx";
import SatisfactionQuestion from "./SatisfactionQuestion.jsx";
import TextQuestion from "./TextQuestion.jsx";
import YesNoQuestion from "./YesNoQuestion.jsx";

const QUESTIONS_COMPONENTS = {
  checkbox: CheckboxesQuestion,
  radio: RadioQuestion,
  list: ListQuestion,
  text: TextQuestion,
  number: function (props) {
    return <InputQuestion type="number" {...props} />;
  },
  rating: RatingQuestion,
  satisfaction: SatisfactionQuestion,
  yesno: YesNoQuestion,
  percentage: PercentageQuestion,
  file: FileQuestion,
};

export default styled(function Question({
  className,
  index,
  total,
  kind,
  text,
  id,
  ...props
}) {
  const Component =
    QUESTIONS_COMPONENTS[kind] ||
    ((props) => (
      <div>
        {kind}: {JSON.stringify(props)}
      </div>
    ));
  return (
    <div className={className}>
      <h2>
        Question {index + 1}/{total}
      </h2>
      <HtmlText html={text} />
      <Component key={id} {...props} />

      {/* 
        {question.kind === "file" && (
          <FileSurvey
            value={getFile()}
            onChange={(e) => setFile(e.target.files[0])}
          />
        )} */}
    </div>
  );
})`
  h2 {
    ${font("headline4")}
    color: ${color("blue")};
  }
`;
