import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { paginatedGql, toStandardResult } from "../util/graphql.js";

const trainingSessionSchema = `
  id
  kind
  training {
    reference
    name
  }
  company {
    code
    name
  }
  reference
  trainee {
    reference
    firstname
    lastname
  }
  amount
  status
  subscriptionDate
  period { from until }
`;

export function useTrainingSessionsStats() {
  const result = useQuery(
    gql`
      query GET_STATS {
        private {
          trainingSessions {
            countByStatus: getTrainingSessionsCountByStatus {
              status
              count
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.trainingSessions", {
    transformData: (data) =>
      data && {
        archived: data.archived,
        ...(data.countByStatus || []).reduce(
          (stats, { status, count }) => ({ ...stats, [status]: count }),
          {}
        ),
      },
  });
}

export function useTrainingSessions(filter, pagination) {
  const result = useQuery(
    gql`
      query GET_TRAINING_SESSIONS($filter: TrainingSessionsFilterPrivateInput, $pagination: PaginationInput) {
        private {
          trainingSessions {
            trainingSessions: findTrainingSessions(
              filter: $filter
              pagination: $pagination
            ) {
              ${paginatedGql(trainingSessionSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        filter,
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.trainingSessions.trainingSessions");
}
