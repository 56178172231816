import { Redirect, useParams } from "react-router-dom";

import ContentLoading from "../components/ContentLoading.jsx";
import Quiz from "../components/Quiz.jsx";
import header from "../img/challenge.jpg";
import { useQuiz, useQuizSubmit } from "../services/quizzes.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export default function QuizDetails() {
  const { challenge: quizAlias } = useParams();

  const [quiz, { ready }] = useQuiz(quizAlias);

  const [doSubmit, result, { loading }] = useQuizSubmit(quiz);

  if (ready && (!quiz || !quiz.isPlayable))
    return <Redirect to={paths.challenges} />;

  return (
    <LoggedInTemplate title={quiz ? quiz.title : "Quiz"}>
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline title="Quiz" />
        {ready ? (
          <Quiz
            quiz={quiz}
            onSubmit={doSubmit}
            submitLoading={loading}
            result={result}
          />
        ) : (
          <ContentLoading />
        )}
      </MainContent>
    </LoggedInTemplate>
  );
}
