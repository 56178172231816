import _keyBy from "lodash/keyBy.js";

import { roles } from "../../../common/labels.js";
import Icon from "../../components/Icon.jsx";
import Link from "../../components/Link.jsx";
import logoDaikin from "../../img/logo_daikin.png";
import logoDaikinElite from "../../img/logo_elite.png";
import { useSessionData } from "../../services/auth.js";
import { paths } from "../../services/router.js";
import { color, context, styled, theme, z } from "../../util/style.js";
import Container from "./Container.jsx";
import { MobileMenu, NavPrimary } from "./Menus.jsx";

const rolesByKey = _keyBy(roles, "value");

const UserData = styled(function ({ className }) {
  const sessionData = useSessionData();

  return (
    <div className={className}>
      <a
        href={paths.logout}
        rel="noopener"
        className="UserData-logout"
        aria-label="Déconnexion"
      >
        <Icon name="logout" size={20} variant="black" hoverVariant="blue" />
      </a>
      <span className="UserData-data">
        <span className="UserData-name">
          {`${sessionData?.companyName || ""}`.trim() || "Inconnu"}
        </span>
        <span className="UserData-rank">
          {rolesByKey[sessionData?.category]?.label}
        </span>
      </span>
    </div>
  );
})`
  line-height: 1.4;
  display: flex;

  .UserData {
    &-logout {
      margin-right: 6px;
    }

    &-name,
    &-rank {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-name {
      color: ${color("text-dark")};
    }
    &-rank {
      white-space: nowrap;
      color: ${color("primary")};
    }
  }
`;

export const HeaderTop = styled(function ({ className, children }) {
  return (
    <div className={className}>
      <Container>
        <Link to={paths.home} className="HeaderTop-logo">
          <img src={logoDaikin} alt="Logo Daikin" />
        </Link>
        <Link to={paths.home} className="HeaderTop-logo">
          <img
            src={logoDaikinElite}
            alt="Logo Daikin Élite"
            className="HeaderTop-logoElite"
          />
        </Link>
        <div className="HeaderTop-infos">{children}</div>
      </Container>
    </div>
  );
})`
  ${theme("big-border")}
  background-color: white;
  position: relative;
  z-index: ${z("top-header")};
  &::after {
    background-color: white;
    border-radius: 50px;
    bottom: -26px;
    box-shadow: 0px 3px 30px 3px rgba(0, 0, 0, 0.56);
    content: "";
    height: 70px;
    left: 0;
    margin: auto;
    max-width: 484px;
    position: absolute;
    right: 0;
    width: 40%;
    z-index: 0;
  }

  ${Container} {
    background-color: white;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    padding-bottom: 16px;
    padding-top: 16px;
    position: relative;
    z-index: 1;
  }

  .HeaderTop {
    &-logo {
      display: inline-block;
      max-width: 190px;
      img {
        display: block;
      }
    }
    &-logoElite {
      bottom: 0;
      display: inline-block;
      left: 50%;
      max-width: 200px;
      position: absolute;
      transform: translateX(-50%);
    }
    &-infos {
      display: inline-block;
    }
  }

  ${context("for-tablet-landscape")`    
    &::after { 
      box-shadow: 0px 3px 8px 1px rgba(0,0,0,0.56);
      max-width: 220px;
    }
    .HeaderTop {
      &-logo {
        max-width: 120px;
      }
      &-logoElite {
        max-width: 140px;
      }
    }
  `}

  ${context("for-phone")`
    ${theme("big-border")}
    border-size: 5px;
    &::after {
      bottom: -12px;
      width: 160px;
    }
    ${Container} {
      padding-bottom: 0;
      padding-top: 0;
    }
    .HeaderTop {
      &-logo {
        max-width: 72px;
      }
      &-logoElite {
        bottom: -5px;
        max-width: 120px;
      }
    }
  `}
`;

export const HeaderNav = styled(function ({ className, children }) {
  return (
    <nav className={className}>
      <Container>{children}</Container>
    </nav>
  );
})`
  background-color: ${color("bg-dark")};
  padding-top: 16px;
  position: sticky;
  z-index: ${z("header")};
  top: -16px;
`;

export default styled(function () {
  return (
    <>
      <HeaderTop>
        <div className="only-mobile">
          <MobileMenu />
        </div>
        <div className="hide-mobile">
          <UserData />
        </div>
      </HeaderTop>
      <HeaderNav className="hide-mobile">
        <NavPrimary />
      </HeaderNav>
    </>
  );
})``;
