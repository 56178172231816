import { getCurrentCode } from "../../common/hotline.js";
import Bonus from "../components/Bonus.jsx";
import Panel, { PanelAction } from "../components/Panel.jsx";
import { ButtonArrowAround } from "../components/typography.jsx";
import videoPlaceholder from "../img/daikin_placeholder_video@2x.png";
import imageMobileSVG from "../img/graphique_ca_mobile@2x.png";
import imageSVG from "../img/graphique_ca@2x.png";
import image1Accessory from "../img/programme/programme_accessoires.jpg";
import image1Accessory2x from "../img/programme/programme_accessoires@2x.jpg";
import image1Gift from "../img/programme/programme_cadeaux.jpg";
import image1Gift2x from "../img/programme/programme_cadeaux@2x.jpg";
import image2Gift from "../img/programme/programme_cadeaux2.jpg";
import image2Gift2x from "../img/programme/programme_cadeaux2@2x.jpg";
import image1Formation from "../img/programme/programme_formation.jpg";
import image1Formation2x from "../img/programme/programme_formation@2x.jpg";
import image1Hotline from "../img/programme/programme_sav.jpg";
import image1Hotline2x from "../img/programme/programme_sav@2x.jpg";
import image2Hotline from "../img/programme/programme_sav2.jpg";
import image2Hotline2x from "../img/programme/programme_sav2@2x.jpg";
import image3Hotline from "../img/programme/programme_sav3.jpg";
import image3Hotline2x from "../img/programme/programme_sav3@2x.jpg";
import image4Hotline from "../img/programme/programme_sav4.jpg";
import image4Hotline2x from "../img/programme/programme_sav4@2x.jpg";
import image1Voucher from "../img/programme/programme_sav5.jpg";
import image1Voucher2x from "../img/programme/programme_sav5@2x.jpg";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  MainContent,
  TwoColumns,
  VideoHeader,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { context, styled } from "../util/style.js";

const ImgSVG = styled.img`
  display: block;
  ${context("for-tablet-landscape")`
  display: none;
`}
`;
const ImgMobileSVG = styled.img`
  display: none;
  ${context("for-tablet-landscape")`
  display: block;
`}
`;

function GiftContent() {
  return (
    <>
      <h2>
        Tout au long de l’année, cumulez des € cadeaux et faites vous plaisir
        sur la boutique !
      </h2>
      <h5>Comment cumuler des € cadeaux ?</h5>
      <p>
        C’est simple,{" "}
        <strong>plus vous achetez, plus vous gagnez&nbsp;!</strong> Chaque K€ de
        chiffre d’affaires réalisé avec DAIKIN cette année vous permet de
        remporter des €&nbsp;cadeaux.{" "}
        <strong>Et en atteignant le palier des 90K€</strong>, vous êtes assuré
        de faire partie du programme l’année prochaine&nbsp;!
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "24px",
        }}
      >
        <ImgSVG src={imageSVG} alt="" width={"100%"} />
        <ImgMobileSVG src={imageMobileSVG} alt="" width={"75%"} />
      </div>
      <TwoColumns
        aside={
          <>
            <img
              src={image1Gift}
              srcSet={`${image1Gift2x} 2x`}
              alt="Comment profiter de mes € cadeaux ?"
            />
            <img
              src={image2Gift}
              srcSet={`${image2Gift2x} 2x`}
              alt="Comment profiter de mes € cadeaux ?"
            />
          </>
        }
      >
        <h5>Comment profiter de mes €&nbsp;cadeaux&nbsp;?</h5>
        <p>
          Rendez-vous sur la boutique cadeaux&nbsp;! Découvrez un large choix de
          produits pour répondre à toutes vos envies&nbsp;: high tech, loisirs,
          électroménager, gastronomie…
        </p>
        <p>
          Vous commandez directement avec vos €&nbsp;cadeaux et si besoin, vous
          pouvez compléter avec un paiement par carte bancaire. Vous recevrez
          ensuite votre commande dans un délai de 3 semaines.
        </p>
        <p>
          Une question sur un produit, une commande&nbsp;? Contactez la hotline
          dédiée 5/7j, de 10h à 13h et de 14h à 18h.
        </p>
      </TwoColumns>
      <PanelAction>
        <ButtonArrowAround to={paths.gifts}>J'y vais</ButtonArrowAround>
      </PanelAction>
    </>
  );
}

function SchoolContent() {
  return (
    <>
      <h2>Développez vos connaissances et vos compétences&nbsp;!</h2>
      <p>
        Daikin vous offre{" "}
        <strong className="color-primary nowrap">
          1 400€ de budget formations
        </strong>{" "}
        pour vous former aux dernières innovations, aux nouveaux produits, pour
        perfectionner vos techniques et développer davantage vos compétences, en
        présentiel ou via des webinars.
      </p>
      <TwoColumns
        aside={
          <img
            src={image1Formation}
            srcSet={`${image1Formation2x} 2x`}
            alt="Comment profiter de mes € cadeaux ?"
          />
        }
      >
        <h5>Comment profiter de mon budget formations&nbsp;?</h5>
        <p>
          Rendez-vous dans l’espace Formations du Club. Vous pouvez consulter
          l’ensemble du catalogue de formations à votre disposition, il vous
          suffit ensuite de réserver aux dates qui vous conviennent.
        </p>
        <p>
          Vous pouvez utiliser votre budget formations en une ou plusieurs fois,
          tout au long de l’année, jusqu’au 31/12/22. Et vous pouvez participer
          à une formation jusqu’au 31/03/23.
        </p>
      </TwoColumns>
      <Bonus icon="school-line">
        En cours d’année, vous pouvez également doubler votre budget formations
        ! Pour cela, il vous suffit d’atteindre les 250K€ de chiffre d’affaires
        avec Daikin
      </Bonus>
      <PanelAction>
        <ButtonArrowAround to={paths.trainingSessions}>
          J'en profite !
        </ButtonArrowAround>
      </PanelAction>
    </>
  );
}

function ClothingContent() {
  return (
    <>
      <h2>
        Soyez un véritable ambassadeur de la marque auprès de vos clients&nbsp;!
      </h2>
      <p>
        Daikin vous offre{" "}
        <strong className="color-primary nowrap">
          850€ de budget accessoires
        </strong>{" "}
        pour commander des objets publicitaires, des PLV, des vêtements aux
        couleurs de Daikin.
      </p>
      <TwoColumns
        aside={
          <img
            src={image1Accessory}
            srcSet={`${image1Accessory2x} 2x`}
            alt="Comment profiter de mon budget accessoires ?"
          />
        }
      >
        <h5>Comment profiter de mon budget accessoires ?&nbsp;</h5>
        <p>
          Rendez-vous sur la boutique accessoires ! Découvrez un large choix de
          produits : PLV, unités factices, vêtements, goodies…
        </p>
        <p>
          Vous commandez directement avec votre cagnotte dédiée et si besoin,
          vous pouvez compléter avec un paiement par carte bancaire. Vous
          recevez ensuite votre commande dans les 3 semaines.
        </p>
        <p>
          Une question sur un produit, une commande ? Contactez la hotline
          dédiée 5/7j, de 10h à 18h.
        </p>
      </TwoColumns>
      <Bonus icon="hands-line">
        En cours d’année, vous pouvez également doubler votre budget accessoires
        ! Pour cela, il vous suffit d’atteindre les 250K€ de chiffre d’affaires
        avec Daikin
      </Bonus>
      <PanelAction>
        <ButtonArrowAround to={paths.accessories}>
          J'en profite !
        </ButtonArrowAround>
      </PanelAction>
    </>
  );
}

function ServicesContent() {
  const { isEliteProPlus } = useSelfMetaData();

  return (
    <>
      <h2>
        Daikin vous accompagne toute l’année avec des services
        après-vente&nbsp;!
      </h2>
      <TwoColumns
        aside={
          <img
            src={image1Hotline}
            srcSet={`${image1Hotline2x} 2x`}
            alt="Remise pièces détachées"
          />
        }
      >
        <h5>Remise pièces détachées</h5>
        <p>
          Vous bénéficiez d’une{" "}
          <strong className="color-primary nowrap">
            remise exceptionnelle de 32%
          </strong>{" "}
          au lieu de 25%.
        </p>
      </TwoColumns>
      <TwoColumns
        align="right"
        aside={
          <img
            src={image2Hotline}
            srcSet={`${image2Hotline2x} 2x`}
            alt="Hotline technique prioritaire"
          />
        }
      >
        <h5>Hotline technique prioritaire</h5>
        <p>
          Vous disposez d’un accès privilégié à la hotline technique Daikin avec
          le{" "}
          <strong className="color-primary nowrap">
            code {getCurrentCode()}
          </strong>{" "}
          qui vous permet de limiter votre temps d’attente.
        </p>
      </TwoColumns>
      <TwoColumns
        aside={
          <img
            src={image3Hotline}
            srcSet={`${image3Hotline2x} 2x`}
            alt="Forfait main d’œuvre"
          />
        }
      >
        <h5>Forfait main d’œuvre</h5>
        <p>
          Vous bénéficiez des forfaits dédiés pour le remplacement de pièces
          sous garantie les 2 premières années suivant la mise en service :{" "}
          <strong className="color-primary nowrap">
            250€ pour les pièces frigorifiques
          </strong>{" "}
          et{" "}
          <strong className="color-primary nowrap">75€ pour les autres.</strong>
        </p>
      </TwoColumns>
      {Boolean(isEliteProPlus) && (
        <TwoColumns
          align="right"
          aside={
            <img
              src={image4Hotline}
              srcSet={`${image4Hotline2x} 2x`}
              alt="Budget intervention"
            />
          }
        >
          <h5>Budget intervention</h5>
          <p>
            Daikin vous offre un budget de{" "}
            <strong className="color-primary">1000€</strong> pour des
            interventions de mise en service, dépannage, assistance… à choisir
            parmi une sélection d’interventions résidentielles et pro.
          </p>
        </TwoColumns>
      )}
    </>
  );
}

function VouchersContent() {
  return (
    <>
      <h2>
        Faites bénéficier à vos clients d’une remise commerciale
        exceptionnelle&nbsp;!
      </h2>
      <TwoColumns
        aside={
          <img
            src={image1Voucher}
            srcSet={`${image1Voucher2x} 2x`}
            alt="Offres de remboursement"
          />
        }
      >
        <h5>10 offres de remboursement d’une valeur de 100€ TTC chacune</h5>
        <p>
          À utiliser quand vous le souhaitez, en une ou plusieurs fois, pour
          proposer une aide financière à votre client sans rogner sur votre
          marge.
        </p>
      </TwoColumns>
      <PanelAction>
        <ButtonArrowAround to={paths.vouchers}>
          J'en profite !
        </ButtonArrowAround>
      </PanelAction>
    </>
  );
}
export default function Program() {
  const { isEliteProPlus } = useSelfMetaData();

  return (
    <LoggedInTemplate title="Programme">
      <VideoHeader video="/files/program_intro.mp4" image={videoPlaceholder} />
      <MainContent>
        <Headline title="Votre programme DAIKIN ELITE" big>
          Tout au long de l’année, Daikin vous fait profiter de nombreux
          avantages exclusifs&nbsp;!
          <br />
          Cumulez des €&nbsp;cadeaux pour vous faire plaisir, développez vos
          connaissances avec votre budget formations, équipez-vous en PLV,
          goodies, unités factices avec le budget accessoires et bénéficiez de
          services après-vente dédiés.
        </Headline>
        <Container>
          <Panel
            pages={[
              {
                id: "gift",
                title: "Des cadeaux",
                icon: "gift-line",
                component: GiftContent,
              },
              {
                id: "formations",
                title: (
                  <>
                    Budget
                    <br />
                    Formations
                  </>
                ),
                icon: "school-line",
                component: SchoolContent,
              },
              {
                id: "accessories",
                title: (
                  <>
                    Budget
                    <br />
                    Accessoires
                  </>
                ),
                icon: "clothing-line",
                component: ClothingContent,
              },
              {
                id: "services",
                title: (
                  <>
                    Services
                    <br />
                    Après-vente
                  </>
                ),
                icon: "tools-line",
                component: ServicesContent,
              },
              ...(isEliteProPlus
                ? [
                    {
                      id: "vouchers",
                      title: (
                        <>
                          Offres de
                          <br />
                          remboursement
                        </>
                      ),
                      icon: "voucher",
                      component: VouchersContent,
                    },
                  ]
                : []),
            ]}
          />
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
