import { color, eq, font, is, styled, theme } from "../util/style.js";
import { Col } from "./layout.jsx";

export const Request = styled.div`
  background: white;
  padding: 28px 42px;
  border-radius: ${theme("border-radius")};
  ${is("highlight")`
    border-top: 8px solid ${color("yellow")};
  `}
`;

export const RequestHeader = styled.div`
  text-align: right;
  margin-bottom: 28px;
`;

export const RequestHeaderItem = styled.span`
  display: inline-block;

  ${is("grey")`
    color: ${color("bg-faq")};
  `}
  ${is("strong")`
    font-weight: ${theme("font-weight-bold")};
  `}
  ${is("small")`
    ${font("small")}
  `}
  ${is("warning")`
    color: ${color("red")};
  `}
  ${is("primary")`
    color: ${color("blue")};
  `}
  ${is("success")`
    color: ${color("green")};
  `}
`;

export const SmallCol = styled(Col)`
  ${font("small")}
  line-height: 1.9;
`;

export const RequestSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 28px;
  }
  ${is("footer")`
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  `}
`;

export const RequestHeaderLine = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
`;

export const RequestFooter = styled.div`
  ${font("small")}

  margin: 0 -42px -28px;
  background: ${color("bg-faq")};
  padding: 16px 42px;
  border-radius: 0 0 ${theme("border-radius")} ${theme("border-radius")};
`;
