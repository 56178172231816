import _pick from "lodash/pick";
import _pickBy from "lodash/pickBy";
import _uniq from "lodash/uniq";
import { useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";

import { format } from "../../common/date.js";
import ContentLoading from "../components/ContentLoading.jsx";
import {
  Controller,
  Form,
  FormCheckbox,
  FormField,
  FormInput,
  FormInputAppend,
  FormLabel,
  FormRadio,
  FormTextarea,
  FormUpload,
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import { usePagination } from "../components/Pagination.jsx";
import ParticipationsRequestsStatHeader from "../components/ParticipationsRequestsStatHeader.jsx";
import { Button, Subtitle, TextButton } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import {
  useUploadInvoiceWorkforceParticipationRequest,
  useWorkforceParticipationRequests,
  useWorkforceParticipationRequestsStats,
} from "../services/participations.js";
import { paths } from "../services/router.js";
import { useSelf } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { useLocationQuery } from "../util/router.js";
import { color, font, styled, theme } from "../util/style.js";

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
  hr {
    margin: 32px 0;
  }
`;

const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const PanelSubtitle = styled.div`
  ${font("paragraph")}
  text-align: center;
`;

const Buttons = styled.div`
  padding: 0 ${theme("padding-container")};
  display: flex;
  justify-content: space-between;
`;

const InvoiceReference = styled.span`
  background: ${color("bg-light")};
  padding: 6px 12px;
  text-transform: uppercase;
  size: 14px;
  font-weight: bold;
  margin-right: 18px;
`;

function booleanKeysToArray(object) {
  return Object.keys(_pickBy(object, Boolean));
}

function useInvoiceForm(defaultId) {
  const requests = {};
  if (defaultId) requests[defaultId] = true;
  return useForm({
    mode: "onBlur",
    defaultValues: { requests },
    resolver: yupResolver(
      Yup.object().shape({
        requests: Yup.object()
          .nullable()
          .test(
            "min-requests",
            "Veuillez sélectionner au moins une demande",
            (requests) => booleanKeysToArray(requests).length >= 1
          ),
        invoice: Yup.mixed()
          .nullable()
          .required("Veuillez sélectionner un fichier"),
      })
    ),
  });
}

export default function InvoiceParticipation() {
  const [stats] = useWorkforceParticipationRequestsStats();
  const pagination = usePagination(999);
  const [
    participationsResult,
    { ready, refetch },
  ] = useWorkforceParticipationRequests(
    { status: "invoiceValidation" },
    pagination
  );

  const participations = (participationsResult?.edges || []).map(
    ({ node }) => node
  );

  const invoiceAction = useUploadInvoiceWorkforceParticipationRequest();

  const [success, setSuccess] = useState(false);

  const query = useLocationQuery();

  const form = useInvoiceForm(query.get("r"));

  const { watch } = form;
  const checkedRequests = watch("requests");

  const [onRequest, { loading: loadingParticipation }] = useFormAction(
    form,
    invoiceAction,
    {
      transformData: (data) => ({
        ..._pick(data, ["invoice"]),
        requests: booleanKeysToArray(data.requests),
      }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre facture n'a pas pu être envoyée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  return (
    <LoggedInTemplate title="Participations main d’œuvre">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Participations main d’œuvre"
      />
      <MainContent>
        <Headline title="Mes demandes" />
        <Container>
          <ParticipationsRequestsStatHeader stats={stats} />
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    En tant que client membre du programme DAIKIN ELITE, vous
                    bénéficiez d'une participation financière pour le changement
                    de vos pièces détachées en garantie sur les produits{" "}
                    <strong>Daikin</strong>.
                  </p>
                  <center>
                    <Button to={paths.participation} ghost>
                      Retour à l'historique
                    </Button>
                  </center>
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <Form onSubmit={onRequest} loading={loadingParticipation}>
                <Panel>
                  {success ? (
                    <div>
                      <strong>La demande a été envoyée</strong> et sera traitée
                      par les équipes DAIKIN dans les plus brefs délais. Vous
                      pouvez annuler à tout moment votre demande dans votre
                      historique des demandes.
                    </div>
                  ) : participations?.length ? (
                    <ParticipationsContent
                      form={form}
                      participations={participations}
                    />
                  ) : (
                    <div>Aucune facture en attente</div>
                  )}
                </Panel>
                {Boolean(!success && participations?.length) && (
                  <Panel>
                    <InvoiceContent form={form} />
                  </Panel>
                )}
                <Buttons>
                  {Boolean(!success) && (
                    <>
                      <Button type="button" ghost to={paths.participation}>
                        Annuler
                      </Button>
                      <Button
                        type="submit"
                        disabled={
                          booleanKeysToArray(checkedRequests)?.length < 1
                        }
                      >
                        J'envoie ma facture
                      </Button>
                    </>
                  )}
                </Buttons>
              </Form>
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}

function ParticipationsContent({ form, participations }) {
  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const requestsKeys = watch("requests");

  const invoiceReferences = useMemo(
    () =>
      booleanKeysToArray(requestsKeys).map(
        (key) =>
          participations.find((participation) => participation.id === key)
            ?.invoice?.reference
      ),
    [requestsKeys, participations]
  );

  return (
    <>
      <PanelSubtitle>
        Sélectionnez les demandes pour lesquelles envoyer la facture et
        n'oubliez pas d'ajouter les références de bon de commande sur votre
        facture.
      </PanelSubtitle>
      <hr />
      {participations.map((participation) => (
        <FormField
          key={participation.id}
          name={`requests.${participation.id}`}
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <FormCheckbox {...register(name)} {...childProps}>
              <strong>
                {participation.reference ? participation.reference + " - " : ""}
                {participation.piece?.kind === "refrigerating"
                  ? "Frigorifique"
                  : "Non frigorifique"}
              </strong>
              <br />
              {participation.piece?.reference} - demande du{" "}
              {format(participation.createdAt)}
            </FormCheckbox>
          )}
        </FormField>
      ))}
      <FormField label="Références à rappeler">
        {invoiceReferences.map((reference) => (
          <InvoiceReference key={reference}>{reference}</InvoiceReference>
        ))}
      </FormField>
    </>
  );
}
function InvoiceContent({ form }) {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = form;

  return (
    <FormField label="Document joint" name="invoice" errors={errors}>
    {({ name, ...childProps }) => (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
              <FormUpload
                {...field}
                {...childProps}
                placeholder="Sélectionnez le fichier à envoyer"
              />
            )}
      />
      )}
      </FormField>
  );
}
