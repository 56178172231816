import { getImageProps } from "../../../../../app/util/medias.js";

export default function ReactStandardTile({ tile, options, className }) {
  return (
    <div
      className={`${className ||
        ""} tile-standard tile-container tile-columns ${
        options.aside ? "tile--aside" : ""
      } tile-columns--${tile.mediaPosition} tile--typography`}
    >
      {!!(tile.withImage || tile.withVideo) && (
        <div className="media-column">
          <figure>
            {!!tile.image &&
              (tile.mediaLink ? (
                <a
                  href={tile.mediaLink}
                  target={tile.mediaLinkTarget}
                  title={tile.mediaLinkTitle}
                >
                  <img
                    loading="lazy"
                    alt=""
                    {...getImageProps(tile.image, "content", true)}
                  />
                </a>
              ) : (
                <img
                  loading="lazy"
                  alt=""
                  {...getImageProps(tile.image, "content", true)}
                />
              ))}
            {!!tile.videoUrlEmbed && (
              <div className="iframe-container ratio-169">
                <iframe title={`Video ${tile.id}`} src={tile.videoUrlEmbed} />
              </div>
            )}
            {!!tile.mediaCaption && (
              <figcaption>{tile.mediaCaption}</figcaption>
            )}
            {!!tile.image && tile.image.credits && (
              <cite className="credits">&copy; {tile.image.credits}</cite>
            )}
          </figure>
        </div>
      )}
      <div className="main-column">
        <div dangerouslySetInnerHTML={{ __html: tile.text }} />
      </div>
    </div>
  );
}
