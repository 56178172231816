import ChallengesSection from "../components/ChallengesSection.jsx";
import header from "../img/challenge.jpg";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export default function Challenges() {
  return (
    <LoggedInTemplate title="Challenges">
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline title="Challenges" />
        <ChallengesSection />
      </MainContent>
    </LoggedInTemplate>
  );
}
