import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";
import { useSessionData, useSessionDataUpdate, useUserSession } from "./auth";

const budgetGql = `
  credits {
    amount
    expiresAt
  }
  totalCredits {
    amount
    expiresAt
  }
  expenses {
    amount
    expiresAt
  }
  totalExpenses {
    amount
    expiresAt
  }
  balances {
    amount
    expiresAt
  }
  totalBalance {
    amount
    expiresAt
  }
  totalReserved {
    amount
    expiresAt
  }
`;

const selfDataGql = `id
email
lastname
firstname
phone
birthDate
cityOfBirth
nationality
socialNumber
dataUsage {
  validated
  validatedAt
}
optIn {
  validated
  validatedAt
}
company {
  id
  name
  category
  importedAt
  sites {
    id
    name
  }
  billingAddresses {
    id
    street
    postalCode
    city
  }
  shippingAddresses {
    id
    street
    postalCode
    city
  }
  salesPerson {
    lastname
    firstname
    email
    phone
  }
  revenues {
    total
    year
  }
  agency {
    id
    reference
    name
    email
    phone
    address {
      id
      street
      postalCode
      city
    }
  }
  budgets {
    gifts {
      ${budgetGql}
    }
    accessories {
      ${budgetGql}
    }
    formations {
      ${budgetGql}
    }
    interventions {
      ${budgetGql}
    }
  }
}`;

export function useSelf() {
  const sessionData = useSessionData();

  const result = useQuery(
    gql`
      query {
        private {
          self {
            ${selfDataGql}
          }
        }
      }
    `,
    {
      skip: !sessionData,
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.self");
}

export function useValidateUsage() {
  const updateSessionData = useSessionDataUpdate();

  const [mutation, result] = useMutation(
    gql`
      mutation ($dataUsage: Boolean, $optIn: Boolean) {
        private {
          validateDataUsageAndOptIn(dataUsage: $dataUsage, optIn: $optIn) {
            id
            dataUsage {
              validated
              validatedAt
            }
            optIn {
              validated
              validatedAt
            }
          }
        }
      }
    `
  );

  return [
    ({ optIn, dataUsage }) =>
      mutation({ variables: { optIn, dataUsage } }).then(updateSessionData),
    ...toStandardResult(result, "private.validateDataUsageAndOptIn"),
  ];
}

export function useUpdateSelf() {
  const updateSessionData = useSessionDataUpdate();

  const [mutation, result] = useMutation(
    gql`
      mutation ($data: UserSelfDataPrivateInput!) {
        private {
          users {
            updateSelf(data: $data) {
              ${selfDataGql}
            }
          }
        }
      }
    `
  );

  return [
    (data) => mutation({ variables: { data } }).then(updateSessionData),
    ...toStandardResult(result, "private.users.updateSelf"),
  ];
}

export function useSelfMetaData() {
  const [self] = useSelf();
  const sessionData = useSessionData();

  const isArchived = sessionData?.status === "archived";
  const isSubscriptionExpired = sessionData?.status === "subscription-expired";
  const isEliteProPlus = self?.company?.category === "eliteProPlus";

  return {
    self,
    isArchived,
    isSubscriptionExpired,
    isEliteProPlus,
    sessionData,
    company: self?.company,
  };
}
