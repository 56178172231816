/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";

class Status {
  constructor() {
    this._mounted = false;
    this._started = null;
    this._stopped = null;
  }
  mounted() {
    return this._mounted;
  }
  _mount() {
    this._mounted = true;
    this._started = new Date();
    this._stopped = null;
  }
  _unmount() {
    this._mounted = false;
    this._stopped = new Date();
  }
}

export function useSafeEffect(fn, ...args) {
  useEffect(function() {
    const status = new Status();
    status._mount();
    const fnUnmount = fn(status);
    return () => {
      status._unmount();
      if (fnUnmount && typeof fnUnmount === "function")
        return fnUnmount(status);
    };
  }, ...args);
}

export function useSafeCallback(fn, ...args) {
  const status = new Status();
  status._mount();
  useEffect(function() {
    return () => status._unmount();
  }, []);
  return useCallback(function(...cbArgs) {
    fn(status, ...cbArgs);
  }, ...args);
}
