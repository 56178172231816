import { css } from "styled-components";

import typographyStyle from "../../../../app/templates/common/TypographyStyle.jsx";

const contexts = {
  "for-small-phone": "(max-width: 380px)",
  "for-phone": "(max-width: 576px)",
  "for-tablet-portrait": "(max-width: 768px)",
  "for-tablet-landscape": "(max-width: 992px)",
  "for-desktop": "(max-width: 1200px)",
};

export default css`
  .tile-container {
    margin-top: 0;
    margin-bottom: 40px;
    clear: both;
  }

  .tile--typography {
    ${typographyStyle}
  }

  .tile-columns {
    &--top,
    &--bottom {
      display: flex;
      flex-direction: column;
    }
    &--top {
      .media-column {
        margin-bottom: 20px;
      }
    }
    &--bottom {
      flex-direction: column-reverse;
      .media-column {
        margin-top: 20px;
      }
    }

    &--left,
    &--small-left {
      .media-column {
        float: left;
        margin: 0 20px 20px 0;
      }
    }

    &--right,
    &--small-right {
      .media-column {
        float: right;
        margin: 0 0 20px 20px;
      }
    }

    &--left,
    &--right {
      .media-column {
        width: 50%;
      }
    }

    &--small-left,
    &--small-right {
      .media-column {
        width: 33%;
      }
    }

    @media ${contexts["for-phone"]} {
      display: flex;
      flex-direction: column;

      &--top,
      &--left,
      &--small-left {
        .media-column {
          margin: 0 0 20px 0;
        }
      }
      &--right,
      &--small-right,
      &--bottom {
        flex-direction: column-reverse;
        .media-column {
          margin: 20px 0 0 0;
        }
      }

      .main-column,
      .media-column {
        width: 100%;
      }
    }

    &.tile--aside {
      display: flex;
      flex-direction: column;

      &.tile-columns--top,
      &.tile-columns--left,
      &.tile-columns--small-left {
        .media-column {
          margin: 0 0 20px 0;
        }
      }

      &.tile-columns--right,
      &.tile-columns--small-right,
      &.tile-columns--bottom {
        flex-direction: column-reverse;
        .media-column {
          margin: 20px 0 0 0;
        }
      }

      .main-column,
      .media-column {
        width: 100%;
      }
    }
  }
`;
