import { forwardRef } from "react";
import Select from "react-select";

import { color, font, styled } from "../../util/style.js";

export default styled(
  forwardRef(function ListQuestion(
    { className, value, onChange, answers, optional },
    ref
  ) {
    const options =
      answers?.map(({ reference, text }) => ({
        value: reference,
        label: text,
      })) || [];
    const selectedValue = options.find(({ value: v }) => value === v);
    return (
      <Select
        className={className}
        ref={ref}
        placeholder="Sélectionnez votre réponse..."
        onChange={(rawValue) => onChange(rawValue?.value || null)}
        options={options}
        value={selectedValue}
        required={!optional}
        isClearable={optional}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    );
  })
)`
  border: 0;
  margin-top: 32px;
  width: 100%;
  color: ${color("grey-text")};
  font: ${font("paragraph")};
`;
