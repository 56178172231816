import { color, font, is, styled } from "../../util/style.js";

const Radio = styled.input.attrs({ type: "radio" })`
  background: white;
  border: 2px solid ${color("grey-white")};
  cursor: pointer;
  height: 24px;
  line-height: 44px;
  margin-right: 0.5em;
  width: 24px;

  ${is("checked")`
    background: ${color("blue")};
  `}
`;

const Label = styled.label`
  font: ${font("strong")};
  line-height: 44px;
  padding: 0;
  vertical-align: middle;
`;

export default styled(function RadioQuestion({
  className,
  value,
  onChange,
  answers,
  optional,
}) {
  return (
    <div className={className}>
      {answers?.map(({ id, reference, text }) => (
        <div key={id}>
          <Label htmlFor={`radio-${id}`}>
            <Radio
              key={id}
              id={`radio-${id}`}
              required={!optional}
              value={reference}
              checked={value === reference}
              onChange={(evt) =>
                onChange && onChange(evt.nativeEvent.target.value)
              }
            />{" "}
            {text}
          </Label>
        </div>
      ))}
    </div>
  );
})``;
