export const roles = [
  { value: "elitePro", label: "Elite Pro" },
  { value: "eliteProPlus", label: "Elite Pro +" },
];

export const categories = [
  {
    value: "products",
    label: "Produits & services",
  },
  {
    value: "events",
    label: "Événements",
  },
  // {
  //   value: "market",
  //   label: "Marché",
  // },
  {
    value: "sales",
    label: "Offres commerciales",
  },
  // {
  //   value: "training",
  //   label: "Formations & webinaires",
  // },
];

export const messageTopics = [
  {
    label: "Accessoires ou Cadeaux",
    value: "accessoriesOrGifts",
  },
  { label: "Formations", value: "formations" },
  { label: "Problème de connexion", value: "authentication" },
  {
    label: "Assistance technique",
    value: "technicalService",
  },
  {
    label: "Modification de mon compte",
    value: "accountUpdate",
  },
];

export const interventionStatuses = [
  {
    label: "Acceptée",
    value: "accepted",
  },
  {
    label: "Refusée",
    value: "declined",
  },
  {
    label: "Terminée",
    value: "done",
  },
  {
    label: "En cours de traitement",
    value: "pending",
  },
  {
    label: "Attente validation installateur",
    value: "requireValidation",
  },
  {
    label: "Annulée",
    value: "cancelled",
  },
];

export const interventionKinds = [
  {
    label: "Diagnostic",
    value: "diagnostic",
  },
  {
    label: "Dépannage",
    value: "repair",
  },
  {
    label: "Expertise",
    value: "expertise",
  },
  {
    label: "Mise en service",
    value: "commissioning",
  },
  {
    label: "Maintenance",
    value: "maintenance",
  },
];

export const interventionContexts = [
  {
    label: "CEC",
    value: "cec",
  },
  {
    label: "Commande",
    value: "ordered",
  },
  {
    label: "Garantie",
    value: "warranty",
  },
  {
    label: "Demande de devis",
    value: "quotation",
  },
];

export const locationKinds = [
  {
    label: "Résidentiel",
    value: "residentiel",
  },
  {
    label: "Tertiaire",
    value: "tertiaire",
  },
];
