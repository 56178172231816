const cdnUri = (
  (process.env.CDN_URI || process.env.WEBSITE_URI || "") + "/"
).replace(/\/\/$/, "/");

export function getImageProps(image, mode = "original", useImageSize = false) {
  return image
    ? {
        src: getMediaUrl(image, mode),
        ...(mode !== "original" && {
          srcSet: `${getMediaUrl(image, mode + "@2x")} 2x`,
        }),
        alt: image.description || "",
        ...(useImageSize && image.width && image.height
          ? { width: image.width, height: image.height }
          : {}),
      }
    : {};
}

export function getUrl(type, image, mode = "original") {
  return image
    ? `${cdnUri}${type}/${image.id}/${mode}/${image.version ||
        image.hash ||
        (image.updatedAt || "<no-date>").toString(36)}-${image.name}`
    : undefined;
}

export function getMediaUrl(image, mode) {
  return getUrl("media", image, mode);
}

const ellipsis = "...";
export function truncateName(name = "", max = 40) {
  if (name.length < max) return name;
  const maxStart = Math.round(((max - ellipsis.length) * 2) / 3);
  const maxEnd = max - ellipsis.length - maxStart;
  return name.substr(0, maxStart - 1) + ellipsis + name.substr(-(maxEnd - 1));
}

export function checkVideoUrl(value) {
  const videoIdMatchers = {
    youtube: {
      getVideoId: (url) => {
        var m = url.match(
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        );

        return m && m[7].length == 11 ? m[7] : null;
      },
      urlEmbed: "https://www.youtube.com/embed/{ID}",
    },
    dailymotion: {
      getVideoId: (url) => {
        const m = url.match(
          /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
        );
        return m !== null ? (m[4] !== undefined ? m[4] : m[2]) : null;
      },
      urlEmbed: "https://dailymotion.com/embed/video/{ID}",
    },
  };

  let video = null;

  Object.entries(videoIdMatchers).forEach(([service, options]) => {
    const videoId = options.getVideoId(value || "");
    if (videoId) {
      video = options.urlEmbed.replace("{ID}", videoId);
    }
  });

  return video;
}
