import { Fragment } from "react";

import { color, font, is, styled, theme } from "../util/style.js";

export default styled(function Steps({
  max,
  current,
  colors = {},
  barOnly,
  className,
}) {
  return (
    <div className={className}>
      {Array.from(Array(max)).map((_, step) => (
        <Fragment key={step}>
          <div
            className={
              "Step" +
              (current === step ? " Step--current" : "") +
              (current >= step + 1 ? " Step--active" : "") +
              (colors?.[step] ? " Step--" + colors[step] : "")
            }
          >
            {step + 1}
          </div>
          {step + 1 < max && (
            <div
              className={
                "Separator" + (current >= step + 1 ? " Separator--active" : "")
              }
            />
          )}
        </Fragment>
      ))}
    </div>
  );
})`
  display: flex;
  align-items: center;
  margin-bottom: 42px;

  ${is("barOnly")`
    height: 28px;
    background: white;
    border: 2px solid ${color("grey-dark")};
    border-radius: 16px;
    overflow: hidden;
  `}

  .Step {
    ${font("headline4")}
    font-weight: ${theme("font-weight-regular")};
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: 2px solid ${color("grey-dark")};
    border-radius: 100%;

    ${is("barOnly")`
      display: none;
    `}

    &--current {
      color: ${color("blue")};
      border-color: ${color("blue")};
    }
    &--active {
      font-weight: ${theme("font-weight-bold")};
      color: white;
      background: ${color("blue")};
      border-color: ${color("blue")};
    }
    &--disabled {
      color: ${color("white-grey2")};
      background: ${color("white-grey")};
      border-color: ${color("white-grey2")};
    }
    &--success {
      background: ${color("green")};
    }
    &--error {
      background: ${color("red")};
    }
  }

  .Separator {
    flex: 1;
    border-top: 2px solid ${color("grey-dark")};
    &--active {
      border-top: 4px solid ${color("blue")};
      ${is("barOnly")`
        border: 0;
        background: ${color("blue")};
      `}
    }

    ${is("barOnly")`
      height: 100%;
      background: white;
      border: 0;
    `}
  }
`;
