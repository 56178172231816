import ReactJsPopup from "reactjs-popup";
import styled from "styled-components";

export default function Popup(props) {
  return typeof window === "undefined" && props.open ? (
    <Placeholder className={(props.className || "popup") + "-overlay"}>
      <Content className={(props.className || "popup") + "-content"}>
        {typeof props.children === "function"
          ? props.children()
          : props.children}
      </Content>
    </Placeholder>
  ) : (
    <ReactJsPopup {...props} />
  );
}

const Placeholder = styled.div`
  position: fixed;
  inset: 0px;
  display: flex;
  pointer-events: auto;
`;

const Content = styled.div`
  position: relative;
  margin: auto;
  pointer-events: none;
`;
