import { useEffect, useState } from "react";
import { matchPath } from "react-router";
import { useLocation, useRouteMatch } from "react-router-dom";
import Popup from "reactjs-popup";
import { keyframes } from "styled-components";

import ChallengesCount from "../../components/ChallengesCount.jsx";
import Icon from "../../components/Icon.jsx";
import Link from "../../components/Link.jsx";
import { useChallenges } from "../../services/challenges.js";
import { usePermissions } from "../../services/permissions.js";
import { paths } from "../../services/router.js";
import {
  color,
  context,
  font,
  is,
  isnot,
  styled,
  theme,
} from "../../util/style.js";

const menu = [
  {
    icon: "home",
    label: "Accueil",
    to: paths.home,
  },
  {
    icon: "tools",
    label: "Services",
    permission: "technical:*",
    submenu: [
      { label: "Hotline technique", to: paths.hotline },
      { label: "Participation main d’œuvre", to: paths.participation },
      { label: "Demande d’intervention", to: paths.interventions },
    ],
  },
  {
    icon: "hands",
    label: "Offres",
    permission: "offers:*",
    submenu: [{ label: "Offres de remboursement", to: paths.vouchers }],
  },
  {
    icon: "school",
    label: "Formations",
    permission: "training:*",
    to: paths.trainingSessions,
  },
  {
    icon: "gift",
    label: "Boutiques",
    submenu: [
      {
        label: "Accessoires",
        to: paths.accessories,
        permission: "stores:clothing",
      },
      {
        label: "Cadeaux",
        to: paths.gifts,
        permission: "stores:gifts",
      },
    ],
  },
  {
    icon: "megaphone",
    label: "Actualités",
    permission: "news:*",
    to: paths.posts,
  },
  {
    icon: "catalog",
    label: "Catalogues",
    to: paths.catalog,
  },
  {
    icon: "trophy",
    label: "Challenges",
    permission: "challenges:*",
    to: paths.challenges,
    MenuItem: function () {
      return (
        <>
          <Icon name="trophy" size={16} variant="white" hoverVariant="blue" />
          <span>Challenges</span>
          <ChallengesCount />
        </>
      );
    },
    useOptions: function () {
      const [challengesResult] = useChallenges();
      const totalCount = challengesResult ? challengesResult.totalCount : 0;
      return totalCount === 0 ? { disabled: true, hidden: true } : {};
    },
  },
];

const menuSecondary = [
  /*
  { label: "Mon compte société", icon: "userline" },
  { label: "Mon activité", icon: "money" },
  { label: "Mes contacts Daikin", icon: "daikin" },
  { label: "Mes notifications", icon: "notification" },
  */
  { label: "Mon programme DAIKIN ELITE", to: paths.program },
  { label: "Consultez notre FAQ", to: paths.faq },
  { label: "Contactez-nous", to: paths.contact },
  {
    label: "Déconnexion",
    icon: "logout",
    forwardedAs: "a",
    rel: "nofollow",
    href: paths.logout,
  },
];

const slideIn = keyframes`
  from {
    transform: scaleY(0);
    transform-origin: center top;
  }
  to {
    transform: scaleY(1);
    transform-origin: center top;
  }
`;

function useMenuWithPermissions(menu) {
  const [hasPermission] = usePermissions();
  return menu
    .map((item) => ({
      ...item,
      ...(item.submenu
        ? {
            submenu: item.submenu.map((subItem) => ({
              ...subItem,
              disabled:
                !!subItem.permission && !hasPermission(subItem.permission),
            })),
          }
        : {}),
    }))
    .map((item) => ({
      ...item,
      hidden:
        // On masque les menus qui n'ont que des sous-menus masqués
        !!item.submenu &&
        !!item.submenu.length &&
        !item.submenu.filter((subItem) => !subItem.hidden).length,
      disabled:
        // On désactive les menus qui n'ont que des sous-menus désactivés
        (!!item.submenu &&
          !!item.submenu.length &&
          !item.submenu.filter((subItem) => !subItem.disabled).length) ||
        (!!item.permission && !hasPermission(item.permission)),
    }))
    .map((item) => ({
      ...item,
      ...(item.submenu
        ? {
            submenu: item.submenu.map((subItem) => ({
              ...subItem,
              disabled: item.disabled || subItem.disabled,
            })),
          }
        : {}),
    }));
}

function useMainMenu() {
  const menuWithPermissions = useMenuWithPermissions(menu);
  return menuWithPermissions.map((item) =>
    item.useOptions ? { ...item, ...item.useOptions() } : item
  );
}
function useSecondaryMenu() {
  const menuWithPermissions = useMenuWithPermissions(menuSecondary);
  return menuWithPermissions.map((item) =>
    item.useOptions ? { ...item, ...item.useOptions() } : item
  );
}

const NavDrawerPopup = styled(function ({ ...props }) {
  const mainMenu = useMainMenu();
  const secondaryMenu = useSecondaryMenu();
  return (
    <Popup {...props}>
      <div className="NavDrawer-head">
        <button
          className="NavDrawer-close"
          onClick={props.onClose}
          aria-label="Fermer"
        >
          <Icon name="close" size={18} variant="blue" />
        </button>
      </div>
      {[...mainMenu, ...secondaryMenu].map((item, index) => (
        <NavDrawerItem {...item} key={index} />
      ))}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlay")};
  }

  &-content {
    position: absolute !important;
    top: 0;
    right: 0;

    ${theme("big-border")}
    background-color: black;
    color: white;
    border-radius: 0 0 ${theme("border-radius")} ${theme("border-radius")};

    .NavDrawer {
      &-head {
        line-height: 1;
        padding: 12px;
        text-align: right;
        border-bottom: 1px solid ${color("border-gold")};
      }
      &-close {
        ${Icon} {
          display: block;
        }
      }
    }
  }
`;

const NavDrawerLink = styled(function ({
  as: LinkComponent = Link,
  to,
  href,
  rel,
  icon,
  label,
  MenuItem,
  withChildren,
  disabled,
  hidden,
  opened,
  onClick,
  className,
}) {
  if (hidden) return null;
  const content = MenuItem ? (
    <MenuItem />
  ) : (
    <>
      {Boolean(icon) && (
        <Icon name={icon} size={16} variant="white" hoverVariant="blue" />
      )}
      {label}
    </>
  );
  return (
    <div className={className}>
      {Boolean(withChildren) ? (
        <button
          className="NavDrawerLink-label"
          disabled={disabled}
          onClick={onClick}
          aria-label={label}
        >
          <span className="NavDrawerLink-title">{content}</span>
          <span className="NavDrawerLink-toggle">
            <Icon
              name={opened ? "arrow-up" : "arrow-down"}
              size={15}
              variant="grey"
            />
          </span>
        </button>
      ) : (
        <LinkComponent
          rel={rel}
          href={href}
          to={to}
          disabled={disabled}
          className="NavDrawerLink-label"
        >
          <span className="NavDrawerLink-title">{content}</span>
        </LinkComponent>
      )}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .NavDrawerLink {
    &-label {
      ${font("small")}
      align-items: center;
      color: white;
      display: flex;
      justify-content: space-between;
      flex: 1;
      font-weight: ${theme("font-weight-semi")};
      padding: 12px 24px;
      text-decoration: none;

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.3;
      }
    }

    &-title {
      align-items: center;
      display: flex;
      ${Icon} {
        margin-right: 8px;
      }
    }

    &-toggle {
      background: transparent;
      border: 0;
    }
  }

  ${is("secondary")`
    ${font("small")}
    border-bottom: 0;
    font-weight: ${theme("font-weight-regular")};
  `}
`;

const NavDrawerItem = styled(function ({
  as,
  to,
  href,
  rel,
  icon,
  label,
  secondary,
  className,
  disabled,
  hidden,
  submenu,
}) {
  const match = useRouteMatch(to);
  const [opened, setOpened] = useState(false);
  if (hidden) return null;
  return (
    <div className={className + (match && to ? " NavDrawerItem--active" : "")}>
      <NavDrawerLink
        to={to}
        icon={icon}
        href={href}
        rel={rel}
        label={label}
        disabled={disabled}
        forwardedAs={as}
        secondary={secondary}
        withChildren={submenu?.length}
        opened={opened}
        onClick={() => setOpened(!opened)}
      />
      {Boolean(submenu && opened) && (
        <div className="NavDrawerItem-sub">
          {(submenu || []).map((subItem, index) => (
            <NavDrawerItem {...subItem} secondary key={index} />
          ))}
        </div>
      )}
    </div>
  );
})`
  ${isnot("secondary")`
    border-bottom: 1px solid ${color("border-gold")};
  `}
  position: relative;

  ${Icon} {
    box-sizing: content-box;
  }

  .NavDrawerItem-sub {
    display: flex;
    flex-direction: column;
  }
`;

export const MobileMenu = styled(function ({ className }) {
  const location = useLocation();
  const [opened, setOpened] = useState(false);

  const open = () => setOpened(true);
  const close = () => setOpened(false);

  useEffect(() => {
    close();
  }, [location]);

  return (
    <div className={className}>
      <button className="MobileMenu-burger" onClick={open} aria-label="Menu">
        <Icon name="burger" size={22} variant="blue" />
      </button>
      <NavDrawerPopup
        modal
        open={opened}
        closeOnDocumentClick
        onClose={close}
      />
    </div>
  );
})`
  display: none;
  .MobileMenu-burger {
    padding: 8px;
    ${Icon} {
      display: block;
    }
  }

  ${context("for-tablet-landscape")`
    display: block;
  `}
`;

const NavPrimaryLink = styled(function ({
  to,
  icon,
  label,
  disabled,
  hidden,
  MenuItem,
  className,
  children,
}) {
  if (hidden) return null;
  return (
    <Link className={className} disabled={disabled} to={to}>
      {MenuItem ? (
        <MenuItem />
      ) : (
        <>
          <Icon name={icon} size={16} variant="white" /> {label}
          {children}
        </>
      )}
    </Link>
  );
})`
  ${font("small")}
  align-items: center;
  color: white;
  display: flex;
  color: white;
  font-weight: ${theme("font-weight-semi")};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${color("primary")};
  }

  ${Icon} {
    margin-right: 4px;
  }

  ${is("disabled")`
    cursor: not-allowed;
    opacity: 0.3;
  `}
`;

const NavPrimaryItem = styled(function ({
  submenu,
  disabled,
  hidden,
  className,
  children,
  to,
  icon,
  label,
  MenuItem,
}) {
  const { url } = useRouteMatch();
  const homeRouteMatch = to === paths.home;
  const routeMatch = matchPath(url, to);
  const subItemActive = (submenu || []).some((child) =>
    matchPath(url, child.to)
  );

  const active =
    (homeRouteMatch ? routeMatch?.isExact : routeMatch) || subItemActive;

  if (hidden) return null;
  return (
    <div className={className + (active ? " NavPrimaryItem--active" : "")}>
      <NavPrimaryLink
        to={to}
        icon={icon}
        label={label}
        disabled={disabled}
        MenuItem={MenuItem}
      />
      {Boolean(submenu?.length) && (
        <div className="NavPrimaryItem-sub">{children}</div>
      )}
    </div>
  );
})`
  position: relative;

  & > ${NavPrimaryLink} {
    padding: 32px 18px;
  }
  &:hover .NavPrimaryItem-sub {
    display: block;
    animation: ${slideIn} 0.3s;
  }

  &.NavPrimaryItem--active {
    &::after {
      content: "";
      background-color: ${color("border-big")};
      bottom: 0;
      border-radius: 50px 50px 0 0;
      height: 8px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
    & > ${NavPrimaryLink} {
      color: ${color("primary")};
    }
    --icon-hover: 1;
  }

  .NavPrimaryItem-sub {
    background-color: ${color("bg-dark")};
    border-radius: 0 0 ${theme("border-radius")} ${theme("border-radius")};
    box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.3);
    left: 0;
    list-style-type: none;
    min-width: 100%;
    padding: 8px;
    position: absolute;
    z-index: 99;
    display: none;
    transform: translateZ(0);
  }

  ${context("for-tablet-landscape")`
    padding: 16px;
  `}
`;

const NavPrimarySubItem = styled(function ({
  to,
  disabled,
  hidden,
  icon = "arrow-next",
  label,
  className,
}) {
  if (hidden) return null;
  return (
    <li className={className}>
      <NavPrimaryLink to={to} icon={icon} label={label} disabled={disabled} />
    </li>
  );
})`
  ${NavPrimaryLink} {
    padding: 16px;
    white-space: nowrap;
    ${Icon} {
      transition: opacity .3s ease;
      opacity: 0.5;
      margin-right: 6px;
    }
  }
  &:hover {
    ${NavPrimaryLink} {
      ${Icon} {
        opacity: 1;
      }
    }
  }
}
`;

export const NavPrimary = styled(function ({ className }) {
  const mainMenu = useMainMenu();
  return (
    <div className={className}>
      {mainMenu.map((item, index) => (
        <NavPrimaryItem {...item} key={index}>
          {(item.submenu || []).map((subItem, index) => (
            <NavPrimarySubItem {...subItem} key={index} />
          ))}
        </NavPrimaryItem>
      ))}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
