import { useForm } from "react-hook-form";

import { format as formatDate } from "../../common/date.js";
import { useSelf, useUpdateSelf } from "../services/self.js";
import { color, font, styled, theme, z } from "../util/style.js";
import * as Yup from "../util/validators.js";
import {
  Form,
  FormButton,
  FormField,
  FormInput,
  useFormAction,
  yupResolver,
} from "./forms.jsx";
import Popup from "./Popup.jsx";

const ADULT_MS_AGO = 18 * 365 * 24 * 60 * 60 * 1000; // 18 ans

function useValidateForm(required = false, options = {}) {
  const ADULT_DATE_AGO = new Date();
  ADULT_DATE_AGO.setTime(ADULT_DATE_AGO.getTime() - ADULT_MS_AGO);
  return useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        birthDate: Yup.date()
          [required ? "required" : "optional"]()
          .nullable()
          .transform((v) => (v instanceof Date && !isNaN(v) ? v : null))
          .max(
            ADULT_DATE_AGO,
            "Vous devez avoir au moins 18 ans pour commander sur la boutique"
          ),
        cityOfBirth: Yup.string()
          .trim()
          [required ? "required" : "optional"]()
          .nullable(),
        nationality: Yup.string()
          .trim()
          [required ? "required" : "optional"]()
          .nullable(),
        socialNumber: Yup.socialNumber()
          [required ? "required" : "optional"]()
          .nullable(),
      })
    ),
    ...options,
  });
}

export default styled(function GdprDataPopup({ onSubmit, required, ...props }) {
  const [selfData, { refetch }] = useSelf();

  const form = useValidateForm(required, {
    defaultValues: selfData
      ? {
          birthDate: selfData.birthDate,
          cityOfBirth: selfData.cityOfBirth,
          nationality: selfData.nationality,
          socialNumber: selfData.socialNumber,
        }
      : {},
  });
  const {
    register,
    formState: { errors },
  } = form;

  const updateSelfAction = useUpdateSelf();

  const [onValidate, { loading }] = useFormAction(form, updateSelfAction, {
    transformData: (data) => ({
      ...data,
      birthDate: data.birthDate
        ? formatDate(data.birthDate, "yyyy-MM-dd")
        : null,
    }),
    onError: (err, setError) => {
      setError(
        err?.path || "_global",
        err || {
          message:
            "Vos informations n'ont pas pu être enregistrées. Veuillez réessayer plus tard.",
        }
      );
    },
    onSuccess: () => {
      refetch();
      onSubmit && onSubmit();
    },
  });

  return (
    <Popup {...props} modal lockScroll>
      {() => (
        <Form onSubmit={onValidate} loading={loading} errors={errors}>
          <header>
            <h3>Afin de commander sur les boutiques DAIKIN ELITE...</h3>
            <div className="explain">
              <p className="explain--title">
                Pourquoi DAIKIN vous demande votre n<sup>o</sup> de sécurité
                sociale ?
              </p>
              <p>
                Dans le cadre de la gratification (cadeaux à ses clients),
                DAIKIN collecte des informations personnelles (Nom, Prénom, N°
                de Sécurité Sociale, etc.) de chaque bénéficiaire. Cette
                collecte d’information est indispensable pour permettre à DAIKIN
                de s’acquitter de ses obligations légales relatives à{" "}
                <dfn title="L. 242-1-4 du Code de la Sécurité Sociale et Circulaire interministérielle DSS/5B/2012/56 du 5 mars 2012, prenant effet au 01/01/2012.">
                  la Loi de Finance de la Sécurité Sociale<sup>1</sup>
                </dfn>{" "}
                de 2011. Ces données seront conservées par DAIKIN afin d’être en
                mesure de les présenter à l’URSSAF en cas de contrôle pendant
                une durée de 3 ans, en plus de l’année en cours au moment du
                contrôle.
              </p>
              <p>
                <em>
                  <small>
                    (1) L. 242-1-4 du Code de la Sécurité Sociale et Circulaire
                    interministérielle DSS/5B/2012/56 du 5 mars 2012, prenant
                    effet au 01/01/2012.
                  </small>
                </em>
              </p>
            </div>
          </header>
          <main>
            <FormField label="Votre nom" disabled>
              <FormInput
                value={[selfData?.firstname, selfData?.lastname]
                  .filter(Boolean)
                  .join(" ")}
                type="text"
                disabled
              />
            </FormField>
            <FormField
              errors={errors}
              name="birthDate"
              label="Votre date de naissance"
              required
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} type="date" />
              )}
            </FormField>
            <FormField
              errors={errors}
              name="cityOfBirth"
              label="Votre ville de naissance"
              required
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} type="text" />
              )}
            </FormField>
            <FormField
              errors={errors}
              name="nationality"
              label="Votre nationalité"
              required
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} type="text" />
              )}
            </FormField>
            <FormField
              errors={errors}
              name="socialNumber"
              label="Votre numéro de Sécurité Sociale"
              required
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} type="text" />
              )}
            </FormField>
          </main>
          <footer>
            <FormButton type="submit">Enregistrer</FormButton>
          </footer>
        </Form>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlay")};
    z-index: ${z("popin")};
  }

  &-content {
    ${theme("content-box")}
    ${theme("big-border")}
    max-width: 600px;
    max-height: 90%;
    overflow-y: auto;
    padding: 24px 48px;
    width: 90%;
    text-align: center;

    header {
      h3 {
        border-bottom: 2px solid ${color("primary")};
        display: inline-block;
        font-size: 1em;
        font-weight: ${theme("font-weight-bold")};
        line-height: 1.4;
        margin: 0 0 24px 0;
        padding-bottom: 8px;
        text-align: center;
      }

      .explain {
        color: ${color("text-dark")};
        margin: 24px 0;
        ${font("small")};
        text-align: left;
        &--title {
          font-weight: ${theme("font-weight-bold")};
        }
      }
    }

    main {
      ${FormField} {
        margin-bottom: 12px;
      }
    }

    footer {
      margin-top: 24px;
    }
  }
`;
