import { useCallback } from "react";

import { color, font, is, styled } from "../../util/style.js";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  background: white;
  border: 2px solid ${color("grey-white")};
  cursor: pointer;
  height: 24px;
  line-height: 44px;
  margin-right: 0.5em;
  width: 24px;

  ${is("checked")`
    background: ${color("blue")};
  `}
`;

const Label = styled.label`
  font: ${font("strong")};
  line-height: 44px;
  padding: 0;
  vertical-align: middle;
`;

export default styled(function CheckboxesQuestion({
  className,
  value,
  onChange,
  answers,
}) {
  const onChangeCheckbox = useCallback(
    (evt) =>
      onChange &&
      onChange([
        ...(value || []).filter((v) => v !== evt.nativeEvent.target.value),
        ...(evt.nativeEvent.target.checked
          ? [evt.nativeEvent.target.value]
          : []),
      ]),
    [onChange, value]
  );
  return (
    <div className={className}>
      {answers?.map(({ id, reference, text }) => (
        <div key={id}>
          <Label htmlFor={`checkbox-${id}`}>
            <Checkbox
              key={id}
              id={`checkbox-${id}`}
              value={reference}
              checked={value?.includes(reference)}
              onChange={onChangeCheckbox}
            />{" "}
            {text}
          </Label>
        </div>
      ))}
    </div>
  );
})``;
