import { useMemo, useRef, useState } from "react";

import {
  Form,
  FormField,
  FormInput,
  FormSelect,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import Tooltip, { ArchivedTooltipContent } from "../components/Tooltip.jsx";
import TrainingSessionItem from "../components/TrainingSession.jsx";
import TrainingSessionsStatHeader from "../components/TrainingSessionsStatHeader.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/bandeau_formations.jpg";
import { usePermissions } from "../services/permissions.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  useTrainingSessions,
  useTrainingSessionsStats,
} from "../services/trainingSessions.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, styled, theme } from "../util/style.js";

const DEFAULT_LIMIT = 10;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;
const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const Requests = styled.div`
  ${TrainingSessionItem} + ${TrainingSessionItem} {
    margin-top: 30px;
  }
`;

const Period = styled.div`
  display: flex;
  justify-content: stretch;
  span {
    border: 1px solid transparent;
    display: inline-block;
    height: 40px;
    line-height: 26px;
    padding: 8px;
  }
`;

const statusOptions = [
  { label: "Tous les statuts", value: undefined },
  { label: "En cours", value: "pending" },
  { label: "Terminées", value: "completed" },
  { label: "Annulées", value: "canceled" },
];

function useTrainingSessionsFilter(initData = {}) {
  const [filter, setFilter] = useState(initData);
  return [
    filter,
    useMemo(
      () => ({
        setSearch: (search) => setFilter((filter) => ({ ...filter, search })),
        setStatus: (status) => setFilter((filter) => ({ ...filter, status })),
        setCompanyCode: (companyCode) =>
          setFilter((filter) => ({ ...filter, companyCode })),
        setPeriod: (period) =>
          setFilter((filter) => ({
            ...filter,
            period:
              period && Object.values(period).filter(Boolean).length
                ? period
                : null,
          })),
      }),
      [setFilter]
    ),
  ];
}

export default function TrainingSessions() {
  const { isArchived, company } = useSelfMetaData();
  const [hasPermission] = usePermissions();
  const [stats] = useTrainingSessionsStats();
  const [
    filter,
    { setSearch, setStatus, setCompanyCode, setPeriod },
  ] = useTrainingSessionsFilter();
  const companiesOptions = company?.sites?.length
    ? [
        { label: "Toutes les entreprises", value: undefined },
        {
          label: company.name,
          value: company.id,
        },
        ...(company.sites || []).map((site) => ({
          label: site.name,
          value: site.id,
        })),
      ]
    : [];
  const topScroll = useRef();

  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [sessionsResult, { ready, refetch }] = useTrainingSessions(
    filter,
    pagination
  );

  const sessions = (sessionsResult?.edges || []).map(({ node }) => node);

  return (
    <LoggedInTemplate title="Formations">
      <ImageHeader image={header} icon="school-line" label="Formations" />
      <MainContent>
        <Headline title="Mes sessions de formation" />
        <Container>
          <TrainingSessionsStatHeader stats={stats} />
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    Retrouvez les formations que vous avez commandées et qui
                    sont en cours, annulées ou terminées.
                  </p>
                  <Form>
                    {companiesOptions.length > 1 && (
                      <FormField>
                        <FormSelect
                          placeholder="Filtrer par société"
                          white
                          value={filter.companyCode}
                          onChange={setCompanyCode}
                          options={companiesOptions}
                        />
                      </FormField>
                    )}
                    <FormField>
                      <FormInput
                        white
                        placeholder="Rechercher..."
                        /*FIXME problème avec controlled components //value={filter.search} */
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </FormField>
                    <FormField>
                      <FormSelect
                        placeholder="Filtrer par statut"
                        white
                        value={filter.status}
                        onChange={setStatus}
                        options={statusOptions}
                      />
                    </FormField>
                    <FormField>
                      <Period>
                        <span>Du</span>
                        <FormInput
                          white
                          type="date"
                          style={{ maxWidth: 200 }}
                          placeholder="À partir du"
                          /*FIXME problème avec controlled components //value={filter.period?.from} */
                          onChange={(e) =>
                            setPeriod({
                              ...(filter.period || {}),
                              from: e.target.value || null,
                            })
                          }
                        />
                        <span>au</span>
                        <FormInput
                          white
                          type="date"
                          style={{ maxWidth: 200 }}
                          placeholder="Jusqu'au"
                          /*FIXME problème avec controlled components //value={filter.period?.until} */
                          onChange={(e) =>
                            setPeriod({
                              ...(filter.period || {}),
                              until: e.target.value || null,
                            })
                          }
                        />
                      </Period>
                    </FormField>
                  </Form>

                  <center>
                    <Button
                      to={paths.trainingSessionAdd}
                      disabled={!hasPermission("training:order")}
                    >
                      Commander une session
                    </Button>
                    {isArchived && (
                      <Tooltip
                        align="center"
                        content={<ArchivedTooltipContent />}
                      ></Tooltip>
                    )}
                  </center>
                  <center style={{ marginTop: "32px" }}>
                    <Button
                      to={{
                        pathname: paths.contact,
                        search: `?title=formations`,
                      }}
                      ghost
                    >
                      Contactez-nous
                    </Button>
                  </center>
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />
              <Requests>
                {sessions.length ? (
                  sessions.map((session) => (
                    <TrainingSessionItem
                      session={session}
                      key={session.id}
                      onUpdate={refetch}
                    />
                  ))
                ) : (
                  <Panel>
                    <h3>Aucun résultat</h3>
                    <div>
                      {Object.values(filter).filter(Boolean).length
                        ? "Aucune formation n'a été trouvée avec cette recherche."
                        : "Vous n'avez aucune formation enregistrée."}
                    </div>
                  </Panel>
                )}
              </Requests>
              {Boolean(ready && sessions.length) && (
                <Pagination result={sessionsResult} pagination={pagination} />
              )}
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
