import fontkit from "@pdf-lib/fontkit";
import { PDFDocument, rgb } from "pdf-lib";
import QRCode from "qrcode";
import React, { useState } from "react";

import { formatAmount } from "../../common/currency.js";
import { format as formatDate } from "../../common/date.js";
import { useUpdateDownloadedInterventionVoucher } from "../services/interventionVouchers.js";
import { useSelfMetaData } from "../services/self.js";
import { useSafeEffect } from "../util/useSafe.js";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import { Button, TextButton } from "./typography.jsx";

async function createPdf(voucher, company) {
  const modelPath = `${process.env.WEBSITE_URI}modeles/modele_voucher_intervention.pdf`;
  const fontPath = `${process.env.WEBSITE_URI}fonts/Poppins/poppins-v15-latin-500.ttf`;

  const [existingPdfBytes, defaultFontBytes] = await Promise.all([
    fetch(modelPath).then((res) => res.arrayBuffer()),
    fetch(fontPath).then((res) => res.arrayBuffer()),
  ]);

  const managerInterventionVoucherViewUrl = `${process.env.WEBSITE_URI}qr/iv/${voucher.id}`;

  let pdf;
  try {
    pdf = await PDFDocument.load(existingPdfBytes);
  } catch (e) {
    return;
  }

  pdf.registerFontkit(fontkit);
  const defaultFont = await pdf.embedFont(defaultFontBytes);

  const pages = pdf.getPages();
  const firstPage = pages[0];

  const { width: pageWidth, height: pageHeight } = firstPage.getSize();

  const voucherTextOptions = {
    font: defaultFont,
    x: 460,
    y: 779,
    size: 12,
    color: rgb(0, 0, 0),
  };
  const fieldsHeadlineTextOptions = {
    ...voucherTextOptions,
    x: 200,
    y: 370,
    color: rgb(2 / 256, 144 / 256, 191 / 256),
  };
  const fieldsLabelTextOptions = {
    ...fieldsHeadlineTextOptions,
    x: 120,
    y: 320,
    size: 10,
  };
  const fieldsValueTextOptions = {
    ...fieldsLabelTextOptions,
    x: 260,
    color: rgb(0, 0, 0),
  };
  const fieldsYDelta = -30;
  const qrcodeOptions = {
    x: 460,
    y: 216,
    width: 92,
    height: 92,
  };
  const qrcodeTextOptions = {
    ...voucherTextOptions,
    x: 460 + 92 / 2,
    y: 208,
    size: 5,
    color: rgb(0.5, 0.5, 0.5),
  };
  const footer1TextOptions = {
    x: 50,
    y: 116,
    size: 8,
    color: rgb(1, 1, 1),
  };
  const footer1YDelta =
    -1.2 * defaultFont.heightAtSize(footer1TextOptions.size);
  const footer2TextOptions = { ...footer1TextOptions, y: 42, size: 5 };

  firstPage.drawText(voucher.voucherCode, voucherTextOptions);

  const fieldsHeadline1 =
    "Pour profiter de votre bon d’achat, envoyez-le au service technique";
  const fieldsHeadline2 =
    "au moment de la validation de votre demande d’intervention";
  const fieldsHeadline1Width = defaultFont.widthOfTextAtSize(
    fieldsHeadline1,
    fieldsHeadlineTextOptions.size
  );
  const fieldsHeadline1Height = defaultFont.heightAtSize(
    fieldsHeadlineTextOptions.size
  );
  const fieldsHeadline2Width = defaultFont.widthOfTextAtSize(
    fieldsHeadline2,
    fieldsHeadlineTextOptions.size
  );
  firstPage.drawText(fieldsHeadline1, {
    ...fieldsHeadlineTextOptions,
    x: (pageWidth - fieldsHeadline1Width) / 2,
  });
  firstPage.drawText(fieldsHeadline2, {
    ...fieldsHeadlineTextOptions,
    y: fieldsHeadlineTextOptions.y - fieldsHeadline1Height * 1.2,
    x: (pageWidth - fieldsHeadline2Width) / 2,
  });

  let labelIndex = 0;
  firstPage.drawText("Bénéficiaire", {
    ...fieldsLabelTextOptions,
    y: fieldsLabelTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText("Nom du site", {
    ...fieldsLabelTextOptions,
    y: fieldsLabelTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText("Montant", {
    ...fieldsLabelTextOptions,
    y: fieldsLabelTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText("Date d’édition", {
    ...fieldsLabelTextOptions,
    y: fieldsLabelTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText("Date de validité", {
    ...fieldsLabelTextOptions,
    y: fieldsLabelTextOptions.y + labelIndex++ * fieldsYDelta,
  });

  labelIndex = 0;
  firstPage.drawText(`${company.name} (${voucher.companyCode})`, {
    ...fieldsValueTextOptions,
    y: fieldsValueTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText(voucher.reference || "-", {
    ...fieldsValueTextOptions,
    y: fieldsValueTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText(formatAmount(voucher.amount) + " €", {
    ...fieldsValueTextOptions,
    y: fieldsValueTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText(formatDate(voucher.usedAt), {
    ...fieldsValueTextOptions,
    y: fieldsValueTextOptions.y + labelIndex++ * fieldsYDelta,
  });
  firstPage.drawText(formatDate(voucher.expirationDate), {
    ...fieldsValueTextOptions,
    y: fieldsValueTextOptions.y + labelIndex++ * fieldsYDelta,
  });

  const url = await QRCode.toDataURL(managerInterventionVoucherViewUrl);
  const qrCodeImage = await pdf.embedPng(url);
  firstPage.drawImage(qrCodeImage, qrcodeOptions);
  const qrcodeText = "VÉRIFICATION D'AUTHENTICITÉ";
  firstPage.drawText(qrcodeText, {
    ...qrcodeTextOptions,
    x:
      qrcodeTextOptions.x -
      defaultFont.widthOfTextAtSize(qrcodeText, qrcodeTextOptions.size) / 2,
  });

  let footerLine = 0;
  firstPage.drawText(
    `Bon d'achat valable du ${formatDate(
      new Date(voucher.expirationDate.substr(0, 4), 0, 1)
    )} au ${formatDate(
      voucher.expirationDate
    )} en France métropolitaine, Corse incluse, uniquement pour les clients ELITE PRO+`,
    {
      ...footer1TextOptions,
      y: footer1TextOptions.y + footerLine++ * footer1YDelta,
    }
  );
  firstPage.drawText(`du programme DAIKIN ELITE.`, {
    ...footer1TextOptions,
    y: footer1TextOptions.y + footerLine++ * footer1YDelta,
  });
  firstPage.drawText(
    `La date limite d’envoi de ce bon d'achat est le ${formatDate(
      voucher.expirationDate
    )} inclus.`,
    {
      ...footer1TextOptions,
      y: footer1TextOptions.y + footerLine++ * footer1YDelta,
    }
  );
  firstPage.drawText(
    `L'intervention doit être planifiée avant le ${formatDate(
      new Date(
        voucher.expirationDate.substr(0, 4),
        parseInt(voucher.expirationDate.substr(5, 2), 10),
        voucher.expirationDate.substr(8, 2)
      )
    )} inclus.`,
    {
      ...footer1TextOptions,
      y: footer1TextOptions.y + footerLine++ * footer1YDelta,
    }
  );
  let contacts = [];
  if (voucher.intervention) {
    switch (voucher.intervention.locationKind) {
      case "residentiel":
        contacts.push("contact-service@daikin.fr");
        break;
      case "tertiaire":
        contacts.push("contact-service-pro@daikin.fr");
        break;
    }
  }
  contacts.push("04 37 72 22 04 (choix 3)");
  firstPage.drawText(
    `Pour toutes questions relatives à cette offre, contacter le service technique : ${contacts.join(
      " ou "
    )}.`,
    {
      ...footer1TextOptions,
      y: footer1TextOptions.y + footerLine++ * footer1YDelta,
    }
  );

  const footer21 =
    "Vos données personnelles sont traitées conformément à notre politique de confidentialité. Pour toute précision, vous pouvez consulter notre politique de confidentialité des données disponible sur daikin.fr.";
  const footer22 =
    "DAIKIN AIRCONDITIONING FRANCE SAS – ZA du Petit Nanterre – 31 rue des Hautes Pâtures – Le Narval – Bâtiment B 92737 NANTERRE CEDEX – RCS Nanterre B 967 501 065 – www.daikin.fr";
  // const footer21Width = defaultFont.widthOfTextAtSize(
  //   footer21,
  //   footer2TextOptions.size
  // );
  const footer21Height = defaultFont.heightAtSize(footer2TextOptions.size);
  // const footer22Width = defaultFont.widthOfTextAtSize(
  //   footer22,
  //   footer2TextOptions.size
  // );
  firstPage.drawText(footer21, {
    ...footer2TextOptions,
    // x: (pageWidth - footer21Width) / 2,
  });
  firstPage.drawText(footer22, {
    ...footer2TextOptions,
    y: footer2TextOptions.y - footer21Height * 1.2,
    // x: (pageWidth - footer22Width) / 2,
  });

  return pdf;
}

async function getPdfUrl(pdf) {
  const pdfBytes = await pdf.save();
  return URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
}

function usePdfDownloadUrl(voucher) {
  const [updateDownloadedInterventionVoucher] =
    useUpdateDownloadedInterventionVoucher(voucher.voucherCode);
  const { company } = useSelfMetaData();

  const [pdfUrl, setPdfUrl] = useState();

  async function onDownload() {
    await updateDownloadedInterventionVoucher();
  }

  useSafeEffect(
    (state) => {
      if (!voucher?.id || !company?.id) return;
      async function initializePdf() {
        const pdf = await createPdf(voucher, company);
        if (!pdf) return;
        const url = await getPdfUrl(pdf);
        if (!state.mounted) return;
        setPdfUrl(url);
      }
      initializePdf();
    },
    [voucher?.id, company?.id]
  );

  return [pdfUrl, onDownload];
}

export function DownloadInterventionVoucherPdfTextButton(voucherProps) {
  const [pdfUrl, onDownload] = usePdfDownloadUrl(voucherProps);
  const [invalidPdf, setInvalidPdf] = useState(false);

  return (
    <div>
      {!voucherProps.downloadable ? (
        <TextButton icon="check" disabled mini>
          Pris en compte par Daikin
        </TextButton>
      ) : Boolean(pdfUrl) ? (
        <TextButton
          to={pdfUrl}
          icon="download"
          rel="nofollow"
          onClick={onDownload}
          download={`Daikin-BonIntervention-${
            voucherProps.voucherCode
          }-${formatDate(new Date(), "ddMMyyyy")}.pdf`}
          mini
        >
          Télécharger le bon
        </TextButton>
      ) : (
        <>
          <TextButton onClick={() => setInvalidPdf(true)} icon="download" mini>
            Télécharger le bon
          </TextButton>

          <ConfirmationPopup
            title={"Bon non disponible"}
            open={Boolean(invalidPdf)}
            onClose={() => setInvalidPdf(false)}
          >
            Le bon d'achat intervention {voucherProps.voucherCode} n’a pas pu
            être téléchargé. Si le problème persiste, veuillez contacter nos
            équipes.
          </ConfirmationPopup>
        </>
      )}
    </div>
  );
}

export function DownloadInterventionVoucherPdfButton(voucherProps) {
  const [pdfUrl, onDownload] = usePdfDownloadUrl(voucherProps);
  const [invalidPdf, setInvalidPdf] = useState(false);

  return !voucherProps.downloadable ? (
    <Button icon="check" disabled external>
      Pris en compte par Daikin
    </Button>
  ) : Boolean(pdfUrl) ? (
    <Button
      to={pdfUrl}
      icon="download"
      rel="nofollow"
      onClick={onDownload}
      download={`Daikin-BonIntervention-${
        voucherProps.voucherCode
      }-${formatDate(new Date(), "ddMMyyyy")}.pdf`}
    >
      Télécharger le bon
    </Button>
  ) : (
    <>
      <Button onClick={() => setInvalidPdf(true)} icon="download" external>
        Télécharger le bon
      </Button>

      <ConfirmationPopup
        title={"Bon non disponible"}
        open={Boolean(invalidPdf)}
        onClose={() => setInvalidPdf(false)}
      >
        Le bon d'achat intervention {voucherProps.voucherCode} n’a pas pu être
        téléchargé. Si le problème persiste, veuillez contacter nos équipes.
      </ConfirmationPopup>
    </>
  );
}
