import { useEffect, useRef } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";

import ContentLoading from "../components/ContentLoading.jsx";
import Contest from "../components/Contest.jsx";
import ContestParticipations from "../components/ContestParticipations.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import header from "../img/challenge.jpg";
import {
  useContest,
  useContestParticipationLike,
  useContestParticipationUnlike,
} from "../services/contests.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export const DEFAULT_LIMIT = 12;

export default function ContestDetails() {
  const { challenge: contestAlias } = useParams();

  const topScroll = useRef();
  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [contest, { ready, refetch: reload }] = useContest(
    contestAlias,
    pagination
  );

  const [doLike, resultLike, { loading: likeLoading }] =
    useContestParticipationLike(contest);
  const [doUnlike, resultUnlike, { loading: unlikeLoading }] =
    useContestParticipationUnlike(contest);

  const contestParticipations = contest?.participations?.edges || [];

  const challengeTitle = contest ? contest.title : "Concours";

  // Rafraîchit la liste des participations après like/unlike
  useEffect(
    function () {
      if (likeLoading || unlikeLoading) return;
      reload();
    },
    [likeLoading, unlikeLoading, resultLike, resultUnlike, reload]
  );

  if (ready && (!contest || !contest.isPlayable))
    return <Redirect to={paths.challenges} />;

  return (
    <LoggedInTemplate title={challengeTitle}>
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline title={challengeTitle} />
        <Container>
          {contest ? (
            <Contest
              {...contest}
              createUrl={
                paths.challenges + "/contest/" + contestAlias + "/create"
              }
            />
          ) : (
            <ContentLoading />
          )}
          <span ref={topScroll} />
          {contestParticipations && (
            <>
              <ContestParticipations
                to={paths.challenges + "/contest/" + contestAlias}
                list={contestParticipations}
                onLike={doLike}
                onUnlike={doUnlike}
              />
              {Boolean(ready && contestParticipations?.length) && (
                <Pagination
                  result={contest?.participations}
                  pagination={pagination}
                />
              )}
            </>
          )}
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
