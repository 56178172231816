import _sortBy from "lodash/sortBy.js";
import { useState } from "react";

import { styled, theme } from "../../util/style.js";
import faceBadExtremeFull from "./images/face-bad-extreme-full.svg";
import faceBadExtreme from "./images/face-bad-extreme.svg";
import faceBadFull from "./images/face-bad-full.svg";
import faceBad from "./images/face-bad.svg";
import faceGoodExtremeFull from "./images/face-good-extreme-full.svg";
import faceGoodExtreme from "./images/face-good-extreme.svg";
import faceGoodFull from "./images/face-good-full.svg";
import faceGood from "./images/face-good.svg";
import faceNeutralFull from "./images/face-moyen-full.svg";
import faceNeutral from "./images/face-moyen.svg";

const Emoticon = styled(function ({ className, src, alt, ...props }) {
  return (
    <div className={className} {...props}>
      <img src={src} alt={alt} />
    </div>
  );
})`
  padding: 0 8px;
  cursor: pointer;
  width: 64px;
  img {
    width: 100%;
    max-width: 120px;
  }
`;

const icons = {
  "-2": { [true]: faceBadExtremeFull, [false]: faceBadExtreme },
  "-1": { [true]: faceBadFull, [false]: faceBad },
  0: { [true]: faceNeutralFull, [false]: faceNeutral },
  1: { [true]: faceGoodFull, [false]: faceGood },
  2: { [true]: faceGoodExtremeFull, [false]: faceGoodExtreme },
};

export default styled(function SatisfactionQuestion({
  value,
  answers,
  onChange,
  className,
}) {
  const [hoverReference, setHoverReference] = useState(null);
  return (
    <div className={className}>
      {_sortBy(answers || [], ({ reference }) => +reference).map(
        ({ reference, text }) => (
          <Emoticon
            key={reference}
            alt={text}
            src={
              icons[reference][
                value === reference || hoverReference === reference
              ]
            }
            onClick={() => onChange(value === reference ? null : reference)}
            onMouseOver={() => setHoverReference(reference)}
            onMouseOut={() => setHoverReference(null)}
          />
        )
      )}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  margin: 10px -8px;
  justify-content: flex-start;
`;
