import _differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import _format from "date-fns/format";
import _formatDistance from "date-fns/formatDistance";
import { fr } from "date-fns/locale";
// import _parseISO from "date-fns/parseISO";
import _parseJSON from "date-fns/parseJSON";
// import _toDate from "date-fns/toDate";

const LOCALES = { fr };
let DEFAULT_LOCALE = fr;
let CURRENT_LOCALE = DEFAULT_LOCALE;
const TODAY = new Date();

export function setLocale(locale) {
  CURRENT_LOCALE =
    typeof locale === "string" ? LOCALES[locale] || DEFAULT_LOCALE : locale;
}

export function getLocale() {
  return CURRENT_LOCALE;
}

export function asDate(anything) {
  if (typeof anything === "string" && anything.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return _parseJSON(`${anything}T00:00:00`);
  }
  return _parseJSON(anything);
}

export function format(date, format = "dd/MM/yyyy", options = {}) {
  if (!date) return null;
  return _format(asDate(date), format, { ...options, locale: CURRENT_LOCALE });
}

export function formatDistance(date, dateReference, format, options = {}) {
  return _formatDistance(asDate(date), asDate(dateReference), {
    addSuffix: true,
    ...options,
    locale: CURRENT_LOCALE,
  });
}

// Différence entre une date et le jour J, en jours
export function daysUntilNow(date) {
  return _differenceInCalendarDays(asDate(date), asDate(TODAY));
}
