import { Button, TextButton } from "./typography.jsx";
import { Col, Row } from "./layout.jsx";
import {
  Request,
  RequestFooter,
  RequestHeader,
  RequestHeaderItem,
  RequestHeaderLine,
  RequestSection,
  SmallCol,
} from "./requests.jsx";
import {
  useArchiveInterventionRequest,
  useCancelInterventionRequest,
} from "../services/interventions.js";
import { useForm, useFormAction } from "./forms.jsx";

import ConfirmationPopup from "./ConfirmationPopup.jsx";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";
import { format } from "../../common/date.js";
import { formatCurrency } from "../../common/currency.js";
import { getUrl } from "../util/medias.js";
import { styled } from "../util/style.js";
import { useSelfMetaData } from "../services/self.js";
import { useState } from "react";

const AttachmentLink = styled(Link)`
  ${Icon} {
    margin-left: 4px;
    vertical-align: middle;
  }
  &:hover {
    --icon-hover: 1;
  }
`;

const kindsByKey = {
  diagnostic: "Diagnostic",
  repair: "Dépannage",
  expertise: "Expertise",
  commissioning: "Mise en service",
  maintenance: "Maintenance",
};

const labelByContext = {
  ordered: (internalReference) => `Commande n°${internalReference || "?"}`,
  cec: (internalReference) => `Ticket CEC n°${internalReference || "?"}`,
  quotation: () => `Demande de devis`,
  warranty: () => `Produit sous garantie`,
};

const statusesByKey = {
  pending: { label: "En cours", type: "primary" },
  done: { label: "Terminée", type: "info" },
  declined: { label: "Refusée", type: "info" },
  requireValidation: { label: "À valider", type: "warning" },
  accepted: { label: "Validée", type: "success" },
  cancelled: { label: "Annulée", type: "info" },
  archived: { label: "Archivée", type: "info" },
};

export default styled(function InterventionRequest({
  id,
  createdAt,
  internalReference,
  personInCharge,
  location,
  amount,
  attachments,
  kind,
  context,
  status,
  statusDetails,
  products,
  onUpdate,
  restrictedAccess,
  archived,
  className,
}) {
  const { isEliteProPlus } = useSelfMetaData();

  const [openCancel, setOpenCancel] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const form = useForm();

  const cancelAction = useCancelInterventionRequest(id);
  const [onCancel, { loading: loadingCancel }] = useFormAction(
    form,
    cancelAction,
    {
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "La demande n'a pas pu être annulée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setOpenCancel(false);
        onUpdate();
      },
    }
  );

  const archiveAction = useArchiveInterventionRequest(id);
  const [onArchive, { loading: loadingArchive }] = useFormAction(
    form,
    archiveAction,
    {
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "La demande n'a pas pu être archivée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setOpenArchive(false);
        onUpdate();
      },
    }
  );
  return (
    <Request className={className}>
      <ConfirmationPopup
        title="Annulation de demande"
        open={Boolean(openCancel)}
        onClose={() => setOpenCancel(false)}
        actions={
          <>
            <Button mini onClick={() => setOpenCancel(false)} ghost>
              Annuler
            </Button>
            <Button mini onClick={onCancel}>
              Confirmer
            </Button>
          </>
        }
      >
        Voulez-vous réellement annuler cette demande ? Cette action est
        irréversible.
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Demande archivée"
        open={Boolean(openArchive)}
        onClose={() => setOpenArchive(false)}
        actions={
          <>
            <Button mini onClick={() => setOpenArchive(false)} ghost>
              Annuler
            </Button>
            <Button mini onClick={onArchive}>
              Confirmer
            </Button>
          </>
        }
      >
        Voulez-vous réellement archiver cette demande ? Cette action est
        irréversible.
      </ConfirmationPopup>
      <RequestHeader>
        <RequestHeaderLine>
          <RequestHeaderItem strong>
            {kindsByKey[kind]}
            {" - "}
            {labelByContext[context](internalReference)}
          </RequestHeaderItem>
          <RequestHeaderItem
            strong
            {...{ [statusesByKey[status]?.type]: true }}
          >
            {statusesByKey[status]?.label}
          </RequestHeaderItem>
        </RequestHeaderLine>
        <RequestHeaderLine>
          <RequestHeaderItem small>
            Demande du {format(createdAt)}
          </RequestHeaderItem>
          {Boolean(isEliteProPlus) && (
            <RequestHeaderItem small>
              Budget ELITE PRO+{" : "}
              {Boolean(amount)
                ? `${formatCurrency(amount, false)} max.`
                : `Aucun`}
            </RequestHeaderItem>
          )}
        </RequestHeaderLine>
      </RequestHeader>
      <RequestSection>
        <Row>
          {Boolean(location) && (
            <SmallCol span={6}>
              <strong>Site d'intervention</strong>
              <div>{location.company}</div>
              <div>{location.street}</div>
              <div>
                {location.postalCode} {location.city}
              </div>
              {Boolean(restrictedAccess) && (
                <div>
                  <span style={{ verticalAlign: "middle" }}>
                    Accès restreint{" "}
                  </span>
                  <div>
                    {Boolean(attachments?.length > 0) && (
                      <AttachmentLink
                        to={getUrl("media", attachments[0], "download")}
                        icon="download"
                        external
                        mini
                      >
                        Télécharger la pièce jointe
                        <Icon
                          name="download"
                          variant="black"
                          hoverVariant="blue"
                          size={18}
                        />
                      </AttachmentLink>
                    )}
                  </div>
                </div>
              )}
            </SmallCol>
          )}
          {Boolean(personInCharge) && (
            <SmallCol span={6}>
              <strong>Responsable site</strong>
              <div>
                {personInCharge.name} ({personInCharge.position})
              </div>
              <div>{personInCharge.phone}</div>
              <div>{personInCharge.email}</div>
            </SmallCol>
          )}
          {Boolean(products?.length) && (
            <SmallCol span={6}>
              <strong>Matériel</strong>
              {products.map(({ quantity, reference, serialNumber }, index) => (
                <div key={index}>
                  {quantity}x {reference} {serialNumber}
                </div>
              ))}
            </SmallCol>
          )}
        </Row>
      </RequestSection>
      <RequestFooter>
        {status === "declined" ? (
          <>{statusDetails}</>
        ) : status === "pending" ? (
          <>
            <p>
              Vous pouvez annuler votre demande avant son traitement par le
              service technique.{" "}
            </p>
            <TextButton
              onClick={() => setOpenCancel(true)}
              icon="close-line"
              disabled={loadingCancel}
              mini
            >
              Annuler ma demande
            </TextButton>
          </>
        ) : status === "requireValidation" ? (
          <>Consultez vos emails pour suivre et valider votre demande.</>
        ) : archived ? (
          <>Cette demande a été archivée</>
        ) : (
          <TextButton
            onClick={() => setOpenArchive(true)}
            icon="archive"
            disabled={loadingArchive}
            mini
          >
            Archiver ma demande
          </TextButton>
        )}
      </RequestFooter>
    </Request>
  );
})``;
