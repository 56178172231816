import { formatCurrency } from "../../common/currency.js";
import { format } from "../../common/date.js";
import { styled } from "../util/style.js";
import {
  Request,
  RequestHeader,
  RequestHeaderItem,
  RequestHeaderLine,
  RequestSection,
} from "./requests.jsx";

const status = {
  pending: "En cours",
  completed: "Terminée",
  canceled: "Annulée",
};

export default styled(function TrainingSession({ session, className }) {
  return (
    <Request className={className}>
      <RequestHeader>
        <RequestHeaderLine>
          <RequestHeaderItem strong>
            [{session.training.reference}] {session.training.name}
          </RequestHeaderItem>
          <RequestHeaderItem
            strong
            warning={session.status === "canceled"}
            primary={session.status === "pending"}
            success={session.status === "completed"}
          >
            {status[session.status]}
          </RequestHeaderItem>
        </RequestHeaderLine>
        <RequestHeaderLine>
          <RequestHeaderItem small>
            Du {format(session.period.from)} au {format(session.period.until)}
          </RequestHeaderItem>
        </RequestHeaderLine>
      </RequestHeader>

      <RequestSection>
        <div>
          Société{" : "}
          <strong>
            {session.company.name} ({session.company.code})
          </strong>
        </div>
        <div>
          Stagiaire{" : "}
          <strong>
            {session.trainee.lastname} {session.trainee.firstname}
          </strong>
        </div>
        <div>
          Budget {session.status === "canceled" ? "d'origine" : "décompté"}
          {" : "}
          <strong>{formatCurrency(session.amount, false)}</strong>
        </div>
      </RequestSection>

      {Boolean(session.details) && (
        <RequestSection>
          <div>
            <strong>Commentaire Daikin</strong>
          </div>
          <div>{session.details}</div>
        </RequestSection>
      )}
    </Request>
  );
})``;
