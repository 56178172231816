import { useCallback } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import HttpStatusRoute from "./components/HttpStatusRoute.jsx";
import Challenges from "./pages/Challenges.jsx";
import Contact from "./pages/Contact.jsx";
import ContestCreate from "./pages/ContestCreate.jsx";
import ContestDetails from "./pages/ContestDetails.jsx";
import ContestParticipationsDetails from "./pages/ContestParticipationDetails.jsx";
import Cookies from "./pages/Cookies.jsx";
import Error404 from "./pages/Error404.jsx";
import ExternalChallengeDetails from "./pages/ExternalChallengeDetails.jsx";
import FAQ from "./pages/FAQ.jsx";
import Home from "./pages/Home.jsx";
import Hotline from "./pages/Hotline.jsx";
import InterventionRequest from "./pages/InterventionRequest.jsx";
import Interventions from "./pages/Interventions.jsx";
import InterventionVoucherCreate from "./pages/InterventionVoucherCreate.jsx";
import InterventionVouchers from "./pages/InterventionVouchers.jsx";
import InvoiceParticipation from "./pages/InvoiceParticipation.jsx";
import Legal from "./pages/Legal.jsx";
import Login from "./pages/Login.jsx";
import ParticipationEdit from "./pages/ParticipationEdit.jsx";
import ParticipationRequest from "./pages/ParticipationRequest.jsx";
import Participations from "./pages/Participations.jsx";
import PollDetails from "./pages/PollDetails.jsx";
import PostDetails from "./pages/PostDetails.jsx";
import Posts from "./pages/Posts.jsx";
import Program from "./pages/Program.jsx";
import QuizDetails from "./pages/QuizDetails.jsx";
import Soon from "./pages/Soon.jsx";
import TrainingSessionAdd from "./pages/TrainingSessionAdd.jsx";
import TrainingSessions from "./pages/TrainingSessions.jsx";
import Unauthorized from "./pages/Unauthorized.jsx";
import Vouchers from "./pages/Vouchers.jsx";
import { useSessionData } from "./services/auth.js";
import { paths } from "./services/router.js";
import { useLocationQuery } from "./util/router.js";

export function Router({ onUserConfirmation, ...props }) {
  return (
    <BrowserRouter
      getUserConfirmation={useCallback(
        (message, callback) => {
          if (onUserConfirmation) {
            return onUserConfirmation({ message, callback });
          }
          return callback(window.confirm(message));
        },
        [onUserConfirmation]
      )}
      {...props}
    />
  );
}

function PrivateRoute(props) {
  const sessionData = useSessionData();

  if (!sessionData)
    return <HttpStatusRoute statusCode={302} url={paths.login} />;

  return <Route {...props} />;
}

export function Routes() {
  const error = useLocationQuery().get("error");

  if (error) return <Redirect to={paths.error + "/" + error} />;

  return (
    <Switch>
      <PrivateRoute path={paths.home} exact component={Home} />
      {/* not in exact because we can navigate inside */}
      <PrivateRoute path={paths.program} component={Program} />
      <PrivateRoute
        path={paths.trainingSessions}
        exact
        component={TrainingSessions}
      />
      <PrivateRoute
        path={paths.trainingSessionAdd}
        exact
        component={TrainingSessionAdd}
      />
      <PrivateRoute path={paths.vouchers} exact component={Vouchers} />
      <PrivateRoute
        path={paths.participation}
        exact
        component={Participations}
      />
      <PrivateRoute
        path={paths.participationRequest}
        exact
        component={ParticipationRequest}
      />
      <PrivateRoute
        path={paths.participationEdit}
        exact
        component={ParticipationEdit}
      />
      <PrivateRoute
        path={paths.participationInvoice}
        exact
        component={InvoiceParticipation}
      />
      <PrivateRoute
        path={paths.interventions}
        exact
        component={Interventions}
      />
      <PrivateRoute
        path={paths.interventionRequest}
        exact
        component={InterventionRequest}
      />
      <PrivateRoute
        path={paths.interventionVouchers}
        exact
        component={InterventionVouchers}
      />
      <PrivateRoute
        path={paths.interventionVoucherCreate}
        exact
        component={InterventionVoucherCreate}
      />
      <HttpStatusRoute
        path={paths.accessories}
        url={paths.accessoriesUrl}
        exact
        statusCode={302}
      />
      <HttpStatusRoute
        path={paths.gifts}
        url={paths.giftsUrl}
        exact
        statusCode={302}
      />
      <PrivateRoute
        path={paths.posts + "/:category/:post"}
        exact
        component={PostDetails}
      />
      <PrivateRoute
        path={paths.posts + "/:category?"}
        exact
        component={Posts}
      />
      <PrivateRoute
        path={paths.challenges + "/quiz/:challenge"}
        exact
        component={QuizDetails}
      />
      <PrivateRoute
        path={paths.challenges + "/external/:challenge"}
        exact
        component={ExternalChallengeDetails}
      />
      <PrivateRoute
        path={paths.challenges + "/contest/:challenge"}
        exact
        component={ContestDetails}
      />
      <PrivateRoute
        path={paths.challenges + "/contest/:challenge/create"}
        exact
        component={ContestCreate}
      />
      <PrivateRoute
        path={paths.challenges + "/contest/:challenge/view/:index"}
        exact
        component={ContestParticipationsDetails}
      />
      <PrivateRoute
        path={paths.challenges + "/poll/:challenge"}
        exact
        component={PollDetails}
      />
      <PrivateRoute path={paths.challenges} exact component={Challenges} />
      {/* not in exact because we can navigate inside */}
      <PrivateRoute path={paths.faq} component={FAQ} />
      <PrivateRoute path={paths.hotline} exact component={Hotline} />
      <Route path={paths.contact} exact component={Contact} />
      <Route path={paths.legal} exact component={Legal} />
      <Route path={paths.cookies} exact component={Cookies} />
      <PrivateRoute path="/soon" exact component={Soon} />
      <Route path={paths.login} exact component={Login} />
      <HttpStatusRoute
        path={paths.logout}
        exact
        statusCode={302}
        url={paths.ssoLogout}
      />
      <HttpStatusRoute
        exact
        statusCode={403}
        path={paths.error + "/:code"}
        component={Unauthorized}
      />
      <HttpStatusRoute statusCode={404} path="*" component={Error404} />
    </Switch>
  );
}
