import { useEffect } from "react";
import { useCallback, useMemo, useState } from "react";

import { useQuizNavigation } from "../services/quizzes.js";
import { paths } from "../services/router.js";
import Container from "../templates/common/Container.jsx";
import { checkVideoUrl, getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import ContentLoading from "./ContentLoading.jsx";
import { Checkbox } from "./forms.jsx";
import HtmlText from "./HtmlText.jsx";
import Steps from "./Steps.jsx";
import { Button, ButtonArrowAround } from "./typography.jsx";

const PanelImage = styled.div`
  ${theme("content-box")}
  border-bottom: 0;
  padding: 25px;
`;

export default styled(function Quiz({
  className,
  quiz,
  onSubmit,
  submitLoading,
  result,
}) {
  const {
    previous,
    next,
    restart,
    showStart,
    showEnd,
    number,
    question,
    isLastQuestion,
    answers,
    addAnswer,
    removeAnswer,
    hasAnswer,
    atLeastOneAnswer,
  } = useQuizNavigation(quiz);

  const submit = useCallback(async () => {
    onSubmit && (await onSubmit(answers));
    next();
  }, [onSubmit, answers, next]);

  const image = (question && question.image) || (quiz && quiz.image);
  return (
    <Container className={className}>
      <div className="QuizSection-image">
        {Boolean(image) && (
          <PanelImage>
            <img src={getMediaUrl(image, "challenge-image")} alt={quiz.title} />
          </PanelImage>
        )}
      </div>
      <div className="QuizSection-content">
        {Boolean(submitLoading) ? (
          <ContentLoading />
        ) : Boolean(showStart) ? (
          <QuizStart quiz={quiz} onStart={next} />
        ) : Boolean(showEnd) ? (
          <QuizEnd quiz={quiz} result={result} onReset={restart} />
        ) : (
          Boolean(question) && (
            <QuizQuestion
              quiz={quiz}
              number={number}
              question={question}
              onPrevious={previous}
              isLastQuestion={isLastQuestion}
              addAnswer={addAnswer}
              removeAnswer={removeAnswer}
              hasAnswer={hasAnswer}
              atLeastOneAnswer={atLeastOneAnswer()}
              onNext={isLastQuestion ? submit : next}
            />
          )
        )}
      </div>
    </Container>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  grid-gap: 56px;

  .QuizSection {
    &-image {
      width: 360px;
    }
    &-content {
      flex: 1;
    }
  }

  ${context("for-tablet-landscape")`
    grid-gap: 32px;
    .QuizSection {
      &-image {
        width: 120px;
      }
    }
  `}
`;

const ButtonsList = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  ${is("center")`
    justify-content: center
  `}
`;

const Alert = styled.div`
  ${font("small")}
  color: ${color("grey-text")};
  margin-bottom: 24px;
`;

const ResponsiveVideoContainer = styled.div`
  margin: 24px 0;
  width: 100%;
  padding-top: 56.2%; /* video ratio */
  position: relative;
`;

const VideoContainer = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
`;

function QuizStart({ quiz, onStart }) {
  const checkedUrl = useMemo(
    () => quiz.url && checkVideoUrl(quiz.url),
    [quiz.url]
  );

  return (
    <>
      <Steps max={quiz?.questions.length} current={0} />
      {Boolean(quiz?.attempted) && (
        <Alert>
          Votre entreprise a déjà participé au quiz. Une seule participation par
          entreprise est acceptée.
        </Alert>
      )}

      <HtmlText html={quiz.introduction} />

      {Boolean(quiz.url) && (
        <ResponsiveVideoContainer>
          <VideoContainer
            src={checkedUrl}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
            allowfullscreen
          />
        </ResponsiveVideoContainer>
      )}

      {Boolean(quiz.button && quiz.button.label) && (
        <ButtonsList center>
          <ButtonArrowAround
            download={Boolean(quiz.document)}
            to={quiz.button.url || getMediaUrl(quiz.document)}
          >
            {quiz.button.label}
          </ButtonArrowAround>
        </ButtonsList>
      )}

      <ButtonsList>
        <Button ghost to={paths.challenges}>
          Retour à la liste
        </Button>
        {!quiz?.attempted && (
          <Button onClick={onStart}>Commencer le quiz</Button>
        )}
      </ButtonsList>
    </>
  );
}

function QuizEnd({ quiz, result, onReset }) {
  const resultsByQuestionNumber = (quiz?.questions || []).reduce(
    (results, question, index) => ({
      ...results,
      [index]:
        (result?.mistakes || []).indexOf(question.id) === -1
          ? "success"
          : "error",
    }),
    {}
  );
  return (
    <>
      <Steps
        max={quiz?.questions.length}
        current={quiz?.questions.length}
        colors={resultsByQuestionNumber}
      />
      <HtmlText
        html={result?.success ? quiz.conclusionSuccess : quiz.conclusionFailure}
      />
      <ButtonsList>
        {/* {Boolean(!result?.success) && (
          <Button ghost onClick={onReset}>
            Réessayer
          </Button>
        )} */}
        <Button to={paths.challenges}>Retour à la liste</Button>
      </ButtonsList>
    </>
  );
}

const QuizQuestion = styled(function ({
  number,
  quiz,
  question,
  onNext,
  onPrevious,
  isLastQuestion,
  className,
  addAnswer,
  removeAnswer,
  hasAnswer,
  atLeastOneAnswer,
}) {
  const [displayHelpMessage, setDisplayHelpMessage] = useState(false);
  const onClickNext = useCallback(
    function () {
      if (!atLeastOneAnswer) {
        setDisplayHelpMessage(true);
        return;
      }
      onNext();
    },
    [atLeastOneAnswer, onNext]
  );
  useEffect(
    function () {
      if (atLeastOneAnswer) setDisplayHelpMessage(false);
    },
    [atLeastOneAnswer]
  );
  return (
    <div className={className}>
      <Steps max={quiz?.questions.length} current={number} />
      <HtmlText html={question.text} />
      <div className="QuizQuestion-answers">
        {(question.answers || []).map((answer) => (
          <label key={answer.id} className="QuizQuestion-answer">
            <Checkbox
              white
              onChange={(event) =>
                event.target.checked
                  ? addAnswer(answer.id)
                  : removeAnswer(answer.id)
              }
              checked={hasAnswer(answer.id)}
            />
            {answer.text}
          </label>
        ))}
      </div>
      <ButtonsList>
        <Button ghost onClick={onPrevious}>
          Précédent
        </Button>
        <Button onClick={onClickNext} grey={!atLeastOneAnswer}>
          {displayHelpMessage
            ? "Choisissez une réponse"
            : isLastQuestion
            ? "Terminer"
            : "Suivant"}
        </Button>
      </ButtonsList>
    </div>
  );
})`
  .QuizQuestion {
    &-answers {
      display: flex;
      flex-direction: column;
      grid-gap: 24px;
    }
    &-answer {
      display: flex;
      grid-gap: 24px;
      align-items: center;
      cursor: pointer;
    }
  }
`;
