export const paths = {
  /*
   * NE PAS OUBLIER DE MODIFIER InternalPageSelect.vue
   */
  home: "/",
  login: "/login",
  logout: "/logout",
  ssoLogin: "/sso/login",
  ssoLogout: "/sso/logout",
  services: "/services",
  vouchers: "/services/offers",
  catalog:
    "https://my.daikin.eu/daf/fr_FR/library/applications/Actualites-Daikin/Catalogues-2023.html",
  program: "/program",
  faq: "/faq",
  contact: "/contact",
  legal: "/legal",
  rules: "/files/reglement_daikin_elite.pdf",
  policy:
    "https://www.daikin.fr/fr_fr/politique-de-protection-des-donnees.html",
  cookies: "/cookies",
  formations: "/formations", // deprecated - still used in x-page://
  trainingSessions: "/formations",
  trainingSessionAdd: "/formations/ajout",
  hotline: "/hotline",
  participation: "/participations",
  participationRequest: "/participations/demande",
  participationEdit: "/participations/completer/:id",
  participationInvoice: "/participations/facture",
  interventions: "/interventions",
  interventionRequest: "/interventions/demande",
  interventionVouchers: "/intervention-vouchers",
  interventionVoucherCreate: "/intervention-vouchers/creation",
  accessories: `/accessories`,
  accessoriesUrl: `${process.env.CDN_URI}/redirect-to-shop/accessories`,
  gifts: `/gifts`,
  giftsUrl: `${process.env.CDN_URI}/redirect-to-shop/gifts`,
  posts: "/news",
  challenges: "/challenges",
  error: "/error",

  facebook: "https://www.facebook.com/daikinfrance",
  instagram: "https://www.instagram.com/daikinfrance/",
  linkedin: "https://fr.linkedin.com/company/daikin-airconditioning-france",
  youtube: "https://www.youtube.com/channel/UCpudHnfcCGuT-Pf-uQV8R2Q",
};
