import { Fragment } from "react";

import { getCurrentCode } from "../../common/hotline.js";
import QuestionsSection from "../components/QuestionsSection.jsx";
import { paths } from "../services/router.js";
import { Headline, MainContent } from "../templates/common/Components.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

const faq = [
  {
    id: "program",
    title: "Mon programme",
    themes: [],
    questions: [
      {
        id: "what-is-daikin",
        question: `Qu'est-ce que DAIKIN ELITE ?`,
        answer: (
          <Fragment>
            Le programme DAIKIN ELITE est un programme fidélisation à
            destination des installateurs directs de DAIKIN. <br />
            Le programme DAIKIN ELITE octroie de nombreux avantages&nbsp;: des
            cadeaux, des accessoires, des services ou encore des formations.
          </Fragment>
        ),
      },
      {
        id: "how-to",
        question: `Comment bénéficier des offres proposées dans le cadre du programme ?`,
        answer: (
          <Fragment>
            Directement sur le site DAIKIN ELITE et dans les différentes
            rubriques concernées&nbsp;: cadeaux, accessoires, formations et
            services
          </Fragment>
        ),
      },
      {
        id: "who-can",
        question: `Qui peut bénéficier du programme ?`,
        answer: (
          <Fragment>
            Seuls les clients directs de DAIKIN ayant souscrit à un contrat
            DAIKIN ELITE ont accès au programme.
          </Fragment>
        ),
      },
      {
        id: "what-is-elite-pro",
        question: `Qu'est ce que le statut ELITE PRO ?`,
        answer: (
          <Fragment>
            Le statut ELITE PRO est acquis grâce à l’atteinte d’un chiffre
            d’affaires supérieur ou égal à 90 000€ HT (hors éco-participations)
            réalisé avec DAIKIN au cours de l’année N-1.
          </Fragment>
        ),
      },
      {
        id: "what-is-elite-pro-plus",
        question: `Qu'est ce que le statut ELITE PRO+ ?`,
        answer: (
          <Fragment>
            Le statut ELITE PRO+ est acquis grâce à l’atteinte d'un chiffre
            d’affaires supérieur ou égal à 250 000€ HT (hors éco-participations)
            réalisé avec DAIKIN au cours de l’année N-1.
          </Fragment>
        ),
      },
      {
        id: "next-level",
        question: `Comment passer au statut supérieur ?`,
        answer: (
          <Fragment>
            Le statut acquis en début d'année est conservé jusqu'à la fin de
            l'année N (il ne peut changer en cours d'année N).
            <br />
            Afin de passer à un statut supérieur (Elite Pro +) en année N+1, il
            faudra en année N réaliser un chiffre d'affaires avec Daikin
            supérieur ou égal à 250 000€ HT (hors éco-participation).
            <br />
            En atteignant le statut Elite Pro +, vous pourrez bénéficier
            d'avantages supplémentaires.
          </Fragment>
        ),
      },
    ],
  },
  {
    id: "gifts",
    title: "Boutique cadeaux",
    themes: [
      {
        id: "euros",
        title: "Mes € cadeaux",
        questions: [
          {
            id: "how-to-get",
            question: `Comment gagner des € Cadeaux ?`,
            answer: (
              <Fragment>
                Les € cadeaux sont calculés grâce à votre chiffre d'affaires
                réalisé avec DAIKIN sur l’année en cours. Ils sont donc crédités
                progressivement sur votre cagnotte tout au long de l'année. Tous
                les mois, en fonction du mois précédent, de nouveaux € cadeaux
                vous sont versés. <br />
                Pour en savoir plus sur la mécanique d'attribution des €
                cadeaux, consulter la rubrique{" "}
                <a href={paths.program}>Mon programme</a>
                sur votre site DAIKIN ELITE.
              </Fragment>
            ),
          },
          {
            id: "view",
            question: `Comment consulter mon solde € Cadeaux ?`,
            answer: (
              <Fragment>
                Votre solde € Cadeaux est accessible dans la rubrique{" "}
                <a href={paths.home}>Mon activité</a> du site DAIKIN ELITE ainsi
                que sur la boutique cadeaux.
              </Fragment>
            ),
          },
          {
            id: "how-many-time",
            question: `Quelle est la durée de vie de mes € Cadeaux ?`,
            answer: (
              <Fragment>
                Tous les € cadeaux attribués sur l'année en cours expirent le
                31/12 de l’année N+1. Par exemple, des € cadeaux attribués en
                2021 seront disponibles jusqu'au 31/12/2022.
              </Fragment>
            ),
          },
          {
            id: "who-call",
            question: `J’ai un soucis ou une question sur mes € cadeaux. Qui contacter ?`,
            answer: (
              <Fragment>
                Si vous avez des questions à propos de vos € cadeaux,
                contactez-nous en utilisant le{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
          {
            id: "can-i-give",
            question: `Est-il possible de transférer mes € cadeaux à un autre membre du programme ?`,
            answer: (
              <Fragment>
                Vos € cadeaux sont personnels et ne peuvent être transmis à
                d’autres membres du programme DAIKIN ELITE.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "orders",
        title: "Passer une commande",
        questions: [
          {
            id: "how-to-order",
            question: `Comment passer commande ?`,
            answer: (
              <Fragment>
                Pour passer une commande, il vous suffit de vous rendre dans la
                rubrique{" "}
                <a href={paths.gifts} rel="noreferrer" target="_blank">
                  Cadeaux
                </a>{" "}
                sur votre site DAIKIN ELITE. Vous accédez à l'ensemble du
                catalogue cadeaux à disposition, réparti en plusieurs
                thématiques et marques. Il vous suffit ensuite de sélectionner
                le/les produits de votre choix ainsi que les quantités
                souhaitées et de cliquer sur "Ajouter au panier". <br />
                Rendez-vous ensuite dans votre panier, renseigner votre adresse
                de livraison et de facturation, puis le montant € cadeaux que
                vous souhaitez utiliser pour votre commande. Ce montant vient
                automatiquement en déduction du prix de votre commande. Une fois
                validée, votre commande est automatiquement transmise au service
                Boutique, qui la traitera dans les meilleurs délais.
                <br /> NB&nbsp;: La boutique permet de paiement en CB, les prix
                affichés sont donc en TTC.
              </Fragment>
            ),
          },
          {
            id: "amount",
            question: `Je n’ai pas assez € cadeaux pour commander le produit que je souhaite. Comment puis-je faire ?`,
            answer: (
              <Fragment>
                Si votre solde € cadeaux n'est pas suffisant, vous pouvez
                compléter votre paiement par carte bancaire.
              </Fragment>
            ),
          },
          {
            id: "by-card",
            question: `Puis-je compléter ma commande en payant avec une carte bancaire ?`,
            answer: (
              <Fragment>
                Oui, le paiement par carte bancaire est disponible pour
                commander les cadeaux. Vous pouvez également payer une partie de
                votre commande avec vos € cadeaux et le restant en carte
                bancaire
              </Fragment>
            ),
          },
          {
            id: "order-products",
            question: `Puis-je commander plusieurs produits à la fois ?`,
            answer: (
              <Fragment>
                Oui, vous pouvez commander autant de produits que vous le
                souhaitez. Notez cependant que si votre commande comporte
                plusieurs produits, ces produits peuvent vous être livrées en
                plusieurs colis.
              </Fragment>
            ),
          },
          {
            id: "overseas-shipping",
            question: `Est-il possible de commander des produits depuis les départements d’Outre-Mer ?`,
            answer: (
              <Fragment>
                Non, les commandes sont uniquement livrées en France
                Métropolitaine, Corse incluse.
              </Fragment>
            ),
          },
          {
            id: "order-error",
            question: `Je ne parviens pas à passer commande, comment faire ?`,
            answer: (
              <Fragment>
                Contactez le service client via le{" "}
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>
              </Fragment>
            ),
          },
          {
            id: "order-email",
            question: `Je n’ai pas reçu d’e-mail de confirmation de ma commande ?`,
            answer: (
              <Fragment>
                A chacune de vos commandes, un email de confirmation contenant
                toutes les informations de votre commande vous est envoyé dans
                les minutes qui suivent. Si vous n’avez pas reçu cet e-mail,
                vérifiez en premier lieu qu’il ne se trouve pas dans dans vos
                spams ou courriers indésirables. <br />
                Si vous le trouvez pas, contactez le service client via le
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>
              </Fragment>
            ),
          },
          {
            id: "cancel-order",
            question: `Comment annuler une commande réalisée en ligne ?`,
            answer: (
              <Fragment>
                Vous disposez d’un délai de 14 jours francs à compter de la
                réception du produit ou de la validation de votre commande s’il
                s’agit d’une prestation de services, pour exercer votre droit de
                rétractation, sans pénalité ni justificatif. <br />
                Les modalités d’annulation de votre commande sont précisées dans
                les Conditions Générales de Vente. <br />
                Si le dernier jour du délai tombe un samedi /dimanche/ jour
                férié, il est prolongé au jour ouvrable suivant. <br />
                En cas de commande contenant plusieurs produits, le délai de 14
                jours pour communiquer votre décision de rétractation court à
                compter de la réception du dernier produit.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "shipping",
        title: "Livraisons & retours",
        questions: [
          {
            id: "where-shipping-address",
            question: `Où dois-je renseigner mon adresse de livraison ? `,
            answer: (
              <Fragment>
                Vous pouvez renseigner votre adresse de livraison dans la
                deuxième étape du formulaire de commande. Il est essentiel de
                donner l'ensemble des informations nécessaires à la livraison
                afin de ne pas retarder celle-ci (digicode, nom de société...).
              </Fragment>
            ),
          },
          {
            id: "shipping-time",
            question: `Quel est le délai de livraison d’une commande ?`,
            answer: (
              <Fragment>
                Le délai de livraison d’une commande en ligne dépend des
                conditions de livraison du partenaire proposant le produit. Les
                produits commandés sont expédiés directement par les partenaires
                fournisseurs, dans un délai maximum de 3 à 4 semaines suivant la
                date d’envoi de la confirmation de la commande.
                <br /> En cas de retard de livraison, nous vous en informerons
                par e-mail. En cas de problème ou de retard anormal, n’hésitez
                pas à contacter le service après-vente de votre programme grâce
                au <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
          {
            id: "shipping-not-done",
            question: `Je n’ai toujours pas reçu ma commande, que faire ?`,
            answer: (
              <Fragment>
                Les délais d’expéditions sont de 3 à 4 semaines suivant la date
                d’envoi de la confirmation de la commande reçue par e-mail.
                <br /> Si le délai est dépassé et que vous n’avez toujours pas
                reçu votre commande, nous vous invitons à nous contacter soit en
                utilisant le <a href={paths.contact}>
                  formulaire de contact
                </a>{" "}
                soit par téléphone au{" "}
                <a href={"tel:0146218393"}>01 46 21 83 93</a>. Merci de nous
                indiquer votre n° de commande dans votre demande ainsi que le
                détail des produits commandés.
              </Fragment>
            ),
          },
          {
            id: "shipping-process",
            question: `Je rencontre des difficultés avec ma commande (commande non arrivée, incomplète, …). Qui dois-je contacter ?`,
            answer: (
              <Fragment>
                Notre service client est en lien permanent avec les partenaires
                qui assurent l’expédition et le service après-vente des produits
                commandés. <br />
                Ainsi, si vous rencontrez des difficultés avec votre commande,
                n’hésitez pas à nous contacter grâce au{" "}
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>.
              </Fragment>
            ),
          },
          {
            id: "shipping-error",
            question: `Ma commande est défectueuse, ou ne correspond pas à ce que j’avais commandé. Que faire ?`,
            answer: (
              <Fragment>
                Notre service client est en lien permanent avec les partenaires
                qui assurent l’expédition et le service après-vente des produits
                commandés.
                <br /> Ainsi, si vous rencontrez des difficultés avec votre
                commande, n’hésitez pas à nous contacter grâce au formulaire de
                contact ou par téléphone au{" "}
                <a href={"tel:0146218393"}>01 46 21 83 93</a>.
              </Fragment>
            ),
          },
          {
            id: "return-products",
            question: `Je souhaite retourner un produit commandé et être remboursé : comment dois-je faire ?`,
            answer: (
              <Fragment>
                Vous disposez d’un délai de 14 jours à compter de la réception
                du produit ou de la validation de votre commande s’il s’agit
                d’une prestation de services, pour exercer votre droit de
                rétractation, sans pénalité ni justificatif. En cas de commande
                contenant plusieurs produits, le délai de 14 jours pour
                communiquer votre décision de rétractation court à compter de la
                réception du dernier produit. <br />
                La rétractation ou le remplacement nécessitent forcément que le
                produit soit renvoyé dans son emballage d'origine avec ses
                conditionnements, accessoires, protections, garantie et notice.
                Seuls les produits retournés dans un état permettant à nouveau
                leur commercialisation seront acceptés. En conséquence, les
                articles retournés incomplets, abîmés, endommagés ou salis ne
                seront pas repris. Les modalités sont précisées dans les
                Conditions Générales de Vente. L’adresse à laquelle vous devez
                adresser le(s) produit(s) concerné(s) vous sera communiquée par
                notre service client.
                <br /> Le(s) produit(s) doivent être retourné(s), sans retard
                excessif et au plus tard 14 jours suivant la communication de
                votre décision de vous rétracter.
              </Fragment>
            ),
          },
          {
            id: "return-shipping",
            question: `Les frais de retour d’un produit sont-ils à ma charge ?`,
            answer: (
              <Fragment>
                Dans le cadre du droit de rétractation, les frais de retour
                restent à votre charge.
              </Fragment>
            ),
          },
          {
            id: "reclamation",
            question: ` Je souhaite déposer une réclamation. Que dois-je faire ?`,
            answer: (
              <Fragment>
                Pour formuler une réclamation, nous vous invitons à contacter le
                service client via le{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "products",
        title: "Produits & prix",
        questions: [
          {
            id: "product-not-available",
            question: `L’offre dont je souhaitais bénéficier est en rupture de stock. Puis-je en bénéficier ultérieurement ?`,
            answer: (
              <Fragment>
                Les produits en rupture de stock ne sont généralement plus
                visibles sur votre boutique cadeaux. Néanmoins, si plusieurs
                personnes commandent en même temps sur le site un même prdouit,
                il est possible que l’une d’elle soit au final confrontée à une
                rupture de stock. <br />
                Si un article s’avérait indisponible suite à votre commande et
                malgré nos mises à jour régulières du site, nous vous en
                informerions par e-mail. Un produit de remplacement vous sera
                proposé si possible, sinon, votre commande serait
                automatiquement annulée et vous serez remboursé(e) en totalité.
              </Fragment>
            ),
          },
          {
            id: "shipping-amount",
            question: `Les frais de livraison sont-ils inclus dans les prix de vente des offres ?`,
            answer: (
              <Fragment>
                Oui, les prix indiqués comprennent les frais de livraison en
                France métropolitaine, Corse incluse.
              </Fragment>
            ),
          },
        ],
      },
    ],
  },
  {
    id: "formations",
    title: "Formations Daikin",
    themes: [
      {
        id: "budget",
        title: "Mon budget formations",
        questions: [
          {
            id: "how-to-get",
            question: `Comment gagner un budget formation ?`,
            answer: (
              <Fragment>
                Un montant de 1400€ HT est attribué à tous les membres des
                statuts ELITE PRO et ELITE PRO+. Un budget complémentaire de
                1400€ HT peut être alloué en cours d’année si vous atteignez
                250K€ HT de chiffre d'affaires avec DAIKIN.
              </Fragment>
            ),
          },
          {
            id: "view",
            question: `Comment consulter mon solde de budget formation ?`,
            answer: (
              <Fragment>
                Votre solde de budget Formation est accessible dans la rubrique{" "}
                Mon activité du site DAIKIN ELITE. Il est également mentionné
                lors de la réservation de votre formation.
              </Fragment>
            ),
          },
          {
            id: "how-many-time",
            question: `Quelle est la durée de vie de mon budget formation ?`,
            answer: (
              <Fragment>
                Tous les crédits annuels de formations ne sont valables que pour
                l’année en cours du contrat. Ils doivent être utilisés au plus
                tard le 15 décembre 2022 pour une formation se déroulant au plus
                tard le 31 mars 2023. Ils ne peuvent être ni reconduits, ni
                reportés sur un autre avantage, ni remboursés si non utilisés.
              </Fragment>
            ),
          },
          {
            id: "who-call",
            question: `J’ai un soucis ou une question sur mon budget. Qui contacter ?`,
            answer: (
              <Fragment>
                Si vous avez la moindre question à propos de vos budgets,
                contactez-nous en utilisant le{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
          {
            id: "can-i-give",
            question: `Est-il possible de transférer mon budget à un autre membre du programme ?`,
            answer: (
              <Fragment>
                Vos budgets sont personnels et ne peuvent être transmis à
                d’autres membres du programme DAIKIN ELITE.
              </Fragment>
            ),
          },
        ],
      },
      // {
      //   id: "sessions",
      //   title: "Mes commandes de formation",
      //   questions: [
      //     {
      //       id: "formations",
      //       question: `Où trouver le catalogue formation ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "how-to-book",
      //       question: `Comment commander une formation ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "formation-not-found",
      //       question: `Je ne trouve pas la formation que je souhaite suivre, comment faire ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "webinaire",
      //       question: `Est-il possible de suivre une formation DAIKIN mais en webinaire ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "amount",
      //       question: `Je n’ai pas assez de crédit formation mais je souhaite quand même commander une formation et compléter la somme manquante, comment faire ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "cancel-formation",
      //       question: `Je souhaite annuler ma formation, comment faire ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "cancel-conditions",
      //       question: `Quelles sont les conditions d'annulation ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //     {
      //       id: "cancel-amount",
      //       question: `En cas d'annulation, vais-je récupérer mon budget formation ?`,
      //       answer: <Fragment>En construction</Fragment>,
      //     },
      //   ],
      // },
    ],
  },
  {
    id: "accessories",
    title: "Boutique accessoires",
    themes: [
      {
        id: "budget",
        title: "Mon budget accessoires",
        questions: [
          {
            id: "how-to-get",
            question: `Comment gagner un budget accessoires ?`,
            answer: (
              <Fragment>
                A la mise à jour annuelle des statuts, un montant de 850€ HT est
                attribué à tous les membres des statuts Elite Pro et Elite Pro +
                <br />
                Un budget complémentaire de 850€ HT peut être alloué en cours
                d’année si vous atteignez 250K€ HT de chiffre d'affaires avec
                DAIKIN.
              </Fragment>
            ),
          },
          {
            id: "view",
            question: `Comment consulter mon solde budget accessoires ?`,
            answer: (
              <Fragment>
                Votre solde de budget accessoires est accessible dans la
                rubrique Mon activité du site DAIKIN ELITE.
              </Fragment>
            ),
          },
          {
            id: "how-many-time",
            question: `Quelle est la durée de vie de mon budget Accessoires ?`,
            answer: (
              <Fragment>
                Tous les crédits annuels d'accessoires ne sont valables que pour
                l’année en cours du contrat. Ils doivent être utilisés au plus
                tard le 31 décembre 2022. Ils ne peuvent être ni reconduits, ni
                reportés sur un autre avantage, ni remboursés si non utilisés.
              </Fragment>
            ),
          },
          {
            id: "who-call",
            question: `J’ai un soucis ou une question sur mon budget. Qui contacter ?`,
            answer: (
              <Fragment>
                Si vous avez des questions à propos de vos budgets,
                contactez-nous en utilisant le{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
          {
            id: "can-i-give",
            question: `Est-il possible de transférer mon budget à un autre membre du programme ?`,
            answer: (
              <Fragment>
                Vos budgets sont personnels et ne peuvent être transmis à
                d’autres membres du programme DAIKIN ELITE.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "order",
        title: "Passer une commande",
        questions: [
          {
            id: "how-to-order",
            question: `Comment je passe commande sur l'espace Accessoire ?`,
            answer: (
              <Fragment>
                Pour passer une commande, il vous suffit de vous rendre dans la
                rubrique <a href={paths.accessories}>Accessoires</a> sur votre
                site DAIKIN ELITE. Vous accédez à l'ensemble du catalogue
                accessoires à disposition, réparti en plusieurs thématiques. Il
                vous suffit ensuite de sélectionner le/les produits de votre
                choix ainsi que les quantités souhaitées et de cliquer sur
                "Ajouter au panier". <br />
                Rendez-vous ensuite dans votre panier, renseigner votre adresse
                de livraison et de facturation, puis le montant de votre budget
                accessoires que vous souhaitez utiliser pour votre commande. Ce
                montant vient automatiquement en déduction du prix de votre
                commande. Une fois validée, votre commande est automatiquement
                transmise au service Boutique, qui la traitera dans les
                meilleurs délais.
                <br />
                NB&nbsp;: La boutique permet de paiement en CB, les prix
                affichés sont donc en TTC.
              </Fragment>
            ),
          },
          {
            id: "amount",
            question: `Je n’ai pas assez de budget pour commander le produit que je souhaite. Comment puis-je faire ?`,
            answer: (
              <Fragment>
                Si votre budget accessoire n'est pas suffisant, vous pouvez
                compléter votre paiement par carte bancaire.
              </Fragment>
            ),
          },
          {
            id: "by-card",
            question: `Puis-je compléter ma commande en payant avec une carte bancaire ?`,
            answer: (
              <Fragment>
                Oui, le paiement par carte bancaire est disponible pour
                commander les accessoires. Vous pouvez également payer une
                partie de votre commande avec votre budget accessoires et le
                restant en carte bancaire
              </Fragment>
            ),
          },
          {
            id: "order-products",
            question: `Puis-je commander plusieurs produits à la fois ?`,
            answer: (
              <Fragment>
                Oui, vous pouvez commander autant de produits que vous le
                souhaitez. Notez cependant que si votre commande comporte
                plusieurs produits, ces produits peuvent vous être livrées en
                plusieurs colis.
              </Fragment>
            ),
          },
          {
            id: "overseas-shipping",
            question: `Est-il possible de commander des produits depuis les départements d’Outre-Mer ?`,
            answer: (
              <Fragment>
                Non, les commandes sont uniquement livrées en France
                Métropolitaine, Corse incluse.
              </Fragment>
            ),
          },
          {
            id: "order-error",
            question: `Je ne parviens pas à passer commande, comment faire ?`,
            answer: (
              <Fragment>
                Contactez le service client via le{" "}
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>
              </Fragment>
            ),
          },
          {
            id: "order-email",
            question: `Je n’ai pas reçu d’email de confirmation de ma commande ?`,
            answer: (
              <Fragment>
                A chacune de vos commandes, un email de confirmation contenant
                toutes les informations de votre commande vous est envoyé dans
                les minutes qui suivent. <br />
                Si vous n’avez pas reçu cet e-mail, vérifiez en premier lieu
                qu’il ne se trouve pas dans dans vos spams ou courriers
                indésirables. Si vous le trouvez pas, contactez le service
                client via le <a href={paths.contact}>
                  formulaire de contact
                </a>{" "}
                ou par téléphone au{" "}
                <a href={"tel:0146218393"}>01 46 21 83 93</a>
              </Fragment>
            ),
          },
          {
            id: "cancel-order",
            question: `Comment annuler une commande réalisée en ligne ?`,
            answer: (
              <Fragment>
                Vous disposez d’un délai de 14 jours francs à compter de la
                réception du produit ou de la validation de votre commande s’il
                s’agit d’une prestation de services, pour exercer votre droit de
                rétractation, sans pénalité ni justificatif. <br />
                Les modalités d’annulation de votre commande sont précisées dans
                les Conditions Générales de Vente. Si le dernier jour du délai
                tombe un samedi /dimanche/ jour férié, il est prolongé au jour
                ouvrable suivant. En cas de commande contenant plusieurs
                produits, le délai de 14 jours pour communiquer votre décision
                de rétractation court à compter de la réception du dernier
                produit.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "shipping",
        title: "Livraisons & retours",
        questions: [
          {
            id: "shipping-address",
            question: `Où dois-je renseigner mon adresse de livraison ?`,
            answer: (
              <Fragment>
                Vous pouvez renseigner votre adresse de livraison dans la
                deuxième étape du formulaire de commande. Il est essentiel de
                donner l'ensemble des informations nécessaires à la livraison
                afin de ne pas retarder celle-ci (digicode, nom de société...).
              </Fragment>
            ),
          },
          {
            id: "shipping-time",
            question: `Quel est le délai de livraison d’une commande ?`,
            answer: (
              <Fragment>
                Les produits commandés sont expédiés dans un délai maximum de 3
                à 4 semaines suivant la date d’envoi de la confirmation de la
                commande. <br />
                En cas de retard de livraison, nous vous en informerons par
                e-mail. En cas de problème ou de retard anormal, n’hésitez pas à
                contacter le service après-vente de votre programme grâce au{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
          {
            id: "shipping-not-done",
            question: `Je n’ai toujours pas reçu ma commande, que faire ?`,
            answer: (
              <Fragment>
                Les délais d’expéditions sont de 3 à 4 semaines suivant la date
                d’envoi de la confirmation de la commande reçue par e-mail.
                <br />
                Si le délai est dépassé et que vous n’avez toujours pas reçu
                votre commande, nous vous invitons à nous contacter soit en
                utilisant le <a href={paths.contact}>
                  formulaire de contact
                </a>{" "}
                soit par téléphone au{" "}
                <a href={"tel:0146218393"}>01 46 21 83 93</a>. Merci de nous
                indiquer votre n° de commande dans votre demande ainsi que le
                détail des produits commandés.
              </Fragment>
            ),
          },
          {
            id: "shipping-process",
            question: `Je rencontre des difficultés avec ma commande (commande non arrivée, incomplète, …). Qui dois-je contacter ?`,
            answer: (
              <Fragment>
                Notre service client est en lien permanent avec les partenaires
                qui assurent l’expédition et le service après-vente des produits
                commandés. <br />
                Ainsi, si vous rencontrez des difficultés avec votre commande,
                n’hésitez pas à nous contacter grâce au{" "}
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>.
              </Fragment>
            ),
          },
          {
            id: "shipping-error",
            question: `Ma commande est défectueuse, ou ne correspond pas à ce que j’avais commandé. Que faire ?`,
            answer: (
              <Fragment>
                Notre service client est en lien permanent avec les partenaires
                qui assurent l’expédition et le service après-vente des produits
                commandés. <br />
                Ainsi, si vous rencontrez des difficultés avec votre commande,
                n’hésitez pas à nous contacter grâce au{" "}
                <a href={paths.contact}>formulaire de contact</a> ou par
                téléphone au <a href={"tel:0146218393"}>01 46 21 83 93</a>.
              </Fragment>
            ),
          },
          {
            id: "return-products",
            question: `Je souhaite retourner un produit commandé et être remboursé : comment dois-je faire ?`,
            answer: (
              <Fragment>
                Vous disposez d’un délai de 14 jours à compter de la réception
                du produit ou de la validation de votre commande s’il s’agit
                d’une prestation de services, pour exercer votre droit de
                rétractation, sans pénalité ni justificatif. En cas de commande
                contenant plusieurs produits, le délai de 14 jours pour
                communiquer votre décision de rétractation court à compter de la
                réception du dernier produit. <br />
                La rétractation ou le remplacement nécessitent forcément que le
                produit soit renvoyé dans son emballage d'origine avec ses
                conditionnements, accessoires, protections, garantie et notice.
                Seuls les produits retournés dans un état permettant à nouveau
                leur commercialisation seront acceptés. En conséquence, les
                articles retournés incomplets, abîmés, endommagés ou salis ne
                seront pas repris. <br />
                Les modalités sont précisées dans les Conditions Générales de
                Vente. L’adresse à laquelle vous devez adresser le(s) produit(s)
                concerné(s) vous sera communiquée par notre service client.
                Le(s) produit(s) doivent être retourné(s), sans retard excessif
                et au plus tard 14 jours suivant la communication de votre
                décision de vous rétracter.
              </Fragment>
            ),
          },
          {
            id: "return-shipping",
            question: `Les frais de retour d’un produit sont-ils à ma charge ?`,
            answer: (
              <Fragment>
                Dans le cadre du droit de rétractation, les frais de retour
                restent à votre charge.
              </Fragment>
            ),
          },
          {
            id: "reclamation",
            question: `Je souhaite déposer une réclamation. Que dois-je faire ?`,
            answer: (
              <Fragment>
                Pour formuler une réclamation, nous vous invitons à contacter le
                service client via le{" "}
                <a href={paths.contact}>formulaire de contact</a>.
              </Fragment>
            ),
          },
        ],
      },
      {
        id: "products",
        title: "Produits & prix",
        questions: [
          {
            id: "products-shipping",
            question: `Les frais de livraison sont-ils inclus dans les prix de vente des offres ?`,
            answer: (
              <Fragment>
                Non, les prix indiqués ne comprennent la participation aux frais
                de port. Ces frais sont pris en charge et vous sont offerts par
                DAIKIN.
              </Fragment>
            ),
          },
        ],
      },
    ],
  },
  {
    id: "services",
    title: "Services Daikin",
    themes: [],
    questions: [
      {
        id: "elite-pro-services",
        question: `Quels sont les services disponibles en étant Elite Pro ?`,
        answer: (
          <Fragment>
            En étant Elite Pro, les services disponibles sont&nbsp;:
            <ul>
              <li>Une remise spécifiques sur les pièces détachées</li>
            </ul>
          </Fragment>
        ),
      },
      {
        id: "elite-pro-plus-services",
        question: `Quels sont les services disponibles en étant Elite Pro + ?`,
        answer: (
          <Fragment>
            Les mêmes services que les installateurs ayant un statut Elite Pro
            mais avec un avantage supplémentaire&nbsp;: <br />
            une allocation crédit de 1 000€ HT à l'année (jusqu'au 31/12/2022)
            pour de l'intervention service technique DAIKIN (crédit utilisable
            pour de la mise en service, du dépannage ou de l'assistance
            spécifique)
          </Fragment>
        ),
      },
      {
        id: "hotline",
        question: `Comment accéder à la hotline technique prioritaire ?`,
        answer: (
          <Fragment>
            Vous pouvez contacter la hotline technique du lundi au vendredi de
            9h à 18h au <a href={"tel:0437722400"}>04 37 72 24 00</a> et avec le
            code {getCurrentCode()}. Nos conseillers vous répondent en priorité.
          </Fragment>
        ),
      },
      {
        id: "remise",
        question: `Comment bénéficier de la remise des 32% ? `,
        answer: (
          <Fragment>
            La remise des 32% sur vos pièces détachées se fait d'office car vous
            êtes membres du programme DAIKIN ELITE. Sachez que vous bénéficiez
            en plus d'une remise de 3% si votre commande de vos pièces détachées
            se fait en ligne sur le site Web Spare Part Bank (via le module de
            commande en ligne). <br />
            Pour rappel, l'accès au site est possible soit via
            <a href="www.codes-daikin.fr">www.codes-daikin.fr</a> ou via
            <a href="https://gsdb.ds-navi.co.jp/gsdb/login.asp">
              https://gsdb.ds-navi.co.jp/gsdb/login.asp
            </a>
          </Fragment>
        ),
      },
      {
        id: "workforce",
        question: `Comment bénéficier du forfait main d'oeuvre ? `,
        answer: (
          <Fragment>
            Pour bénéficier du forfait main d'oeuvre, il vous suffit de remplir
            le formulaire en ligne directement dans la rubrique{" "}
            <a href={paths.participation}>
              Demande participation main d'oeuvre
            </a>{" "}
            du site DAIKIN ELITE.
          </Fragment>
        ),
      },
      {
        id: "workforce-conditions",
        question: `Quelles sont les conditions d'annulation pour l'utilisation du forfait main d'oeuvre ? `,
        answer: (
          <Fragment>
            Si vous avez déjà rempli le formulaire main d'oeuvre mais que vous
            n'avez pas encore envoyé votre facture, il vous suffit de ne pas
            l'envoyer pour que la demande soit annulée. <br />
            Vous pouvez également en informer directement le service technique à
            l'adresse suivante&nbsp;:{" "}
            <a href="mailto:contact-service-pro@daikin.fr">
              contact-service-pro@daikin.fr
            </a>
          </Fragment>
        ),
      },
      {
        id: "intervention",
        question: `Comment demander une intervention technique ? `,
        answer: (
          <Fragment>
            Pour effectuer une demande d'intervention technique, il vous suffit
            de remplir le formulaire en ligne directement dans la rubrique{" "}
            <a href={paths.interventions}>Demande d'intervention</a> du site
            DAIKIN ELITE. <br />
            Si vous êtes Elite Pro +, vous disposez d'un budget de 1 000€ HT qui
            peut venir en déduction du montant de l'intervention technique. Si
            vous souhaitez l'utiliser, il vous suffit de le mentionner et de
            renseigner le montant souhaité dans le formulaire en ligne.
          </Fragment>
        ),
      },
      {
        id: "intervention-budget",
        question: `En tant qu'Elite Pro +, comment utiliser mon budget Intervention ?`,
        answer: (
          <Fragment>
            Pour utiliser votre budget intervention, il vous suffit de le
            mentionner et de renseigner le montant souhaité dans le formulaire
            en ligne dédié aux demandes d'Intervention. Si vous validez la
            demande d'intervention, votre budget sera automatiquement imputé à
            l'issue de l'intervention. Si l'intervention n'est pas réalisée,
            votre budget reste disponible.
          </Fragment>
        ),
      },
      {
        id: "cancel-intervention",
        question: `Quelles sont les conditions d'annulation pour l'utilisation du budget intervention technique ? `,
        answer: (
          <Fragment>
            L'annulation de l'utilisation de votre forfait est possible à compté
            de votre demande d'intervention jusqu'au jour J de l'intervention.
          </Fragment>
        ),
      },
    ],
  },
  {
    id: "personal-data",
    title: "Mes données personnelles",
    themes: [],
    questions: [
      {
        id: "update-data",
        question: `Comment modifier les informations de mon compte ?`,
        answer: (
          <Fragment>
            Pour modifier vos informations personnelles, n’hésitez pas à vous
            rapprocher de votre commercial DAIKIN ou contactez-nous via le{" "}
            <a href={paths.contact}>formulaire de contact</a>.
          </Fragment>
        ),
      },
      {
        id: "phone",
        question: `À quoi sert mon numéro de téléphone sur le programme ?`,
        answer: (
          <Fragment>
            Votre numéro de téléphone permet de vous contacter rapidement pour
            les livraisons de vos commandes.
          </Fragment>
        ),
      },
      {
        id: "delete",
        question: `Comment supprimer mon compte ?`,
        answer: (
          <Fragment>
            Nous sommes désolés d’apprendre que vous souhaitez quitter le
            programme ELITE. Veuillez effectuer votre demande via le{" "}
            <a href={paths.contact}>formulaire de contact</a> sur le site.{" "}
            <br />
            Une fois votre demande validée, vous ne pourrez plus accéder à votre
            compte et donc bénéficier de vos avantages (budget accessoires,
            budget formation, € cadeaux...)
          </Fragment>
        ),
      },
    ],
  },
];

export default function FAQ() {
  return (
    <LoggedInTemplate title="FAQ">
      <MainContent>
        <Headline
          title={
            <>
              Une question ?<br />
              Vos réponses se trouvent peut-être ici
            </>
          }
        />
        <QuestionsSection title="Sélectionnez une thématique" content={faq} />
      </MainContent>
    </LoggedInTemplate>
  );
}
