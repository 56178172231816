export const categories = [
  {
    id: "all",
    title: "Toutes les actualités",
  },
  {
    id: "products",
    title: "Produits & services",
  },
  {
    id: "events",
    title: "Événements",
  },
  // {
  //   id: "market",
  //   title: "Marché",
  // },
  {
    id: "sales",
    title: "Offres commerciales",
  },
  // {
  //   id: "training",
  //   title: "Formations & webinaires",
  // },
];
