// https://usehooks.com/useLocalStorage/

import { useCallback, useState } from "react";

// Hook
export default function useLocalStorage(
  key,
  initialValue = null,
  transformValuesToStore = null
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      // Get from local storage by key
      const item = key ? window.localStorage.getItem(key) : null;
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== "undefined" && key) {
          window.localStorage.setItem(
            key,
            JSON.stringify(
              transformValuesToStore
                ? transformValuesToStore(valueToStore)
                : valueToStore
            )
          );
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key, storedValue, transformValuesToStore]
  );

  const remove = useCallback(() => {
    if (key) window.localStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, remove];
}
