import { getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import {
  Controller,
  Form,
  FormField,
  FormInput,
  FormSelect,
  FormTextarea,
  FormUpload,
} from "./forms.jsx";
import HtmlText from "./HtmlText.jsx";
import { Button } from "./typography.jsx";

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;

const ContestParticipationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  grid-gap: 56px;

  ${context("for-tablet-landscape")`
    flex-wrap: wrap;
    width: auto;
  `}
`;

const ContestParticipationImage = styled(Panel)`
  width: 530px;

  ${context("for-tablet-landscape")`
    width: 100%;
  `}

  .FormField-input label {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      text-align: center;
    }
  }
`;

const ContestParticipationContent = styled.div`
  flex: 1;
`;

const ContestParticipationDescription = styled.p`
  ${font("paragraph")};
  margin: 0;
  padding: 0;
`;

const ContestParticipationSuccessTitle = styled.h3`
  ${font("headline5")}
  color: ${color("primary")};
`;

const Buttons = styled.div`
  padding: 0;
  display: flex;
  grid-gap: 24px;
  justify-content: space-between;
`;

const INSTALLATION_TYPES = [
  { value: "Résidentiel", label: "Résidentiel" },
  { value: "Tertiaire", label: "Tertiaire" },
];

const PRODUCT_TYPES = [
  { value: "Air/Air", label: "Air/Air" },
  { value: "Air/Eau", label: "Air/Eau" },
  {
    value: "Chauffe-eau thermodynamique",
    label: "Chauffe-eau thermodynamique",
  },
];

export default styled(function ContestParticipationCreateForm({
  contest,
  success,
  form,
  detailsUrl,
  ...props
}) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const installationType = watch("installationType");

  if (success) {
    return (
      <ContestParticipationContainer>
        {Boolean(contest?.image) && (
          <ContestParticipationImage>
            <img
              src={getMediaUrl(contest?.image, "challenge-image")}
              alt={contest?.title}
            />
          </ContestParticipationImage>
        )}
        <ContestParticipationContent>
          <ContestParticipationSuccessTitle>
            Merci pour votre participation !
          </ContestParticipationSuccessTitle>
          <HtmlText html={contest?.conclusion} />
          <Buttons>
            <Button to={detailsUrl}>Retour à la liste</Button>
          </Buttons>
        </ContestParticipationContent>
      </ContestParticipationContainer>
    );
  }

  return (
    <Form {...props}>
      <ContestParticipationContainer>
        <ContestParticipationImage>
          <FormField
            required
            label="Fichier"
            name="file"
            errors={errors}
            info="Formats acceptés : jpg, png"
          >
            {({ name, ...childProps }) => (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <FormUpload
                    {...field}
                    {...childProps}
                    image
                    placeholder="Sélectionnez l'image à envoyer"
                  />
                )}
              />
            )}
          </FormField>
        </ContestParticipationImage>
        <ContestParticipationContent>
          <Panel>
            {Boolean(contest) && (
              <ContestParticipationDescription>
                <HtmlText html={contest.introduction} />
              </ContestParticipationDescription>
            )}

            <FormField
              required
              label="Titre de votre projet"
              name="title"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>

            <FormField
              required
              label="Type d'installation"
              name="installationType"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      {...childProps}
                      placeholder="Type d'installation"
                      options={[
                        ...INSTALLATION_TYPES,
                        { value: "Autre", label: "Autre..." },
                      ]}
                    />
                  )}
                />
              )}
            </FormField>

            {installationType === "Autre" && (
              <FormField
                required
                label="Précisez le type d'installation"
                name="installationTypeOther"
                errors={errors}
              >
                {({ name, ...childProps }) => (
                  <FormInput {...register(name)} {...childProps} />
                )}
              </FormField>
            )}

            <FormField
              required
              label="Type de produit installé"
              name="productType"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <FormSelect
                      {...field}
                      {...childProps}
                      placeholder="Type de produit installé"
                      options={PRODUCT_TYPES}
                    />
                  )}
                />
              )}
            </FormField>

            <FormField
              required
              label="Ville du projet"
              name="city"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>

            <FormField
              required
              label="Description de l'installation effectuée"
              name="description"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormTextarea {...register(name)} {...childProps} />
              )}
            </FormField>

            <Buttons>
              <Button ghost to={detailsUrl}>
                Annuler
              </Button>
              <Button type="submit">J'envoie ma participation</Button>
            </Buttons>
          </Panel>
        </ContestParticipationContent>
      </ContestParticipationContainer>
    </Form>
  );
})``;
