import { useMemo, useRef } from "react";

import { format as formatDate } from "../../common/date.js";
import Tooltip, {
  ArchivedTooltipContent,
  ExpiredTooltipContent,
} from "../components/Tooltip.jsx";
import { useChallenges } from "../services/challenges.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import Container from "../templates/common/Container.jsx";
import { getMediaUrl } from "../util/medias.js";
import { color, context, font, styled, theme } from "../util/style.js";
import ContentLoading from "./ContentLoading.jsx";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";
import Pagination, { usePagination } from "./Pagination.jsx";

const DEFAULT_LIMIT = 10;

const Challenge = styled(function ({
  attempted,
  multiple,
  to,
  thumbnail,
  title,
  date,
  summary,
  isPlayable,
  playable,
  className,
  buttonLabel,
}) {
  const disabled = !to || !isPlayable;
  return (
    <div className={className}>
      <div className="Challenge-image">
        {Boolean(thumbnail) && (
          <img
            src={getMediaUrl(thumbnail, "challenge-thumbnail")}
            alt={title}
          />
        )}
      </div>
      <div className="Challenge-container">
        <Link className="Challenge-title" to={to} disabled={disabled}>
          {title}
        </Link>
        {date && (
          <div className="Challenge-date">
            {formatDate(date, "dd MMMM yyyy")}
          </div>
        )}
        {Boolean(attempted) && (
          <div className="Challenge-attempted">
            Votre entreprise a déjà participé au challenge.{" "}
            {!multiple &&
              "Une seule participation par entreprise est acceptée."}
          </div>
        )}
        <div className="Challenge-content">{summary}</div>

        {(Boolean(multiple) || !attempted) && Boolean(to) && (
          <div className="Challenge-bottomActions">
            <div>
              {isPlayable ? (
                <Link className="Challenge-seeMore" to={to} disabled={disabled}>
                  {buttonLabel}
                  <Icon name="arrow-next" size={18} variant="blue" />
                </Link>
              ) : (
                <Link className="Challenge-seeMore" to={to} disabled={disabled}>
                  Participations{" "}
                  {playable?.from && +new Date(playable?.from) > +new Date()
                    ? `ouvertes à partir du ${formatDate(
                        playable?.from,
                        "dd MMMM yyyy"
                      )}`
                    : playable?.until &&
                      +new Date(playable?.until) < +new Date()
                    ? `fermées depuis le ${formatDate(
                        playable?.until,
                        "dd MMMM yyyy"
                      )}`
                    : `fermées`}
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
})`
  position: relative;
  padding: 24px;
  border-radius: ${theme("border-radius")};
  background: white;
  display: flex;
  grid-gap: 32px;
  align-items: flex-start;

  & + & {
    margin-top: 32px;
  }

  .Challenge {
    &-container {
      flex: 1;
    }
    &-image {
      background: ${color("white-grey2")};
      img {
        display: block;
        width: 220px;
        height: 220px;
      }
    }
    &-title {
      ${font("headline5")}
      color: ${color("primary")};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-tags {
      display: flex;
      grid-gap: 2px;
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    &-date {
      ${font("small")}
      color: ${color("grey-text")};
    }
    &-content {
      margin-top: 24px;
      overflow: hidden;
      margin-bottom: 48px;
    }
    &-tag {
      ${font("mini")}
      display: inline-block;
      background: ${color("grey-light")};
      color: white;
      padding: 2px 6px;
    }
    &-bottomActions {
      position: absolute;
      bottom: 24px;
      right: 24px;
      display: flex;
      grid-gap: 8px;
      justify-content: flex-end;
    }
    &-attempted {
      ${font("small")}
      color: ${color("grey-text")};
    }
    &-seeMore {
      ${Icon} {
        vertical-align: middle;
        margin-left: 12px;
        position: relative;
        left: 0;
        transition: left 0.3s ease;
      }
      &:hover {
        --icon-hover: 1;
        ${Icon} {
          left: 3px;
        }
      }
    }
  }

  ${context("for-desktop")`
    .Challenge {
      &-image img {
        width: 110px;
        height: 110px;
      }
    }
  `}

  ${context("for-tablet-landscape")`
    .Challenge {
      &-image img {
        width: 220px;
        height: 220px;
      }
    }
  `}

  ${context("for-tablet-portrait")`
    .Challenge {
      &-image {
        display: none;
      }
    }
  `}
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const CHALLENGE_ALIAS_BY_TYPE = {
  QuizPrivate: "quiz",
  ExternalChallengePrivate: "external",
  ContestPrivate: "contest",
  PollPrivate: "poll",
};

export default styled(function ChallengesSection({ className }) {
  const topScroll = useRef();
  const { isArchived, isSubscriptionExpired } = useSelfMetaData();

  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [challengesResult, { ready, loading }] = useChallenges(pagination);

  const challenges = useMemo(
    () => (challengesResult?.edges || []).map(({ node }) => node),
    [challengesResult]
  );

  return (
    <>
      <Container className={className}>
        <div className="ChallengesSection-listChallenges">
          {isArchived && (
            <Tooltip align="center" content={<ArchivedTooltipContent />} />
          )}
          {isSubscriptionExpired && (
            <Tooltip align="center" content={<ExpiredTooltipContent />} />
          )}
          <ScrollRef ref={topScroll} />
          {loading ? (
            <ContentLoading />
          ) : challenges?.length ? (
            (challenges || []).map((challenge) => (
              <Challenge
                key={challenge.id}
                to={
                  !isArchived &&
                  !isSubscriptionExpired &&
                  `${paths.challenges}/${
                    CHALLENGE_ALIAS_BY_TYPE[challenge.__typename]
                  }/${challenge.alias}`
                }
                {...challenge}
                buttonLabel={
                  (challenge.__typename !== "QuizPrivate" &&
                    challenge.__typename !== "PollPrivate" &&
                    challenge.button.label) ||
                  "Participer"
                }
              />
            ))
          ) : (
            <Panel>
              <h3>Aucun résultat</h3>
              <div>Aucun challenge disponible pour le moment.</div>
            </Panel>
          )}
          {Boolean(ready && challenges?.length) && (
            <Pagination result={challengesResult} pagination={pagination} />
          )}
        </div>
      </Container>
    </>
  );
})``;
