import { useInterventionsStats } from "../services/interventions.js";
import { context, styled } from "../util/style.js";
import Stat from "./Stat.jsx";

const StatRow = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 24px;

  ${context("for-tablet-portrait")`
    display: grid;
    grid-gap: 8px 24px;
    grid-template:
      'pending declined'
      'validation archived'
      'accepted empty' / 1fr 1fr;
  `}
`;

export default styled(function InterventionsRequestsStatHeader({
  stats,
  className,
}) {
  return (
    <div className={className}>
      <StatRow>
        <Stat
          style={{ gridArea: "pending" }}
          icon="clipboard-reload"
          value={stats?.pending || 0}
          label={stats?.pending > 1 ? "demandes en cours" : "demande en cours"}
          type="primary"
        />
        <Stat
          style={{ gridArea: "validation" }}
          icon="hourglass"
          value={stats?.requireValidation || 0}
          label={
            stats?.requireValidation > 1
              ? "demandes à valider"
              : "demande à valider"
          }
          type="warning"
        />
        <Stat
          style={{ gridArea: "accepted" }}
          icon="clipboard-valid"
          value={stats?.accepted || 0}
          label={
            stats?.accepted > 1 ? "demandes acceptées" : "demande acceptée"
          }
          type="success"
        />
        <Stat
          style={{ gridArea: "declined" }}
          icon="clipboard-error"
          value={stats?.declined || 0}
          label={stats?.declined > 1 ? "demandes refusées" : "demande refusée"}
        />
        <Stat
          style={{ gridArea: "archived" }}
          icon="archive"
          value={stats?.archived || 0}
          label={
            stats?.archived > 1 ? "demandes archivées" : "demande archivée"
          }
        />
      </StatRow>
    </div>
  );
})`
  max-width: 660px;
  margin: 0 auto 72px;

  ${context("for-tablet-portrait")`
    padding: 0 32px;
  `}
`;
