import _escapeRegExp from "lodash/escapeRegExp";
import { useMemo } from "react";

import { useSessionData } from "./auth.js";

export function usePermissions() {
  const sessionData = useSessionData();
  const permissions = sessionData?.permissions || [];
  const permissionsMatchs = useMemo(
    () =>
      permissions
        .filter((permission) => permission[0] !== "!")
        .map(
          (permissionMatch) =>
            new RegExp(
              "^" +
                _escapeRegExp(permissionMatch).replace(/\\\*/gim, "(.+)") +
                "$"
            )
        ),
    permissions
  );
  const permissionsExceptions = useMemo(
    () =>
      permissions
        .filter((permission) => permission[0] === "!")
        .map(
          (permissionsException) =>
            new RegExp(
              "^" +
                _escapeRegExp(permissionsException.substring(1)).replace(
                  /\\\*/gim,
                  "(.+)"
                ) +
                "$"
            )
        ),
    permissions
  );
  return useMemo(
    () => [
      (permission) =>
        permissionsExceptions.filter((permissionException) =>
          Boolean(permissionException.test(permission))
        ).length === 0 &&
        (!permissionsMatchs.length ||
          permissionsMatchs.reduce(
            (hasPermission, permissionMatch) =>
              hasPermission || Boolean(permissionMatch.test(permission)),
            false
          )),
      permissions,
    ],
    [permissions, permissionsMatchs]
  );
}
