import React, { useState } from "react";

import ConfirmationPopup from "../components/ConfirmationPopup.jsx";
import {
  Controller,
  Form,
  FormField,
  FormInput,
  FormSelect,
  FormTextarea,
  useForm,
  useFormAction,
  yupResolver,
} from "../components/forms.jsx";
import { ButtonArrowAround } from "../components/typography.jsx";
import headerMobile from "../img/contact_bandeau_mobile.jpg";
import headerMobile2x from "../img/contact_bandeau_mobile@2x.jpg";
import header from "../img/contact_bandeau.jpg";
import header2x from "../img/contact_bandeau@2x.jpg";
import contactBackground from "../img/contact_form_photo.jpg";
import { useSessionData } from "../services/auth.js";
import { useContact } from "../services/contact.js";
import { usePermissions } from "../services/permissions.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import LoggedOutTemplate from "../templates/LoggedOutTemplate.jsx";
import { useLocationQuery } from "../util/router.js";
import { context, font, styled, theme } from "../util/style.js";
import * as Yup from "../util/validators.js";

const SUBJECTS = [
  {
    label: "J'ai une demande à propos des accessoires ou des cadeaux",
    value: "accessoriesOrGifts",
    permission: "stores:*",
  },
  {
    label: "J'ai une demande à propos des formations",
    value: "formations",
    permission: "training:*",
  },
  {
    label: "Je n'arrive pas à me connecter au site",
    value: "authentication",
    permission: "manager:*",
  },
  {
    label:
      "J’ai une demande liée au service technique (participation main d’œuvre, intervention technique, hotline technique…)",
    value: "technicalService",
    permission: "technical:*",
  },
  {
    label: "Modification de mon compte",
    value: "accountUpdate",
  },
];

function useContactForm(loggedIn, defaultValues = {}) {
  return useForm({
    mode: "onBlur",
    defaultValues,
    resolver: yupResolver(
      Yup.object().shape({
        ...(Boolean(!loggedIn) && {
          email: Yup.email().required("Veuillez renseigner votre e-mail"),
        }),
        title: Yup.string()
          .nullable()
          .required("L'objet du message est obligatoire"),
        message: Yup.string()
          .nullable()
          .required("Veuillez décrire votre demande"),
      })
    ),
  });
}

function usePermissionSubjects() {
  const [hasPermission] = usePermissions();
  return SUBJECTS.filter(
    (item) => !item.permission || hasPermission(item.permission)
  );
}

const ContactPanel = styled(function ({ loggedIn, className }) {
  const title = useLocationQuery().get("title");
  const [success, setSuccess] = useState(false);
  const contactAction = useContact(loggedIn);
  const subjects = usePermissionSubjects();

  const form = useContactForm(loggedIn, { title });
  const {
    register,
    reset,
    control,
    formState: { errors },
  } = form;

  const [onContact, { loading: loadingContact }] = useFormAction(
    form,
    contactAction,
    {
      transformData: (data) => ({ ...data }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre demande n'a pas pu être envoyée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setSuccess(true);
        reset({ title: "", message: "" });
      },
    }
  );

  return (
    <div className={className}>
      <div className="ContactPanel-colImage">
        <h2>
          Contactez-nous&nbsp;!
          <br />
          L’équipe Daikin vous répond dans les meilleurs délais
        </h2>
      </div>
      <div className="ContactPanel-colForm">
        <ConfirmationPopup
          title="Message envoyé"
          open={Boolean(success)}
          onClose={() => setSuccess(false)}
        >
          Votre message a été transmis à nos équipes et sera traité dans les
          plus brefs délais !
        </ConfirmationPopup>
        <Form onSubmit={onContact} loading={loadingContact} errors={errors}>
          {Boolean(!loggedIn) && (
            <FormField errors={errors} name="email" required>
              {({ name, ...childProps }) => (
                <FormInput
                  type="email"
                  {...register(name)}
                  {...childProps}
                  placeholder="Votre e-mail"
                />
              )}
            </FormField>
          )}
          <FormField errors={errors} name="title" required>
            {({ name, ...childProps }) => (
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <FormSelect
                    {...field}
                    {...childProps}
                    options={subjects}
                    placeholder="Choisir un objet..."
                  />
                )}
              />
            )}
          </FormField>
          <FormField errors={errors} name="message" required>
            {({ name, ...childProps }) => (
              <FormTextarea
                {...register(name)}
                {...childProps}
                placeholder="Votre message"
              />
            )}
          </FormField>
          <FormField>
            <ButtonArrowAround type="submit">J'envoie</ButtonArrowAround>
          </FormField>
        </Form>
      </div>
    </div>
  );
})`
  ${theme("content-box")};
  display: flex;
  flex-wrap: wrap;
  .ContactPanel {
    &-colImage,
    &-colForm {
      flex: 0 0 50%;
    }
    &-colImage {
      align-items: flex-end;
      background-image: url(${contactBackground});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: flex;
      max-width: 40%;
      padding: 56px;
      h2 {
        ${font("headline4")}
        color: white;
        margin: 0;
        max-width: 360px;
      }
    }
    &-colForm {
      flex: 1;
      padding: 72px;
    }
  }

  ${context("for-tablet-landscape")`
    .ContactPanel {
      &-colImage,
      &-colForm {
        flex: 0 0 100%;
        max-width: none;
      }
      &-colImage {
        background-position: center;
        justify-content: center;
        padding: 24px;
        text-align: center;
      }
      &-colForm {
        padding: 24px;
      }
    }
  `}
`;

const Content = styled.div`
  margin: auto;
`;

export default function Contact() {
  const sessionData = useSessionData();

  return sessionData ? (
    <LoggedInTemplate title="Contact">
      <ImageHeader
        image={header}
        image2x={header2x}
        imageMobile={headerMobile}
        imageMobile2x={headerMobile2x}
        icon="chat"
        label="Contact"
      />
      <MainContent>
        <Container>
          <Headline title="Une question ? Besoin d'aide ?" />
          <ContactPanel loggedIn />
        </Container>
      </MainContent>
    </LoggedInTemplate>
  ) : (
    <LoggedOutTemplate title="Contact">
      <Content>
        <ContactPanel />
      </Content>
    </LoggedOutTemplate>
  );
}
