import { context, styled } from "../../util/style.js";
import { getHeight } from "./data.js";

// Pourcentage de la jauge a partir duquel le texte devient blanc
const activeSteps = [30, 50, 64, 140, 160, 174, 250, 270, 285];

export default styled(function({ revenue, className, width }) {
  const jaugeHeight = getHeight(revenue / 1000);

  return (
    <svg
      version="1.1"
      id="GraphiqueCA"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 370 305"
      className={className}
      width={width}
      xmlSpace="preserve"
      style={{ enableBackground: "new 0 0 370 305" }}
    >
      {/* <!-- MASK DE LA GAUGE  --> */}
      <defs>
        <clipPath id="gauge-mask">
          <rect x="117" y="0" width="130" height="305" rx="30" ry="30" />
        </clipPath>
      </defs>
      {/* <!-- MASK DE LA GAUGE  --> */}
      {/* <!-- FORME GAUGE  -->	 */}
      <path
        id="gauge-background"
        fill="#F5F5F5"
        d="M144.6480103,3.0000069h73.6100006c14.3594055,0,26,11.6405954,26,26v246.6860046
	c0,14.3594055-11.6405945,26-26,26h-73.6100006c-14.3594055,0-26-11.6405945-26-26V29.0000076
	C118.6480103,14.6406021,130.2886047,3.0000069,144.6480103,3.0000069z"
      />
      {/* <!-- /FORME GAUGE  -->	 */}
      {/* <!-- GAUGE POURCENTAGE  --> */}
      <rect
        id="gauge-rectangle"
        x="117"
        y="0"
        className="gauge-bg-color"
        width="130"
        height={jaugeHeight}
        rx="30"
        ry="30"
        clipPath="url(#gauge-mask)"
      />
      {/* <!-- /GAUGE POURCENTAGE  --> */}
      {/* <!-- BORD DE LA GAUGE  --> */}
      <path
        id="gauge-border"
        fill="#707070"
        d="M218.2579041,304.6855469h-73.6098633c-15.9907227,0-28.9999924-13.0097656-28.9999924-29V29
	c0-15.9907227,13.0092697-29,28.9999924-29h73.6098633c15.9902344,0,29,13.0092773,29,29v246.6855469
	C247.2579041,291.6757812,234.2481384,304.6855469,218.2579041,304.6855469z M144.6480408,3
	c-14.3364258,0-25.9999924,11.6635742-25.9999924,26v246.6855469c0,14.3369141,11.6635666,26,25.9999924,26h73.6098633
	c14.3369141,0,26-11.6630859,26-26V29c0-14.3364258-11.6630859-26-26-26H144.6480408z"
      />
      {/* <!-- /BORD DE LA GAUGE  --> */}
      {/* <!-- GAUGE : TXT PALIER 1  -->	 */}
      <text
        dx="180"
        dy="30"
        className={
          jaugeHeight > activeSteps[0] ? "titre-palier-active" : "titre-palier"
        }
        textAnchor="middle"
      >
        PALIER 1
      </text>
      <text
        dx="136"
        dy="50"
        className={
          jaugeHeight > activeSteps[1]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="136"
        dy="65"
        className={
          jaugeHeight > activeSteps[2]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        2€ CADEAUX
      </text>
      {/* <!-- /GAUGE : TXT PALIER 1  -->	 */}
      {/* <!-- GAUGE : TXT PALIER 2  -->	 */}
      <text
        dx="180"
        dy="140"
        className={
          jaugeHeight > activeSteps[3] ? "titre-palier-active" : "titre-palier"
        }
        textAnchor="middle"
      >
        PALIER 2
      </text>
      <text
        dx="136"
        dy="160"
        className={
          jaugeHeight > activeSteps[4]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="136"
        dy="175"
        className={
          jaugeHeight > activeSteps[5]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        3€ CADEAUX
      </text>
      {/* <!-- /GAUGE : TXT PALIER 2  -->	 */}
      {/* <!-- GAUGE : TXT PALIER 3  -->	 */}
      <text
        dx="180"
        dy="250"
        className={
          jaugeHeight > activeSteps[6] ? "titre-palier-active" : "titre-palier"
        }
        textAnchor="middle"
      >
        PALIER 3
      </text>
      <text
        dx="136"
        dy="270"
        className={
          jaugeHeight > activeSteps[7]
            ? "gauge-ca-txt-valeur-active"
            : "gauge-ca-txt-valeur"
        }
        textAnchor="start"
      >
        1K€ DE CA =
      </text>
      <text
        dx="136"
        dy="285"
        className={
          jaugeHeight > activeSteps[8]
            ? "gauge-ca-txt-cadeau-active"
            : "gauge-ca-txt-cadeau"
        }
        textAnchor="start"
      >
        4€ CADEAUX
      </text>
      {/* <!-- /GAUGE : TXT PALIER 3  -->	 */}

      {/* Regroupement 1 */}
      <svg y="10">
        {/* <!-- GAUGE : CERCLE FIN DE PALIER 1  --> */}
        <ellipse
          id="step1-gauge-cercle"
          fill="#575257"
          className="st3"
          cx="180"
          cy="97"
          rx="15"
          ry="15"
        />
        <path
          id="step1-gauge-cercle-fleche"
          fill="#DAC783"
          d="M186.8809814,96.7040176c-0.5,0.5-5.6420288,5.4160156-5.6420288,5.4160156
	c-0.4973755,0.5246582-1.3258667,0.546814-1.8505249,0.0494995c-0.0169678-0.0160522-0.0334473-0.0325928-0.0494995-0.0494995
	c0,0-5.190979-4.9199829-5.6420288-5.4160156c-0.5213013-0.5419922-0.5213013-1.3989868,0-1.940979
	c0.4671631-0.5246582,1.2711792-0.5712891,1.7958374-0.104187c0.0367432,0.0327148,0.0715332,0.0675049,0.104187,0.104187
	l4.6939697,4.5139771l4.6939697-4.5139771c0.4671631-0.5246582,1.2711792-0.5712891,1.7958374-0.104187
	c0.0367432,0.0327148,0.0715332,0.0675049,0.104187,0.104187C187.4050903,95.3060684,187.4033813,96.163063,186.8809814,96.7040176z
	"
        />
        {/* <!-- /GAUGE : CERCLE FIN DE PALIER 1  --> */}
        {/* <!-- CA PALIER 1  --> */}
        <text dx="55" dy="93" className="ca-valeur" textAnchor="end">
          90K
        </text>
        <text dx="55" dy="107" className="ca-valeur" textAnchor="end">
          CA
        </text>

        <path
          id="step1-ca-picto-euro"
          fill="#2D2D2D"
          d="M71.7500076,86.1349945c2.1316071-0.0010376,4.1947784,0.7524567,5.8240051,2.1269989
	l0.6840057,0.5789948v-3.1920013l-0.1869965-0.1230011c-5.3299866-3.4893799-12.4795074-1.9972839-15.9688873,3.3327026
	c-0.7236938,1.105423-1.2520752,2.3269501-1.5621185,3.6112976h-3.4820099l-0.6170044,1.6620026h3.8300018
	c-0.0299988,0.3430023-0.0480042,0.6900024-0.0480042,1.0390015s0.0180054,0.6999969,0.0480042,1.0390015h-2.1129913
	L57.541008,97.871994h3c1.4949188,6.1927185,7.7269897,10.0010376,13.9197083,8.5061188
	c1.2843475-0.3100433,2.5058746-0.8384399,3.6112976-1.5621185l0.1869965-0.1230011v-3.1920013l-0.6840057,0.5789948
	c-3.8202057,3.216156-9.5243073,2.7264862-12.7404633-1.0937195c-0.7701721-0.9148254-1.3512421-1.9733582-1.7095337-3.1142883
	h8.1649933l0.6170044-1.6620026h-9.1309967c-0.0813904-0.6899414-0.0813904-1.3870544,0-2.0769958h9.8999939l0.6170044-1.6620026
	H63.1230087c1.1835175-3.7687225,4.675827-6.3334961,8.6260071-6.3350067L71.7500076,86.1349945z"
        />

        <path
          id="step1-ca-cercle"
          fill="#2D2D2D"
          d="M38.9644508,121.4223633c-4.8276367,0-9.7104473-1.2944336-14.1103497-4.0117188
	c-12.6367188-7.8046875-16.5678711-24.4350586-8.7631836-37.0717773
	c7.8041992-12.6367188,24.435545-16.5664062,37.0717773-8.7631836c3.0483398,1.8828125,5.652832,4.3144531,7.7402344,7.2275391
	c0.4824219,0.6733398,0.3276367,1.6103516-0.3457031,2.0927734c-0.6728516,0.4824219-1.6108398,0.3276367-2.0927734-0.3457031
	c-1.8549805-2.5883789-4.1689453-4.7490234-6.8779297-6.421875c-11.2304688-6.9350586-26.0078106-3.4423828-32.9428711,7.7866211
	c-3.3598633,5.4399414-4.3999023,11.8623047-2.9291992,18.0844727s5.2763672,11.4990234,10.7158203,14.8583984
	c11.2294903,6.934082,26.0063457,3.4423828,32.9428711-7.7866211c0.4350586-0.7045898,1.359375-0.9228516,2.0644531-0.4882812
	c0.7050781,0.4355469,0.9233398,1.3598633,0.4882812,2.0644531
	C56.8384743,116.8837891,48.0005836,121.4223633,38.9644508,121.4223633z"
        />
        {/* <!-- /CA PALIER 1  --> */}
        {/* <!-- CADEAU PALIER 1  --> */}
        <text dx="270" dy="135" className="cadeau-txt" textAnchor="start">
          Bonus palier 1
        </text>
        <text dx="270" dy="150" className="cadeau-txt" textAnchor="start">
          +90€ cadeaux
        </text>

        <path
          id="step1-cadeau-picto"
          fill="#2D2D2D"
          d="M318.9014587,88.440918h-8.3771973
	c1.5263672-0.5274658,3.0380859-1.1206055,4.5305176-1.7856445c1.8388672-0.9604492,2.8164062-3.3505859,2.2744141-5.5595703
	c-0.5878906-2.3959961-2.6845703-3.8266602-5.6083984-3.8266602c-3.973877,0-5.6049805,3.9208984-6.2746582,7.1870117
	c-0.3892822-0.1101074-0.791626-0.1879883-1.2155762-0.1879883c-0.4233398,0-0.8251953,0.0776367-1.2138672,0.1873779
	c-0.6699219-3.2659912-2.3015137-7.1864014-6.2763672-7.1864014c-2.9238281,0-5.0205078,1.4296875-5.6083984,3.824707
	c-0.5419922,2.2099609,0.4365234,4.6005859,2.3330078,5.5888672c1.4599609,0.6494141,2.9545898,1.2348633,4.4707031,1.7583008
	h-7.7744141c-0.5527344,0-1,0.4477539-1,1v5.2250977c0,0.5522461,0.4472656,1,1,1h1.6132812v14.6757812c0,0.5522461,0.4472656,1,1,1
	h11.4487305c0.0026855,0,0.0047607,0.0014648,0.0073242,0.0014648s0.0046387-0.0014648,0.0073242-0.0014648h12.050293
	c0.5527344,0,1-0.4477539,1-1V95.6660156h1.6132812c0.5527344,0,1-0.4477539,1-1V89.440918
	C319.9014587,88.8886719,319.4541931,88.440918,318.9014587,88.440918z M311.7207947,79.269043
	c2.6982422,0,3.4550781,1.4428711,3.6660156,2.3032227c0.3242188,1.3188477-0.2285156,2.7729492-1.2011719,3.2832031
	c-1.8845215,0.8381348-3.8286133,1.5644531-5.8031006,2.182373c-0.2460938-0.5883789-0.6192627-1.1064453-1.0767822-1.5372314
	C307.7749939,82.751709,308.9197693,79.269043,311.7207947,79.269043z M304.2305603,86.2680664
	c0.3188477,0,0.6208496,0.065918,0.9013672,0.1750488c-0.0581055,0.5070801-0.0942383,0.9570312-0.1162109,1.3249512h-1.5683594
	c-0.0222168-0.3681641-0.0582275-0.8183594-0.1164551-1.3256836
	C303.6109314,86.3337402,303.9124451,86.2680664,304.2305603,86.2680664z M293.0743103,81.5703125
	c0.2109375-0.859375,0.9677734-2.3012695,3.6660156-2.3012695c2.8012695,0,3.9467773,3.4816895,4.416626,6.2302246
	c-0.458252,0.4311523-0.8319092,0.9494629-1.0782471,1.5384521c-1.9694824-0.6159668-3.9003906-1.3360596-5.7446289-2.1549072
	C293.3038025,84.3447266,292.7510681,82.8896484,293.0743103,81.5703125z M291.1612244,90.440918h12.0693359v3.2250977h-10.4560547
	h-1.6132812V90.440918z M293.7745056,95.6660156h9.4560547v13.6757812h-9.4560547V95.6660156z M315.2881775,109.3417969h-10.0576172
	V95.6660156h10.0576172V109.3417969z M317.9014587,93.6660156h-1.6132812h-11.0576172V90.440918h12.6708984V93.6660156z"
        />

        <path
          id="step1-cadeau-cercle"
          fill="#2D2D2D"
          d="M304.7774353,121.6870117
	c-15.6181641,0-28.3242188-12.7060547-28.3242188-28.3242188c0-15.6176758,12.7060547-28.3237305,28.3242188-28.3237305
	c15.6171875,0,28.3232422,12.7060547,28.3232422,28.3237305
	C333.1006775,108.980957,320.3946228,121.6870117,304.7774353,121.6870117z M304.7774353,67.0390625
	c-14.515625,0-26.3242188,11.8085938-26.3242188,26.3237305s11.8085938,26.3242188,26.3242188,26.3242188
	c14.5146484,0,26.3232422-11.809082,26.3232422-26.3242188S319.2920837,67.0390625,304.7774353,67.0390625z"
        />
        {/* <!-- /CADEAU PALIER 1  --> */}
        <rect
          id="step1-ca-line"
          x="87"
          y="94"
          className="line-color"
          width="22"
          height="1"
        />
        <rect
          id="step1-cadeau-line"
          x="252"
          y="94"
          className="line-color"
          width="21"
          height="1"
        />
      </svg>
      {/* Regroupement 1 */}

      {/* Regroupement 2 */}
      <svg y="-10">
        {/* <!-- GAUGE : CERCLE FIN DE PALIER 2  --> */}
        <ellipse
          id="step2-gauge-cercle"
          fill="#575257"
          className="st3"
          cx="180"
          cy="210"
          rx="15"
          ry="15"
        />
        <path
          id="step2-gauge-cercle-fleche"
          fill="#DAC783"
          d="M186.8809814,209.70401c-0.5,0.5-5.6420288,5.4160156-5.6420288,5.4160156
	c-0.4973755,0.5246582-1.3258667,0.546814-1.8505249,0.0494995c-0.0169678-0.0160522-0.0334473-0.0325928-0.0494995-0.0494995
	c0,0-5.190979-4.9199829-5.6420288-5.4160156c-0.5213013-0.5419922-0.5213013-1.3989868,0-1.940979
	c0.4671631-0.5246582,1.2711792-0.5712891,1.7958374-0.104187c0.0367432,0.0327148,0.0715332,0.0675049,0.104187,0.104187
	l4.6939697,4.5139771l4.6939697-4.5139771c0.4671631-0.5246582,1.2711792-0.5712891,1.7958374-0.104187
	c0.0367432,0.0327148,0.0715332,0.0675049,0.104187,0.104187
	C187.4050903,208.3060608,187.4033813,209.1630554,186.8809814,209.70401z"
        />
        {/* <!-- /GAUGE : CERCLE FIN DE PALIER 2  --> */}
        {/* <!-- CA PALIER 2  --> */}
        <text dx="57" dy="209" className="ca-valeur" textAnchor="end">
          250K
        </text>
        <text dx="57" dy="223" className="ca-valeur" textAnchor="end">
          CA
        </text>

        <path
          id="step2-ca-picto-euro"
          fill="#2D2D2D"
          d="M71.7500076,200.8230133
	c2.1316071-0.0010376,4.1947784,0.7524567,5.8240051,2.1269989l0.6840057,0.5789948v-3.1920013l-0.1869965-0.1230011
	c-5.3299866-3.4893799-12.4795074-1.9972839-15.9688873,3.3327026c-0.7236938,1.105423-1.2520752,2.3269501-1.5621185,3.6112976
	h-3.4820099l-0.6170044,1.6620026h3.8300018c-0.0299988,0.3430023-0.0480042,0.6900024-0.0480042,1.0390015
	s0.0180054,0.6999969,0.0480042,1.0390015h-2.1129913l-0.6170044,1.6620026h3
	c1.4949188,6.1927185,7.7269897,10.0010376,13.9197083,8.5061188c1.2843475-0.3100433,2.5058746-0.8384399,3.6112976-1.5621185
	l0.1869965-0.1230011v-3.1920013l-0.6840057,0.5789948c-3.8202057,3.216156-9.5243073,2.7264862-12.7404633-1.0937195
	c-0.7701721-0.9148254-1.3512421-1.9733582-1.7095337-3.1142883h8.1649933l0.6170044-1.6620026h-9.1309967
	c-0.0813904-0.6899414-0.0813904-1.3870544,0-2.0769958h9.8999939l0.6170044-1.6620026H63.1230087
	c1.1835175-3.7687225,4.675827-6.3334961,8.6260071-6.3350067L71.7500076,200.8230133z"
        />

        <path
          id="step2-ca-cercle"
          fill="#2D2D2D"
          d="M38.9639626,236.1103516c-4.8271484,0-9.709959-1.2939453-14.1098614-4.0117188
	c-12.6367188-7.8046875-16.5678711-24.4345703-8.7631836-37.0712891
	c7.8051758-12.6357422,24.4345684-16.5703125,37.0717773-8.7636719c3.0473633,1.8818359,5.6518555,4.3134766,7.7402344,7.2265625
	c0.4824219,0.6738281,0.328125,1.6103516-0.3452148,2.0927734c-0.6748047,0.484375-1.6113281,0.3261719-2.0932617-0.3447266
	c-1.855957-2.5888672-4.1699219-4.75-6.8779297-6.421875c-11.2304688-6.9365234-26.0073223-3.4414062-32.9428711,7.7871094
	c-6.9355469,11.2294922-3.4423828,26.0068359,7.7866211,32.9423828c11.2299786,6.9375,26.0073223,3.4414062,32.9428711-7.7871094
	c0.4350586-0.7041016,1.359375-0.9228516,2.0644531-0.4882812c0.7050781,0.4355469,0.9233398,1.359375,0.4882812,2.0644531
	C56.8384743,231.5712891,48.0005836,236.1103516,38.9639626,236.1103516z"
        />
        {/* <!-- /CA PALIER 2  --> */}
        {/* <!-- CADEAU PALIER 2  --> */}
        <text dx="270" dy="255" className="cadeau-txt" textAnchor="start">
          Bonus palier 2
        </text>
        <text dx="270" dy="270" className="cadeau-txt" textAnchor="start">
          +250€ cadeaux
        </text>

        <path
          id="step2-cadeau-picto"
          fill="#2D2D2D"
          d="M318.9014587,206.9101562h-8.3771973
	c1.5255127-0.5272217,3.0372314-1.1201172,4.5305176-1.7851562c1.8388672-0.9609375,2.8164062-3.3505859,2.2744141-5.5605469
	c-0.5878906-2.3955078-2.6845703-3.8261719-5.6083984-3.8261719c-3.973877,0-5.6049805,3.9208984-6.2746582,7.1870117
	c-0.3892822-0.1101074-0.791626-0.1879883-1.2155762-0.1879883c-0.4233398,0-0.8251953,0.0776367-1.2138672,0.1873779
	c-0.6699219-3.2659912-2.3015137-7.1864014-6.2763672-7.1864014c-2.9238281,0-5.0205078,1.4296875-5.6083984,3.8251953
	c-0.5419922,2.2089844,0.4365234,4.6005859,2.3330078,5.5888672c1.4608154,0.6494141,2.9554443,1.2346191,4.4707031,1.7578125
	h-7.7744141c-0.5527344,0-1,0.4472656-1,1v5.2246094c0,0.5527344,0.4472656,1,1,1h1.6132812v14.6757812c0,0.5527344,0.4472656,1,1,1
	h11.4462891c0.003418,0,0.0063477,0.0019531,0.0097656,0.0019531s0.0063477-0.0019531,0.0097656-0.0019531h12.0478516
	c0.5527344,0,1-0.4472656,1-1v-14.6757812h1.6132812c0.5527344,0,1-0.4472656,1-1v-5.2246094
	C319.9014587,207.3574219,319.4541931,206.9101562,318.9014587,206.9101562z M311.7207947,197.7382812
	c2.6982422,0,3.4550781,1.4433594,3.6660156,2.3027344c0.3242188,1.3193359-0.2285156,2.7734375-1.2011719,3.2832031
	c-1.8858643,0.8387451-3.8300781,1.5648193-5.8032227,2.1824951c-0.2460938-0.5882568-0.6191406-1.1060791-1.0766602-1.5368652
	C307.7749939,201.2213135,308.9197693,197.7382812,311.7207947,197.7382812z M304.2305603,204.7373047
	c0.3188477,0,0.6208496,0.065918,0.9013672,0.1751709c-0.0579834,0.506958-0.0942383,0.9569092-0.1162109,1.3248291h-1.5683594
	c-0.0222168-0.3681641-0.0582275-0.8181152-0.1164551-1.3254395
	C303.6109314,204.8029785,303.9124451,204.7373047,304.2305603,204.7373047z M293.0743103,200.0400391
	c0.2109375-0.859375,0.9677734-2.3017578,3.6660156-2.3017578c2.8013916,0,3.9470215,3.4816895,4.416748,6.2302246
	c-0.458252,0.4309082-0.8320312,0.9492188-1.0783691,1.5380859c-1.9680176-0.6154785-3.8991699-1.3359375-5.7446289-2.1550293
	C293.3038025,202.8134766,292.7510681,201.359375,293.0743103,200.0400391z M291.1612244,208.9101562h12.0693359v3.2246094
	h-10.4560547h-1.6132812V208.9101562z M293.7745056,214.1347656h9.4560547v13.6757812h-9.4560547V214.1347656z
	 M315.2881775,227.8105469h-10.0576172v-13.6757812h10.0576172V227.8105469z M317.9014587,212.1347656h-1.6132812h-11.0576172
	v-3.2246094h12.6708984V212.1347656z"
        />

        <path
          id="step2-cadeau-cercle"
          fill="#2D2D2D"
          d="M304.7774353,240.15625c-15.6181641,0-28.3242188-12.7060547-28.3242188-28.3242188
	s12.7060547-28.3242188,28.3242188-28.3242188c15.6171875,0,28.3232422,12.7060547,28.3232422,28.3242188
	S320.3946228,240.15625,304.7774353,240.15625z M304.7774353,185.5078125c-14.515625,0-26.3242188,11.8085938-26.3242188,26.3242188
	s11.8085938,26.3242188,26.3242188,26.3242188c14.5146484,0,26.3232422-11.8085938,26.3232422-26.3242188
	S319.2920837,185.5078125,304.7774353,185.5078125z"
        />
        {/* <!-- /CADEAU PALIER 2  --> */}
        <rect
          id="step2-ca-line"
          x="87"
          y="208"
          className="line-color"
          width="22"
          height="1"
        />
        <rect
          id="step2-cadeau-line"
          x="252"
          y="209"
          className="line-color"
          width="21"
          height="1"
        />
      </svg>
      {/* Regroupement 2 */}
    </svg>
  );
})`
  display: none;
  ${context("for-tablet-landscape")`
    display: block;
  `}

  .titre-palier {
    fill: #575257;
    font-weight: 500;
    font-size: 12px;
  }

  .titre-palier-active {
    fill: #ffffff;
    font-weight: 500;
    font-size: 12px;
  }

  .ca-valeur {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 15px;
  }

  .ca-label {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 13px;
  }

  .cadeau-txt {
    fill: #0d97e2;
    font-weight: 700;
    font-size: 12px;
  }

  .gauge-ca-txt-valeur {
    fill: #575257;
    font-weight: 600;
    font-size: 14px;
  }

  .gauge-ca-txt-valeur-active {
    fill: #ffffff;
    font-weight: 600;
    font-size: 14px;
  }

  .gauge-ca-txt-cadeau {
    fill: #575257;
    font-weight: 700;
    font-size: 14px;
  }

  .gauge-ca-txt-cadeau-active {
    fill: #ffffff;
    font-weight: 700;
    font-size: 14px;
  }

  .gauge-bg-color {
    fill: #0d97e2;
  }

  .line-color {
    fill: #575257;
  }
`;
