import { useState } from "react";

import { styled, theme } from "../../util/style.js";
import noFull from "./images/thumb-down-full.svg";
import no from "./images/thumb-down.svg";
import yesFull from "./images/thumb-up-full.svg";
import yes from "./images/thumb-up.svg";

const Emoticon = styled(function YesNoQuestion({
  className,
  src,
  alt,
  ...props
}) {
  return (
    <div className={className} {...props}>
      <img src={src} alt={alt} />
    </div>
  );
})`
  padding: 0 8px;
  cursor: pointer;
  width: 64px;
  img {
    width: 100%;
    max-width: 120px;
  }
`;

const icons = {
  no: { [true]: noFull, [false]: no },
  yes: { [true]: yesFull, [false]: yes },
};

export default styled(function ({ value, answers, onChange, className }) {
  const [hoverReference, setHoverReference] = useState(null);

  return (
    <div className={className}>
      {["no", "yes"].map((ref) => {
        const { reference, text } =
          answers.find(({ reference }) => reference === ref) || {};
        return (
          <Emoticon
            key={reference}
            alt={text}
            src={
              icons[reference][
                value === reference || hoverReference === reference
              ]
            }
            onClick={() => onChange(value === reference ? null : reference)}
            onMouseOver={() => setHoverReference(reference)}
            onMouseOut={() => setHoverReference(null)}
          />
        );
      })}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  margin: 10px -8px;
  justify-content: flex-start;
`;
