import { paths } from "../app/services/router.js";

const X_PAGE_PREFIX = "x-page://";

const xPageRegExp = new RegExp(X_PAGE_PREFIX + "(\\w+)", "mg");

export function transformLink(link) {
  if (typeof link !== "string") return link;
  if (link && link.indexOf(X_PAGE_PREFIX) === 0) {
    return paths[link.substr(X_PAGE_PREFIX.length)];
  }
  return link;
}

export function transformTile(tile) {
  const newTile = { ...tile };
  if (tile && tile.link && tile.link.indexOf(X_PAGE_PREFIX) === 0) {
    const newLink = transformLink(tile.link);
    if (newLink) {
      newTile.link = newLink;
      newTile.linkTarget = ""; // Force non _blank
    }
  }
  if (tile && tile.text) {
    newTile.text = tile.text.replaceAll(xPageRegExp, transformLink);
  }
  return newTile;
}
