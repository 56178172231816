import { paths } from "../services/router.js";
import { getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import HtmlText from "./HtmlText.jsx";
import { Button } from "./typography.jsx";

const PanelImage = styled.div`
  ${theme("content-box")}
  border-bottom: 0;
  padding: 25px;
`;

export default styled(function Contest({
  title,
  alias,
  introduction,
  image,
  multiple,
  attempted,
  createUrl,
  className,
}) {
  return (
    <div className={className}>
      <div className="ContestSection">
        <div className="ContestSection-image">
          {Boolean(image) && (
            <PanelImage>
              <img src={getMediaUrl(image, "challenge-image")} alt={title} />
            </PanelImage>
          )}
        </div>
        <div className="ContestSection-content">
          {Boolean(attempted) && (
            <Alert>
              Votre entreprise a déjà participé au concours.{" "}
              {!multiple &&
                "Une seule participation par entreprise est acceptée."}
            </Alert>
          )}

          <HtmlText html={introduction} />

          <ButtonsList>
            <Button ghost to={paths.challenges}>
              Retour à la liste
            </Button>
            {(!attempted || multiple) && (
              <Button to={createUrl}>Publier un projet</Button>
            )}
          </ButtonsList>
        </div>
      </div>
    </div>
  );
})`
  .ContestSection {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
    z-index: 0;
    grid-gap: 56px;

    &-image {
      width: 360px;
    }
    &-content {
      flex: 1;
    }
  }
`;

const ButtonsList = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  ${is("center")`
    justify-content: center
  `}
`;

const Alert = styled.div`
  ${font("small")}
  color: ${color("grey-text")};
  margin-bottom: 24px;
`;
