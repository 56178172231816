import normalize from "!!raw-loader!./normalize.css"; // eslint-disable-line import/no-unresolved
import { Helmet } from "react-helmet";
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";

import { color, context, font } from "../util/style.js";

export const colors = {
  black: "#313131",
  "black-green": "#4D533C",
  grey: "#575257",
  "grey-text": "#758389",
  blue: "#0D97E2",
  "blue-dark": "#115A98",
  yellow: "#C2AC6D",
  "yellow-light": "#DAC783",
  "grey-dark": "#3E3E3E",
  "grey-light": "#646464",
  "white-grey": "#F5F5F5",
  "white-grey2": "#C6C6C6",
  "white-grey3": "#CECECE",
  "white-grey4": "#E5E5E5",
  orange: "#CC423B",
  red: "#DF5601",
  green: "#409600",
};

const fonts = {
  primary: "'Poppins', Calibri, Verdana, sans-serif",
  //  secondary: "'Zilla Slab', Georgia, 'Times New Roman', serif",
};

export const theme = {
  /** Some props */
  "document-width-default": "1176px",
  "document-width-desktop": "970px",
  "document-width-tablet-landscape": "750px",
  "document-width-tablet-mobile": "100%",
  gutters: "8px",
  "padding-container": "32px",
  "padding-container-mobile": "12px",
  "font-weight-regular": "400",
  "font-weight-medium": "500",
  "font-weight-semi": "600",
  "font-weight-bold": "700",
  "border-radius": "8px",

  fonts: {
    ...fonts,
    mini: `
      font-size: 1.2rem;
      line-height: 1.5;
    `,
    small: `
      font-size: 1.4rem;
      line-height: 1.2;
    `,
    paragraph: `
      font-family: ${fonts.primary};
      font-size: 1.6rem;
      line-height: 1.9;
    `,
    strong: `
      font-family: ${fonts.primary};
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.9;
    `,
    headline1: `
      font-size: 6.2rem;
      font-weight: 700;
      line-height: 1.1;
    `,
    headline2: `
      font-size: 3.6rem;
      font-weight: 700;
      line-height: 1.3;
    `,
    headline3: `
      font-size: 2.6rem;
      font-weight: 700;
      line-height: 1.3;
    `,
    headline4: `
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 1.3;
    `,
    headline5: `
      font-size: 2rem;
      font-weight: 700;
      line-height: 1.3;
    `,
  },
  colors: {
    ...colors,

    text: colors["grey"],
    "text-dark": colors["grey"],
    "text-grey": colors["grey-text"],
    "text-white": colors["white-grey2"],
    primary: colors["blue"],
    secondary: colors["yellow"],
    disabled: colors["white-grey3"],
    list: colors["yellow-light"],
    "bg-dark": colors["grey-dark"],
    "bg-dark-light": colors["grey-light"],
    "bg-page": colors["white-grey"],
    "bg-light": colors["white-grey"],
    "bg-faq": colors["white-grey4"],
    "bg-header1": colors["white-grey2"],
    "bg-header2": colors["blue-dark"],
    border: colors["white-grey2"],
    "icon-black": colors["black"],
    "border-black": colors["color-icon-black"],
    "border-blue": colors["blue"],
    "border-gold": colors["yellow"],
    "border-big": colors["yellow"],
    social: colors["yellow"],
    "title-special": colors["black-green"],
    input: colors["white-grey"],
    "input-text": colors["blue"],
    "input-focus": colors["blue"],
    "input-disabled": colors["white-grey4"],
    "input-error": colors["red"],
    separator: colors["grey-dark"],

    overlay: `rgba(0, 0, 0, 0.4)`,

    "gradient-blue-button": `
      background: rgb(1,31,115);
      background: linear-gradient(90deg, rgba(1,31,115,1) 0%, rgba(1,149,223,1) 50%, rgba(1,31,115,1) 100%);
      background-size: 200% auto;
    `,
    "gradient-gold-button": `
      background: rgb(165,141,81);
      background: linear-gradient(90deg, rgba(165,141,81,1) 0%, rgba(214,194,127,1) 50%, rgba(165,141,81,1) 100%);
      background-size: 200% auto;
    `,
    "gradient-blue-topBar": `
      background: rgb(1,31,115);
      background: linear-gradient(45deg, rgba(1,31,115,1) 30%, rgba(1,149,223,1) 100%);
    `,
    "gradient-blue-bottomBar": `
      background: rgb(1,31,115);
      background: linear-gradient(225deg, rgba(1,31,115,1) 50%, rgba(1,149,223,1) 100%);
    `,
    "gradient-blue-tab": `
      background: rgb(1,31,115);
      background: linear-gradient(45deg, rgba(1,31,115,1) 0%, rgba(1,149,223,1) 100%);
    `,
    "gradient-blue-tab-reversed": `
      background: rgb(1,31,115);
      background: linear-gradient(225deg, rgba(1,31,115,1) 0%, rgba(1,149,223,1) 100%);
    `,
    "gradient-blue-widget": `
      background: rgb(1,137,223);
      background: linear-gradient(90deg, rgba(1,137,223,1) 0, rgba(97,195,253,1) 100%);
    `,
  },
  "big-border": `border-bottom: 12px solid ${colors["yellow"]};`,
  "big-border-top": `border-top: 12px solid ${colors["yellow"]};`,
  "box-shadow": `
    box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
    -webkit-box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
    -moz-box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
  `,
  "content-box": `
    background-color: white;
    border-radius: 8px;
    border-bottom: 12px solid ${colors["yellow"]};
    box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
    -webkit-box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
    -moz-box-shadow: 1px 49px 69px 0px rgba(29,144,206,0.26);
  `,

  contexts: {
    "for-small-phone": "(max-width: 380px)",
    "for-phone": "(max-width: 576px)",
    "for-tablet-portrait": "(max-width: 768px)",
    "for-tablet-landscape": "(max-width: 992px)",
    "for-desktop": "(max-width: 1200px)",
    retina:
      "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2) only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2) only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)",
  },

  z: [
    "popin",
    "menu",
    "side-widgets",
    "side-widgets-popup",
    "top-header",
    "header",
    "footer",
    "main",
  ]
    .reverse()
    .reduce((z, key, index) => ({ ...z, [key]: index + 100 }), {}),
};

const FontsStyle = createGlobalStyle`
  /* poppins-regular - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Poppins/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/Poppins/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/Poppins/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/Poppins/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
   
  /* poppins-500 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/Poppins/poppins-v15-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/Poppins/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/Poppins/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-500.woff') format('woff'), /* Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/Poppins/poppins-v15-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
  }
   
  /* poppins-600 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Poppins/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/Poppins/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/Poppins/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/Poppins/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
  }
   
   /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Poppins/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
      url('/fonts/Poppins/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/Poppins/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
      url('/fonts/Poppins/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/Poppins/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
`;

const GlobalStyle = createGlobalStyle`
  ${normalize}
  html {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 62.5%;
    height: 100%;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    ${font("paragraph")}
    color: ${color("text-dark")};
    background-color: ${color("bg-page")};
    height: 100%;
  }
  
  main {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: relative;
  }
  
  img {
    height: auto;
    max-width: 100%;
  }
  
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
    }
  }
  
  a {
    color: ${color("text-dark")};
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
    &:hover {
      color: ${color("primary")};
      transition: color 0.3s ease;
    }
  }
  
  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
  }
  
  h1 {
    ${font("headline1")}
    color: ${color("primary")};
    margin: 0 0 16px;
    ${context("for-tablet-landscape")`    
      ${font("headline3")};
      color: ${color("primary")};
    `}
  }
  
  h2 {
    ${font("headline2")}
    color : ${color("text-dark")};
    margin: 0 0 40px;
    ${context("for-tablet-landscape")`    
      ${font("headline4")}
      color: ${color("text-dark")};
      margin: 0 0 24px;
    `}
  }
  
  h3 {
    ${font("headline3")}
    color: ${color("text-dark")};
    margin: 0 0 16px;
    ${context("for-tablet-landscape")`    
      ${font("headline5")}
      color: ${color("text-dark")};
    `}
  }
  
  h4 {
    ${font("headline4")}
    color: ${color("primary")};
    margin: 0 0 16px;
    ${context("for-tablet-landscape")`    
      ${font("headline5")}
      color: ${color("primary")};
    `}
  }
  
  h5 {
    ${font("headline5")}
    color: ${color("primary")};
    margin: 0 0 16px;
  }
  
  p {
    margin: 0 0 16px;
  }
  
  hr {
    border: 0;
    border-top: 1px solid ${color("border")};
    line-height: 1;
    margin: 56px 0;
    padding-top: 1px; /* important bug firefox parfois */
    ${context("for-tablet-landscape")`
      margin: 32px 0;
    `}
  }
  
  .color-primary {
    color: ${color("primary")};
  }
  
  .color-text-dark {
    color: ${color("text-dark")};
  }

  .nowrap {
    white-space: nowrap;
  }

  dl {
    display: grid;
    grid-template: "dt dd" / auto 1fr;
    dt {
      font-weight: ${theme["font-weight-semi"]};
    }
    ${context("for-tablet-portrait")`
      display: block;
    `}
  }

  .only-mobile {
    display: none !important;
    ${context("for-tablet-landscape")`
      display: block !important;
    `}
  }
  .hide-mobile {
    ${context("for-tablet-landscape")`
      display: none !important;
    `}
  }
  .only-phone {
    display: none !important;
    ${context("for-phone")`
      display: block !important;
    `}
  }
  .hide-phone {
    ${context("for-phone")`
      display: none !important;
    `}
  }
`;

export const ThemeProvider = function ({ children }) {
  // https://www.smashingmagazine.com/2019/06/optimizing-google-fonts-performance/
  return (
    <StyledThemeProvider theme={theme}>
      <>
        <FontsStyle />
        <GlobalStyle />
        {children}
      </>
    </StyledThemeProvider>
  );
};
