import { color, font, styled } from "../../util/style.js";

const Input = styled.input`
  border: 0;
  color: ${color("grey-text")};
  font: ${font("paragraph")};
  width: 100%;
  padding: 8px;
`;

export default function InputQuestion({ onChange, value, optional, ...props }) {
  return (
    <Input
      onChange={(evt) => onChange && onChange(evt.nativeEvent.target.value)}
      value={value}
      required={!optional}
      {...props}
    />
  );
}
