import { Helmet } from "react-helmet";

import GoogleTagManager from "../components/GoogleTagManager.jsx";
//import Wysistat from "../../components/Wysistat.jsx";
//import logo from "../../img/logo-presselib.png";
import { ThemeProvider } from "./theme.jsx";

export default function Root({ children }) {
  return (
    <>
      <Helmet titleTemplate={`%s - Daikin`}>
        <html lang="fr" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00aba9" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <GoogleTagManager id={process.env.GOOGLE_TAG_MANAGER} />
      {/*{Boolean(index?.parameters?.wysistatId) && (
        <Wysistat name={index.parameters.wysistatId} />
      )}*/}
      <ThemeProvider>{children}</ThemeProvider>
    </>
  );
}
