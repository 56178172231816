import { Redirect, useParams } from "react-router-dom";
import _keyBy from "lodash/keyBy";
import { format as formatDate } from "../../common/date.js";
import { usePost } from "../services/posts.js";
import Container from "../templates/common/Container.jsx";
import { color, font, styled, context, theme } from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";
import { paths } from "../services/router.js";
import { Button } from "./typography.jsx";
import PostCategories from "./PostCategories.jsx";
import { categories } from "../../common/categories.js";
import ContentLoading from "./ContentLoading.jsx";
import { getMediaUrl } from "../util/medias.js";
import Tiles from "./Tiles.jsx";

const categoriesByKey = _keyBy(categories, "id");

const PostFull = styled(function({
  to,
  image,
  title,
  date,
  category,
  content,
  className,
}) {
  const { category: currentCategory } = useParams();

  return (
    <div className={className}>
      {Boolean(image) && (
        <div className="Post-image">
          <img src={getMediaUrl(image, "post-banner")} alt={title} />
        </div>
      )}
      {Boolean(categoriesByKey[category]) && (
        <div className="Post-tags">
          <span className="Post-tag">{categoriesByKey[category]?.title}</span>
        </div>
      )}
      <div className="Post-title">{title}</div>
      <div className="Post-date">{formatDate(date, "dd MMMM yyyy")}</div>
      <div className="Post-content">
        <Tiles tiles={content} />
      </div>

      <div className="Post-bottomActions">
        <Button ghost to={`${paths.posts}/${currentCategory}`}>
          Retour
        </Button>
      </div>
    </div>
  );
})`
  position: relative;
  padding: 40px 52px;
  border-radius: ${theme("border-radius")};
  background: white;

  & + & {
    margin-top: 32px;
  }

  .Post {
    &-image {
      margin-bottom: 12px;
    }
    &-title {
      ${font("headline3")}
      color: ${color("primary")};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-tags {
      display: flex;
      grid-gap: 2px;
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    &-date {
      ${font("small")}
      color: ${color("grey-text")};
    }
    &-content {
      margin-top: 24px;
      overflow: hidden;
    }
    &-tag {
      ${font("mini")}
      display: inline-block;
      background: ${color("grey-light")};
      color: white;
      padding: 2px 6px;
    }
    &-bottomActions {
      display: flex;
      grid-gap: 8px;
      justify-content: center;
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid ${color("white-grey2")};
    }
    &-seeMore {
      ${Icon} {
        vertical-align: middle;
        margin-left: 12px;
        position: relative;
        left: 0;
        transition: left 0.3s ease;
      }
      &:hover {
        --icon-hover: 1;
        ${Icon} {
          left: 3px;
        }
      }
    }
  }
`;

export default styled(function PostsSection({ className }) {
  const { category, post: postAlias } = useParams();

  const [post, { ready }] = usePost(postAlias);

  if (ready && !post) return <Redirect to={`${paths.posts}/${category}`} />;

  return (
    <Container className={className}>
      <div className="PostsSection-categories">
        <PostCategories />
      </div>

      <div className="PostsSection-content">
        {ready ? (
          <PostFull
            image={post.image}
            title={post.title}
            category={post.category}
            date={post.date}
            content={post.contents}
          />
        ) : (
          <ContentLoading />
        )}
      </div>
    </Container>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  grid-gap: 56px;

  .PostsSection {
    &-categories {
      flex: 1;
    }
    &-content {
      width: 60%;
      display: block;
    }
  }

  ${context("for-tablet-landscape")`
    flex-direction: column;
    grid-gap: 32px;
    align-items: stretch;
    .PostsSection {
      &-content {
        width: 100%;
      }
    }
  `}
`;
