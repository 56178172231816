import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { paginatedGql, toStandardResult } from "../util/graphql.js";
import { challengeSchema } from "./challenges.js";

const contestSchema = `
  ${challengeSchema}
  introduction
  image {
    id
    name
    version
  }
  document {
    id
    name
    version
  }
`;

export function useContest(
  contestAlias,
  pagination = { after: 0, limit: 100 }
) {
  const result = useQuery(
    gql`
      query GET_CONTEST($alias: ID! $pagination: PaginationInput) {
        private {
          challenges {
            contest: fetchContestByAlias(alias: $alias) {
              ${contestSchema}
              participations(
                pagination: $pagination
              ) {
                ${paginatedGql(`
                  id
                  title
                  date
                  description
                  file { id name version }
                  likesCount
                  userHasLiked
                  company { name }
                  meta { reference label value }
                `)}
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        alias: contestAlias,
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.challenges.contest");
}

export function useContestParticipationSubmit(contest) {
  const [mutation, result] = useMutation(
    gql`
      mutation (
        $contestId: ID!
        $participation: ContestParticipationPrivateInput!
      ) {
        private {
          challenges {
            submitContestParticipation(id: $contestId, data: $participation) {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  return [
    (participation) =>
      mutation({
        variables: {
          contestId: contest.id,
          participation,
        },
      }),
    ...toStandardResult(
      result,
      "private.challenges.submitContestParticipation"
    ),
  ];
}

export function useContestParticipationLike(contest) {
  const [mutation, result] = useMutation(
    gql`
      mutation ($contestParticipationId: ID!) {
        private {
          challenges {
            likeContestParticipation(id: $contestParticipationId)
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  return [
    (contestParticipationId) =>
      mutation({
        variables: {
          contestParticipationId,
        },
      }),
    ...toStandardResult(result, "private.challenges.likeContestParticipation"),
  ];
}

export function useContestParticipationUnlike(contest) {
  const [mutation, result] = useMutation(
    gql`
      mutation ($contestParticipationId: ID!) {
        private {
          challenges {
            unlikeContestParticipation(id: $contestParticipationId)
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  return [
    (contestParticipationId) =>
      mutation({
        variables: {
          contestParticipationId,
        },
      }),
    ...toStandardResult(
      result,
      "private.challenges.unlikeContestParticipation"
    ),
  ];
}
