import { useRef, useState } from "react";

import DisabledHelp from "../components/DisabledHelp.jsx";
import { FormField, FormSelect } from "../components/forms.jsx";
import InterventionBudgetHeader from "../components/InterventionBudgetHeader.jsx";
import InterventionRequest from "../components/InterventionRequest.jsx";
import InterventionsRequestsStatHeader from "../components/InterventionsRequestsStatHeader.jsx";
import { Col, Row } from "../components/layout.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import {
  useInterventions,
  useInterventionsStats,
} from "../services/interventions.js";
import { usePermissions } from "../services/permissions.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, styled, theme } from "../util/style.js";

const DEFAULT_LIMIT = 10;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;
const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const InterventionRequests = styled.div`
  ${InterventionRequest} + ${InterventionRequest} {
    margin-top: 30px;
  }
`;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const statusOptions = [
  { label: "Toutes les demandes", value: undefined },
  { label: "En cours", value: "pending" },
  { label: "À valider", value: "requireValidation" },
  { label: "Acceptées", value: "accepted" },
  { label: "Refusées", value: "declined" },
  { label: "Archivées", value: "archived" },
];

export default function Interventions() {
  const { isEliteProPlus, company, sessionData } = useSelfMetaData();
  const { subscriptionExpirationDate } = sessionData;

  const isExpired = new Date(subscriptionExpirationDate) < new Date();
  const [stats] = useInterventionsStats();
  const [hasPermission] = usePermissions();

  const budgetInterventions = company?.budgets?.interventions;
  const [filterStatus, setFilterStatus] = useState();
  const topScroll = useRef();

  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [interventionsResult, { ready, refetch }] = useInterventions(
    { status: filterStatus },
    pagination
  );

  const interventions = (interventionsResult?.edges || []).map(
    ({ node }) => node
  );

  return (
    <LoggedInTemplate title="Demandes d'intervention">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Demande d'intervention"
      />
      <MainContent>
        <Headline title="Mes demandes" />
        <Container>
          <InterventionsRequestsStatHeader stats={stats} />
          {Boolean(isEliteProPlus) && (
            <InterventionBudgetHeader budget={budgetInterventions} />
          )}
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  {isEliteProPlus ? (
                    <p>
                      En tant que client ELITE PRO+, vous disposez d'un{" "}
                      <strong>budget de 1000€/an</strong> pour des interventions
                      Daikin : diagnostic, dépannage, expertise, mise en service
                      ou encore maintenance.
                    </p>
                  ) : (
                    <p>
                      En tant que client ELITE PRO, vous pouvez demander une
                      intervention Daikin : diagnostic, dépannage, expertise,
                      mise en service ou encore maintenance.
                    </p>
                  )}
                  <FormField>
                    <FormSelect
                      placeholder="Filtrer par..."
                      white
                      value={filterStatus}
                      onChange={(filterStatus) => setFilterStatus(filterStatus)}
                      options={statusOptions}
                    />
                  </FormField>

                  <center>
                    <Button
                      to={paths.interventionRequest}
                      disabled={!hasPermission("technical:create") || isExpired}
                    >
                      J'effectue une demande
                    </Button>
                  </center>
                  {Boolean(isExpired) && (
                    <DisabledHelp>
                      L'accès aux demandes d'intervention sera de nouveau
                      disponible lorsque le contrat DAIKIN ELITE 2023 nous sera
                      retourné signé (NB : Il faut compter environ 2 semaines
                      entre l’envoi du contrat signé et l’activation de ces
                      fonctionnalités et de vos avantages).
                    </DisabledHelp>
                  )}
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />
              <InterventionRequests>
                {interventions.length ? (
                  interventions.map((intervention) => (
                    <InterventionRequest
                      {...intervention}
                      key={intervention.id}
                      onUpdate={refetch}
                    />
                  ))
                ) : (
                  <Panel>
                    <h3>Aucun résultat</h3>
                    <div>
                      {filterStatus
                        ? "Aucune demande n'a été trouvée avec cette recherche."
                        : "Vous n'avez aucune demande enregistrée."}
                    </div>
                  </Panel>
                )}
              </InterventionRequests>
              {Boolean(ready && interventions.length) && (
                <Pagination
                  result={interventionsResult}
                  pagination={pagination}
                />
              )}
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
