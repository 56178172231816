import styled from "styled-components";

import { format as formatDate } from "../../common/date.js";
import { getCurrentCode, getNextCodeAndDate } from "../../common/hotline.js";
import HotlineNumber from "../components/HotlineNumber.jsx";
import Tooltip, {
  ArchivedTooltipContent,
  DisabledTooltipContent,
  ExpiredTooltipContent,
} from "../components/Tooltip.jsx";
import { List } from "../components/typography.jsx";
import hotlinePresentation from "../img/hotline_photo.jpg";
import hotlinePresentation2x from "../img/hotline_photo@2x.jpg";
import headerMobile from "../img/services_bandeau_mobile.jpg";
import headerMobile2x from "../img/services_bandeau_mobile@2x.jpg";
import header from "../img/services_bandeau.jpg";
import header2x from "../img/services_bandeau@2x.jpg";
import { usePermissions } from "../services/permissions.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
  PanelInfos,
  TwoColumns,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

const HotlineContainer = styled.div`
  margin: 32px 0;
`;

const A_WEEK_IN_MS = 1000 * 3600 * 24 * 7;

export default function Hotline() {
  const [hasPermission] = usePermissions();
  const { isArchived, isSubscriptionExpired } = useSelfMetaData();
  const nextCodeAndDate = getNextCodeAndDate();

  return (
    <LoggedInTemplate title="Hotline technique">
      <ImageHeader
        image={header}
        image2x={header2x}
        imageMobile={headerMobile}
        imageMobile2x={headerMobile2x}
        icon="tools-line"
        label="Services"
      />
      <MainContent>
        <Headline title="Hotline technique" />
        <Container>
          <TwoColumns
            aside={
              <PanelInfos
                icon="chat"
                label="Une question ? Besoin d'aide ?"
                image={hotlinePresentation}
                image2x={hotlinePresentation2x}
              />
            }
          >
            <p>
              Notre hotline technique est à votre disposition sans interruption
              de 08h00 à 18h00 du lundi au vendredi.
            </p>
            <HotlineContainer>
              {hasPermission("technical:hotline") ? (
                <>
                  <h5 className="color-text-dark">
                    Et avec le{" "}
                    <span className="color-primary">
                      code {getCurrentCode()}
                    </span>
                    , nos conseillers vous répondent en priorité !
                  </h5>
                  <HotlineNumber />
                  {Boolean(nextCodeAndDate) &&
                    +nextCodeAndDate.fromDate - +new Date() < A_WEEK_IN_MS && (
                      <p style={{ marginTop: 8 }}>
                        <strong>
                          Nouveau code à partir du{" "}
                          {formatDate(nextCodeAndDate.fromDate, "do MMMM yyyy")}{" "}
                          :{" "}
                          <span className="color-primary">
                            {nextCodeAndDate.code}
                          </span>
                        </strong>
                      </p>
                    )}
                </>
              ) : (
                <>
                  <h5 className="color-text-dark">
                    Et avec le <span className="color-primary">code XXXX</span>,
                    nos conseillers vous répondent en priorité !
                  </h5>
                  <Tooltip
                    align="left"
                    content={
                      isArchived ? (
                        <ArchivedTooltipContent />
                      ) : isSubscriptionExpired ? (
                        <ExpiredTooltipContent />
                      ) : (
                        <DisabledTooltipContent />
                      )
                    }
                  />
                  <HotlineNumber />
                </>
              )}
            </HotlineContainer>
            <p>Si votre demande concerne les sujets suivants :</p>
            <List>
              <li>Avoir des informations sur les codes défaut</li>
              <li>Accéder à des fiches pratiques d'aide au dépannage</li>
              <li>Avoir des réponses concernant des pannes simples</li>
            </List>
            <p>
              Alors nous vous invitons à télécharger l'application mobile{" "}
              <strong>Daikin e-Care</strong> disponible sur Android et IOS
              (consulter les modules Pro Assist' et e-Doctor). Cela vous
              permettra d'obtenir des solutions pour répondre en autonomie à
              l'ensemble des problématiques citées ci-dessus.
            </p>
          </TwoColumns>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
