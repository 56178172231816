import iconsSheet from "../img/daikin_icones_sprites_128.png";
import { is, prop, styled } from "../util/style.js";

const icons = [
  "home",
  "hands",
  "school",
  "tools",
  "gift",
  "facebook",
  "instagram",
  "linkedin",
  "youtube",
  "arrow-next",
  "arrow-prev",
  "user",
  "security",
  "user-line",
  "money",
  "daikin",
  "notification",
  "tools-line",
  "school-line",
  "clothing-line",
  "gift-line",
  "arrow-top",
  "phone",
  "voucher",
  "play",
  "chat",
  "help",
  "clothing",
  "burger",
  "close",
  "arrow-down",
  "arrow-up",
  "check",
  "hands-line",
  "download",
  "upload",
  "logout",
  "gear-euros",
  "gear-phone",
  "clipboard-reload",
  "hourglass",
  "clipboard-valid",
  "clipboard-error",
  "archive",
  "trash",
  "calendar",
  "clock",
  "close-line",
  "plus-line",
  "megaphone",
  "gift-copy",
  "trophy",
  "megaphone-line",
  "trophy-line",
  "training-pending",
  "training-done",
  "training-cancelled",
  "calendar",
  "search",
  "users-multiple",
  "catalog",
];

const variants = ["black", "blue", "white", "gold", "grey"];

const DEFAULT_SIZE = 32;

export default styled(function Icon({ className }) {
  return <span className={className} />;
})`
  position: relative;
  display: inline-block;

  width: ${({ size = DEFAULT_SIZE }) => size}px;
  height: ${({ size = DEFAULT_SIZE }) => size}px;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ size = DEFAULT_SIZE }) => size}px;
    height: ${({ size = DEFAULT_SIZE }) => size}px;
    transition: var(--icon-transition, opacity 0.3s ease);

    background-image: url(${iconsSheet});
    background-size: ${({ size = DEFAULT_SIZE }) => size * icons.length}px
      ${({ size = DEFAULT_SIZE }) => size * variants.length}px;
    background-repeat: no-repeat;

    transform: translate(-50%, -50%) translateZ(0);
  }

  &::before {
    background-position: ${({ name, size = DEFAULT_SIZE }) =>
        icons.indexOf(name) * size * -1}px
      ${({ variant = variants[0], size = DEFAULT_SIZE }) =>
        variants.indexOf(variant) * size * -1}px;
    opacity: 1;
  }

  &::after {
    opacity: 0;
  }

  ${is("hoverVariant")`
    &::after {
      background-position: ${({ name, size = DEFAULT_SIZE }) =>
        icons.indexOf(name) * size * -1}px
        ${({ hoverVariant = variants[0], size = DEFAULT_SIZE }) =>
          variants.indexOf(hoverVariant) * size * -1}px;
    }

    &::after {
      opacity: var(--icon-hover, 0);
    }
    &::before {
      opacity: calc(1 - var(--icon-hover, 0));
    }
    &:hover::after {
      opacity: 1;
    }
    &:hover::before {
      opacity: 0;
    }
  `}
`;
