import { color, font, styled } from "../../util/style.js";
import { ButtonArrowAround } from "../typography.jsx";

export default styled(function FileQuestion({ onChange, className, value }) {
  return (
    <div className={className}>
      <Upload
        onChange={(evt) =>
          onChange && onChange(evt.nativeEvent.target.files[0])
        }
      >
        <ButtonArrowAround buttonAs="span">
          Joindre un fichier
        </ButtonArrowAround>
        {Boolean(value) && <FileName>{value.name}</FileName>}
      </Upload>
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 0;
  width: 100%;
  padding: 8px;
  font: ${font("strong")};
`;

const Upload = styled(function FormUpload({ className, children, onChange }) {
  return (
    <label className={className}>
      <Input name="file" onChange={onChange} />
      {children}
    </label>
  );
})`
  position: relative;
`;

const Input = styled.input.attrs({ type: "file" })`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`;

const FileName = styled.div`
  color: ${color("blue")};
  font: ${font("strong")};
  margin-top: 8px;
`;
