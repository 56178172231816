import _pick from "lodash/pick";
import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  useFormAction,
} from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import ParticipationRequestForm, { useParticipationForm } from '../components/ParticipationRequestForm.jsx'
import ParticipationsRequestsStatHeader from "../components/ParticipationsRequestsStatHeader.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import {
  useUpdateWorkforceParticipationRequest,
  useWorkforceParticipationRequest,
  useWorkforceParticipationRequestsStats,
} from "../services/participations.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, font, styled, theme } from "../util/style.js";

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
  hr {
    margin: 32px 0;
  }
`;

const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const Title = styled.div`
  ${font("headline5")}
  text-align: center;
  margin-bottom: 42px;
`;

export default function ParticipationEdit() {
  const [stats] = useWorkforceParticipationRequestsStats();
  const {id} = useParams();

  const [workforceParticipationRequest] = useWorkforceParticipationRequest(id)
  const updateParticipationAction = useUpdateWorkforceParticipationRequest(id);

  const [success, setSuccess] = useState(false);

  const form = useParticipationForm(workforceParticipationRequest);
  const { reset } = form;

  const [onRequest, { loading: loadingParticipation }] = useFormAction(
    form,
    updateParticipationAction,
    {
      transformData: (data) => ({
        ..._pick(data, [
          "reference",
          "pieceKind",
          "pieceReference",
          "productSerial",
          "productReference",
          "invoicePieceReference",
        ]),
      }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre demande n'a pas pu être mise à jour. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setSuccess(true);
        reset({});
      },
    }
  );

  return (
    <LoggedInTemplate title="Participations main d’œuvre">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Participations main d’œuvre"
      />
      <MainContent>
        <Headline title="Mes demandes" />
        <Container>
          <ParticipationsRequestsStatHeader stats={stats} />
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    En tant que client membre du programme DAIKIN ELITE, vous
                    bénéficiez d'une participation financière pour le changement
                    de vos pièces détachées en garantie sur les produits{" "}
                    <strong>Daikin</strong>.
                  </p>
                  <center>
                    <Button to={paths.participation} ghost>
                      Retour à l'historique
                    </Button>
                  </center>
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <Title>
                Merci de compléter votre demande
              </Title>
              {Boolean(workforceParticipationRequest) && (
                <ParticipationRequestForm key={workforceParticipationRequest.id}
                  onSubmit={onRequest} loading={loadingParticipation} 
                  form={form} successMessage={Boolean(success) && <div>
                    <strong>La demande a été modifiée et envoyée</strong> et sera traitée
                    par les équipes DAIKIN dans les plus brefs délais. Vous
                    pouvez annuler à tout moment votre demande dans votre
                    historique des demandes.
                  </div>}>
              </ParticipationRequestForm>)}
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}