import { styled, color } from "../util/style.js";
import { Button } from "../components/typography.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { useUserSession } from "../services/auth.js";
import LoggedOutTemplate, { Box } from "../templates/LoggedOutTemplate.jsx";

export default function Error404() {
  const [session] = useUserSession();

  const Template = session ? LoggedInTemplate : LoggedOutTemplate;
  const Container = session ? LoggedInContainer : Box;

  return (
    <Template title="Erreur">
      <Container>
        <StatusCode>404</StatusCode>
        <Title>Page introuvable</Title>
        <Text>Désolé, la page demandée n'existe pas ou a été déplacée</Text>
        <Button to="/">Retour à l'accueil</Button>
      </Container>
    </Template>
  );
}

const LoggedInContainer = styled.div`
  margin: 0 auto;
  max-width: 344px;
  padding: 56px 12px;
  text-align: center;
`;

const StatusCode = styled.div`
  font-size: 72px;
  line-height: 72px;
  font-weight: bold;
  color: ${color("primary")};
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin-bottom: 24px;
`;

const Text = styled.p`
  margin-bottom: 40px;
`;
