import { useRef, useState } from "react";

import DisabledHelp from "../components/DisabledHelp.jsx";
import { Form, FormField, FormSelect } from "../components/forms.jsx";
import { Col, Row } from "../components/layout.jsx";
import Pagination, { usePagination } from "../components/Pagination.jsx";
import ParticipationItem from "../components/ParticipationRequestItem.jsx";
import ParticipationsRequestsStatHeader from "../components/ParticipationsRequestsStatHeader.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/maindoeuvre_bandeau.jpg";
import header2x from "../img/maindoeuvre_bandeau@2x.jpg";
import { useSessionData } from "../services/auth.js";
import {
  useWorkforceParticipationRequests,
  useWorkforceParticipationRequestsStats,
} from "../services/participations.js";
import { usePermissions } from "../services/permissions.js";
import { paths } from "../services/router.js";
import { useSelf } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, styled, theme } from "../util/style.js";

const DEFAULT_LIMIT = 10;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;
const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const Requests = styled.div`
  ${ParticipationItem} + ${ParticipationItem} {
    margin-top: 30px;
  }
`;

const statusOptions = [
  { label: "Toutes les demandes", value: undefined },
  { label: "En cours", value: "pending" },
  { label: "À compléter", value: "incomplete" },
  { label: "Facture en attente", value: "invoiceValidation" },
  { label: "Acceptées", value: "accepted" },
  { label: "Refusées", value: "declined" },
  { label: "Archivées", value: "archived" },
];

export default function Participations() {
  const { subscriptionExpirationDate } = useSessionData();
  const isExpired = new Date(subscriptionExpirationDate) < new Date();
  const [stats] = useWorkforceParticipationRequestsStats();
  const [filterStatus, setFilterStatus] = useState();
  const topScroll = useRef();
  const [hasPermission] = usePermissions();

  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [participationsResult, { ready, refetch }] =
    useWorkforceParticipationRequests({ status: filterStatus }, pagination);

  const participations = (participationsResult?.edges || []).map(
    ({ node }) => node
  );

  return (
    <LoggedInTemplate title="Participations main d’œuvre">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Participations main d’œuvre"
      />
      <MainContent>
        <Headline title="Mes demandes" />
        <Container>
          <ParticipationsRequestsStatHeader stats={stats} />
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    En tant que client membre du programme DAIKIN ELITE, vous
                    bénéficiez d'une participation financière pour le changement
                    de vos pièces détachées en garantie sur les produits{" "}
                    <strong>Daikin</strong>.
                  </p>
                  <Form>
                    <FormField>
                      <FormSelect
                        placeholder="Filtrer par..."
                        white
                        value={filterStatus}
                        onChange={(filterStatus) =>
                          setFilterStatus(filterStatus)
                        }
                        options={statusOptions}
                      />
                    </FormField>
                  </Form>

                  <center>
                    <Button
                      to={paths.participationRequest}
                      disabled={!hasPermission("technical:create")}
                    >
                      J'effectue une demande
                    </Button>
                  </center>
                  <center style={{ marginTop: "32px" }}>
                    <Button
                      to={paths.participationInvoice}
                      ghost
                      disabled={!hasPermission("technical:sendInvoices")}
                    >
                      J'envoie une facture
                    </Button>
                  </center>
                  {Boolean(isExpired) && (
                    <DisabledHelp>
                      L'accès aux demandes de participation main d'œuvre sera de
                      nouveau disponible lorsque le contrat DAIKIN ELITE 2023
                      nous sera retourné signé (NB : Il faut compter environ 2
                      semaines entre l’envoi du contrat signé et l’activation de
                      ces fonctionnalités et de vos avantages).
                    </DisabledHelp>
                  )}
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />
              <Requests>
                {participations.length ? (
                  participations.map((participation) => (
                    <ParticipationItem
                      request={participation}
                      key={participation.id}
                      onUpdate={refetch}
                    />
                  ))
                ) : (
                  <Panel>
                    <h3>Aucun résultat</h3>
                    <div>
                      {filterStatus
                        ? "Aucune demande n'a été trouvée avec cette recherche."
                        : "Vous n'avez aucune demande enregistrée."}
                    </div>
                  </Panel>
                )}
              </Requests>
              {Boolean(ready && participations.length) && (
                <Pagination
                  result={participationsResult}
                  pagination={pagination}
                />
              )}
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
