import { color, font, styled } from "../util/style.js";
import Icon from "./Icon.jsx";

export default styled(function({ className, number = "04 37 72 24 00" }) {
  return (
    <div className={className}>
      <div className="Hotline-icon">
        <Icon name="phone" size={26} variant="black" />
      </div>
      <a href={`tel:${number.replace(/\D+/img, '')}`}>{number}</a>
    </div>
  );
})`
  ${font("headline3")}
  color: ${color("primary")};
  align-items: center;
  display: flex;

  .Hotline-icon {
    border: 2px solid ${color("border-black")};
    border-radius: 50px;
    display: inline-block;
    margin-right: 16px;
    padding: 4px;
  }
  ${Icon} {
    display: block;
  }
  a {
    ${font("headline3")}
    color: ${color("primary")};
    text-decoration:none;
  }
`;
