import { useState } from "react";

import { formatCurrency } from "../../common/currency.js";
import { daysUntilNow, format } from "../../common/date.js";
import DefaultSlider from "../components/DefaultSlider.jsx";
import Icon from "../components/Icon.jsx";
import Link from "../components/Link.jsx";
import { useRevenuesData } from "../components/side-widgets/WidgetRevenues.jsx";
import Tooltip, {
  ArchivedTooltipContent,
  ExpiredTooltipContent,
  DisabledTooltipContent as GenericDisabledTooltipContent,
  TrainingTooltipContent,
} from "../components/Tooltip.jsx";
import {
  Button,
  ButtonArrowAround,
  Paragraph,
  Title,
} from "../components/typography.jsx";
import imageClothing from "../img/accueil/photo_accessoires.jpg";
import imageGift from "../img/accueil/photo_cadeaux.jpg";
import imageTraining from "../img/accueil/photo_formations.jpg";
import imageInterventions from "../img/accueil/photo_interventions.jpg";
import imageVouchers from "../img/accueil/photo_offres.jpg";
import { useCarousel } from "../services/carousel.js";
import { usePermissions } from "../services/permissions.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import { MainContent } from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { getMediaUrl } from "../util/medias.js";
import {
  color,
  context,
  css,
  eq,
  font,
  is,
  styled,
  theme,
} from "../util/style.js";

const MainSlide = styled(function ({ title, text, label, url, className }) {
  return (
    <div className={className}>
      <Container>
        <Title>{title}</Title>
        {Boolean(text) && <Paragraph>{text}</Paragraph>}
        {Boolean(label) && (
          <Button gold to={url}>
            {label}
          </Button>
        )}
      </Container>
    </div>
  );
})`
  background-color: ${color("bg-dark-light")};
  background-image: url(${(props) => getMediaUrl(props.image, "carousel@2x")});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 160px;
  text-align: center;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  height: 540px;
  width: 100vw;

  ${Title} {
    color: white;
    margin-top: 0;
  }
  ${Paragraph} {
    ${font("headline4")};
    margin: 32px auto;
    max-width: 800px;
    & > :last-child {
      margin-bottom: 0;
    }
  }

  ${context("for-tablet-landscape")`
    ${Title} {
      ${font("headline4")}
      padding: 0;
    }
    ${Paragraph} {
      ${font("paragraph")};
      margin: 16px 0;
      max-width: none;
    }
    ${Container} {
      padding: 0 60px;
    }
  `}

  ${context("for-tablet-landscape")`
    padding-bottom: 40px;
    height: 460px;
  `}
`;

const MainSlider = styled(DefaultSlider)`
  .slick-dots {
    position: absolute;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
  ${context("for-tablet-landscape")`
    .slick-dots {
      bottom: 10px;
    }
  `}
`;

const MainSliderContainer = styled(function ({ slides, className }) {
  return (
    <div className={className}>
      <MainSlider
        {...{
          adaptiveHeight: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 6000,
          dots: true,
          infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          speed: 500,
        }}
      >
        {(slides || []).map((slide, index) => (
          <MainSlide {...slide} key={"slide-" + index} />
        ))}
      </MainSlider>
    </div>
  );
})`
  background-color: ${color("bg-page")};
`;

const Shortcut = styled(function ({
  to,
  label,
  description,
  content,
  red,
  emphased,
  icon,
  disabled,
  disabledDescription,
  ...props
}) {
  const Component = to ? Link : "div";

  return (
    <Component
      {...(to ? { to, alt: label } : {})}
      disabled={disabled}
      {...props}
    >
      <div className="Shortcut-content">
        <div className="Shortcut-iconCircle">
          <Icon
            className="hide-mobile"
            name={icon}
            size={56}
            variant="black"
            hoverVariant={to ? "blue" : undefined}
          />
          <Icon
            className="only-mobile"
            name={icon}
            size={32}
            variant="white"
            hoverVariant={to ? "white" : undefined}
          />
        </div>
        <h2 className="Shortcut-title">{label}</h2>
      </div>
      <div className="Shortcut-footer">
        {disabled ? (
          <div>{disabledDescription || description}</div>
        ) : (
          <div>
            {description}
            {content && (
              <div className="Shortcut-expirationDate">{content}</div>
            )}
          </div>
        )}
      </div>
    </Component>
  );
})`
  background-color: white;
  border-radius: ${theme("border-radius")};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  box-shadow: none;
  text-decoration: none;
  transition: all 0.3s ease;
  transform: scale(1) translateZ(0);

  ${is("disabled")`
    cursor: not-allowed;
    .Shortcut-content,
    .Shortcut-footer {
      opacity: 0.3;
    }
  `}

  ${({ to, disabled }) =>
    to && !disabled
      ? css`
          &:hover {
            ${theme("box-shadow")}
            transition: all 0.3s ease;
            transform: scale(1.05) translateZ(0);
            --icon-hover: 1;
          }
        `
      : ""}
  .Shortcut {
    &-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-content,
    &-footer {
      padding: 16px 8px;
    }
    &-title,
    &-iconCircle {
      margin: 8px auto;
    }
    &-iconCircle {
      border: 2px solid ${color("border-black")};
      border-radius: 50px;
      display: inline-block;
      padding: 8px;
      ${Icon} {
        display: block;
      }
    }
    &-title {
      ${font("headline4")}
      color: ${color("primary")};
    }
    &-expirationDate {
      font-size: 1.2rem;
      font-weight: ${theme("font-weight-regular")};
    }
    &-footer {
      align-items: center;
      background-color: ${color("bg-light")};
      border-radius: ${theme("border-radius")};
      display: flex;
      justify-content: center;
      margin: auto 0 0 0;
      min-height: 120px;

      color: ${color("grey-text")};
      ${font("paragraph")}
      font-weight: ${theme("font-weight-regular")};
      line-height: 1.4;

      ${({ emphased, disabled }) =>
        emphased && !disabled
          ? css`
              color: ${color("text-dark")};
              ${font("headline5")};
            `
          : ""}
    }
  }

  ${context("for-tablet-landscape")`
    ${color("gradient-blue-button")}
    border-radius: 0;
    padding: 0;
    .Shortcut {
      &-title, &-footer {
        display: none;
      }
      &-iconCircle {
        border-color: white;
        margin: 0;
      }
    }
  `}

  ${context("for-phone")`
    .Shortcut {
      &-iconCircle {
        padding: 4px;
      }
      &-content {
        padding: 12px;
      }
    }
  `}
`;

const Shortcuts = styled(function Shortcuts({ className }) {
  const { company, isEliteProPlus, isSubscriptionExpired, isArchived } =
    useSelfMetaData();
  const [hasPermission] = usePermissions();
  const [showTrainingTooltip, setShowTrainingTooltip] = useState(false);
  const [showStoresClothingTooltip, setShowStoresClothingTooltip] =
    useState(false);
  const [showStoresGiftsTooltip, setShowStoresGiftsTooltip] = useState(false);
  const [showVouchersTooltip, setShowVouchersTooltip] = useState(false);
  const { vouchersActive, vouchersActiveUsed } = useRevenuesData(company);
  const vouchersLeft = vouchersActive.length - vouchersActiveUsed.length;
  const budgets = company?.budgets;

  const DisabledTooltipContent = isArchived
    ? ArchivedTooltipContent
    : GenericDisabledTooltipContent;

  return (
    <section className={className}>
      {hasPermission("technical:*") && isEliteProPlus && (
        <div className="Shortcuts-item">
          <Shortcut
            icon="tools-line"
            label="Interventions Daikin"
            emphased
            description={
              <>
                Budget
                <br />
                {formatCurrency(
                  budgets?.interventions?.totalBalance?.amount || 0,
                  false
                )}
              </>
            }
            content={
              budgets?.interventions?.totalBalance?.expiresAt !==
                "1970-01-01T00:00:00.000Z" && (
                <>
                  valables jusqu'au{" "}
                  {format(budgets?.interventions?.totalBalance?.expiresAt)}
                </>
              )
            }
            to={paths.interventions}
          />
        </div>
      )}
      <div className="Shortcuts-item">
        <Shortcut
          icon="school-line"
          label="Vos formations"
          disabled={!hasPermission("training:order")}
          disabledDescription={"Dévelopez votre expertise avec Daikin"}
          onMouseEnter={() => setShowTrainingTooltip(true)}
          onMouseLeave={() => setShowTrainingTooltip(false)}
          description={
            <>
              Budget
              <br />
              {formatCurrency(
                budgets?.formations?.totalBalance?.amount || 0,
                false
              )}
            </>
          }
          content={
            budgets?.formations?.totalBalance?.expiresAt !==
              "1970-01-01T00:00:00.000Z" && (
              <>
                valables jusqu'au{" "}
                {format(budgets?.formations?.totalBalance?.expiresAt)}
              </>
            )
          }
          red={daysUntilNow(budgets?.formations?.totalBalance.expiresAt) < 30}
          emphased
          to={paths.trainingSessions}
        />
        {hasPermission("training:order") ? (
          <Tooltip
            content={
              isSubscriptionExpired &&
              !budgets?.formations?.totalBalance?.amount ? (
                <ExpiredTooltipContent />
              ) : (
                <TrainingTooltipContent />
              )
            }
            show={showTrainingTooltip}
            float
          ></Tooltip>
        ) : (
          <Tooltip
            content={<DisabledTooltipContent />}
            show={showTrainingTooltip}
            float
          ></Tooltip>
        )}
      </div>
      <div className="Shortcuts-item">
        <Shortcut
          icon="clothing-line"
          label="Vos accessoires"
          disabled={!hasPermission("stores:clothing")}
          disabledDescription={
            "Équipez vos collaborateurs avec les accessoires Daikin"
          }
          description={
            <>
              Budget
              <br />
              {formatCurrency(
                budgets?.accessories?.totalBalance?.amount || 0,
                false
              )}
            </>
          }
          red={daysUntilNow(budgets?.accessories?.totalBalance.expiresAt) < 30}
          content={
            budgets?.accessories?.totalBalance?.expiresAt !==
              "1970-01-01T00:00:00.000Z" && (
              <>
                valables jusqu'au{" "}
                {format(budgets?.accessories?.totalBalance?.expiresAt)}
              </>
            )
          }
          emphased
          to={paths.accessories}
          onMouseEnter={() => setShowStoresClothingTooltip(true)}
          onMouseLeave={() => setShowStoresClothingTooltip(false)}
        />
        {!hasPermission("stores:clothing") ? (
          <Tooltip
            content={<DisabledTooltipContent />}
            show={showStoresClothingTooltip}
            float
          ></Tooltip>
        ) : (
          isSubscriptionExpired &&
          !budgets?.accessories?.totalBalance?.amount && (
            <Tooltip
              content={<ExpiredTooltipContent />}
              show={showStoresClothingTooltip}
              float
            ></Tooltip>
          )
        )}
      </div>
      <div className="Shortcuts-item">
        <Shortcut
          icon="gift-line"
          label="Vos cadeaux"
          disabled={!hasPermission("stores:gifts")}
          disabledDescription={
            "Faites-vous plaisir sur une large sélection de produits..."
          }
          description={
            budgets?.gifts?.balances?.length === 1 && (
              <>
                Budget
                <br />
                {formatCurrency(
                  budgets?.gifts?.totalBalance?.amount || 0,
                  false
                )}
              </>
            )
          }
          red={daysUntilNow(budgets?.gifts?.totalBalance.expiresAt) < 30}
          content={
            budgets?.gifts?.balances?.length === 1
              ? budgets?.gifts?.totalBalance?.expiresAt !==
                  "1970-01-01T00:00:00.000Z" && (
                  <>
                    valables jusqu'au{" "}
                    {format(budgets?.gifts?.totalBalance?.expiresAt)}
                  </>
                )
              : Boolean(budgets?.gifts?.balances?.length) &&
                (budgets?.gifts?.balances || []).map(
                  ({ expiresAt, amount }, index) => (
                    <div
                      key={"giftheader" + index}
                      style={{ marginTop: index !== 0 ? "8px" : 0 }}
                    >
                      <strong style={{ fontSize: 18 }}>
                        Budget : {formatCurrency(amount, false)}
                      </strong>
                      <br />
                      valables jusqu'au {format(expiresAt)}
                    </div>
                  )
                )
          }
          emphased
          to={paths.gifts}
          onMouseEnter={() => setShowStoresGiftsTooltip(true)}
          onMouseLeave={() => setShowStoresGiftsTooltip(false)}
        />
        {!hasPermission("stores:gifts") ? (
          <Tooltip
            content={<DisabledTooltipContent />}
            show={showStoresGiftsTooltip}
            float
          ></Tooltip>
        ) : (
          isSubscriptionExpired &&
          !budgets?.gifts?.totalBalance?.amount && (
            <Tooltip
              content={<ExpiredTooltipContent />}
              show={showStoresGiftsTooltip}
              float
            ></Tooltip>
          )
        )}
      </div>
      {isEliteProPlus && (
        <div className="Shortcuts-item">
          <Shortcut
            icon="hands-line"
            label="Vos offres client"
            description={
              <>
                {vouchersLeft} / {vouchersActive.length} bons de
                <br />
                {vouchersActive.length ? vouchersActive[0].amount : 100}
                 € TTC
              </>
            }
            red={
              true ||
              (Boolean(vouchersActive.length) &&
                Boolean(vouchersActive[0].expirationDate) &&
                daysUntilNow(vouchersActive[0].expirationDate) < 30)
            }
            content={
              Boolean(vouchersActive.length) &&
              Boolean(vouchersActive[0].expirationDate) && (
                <>
                  valables jusqu'au {format(vouchersActive[0].expirationDate)}
                </>
              )
            }
            emphased
            to={paths.vouchers}
            onMouseEnter={() => setShowVouchersTooltip(true)}
            onMouseLeave={() => setShowVouchersTooltip(false)}
          />
          {isSubscriptionExpired && !vouchersActive && (
            <Tooltip
              content={<ExpiredTooltipContent />}
              show={showVouchersTooltip}
              float
            ></Tooltip>
          )}
        </div>
      )}
    </section>
  );
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -${theme("gutters")};
  margin-right: -${theme("gutters")};
  margin-bottom: 200px;
  margin-top: -160px;
  .Shortcuts-item {
    flex: 1 1 0;
    margin: ${theme("gutters")};
    max-width: 280px;
  }

  ${context("for-tablet-landscape")`
    border-radius: ${theme("border-radius")};
    margin-bottom: 56px;
    margin-top: 0;
    overflow: hidden;
    .Shortcuts-item {
      border-radius: 0;
      margin: 0;
    }
  `}

  ${context("for-phone")`
    margin-left: 0;
    margin-right: 0;
  `}
`;

const ImageAndText = styled(function ({
  image,
  action,
  additionalText,
  className,
  children,
}) {
  return (
    <Container as="section" className={className}>
      <div className="ImageAndText-img">
        <img src={image} alt="" />
      </div>
      <div className="ImageAndText-content">
        <div className="ImageAndText-description">{children}</div>
        <div className="ImageAndText-action">{action}</div>
        {Boolean(additionalText) && (
          <div className="ImageAndText-description">{additionalText}</div>
        )}
      </div>
    </Container>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 100px auto;
  .ImageAndText {
    &-img {
      display: block;
      flex: 1;
      margin-left: -200px;
      max-width: 810px;
      img {
        height: auto;
        width: 100%;
      }
    }
    &-content {
      flex: 1;
      padding: 0 80px;
      align-self: center;
      margin-bottom: 100px;

      ${Title} {
        ${font("headline2")};
        color: ${color("title-special")};
        margin: 0 0 20px;
      }
      ${Paragraph} {
        color: ${color("grey-text")};
      }
    }
    &-action {
      background-color: white;
      border-radius: 8px;
      border: 1px solid ${color("yellow")};
      margin: 20px 0;
      padding: 40px;
      text-align: left;
      max-width: 576px;
      width: 100%;
      h3 {
        ${font("headline4")}
        margin: 0;
      }
      p {
        ${font("paragraph")}
        margin-bottom: 0;
        small {
          ${font("small")}
          display: block;
        }
      }

      ${context("for-phone")`
        padding: 24px;
      `}
    }
  }
  ${eq("align", "right")`
    .ImageAndText {
      &-img {
        margin-left: 0;
        margin-right: -200px;
        order: 2;
      }
      &-content {
        order: 1;
      }
    }
  `}

  ${context("for-desktop")`
    .ImageAndText {
      &-img {
        margin-left: -300px;
      }
      &-content {
        padding: 0 60px;
      }
    }

    ${eq("align", "right")`
      .ImageAndText-img {
        margin-right: -300px;
        margin-left: 0px;
      }
    `}
  `}

  ${context("for-tablet-landscape")`
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    .ImageAndText {
      &-img {
        margin: 0;
        order: 0;
        width: 100%;
      }
      &-content {
        flex: 0 0 100%;
        order: 2;
        padding: 8px 32px;
        margin-bottom: 0;

        ${Title} {
          ${font("headline4")};
          margin: 16px 0;
        }
      }
      &-action {
        flex: 0 0 100%;
        order: 1;
        max-width: none;
      }

      ${eq("align", "right")`
        .ImageAndText {
          &-img {
            order: 0;
          }
          &-content {
            order: 1;
          }  
        }
      `}
    }
  `}
`;

const ContentContainer = function ({ children }) {
  return (
    <>
      <div className="only-mobile">
        <DefaultSlider
          {...{
            adaptiveHeight: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500,
          }}
        >
          {children}
        </DefaultSlider>
      </div>
      <div className="hide-mobile">{children}</div>
    </>
  );
};

const BudgetBlocks = styled.div`
  display: flex;
  margin: 0 -12px;
  ${context("for-tablet-landscape")`
    display: block;
  `}
`;

const BudgetBlock = styled.div`
  flex: 1;
  margin-bottom: 12px;
  padding: 0 12px;
`;

const BudgetDate = styled.div`
  ${font("paragraph")}
  font-size: 0.85em;
  color: ${color("yellow")};
`;

const BudgetLine = styled(function ({ label, value, className }) {
  return (
    <div className={className}>
      <div className="BudgetLine-label">{label}</div>
      <hr className="BudgetLine-separator" />
      <div className="BudgetLine-value">{value}</div>
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
  position: relative;

  ${is("primary")`
    color: ${color("yellow")};
  `}

  ${is("danger")`
    color: ${color("red")};
  `}

  .BudgetLine {
    &-separator {
      flex: 1;
      margin: 0;
    }
    &-label,
    &-value {
      background-color: white;
      font-weight: ${theme("font-weight-bold")};
      padding: 0 12px;
    }
    &-label {
      padding-left: 0;
    }
    &-value {
      padding-right: 0;
    }
  }
`;

function HomeBlocks() {
  const [hasPermission] = usePermissions();
  const { isSubscriptionExpired, isEliteProPlus, company } = useSelfMetaData();
  const {
    interventionsByExpiration,
    formationsByExpiration,
    accessoriesByExpiration,
    giftsByExpiration,
    vouchersActive,
    vouchersActiveUsed,
  } = useRevenuesData(company);

  const voucherExpirationDate =
    Boolean(vouchersActive) &&
    Boolean(vouchersActive.length) &&
    vouchersActive[0].expirationDate;
  const voucherUsageDate = new Date(voucherExpirationDate);
  voucherUsageDate.setDate(voucherUsageDate.getDate() + 15);

  const budgets = company?.budgets;

  return (
    <>
      {hasPermission("technical:*") && isEliteProPlus && (
        <ImageAndText
          align="right"
          image={imageInterventions}
          action={
            <>
              <BudgetBlocks>
                {interventionsByExpiration.map(({ date, budget }) => (
                  <BudgetBlock key={"intervention-" + format(date)}>
                    <BudgetDate>
                      À dépenser jusqu'au <strong>{format(date)}</strong>
                    </BudgetDate>
                    <BudgetLine
                      label="Offert"
                      value={formatCurrency(budget.credits || 0, false)}
                    />
                    <BudgetLine
                      label="Dépensé"
                      value={formatCurrency(
                        -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                        false
                      )}
                    />
                    <BudgetLine
                      label="Solde"
                      value={formatCurrency(budget.balance || 0, false)}
                      danger={daysUntilNow(date) < 30}
                      primary={daysUntilNow(date) >= 30}
                    />
                  </BudgetBlock>
                ))}
                {Boolean(!interventionsByExpiration?.length) && (
                  <BudgetBlock>
                    <BudgetDate>Aucun budget disponible</BudgetDate>
                  </BudgetBlock>
                )}
              </BudgetBlocks>
              {!!budgets?.interventions?.totalReserved?.amount && (
                <BudgetBlocks>
                  <BudgetBlock>
                    <BudgetLine
                      label="Réservés"
                      value={formatCurrency(
                        budgets?.interventions?.totalReserved?.amount || 0,
                        false
                      )}
                    />
                  </BudgetBlock>
                </BudgetBlocks>
              )}
              {isSubscriptionExpired &&
                !budgets?.interventions?.totalBalance?.amount && (
                  <Tooltip
                    align="left"
                    content={<ExpiredTooltipContent />}
                  ></Tooltip>
                )}
              <ButtonArrowAround to={paths.interventions}>
                J'en profite
              </ButtonArrowAround>
            </>
          }
        >
          <Title>Interventions Daikin</Title>
          <Paragraph>
            Vous pouvez formuler vos demandes de mise en service, dépannage ou
            encore assistance directement en ligne. Et, en tant qu'installateur
            ELITE PRO+, vous bénéficiez d'un budget offert venant en déduction
            des factures d'intervention résidentielles ou tertiaires.
          </Paragraph>
        </ImageAndText>
      )}
      {hasPermission("training:order") && (
        <ImageAndText
          align="left"
          image={imageTraining}
          action={
            <>
              <BudgetBlocks>
                {formationsByExpiration.map(({ date, budget }) => (
                  <BudgetBlock key={"formations" + format(date)}>
                    <BudgetDate>
                      À dépenser jusqu'au <strong>{format(date)}</strong>
                    </BudgetDate>
                    <BudgetLine
                      label="Offert"
                      value={formatCurrency(budget.credits || 0, false)}
                    />
                    <BudgetLine
                      label="Dépensé"
                      value={formatCurrency(
                        -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                        false
                      )}
                    />
                    <BudgetLine
                      label="Solde"
                      value={formatCurrency(budget.balance || 0, false)}
                      danger={daysUntilNow(date) < 30}
                      primary={daysUntilNow(date) >= 30}
                    />
                  </BudgetBlock>
                ))}
                {Boolean(!formationsByExpiration?.length) && (
                  <BudgetBlock>
                    <BudgetDate>Aucun budget disponible</BudgetDate>
                  </BudgetBlock>
                )}
              </BudgetBlocks>
              {!!budgets?.formations?.totalReserved?.amount && (
                <BudgetBlocks>
                  <BudgetBlock>
                    <BudgetLine
                      label="Réservés"
                      value={formatCurrency(
                        budgets?.formations?.totalReserved?.amount || 0,
                        false
                      )}
                    />
                  </BudgetBlock>
                </BudgetBlocks>
              )}
              {isSubscriptionExpired &&
              !budgets?.formations?.totalBalance?.amount ? (
                <Tooltip content={<ExpiredTooltipContent />}></Tooltip>
              ) : (
                <Tooltip content={<TrainingTooltipContent />}></Tooltip>
              )}
              <ButtonArrowAround to={paths.trainingSessionAdd}>
                Je réserve une formation
              </ButtonArrowAround>
            </>
          }
          additionalText={
            formationsByExpiration.length === 2 &&
            new Date(formationsByExpiration[0].date).getFullYear() === 2023 &&
            new Date(formationsByExpiration[1].date).getFullYear() === 2023 && (
              <>
                Budget formation octroyé en décembre 2022 suite à l'atteinte des
                250 k€ de CA avec Daikin. Afin de vous laisser le temps d'en
                bénéficier, nous l'avons reporté sur 2023.
              </>
            )
          }
        >
          <Title>Vos formations</Title>
          <Paragraph>
            Développez votre expertise avec Daikin.
            <br />
            Nos experts sont à votre service pour vous accompagner et vous
            apporter des formations de haute qualité, des conseils pratiques et
            toutes les informations sur les nouvelles technologies.
          </Paragraph>
        </ImageAndText>
      )}
      {hasPermission("stores:clothing") && (
        <ImageAndText
          align="right"
          image={imageClothing}
          action={
            <>
              <BudgetBlocks>
                {accessoriesByExpiration.map(({ date, budget }) => (
                  <BudgetBlock key={"accessory" + format(date)}>
                    <BudgetDate>
                      À dépenser jusqu'au <strong>{format(date)}</strong>
                    </BudgetDate>
                    <BudgetLine
                      label="Offert"
                      value={formatCurrency(budget.credits || 0, false)}
                    />
                    <BudgetLine
                      label="Dépensé"
                      value={formatCurrency(
                        -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                        false
                      )}
                    />
                    <BudgetLine
                      label="Solde"
                      value={formatCurrency(budget.balance || 0, false)}
                      danger={daysUntilNow(date) < 30}
                      primary={daysUntilNow(date) >= 30}
                    />
                  </BudgetBlock>
                ))}
                {Boolean(!accessoriesByExpiration?.length) && (
                  <BudgetBlock>
                    <BudgetDate>Aucun budget disponible</BudgetDate>
                  </BudgetBlock>
                )}
              </BudgetBlocks>
              {isSubscriptionExpired &&
                !budgets?.accessories?.totalBalance?.amount && (
                  <Tooltip
                    align="left"
                    content={<ExpiredTooltipContent />}
                  ></Tooltip>
                )}
              <ButtonArrowAround to={paths.accessories}>
                J'en profite
              </ButtonArrowAround>
            </>
          }
        >
          <Title>Vos accessoires</Title>
          <Paragraph>
            En tant que membre du programme DAIKIN ELITE, vous disposez d’un
            budget accessoires que vous pouvez utiliser toute l’année : textile,
            PLV, carnets, clés USB&hellip;
          </Paragraph>
        </ImageAndText>
      )}
      {hasPermission("stores:gifts") && (
        <ImageAndText
          align="left"
          image={imageGift}
          action={
            <>
              <BudgetBlocks>
                {giftsByExpiration.map(({ date, budget }) => (
                  <BudgetBlock key={"gifts" + format(date)}>
                    <BudgetDate>
                      À dépenser jusqu'au <strong>{format(date)}</strong>
                    </BudgetDate>
                    <BudgetLine
                      label="Offert"
                      value={formatCurrency(budget.credits || 0, false)}
                    />
                    <BudgetLine
                      label="Dépensé"
                      value={formatCurrency(
                        -budget.expenses || 0, // on inverse pour ne pas avoir le montant affiché négatif
                        false
                      )}
                    />
                    <BudgetLine
                      label="Solde"
                      value={formatCurrency(budget.balance || 0, false)}
                      danger={daysUntilNow(date) < 30}
                      primary={daysUntilNow(date) >= 30}
                    />
                  </BudgetBlock>
                ))}
                {Boolean(!giftsByExpiration?.length) && (
                  <BudgetBlock>
                    <BudgetDate>Aucun budget disponible</BudgetDate>
                  </BudgetBlock>
                )}
              </BudgetBlocks>
              {isSubscriptionExpired &&
                !budgets?.gifts?.totalBalance?.amount && (
                  <Tooltip
                    align="left"
                    content={<ExpiredTooltipContent />}
                  ></Tooltip>
                )}
              <ButtonArrowAround to={paths.gifts}>
                Je découvre
              </ButtonArrowAround>
            </>
          }
        >
          <Title>Vos cadeaux</Title>
          <Paragraph>
            Grâce à votre chiffre d’affaires, cumulez les € cadeaux et
            faites-vous plaisir avec une large sélection de produits high tech,
            voyages, gastronomie, sport&hellip;
          </Paragraph>
        </ImageAndText>
      )}
      {isEliteProPlus && (
        <ImageAndText
          align="right"
          image={imageVouchers}
          action={
            <>
              <BudgetBlocks>
                {vouchersActive?.length ? (
                  <BudgetBlock>
                    {Boolean(voucherExpirationDate) && (
                      <BudgetDate>
                        À dépenser jusqu'au{" "}
                        <strong>{format(voucherExpirationDate)}</strong>
                      </BudgetDate>
                    )}
                    <BudgetLine
                      label={`Bons de ${
                        vouchersActive.length ? vouchersActive[0].amount : 100
                      } € TTC offerts`}
                      value={vouchersActive.length}
                    />
                    <BudgetLine
                      label="Téléchargés"
                      value={
                        vouchersActive.filter((voucher) => voucher.downloaded)
                          .length
                      }
                    />
                    <BudgetLine
                      label="Utilisés"
                      value={vouchersActiveUsed.length}
                    />
                    <BudgetLine
                      label="Solde"
                      value={vouchersActive.length - vouchersActiveUsed.length}
                      danger={daysUntilNow(voucherExpirationDate) < 30}
                      primary={daysUntilNow(voucherExpirationDate) >= 30}
                    />
                  </BudgetBlock>
                ) : (
                  <BudgetBlock>
                    <BudgetDate>Aucune offre disponible</BudgetDate>
                  </BudgetBlock>
                )}
              </BudgetBlocks>
              {isSubscriptionExpired && !vouchersActive.length && (
                <Tooltip
                  align="left"
                  content={<ExpiredTooltipContent />}
                ></Tooltip>
              )}
              <ButtonArrowAround to={paths.vouchers}>
                J'en profite
              </ButtonArrowAround>
            </>
          }
        >
          <Title>Vos offres client</Title>
          <Paragraph>
            Proposez à votre client, particulier ou professionnel, une aide
            financière et remportez une offre. Les bons sont disponibles en
            téléchargement et utilisables en 1 ou plusieurs fois.
            {Boolean(voucherExpirationDate) &&
              `Budget disponible jusqu'au ${format(
                voucherExpirationDate
              )} et utilisable par vos clients jusqu'au ${format(
                voucherUsageDate
              )}.`}
          </Paragraph>
        </ImageAndText>
      )}
    </>
  );
}

export default function Home() {
  const [carousel] = useCarousel();

  return (
    <LoggedInTemplate title="Accueil">
      <MainSliderContainer slides={carousel?.slides || []} />
      <MainContent extendedTop>
        <Container>
          <Shortcuts />
        </Container>
        <ContentContainer>
          <HomeBlocks />
        </ContentContainer>
      </MainContent>
    </LoggedInTemplate>
  );
}
