import * as Yup from "yup";

Yup.addMethod(Yup.object, "atLeastOneOf", function (...list) {
  return this.test({
    name: "atLeastOneOf",
    message: "${path} must have at least one of these keys: ${keys}",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => value[f] != null),
  });
});

export const username = () => Yup.string().email().lowercase();

export const password = () => Yup.string();

export const email = () =>
  Yup.string().trim().email("L'e-mail doit être valide").lowercase();

export const siret = () =>
  Yup.string()
    .trim()
    .test(
      "siret-valid",
      "Le SIRET ${path} doit contenir 14 chiffres",
      (value) => !value || (value || "").replace(/\s+/gim, "").match(/^\d{14}$/)
    )
    .transform((value) => value.replace(/\D+/gim, ""));

export const phone = () =>
  Yup.string()
    .trim()
    .test(
      "phone-valid",
      "Le numéro de téléphone ${path} doit contenir des chiffres ou symboles valides",
      (value) => value.replace(/[\s()\-.]+/gim, "").match(/^\+?\d{2,15}$/)
    );

export const address = () =>
  Yup.object().shape({
    company: Yup.string().trim(),
    recipient: Yup.string().trim(),
    location: Yup.string().trim(),
    street: Yup.string().trim(),
    additional: Yup.string().trim(),
    postalCode: Yup.string().trim(),
    city: Yup.string().trim(),
    cedex: Yup.string().trim(),
    country: Yup.string().trim(),
  });

export const socialNumber = () =>
  Yup.string()
    .trim()
    .test(
      "is-social-number-valid",
      "Le numéro n'est pas valide (13 chiffres)",
      (value) =>
        !value || // Soit c'est vide
        ((value || "").replace(/[\d\s]/gim, "").length === 0 &&
          (value || "").replace(/\D/gim, "").length === 13) // Soit autre chose que des chiffres et des espaces, soit pas 13 chiffres
    );
