import { useState } from "react";

import { rolesPrivate as roles } from "../../../common/profiles.js";
import { usePermissions } from "../../services/permissions.js";
import { paths } from "../../services/router.js";
import { useCompanyUserCreate, useCompanyUsers } from "../../services/users.js";
import { color, font, styled } from "../../util/style.js";
import {
  Form,
  FormCheckboxes,
  FormField,
  FormInput,
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../forms.jsx";
import { ButtonArrowAround } from "../typography.jsx";
import { Section, Subtitle, Title, TitleHelp } from "./Components.jsx";

const ROLES_BY_ID = roles.reduce(
  (all, { id, label }) => ({ ...all, [id]: label }),
  {}
);
const STATUSES_BY_ID = {
  pending: "Création en cours...",
  toComplete: "Connexion requise",
  active: "Actif",
};

export default function WidgetUsers({ close }) {
  const [accounts, { loading: loadingAccounts, refetch: refetchAccounts }] =
    useCompanyUsers();
  const [hasPermission] = usePermissions();
  const [hasAddedNewUser, setHasAddedNewUser] = useState(false);

  const pendingAccounts = (accounts || []).filter(
    ({ status }) => status !== "active"
  );
  const activeAccounts = (accounts || []).filter(
    ({ status }) => status === "active"
  );

  const newUserForm = useAccountForm();
  const [onSubmitNewUser, { loading: loadingNewUser }] = useFormAction(
    newUserForm,
    useCompanyUserCreate(),
    {
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message: "Le compte n'a pas pu être créé.",
          }
        );
      },
      onSuccess() {
        setHasAddedNewUser(true);
        newUserForm.reset();
        refetchAccounts();
      },
    }
  );

  return (
    <>
      <Section>
        <Title>Mes comptes utilisateurs</Title>

        <Subtitle>Comptes actifs</Subtitle>
        {loadingAccounts ? (
          <p>Chargement...</p>
        ) : Boolean(activeAccounts.length) ? (
          <UsersList>
            {activeAccounts.map((account) => (
              <UserItem key={account.id} user={account} />
            ))}
          </UsersList>
        ) : (
          <p>Aucun compte actif</p>
        )}

        {Boolean(pendingAccounts.length) && (
          <>
            <Subtitle>Comptes en cours de création</Subtitle>
            <UsersList>
              {pendingAccounts.map((account) => (
                <UserItem key={account.id} user={account} />
              ))}
            </UsersList>
          </>
        )}

        {hasPermission("users:create") && (
          <>
            <Subtitle>Créer un nouveau compte</Subtitle>
            {Boolean(hasAddedNewUser) && (
              <SuccessMessage>
                Votre demande a bien été prise en compte.
              </SuccessMessage>
            )}
            {activeAccounts.length + pendingAccounts.length >= 4 ? (
              <Explanation>
                Vous avez atteint la limite du nombre de comptes autorisés.
                Contactez votre commercial pour plus d'informations.
              </Explanation>
            ) : (
              <>
                {Boolean(hasAddedNewUser) ? (
                  <Explanation>
                    Pour créer un nouveau sous-compte, merci de remplir à
                    nouveau ce formulaire.
                  </Explanation>
                ) : (
                  <Explanation>
                    Remplissez le formulaire suivant pour demander la création
                    d'un compte. Vous recevrez un email quand celui-ci sera
                    actif.
                  </Explanation>
                )}
                <AccountForm
                  form={newUserForm}
                  onSubmit={onSubmitNewUser}
                  loading={loadingNewUser}
                />
              </>
            )}
          </>
        )}
      </Section>

      <hr />

      <Section>
        <TitleHelp>
          Vous souhaitez modifier ou supprimer un compte existant ?
        </TitleHelp>
        <Explanation>
          Contactez votre commercial avec votre demande, l'équipe Daikin se
          charge de la traiter dans les meilleurs délais.
        </Explanation>
        <center>
          <ButtonArrowAround to={paths.contact} onClick={close}>
            J'envoie ma demande
          </ButtonArrowAround>
        </center>
      </Section>
    </>
  );
}

function useAccountForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required().trim(),
        civility: Yup.string().optional().trim().nullable(),
        firstname: Yup.string().optional().trim().nullable(),
        lastname: Yup.string().optional().trim().nullable(),
        phone: Yup.string().optional().trim().nullable(),
        roles: Yup.array()
          .of(Yup.mixed().oneOf(["manager", "stores", "training", "technical"]))
          .min(1),
      })
    ),
  });
}

const Explanation = styled.p`
  ${font("paragraph")}
  color: ${color("text-dark")};
  line-height: 1.4;
`;

const SuccessMessage = styled.p`
  ${font("strong")}
  color: ${color("blue")};
  line-height: 1.4;
`;

const UsersList = styled.div`
  margin-bottom: 24px;
`;

const UserItem = styled(function UserItem({ className, user, ...props }) {
  return (
    <div className={className} {...props}>
      <span className={"Status Status--" + user.status}>
        {STATUSES_BY_ID[user.status]}
      </span>
      <h4 className="Name">
        {[user.firstname, user.lastname].filter(Boolean).join(" ")}
      </h4>
      <div className="Roles">
        {user.roles.map((role) => ROLES_BY_ID[role]).join(", ")}
      </div>
      <div className="Contact">
        {[user.email, user.phone].filter(Boolean).join(" - ")}
      </div>
    </div>
  );
})`
  ${font("paragraph")}
  color: ${color("text-dark")};
  line-height: 1.4;
  margin: 0 0 48px 0;
  padding-left: 24px;
  position: relative;
  overflow: hidden;
  &::before {
    background-color: ${color("list")};
    content: "";
    display: block;
    height: 10px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 10px;
  }

  .Name {
    ${font("headline5")}
    color: ${color("text-dark")};
    margin: 0;
    padding: 0;
  }
  .Status {
    ${font("mini")}
    color: ${color("grey-text")};
    float: right;
    font-weight: 700;
    line-height: 1;
    padding: 0.5em 0.25em;
    text-transform: uppercase;
    &--toComplete {
      color: ${color("red")};
    }
    &--active {
      color: ${color("green")};
    }
  }
  .Roles {
    color: ${color("grey-text")};
  }
  .Contact {
    color: ${color("blue")};
  }
`;

function AccountForm({ form, loading, onSubmit }) {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Form onSubmit={onSubmit} loading={loading} errors={errors}>
      <FormField label="Email : " name="email" required>
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <FormField label="Prénom : " name="firstname">
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <FormField label="Nom : " name="lastname">
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      {/* <FormField label="Téléphone : " name="phone">
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField> */}
      <FormField
        label="Profil souhaité — plusieurs combinaisons possibles : "
        name="roles"
      >
        {({ name, ...childProps }) => (
          <FormCheckboxes
            options={roles
              .filter(({ id }) => id !== "manager")
              .map(({ id, label }) => ({ value: id, label }))}
            {...register(name)}
            {...childProps}
          />
        )}
      </FormField>
      <ButtonArrowAround type="submit">Créer ce compte</ButtonArrowAround>
    </Form>
  );
}
