import { formatCurrency } from "../../common/currency.js";
import { format } from "../../common/date.js";
import { styled } from "../util/style.js";
import { DownloadInterventionVoucherPdfTextButton } from "./DownloadPdfInterventionVoucher.jsx";
import { Col, Row } from "./layout.jsx";
import {
  Request,
  RequestFooter,
  RequestHeader,
  RequestHeaderItem,
  RequestHeaderLine,
  RequestSection,
} from "./requests.jsx";

export default styled(function ParticipationRequestItem({
  className,
  ...voucherProps
}) {
  const {
    id,
    voucherCode,
    amount,
    expirationDate,
    reference,
    downloaded,
    usedAt,
  } = voucherProps;

  // Si créé il y a moins de X minutes
  const isRecenltyUsed = (new Date() - new Date(usedAt)) / 1000 / 60 < 30; // minutes
  // Si expire dans moins d'un mois (on suppose qu'on ne renvoie pas les expirations passées)
  const isOld =
    (new Date(expirationDate) - new Date()) / 1000 / 60 / 60 / 24 < 30; // jours
  const isExpired = new Date(expirationDate) - new Date() < 0;

  return (
    <Request className={className} highlight={isRecenltyUsed}>
      <RequestHeader>
        <RequestHeaderLine>
          <RequestHeaderItem strong grey={isOld}>
            {voucherCode || "-"}
          </RequestHeaderItem>
          <RequestHeaderItem strong primary={isRecenltyUsed}>
            {formatCurrency(amount)}
          </RequestHeaderItem>
        </RequestHeaderLine>
        <RequestHeaderLine>
          <RequestHeaderItem small>
            Demande du {format(usedAt)}
          </RequestHeaderItem>
        </RequestHeaderLine>
      </RequestHeader>

      {Boolean(reference) && (
        <RequestSection>
          <div>
            Nom du site{" : "}
            {reference}
          </div>
        </RequestSection>
      )}

      <RequestFooter>
        <Row center>
          <Col span={6} sm={12}>
            {Boolean(isExpired) ? (
              <div>Le bon est expiré.</div>
            ) : (
              <DownloadInterventionVoucherPdfTextButton {...voucherProps} />
            )}
          </Col>
        </Row>
      </RequestFooter>
    </Request>
  );
})``;
