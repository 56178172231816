import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

export function useContact(loggedIn) {
  const [mutation, result] = useMutation(
    loggedIn
      ? gql`
          mutation SEND_CONTACT(
            $subject: ContactSubjectEnum!
            $message: String!
          ) {
            base: private {
              ns: contacts {
                success: sendContact(subject: $subject, message: $message)
              }
            }
          }
        `
      : gql`
          mutation SEND_CONTACT(
            $email: String!
            $subject: ContactSubjectEnum!
            $message: String!
          ) {
            base: public {
              ns: contacts {
                success: sendContact(
                  email: $email
                  subject: $subject
                  message: $message
                )
              }
            }
          }
        `
  );
  return [
    ({ title, message, email }) =>
      mutation({ variables: { subject: title, message, email } }),
    ...toStandardResult(result, "base.ns.success"),
  ];
}
