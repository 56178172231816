import _get from "lodash/get";

export function toStandardResult(
  result = {},
  path = "",
  { transformData } = {}
) {
  const data = _get(result, `data${path ? "." + path : path}`);
  return [
    transformData ? transformData(data) : data,
    {
      ready: result.called && !result.loading,
      graphQLErrors: result?.error?.graphQLErrors,
      ...result,
    },
  ];
}

export function paginatedGql(nodeProperties) {
  return `totalCount pageInfo { endCursor hasPreviousPage hasNextPage } edges { cursor node { ${nodeProperties} } }`;
}

export function tilesFragment() {
  return `id contexts kind data`;
}
