import { getImageProps } from "../../../../../app/util/medias.js";

export default function ReactImageTile({ tile, options, className }) {
  return (
    <div
      className={`${className ||
        ""} tile-container tile-image tile--typography`}
    >
      <figure>
        {!!tile.image && !!tile.link ? (
          <a
            href={tile.link}
            target={tile.linkTarget}
            rel={tile.linkTarget === "_blank" ? "nofollow noreferrer" : ""}
            title={tile.linkTitle}
          >
            <img
              loading="lazy"
              alt=""
              {...getImageProps(tile.image, "content", true)}
            />
          </a>
        ) : (
          <img
            loading="lazy"
            alt=""
            {...getImageProps(tile.image, "content", true)}
          />
        )}
        {!!tile.caption && <figcaption>{tile.caption}</figcaption>}
        {!!(tile.image && tile.image.credits) && (
          <cite className="credits">{tile.image.credits}</cite>
        )}
      </figure>
    </div>
  );
}
