import { color, context, font, styled, theme } from "../util/style.js";
import Icon from "./Icon.jsx";

export default styled(function({ bonus = 2, icon, className, children }) {
  return (
    <div className={className}>
      <div className="Bonus-colVisuel">
        <div className="Bonus-iconBorder">
          <Icon className="Bonus-icon" name={icon} size={64} variant="black" />
        </div>
        <div className="Bonus-title">
          BONUS <span className="Bonus-x2">x{bonus}</span>
        </div>
      </div>
      <p className="Bonus-content">{children}</p>
    </div>
  );
})`
  align-items: center;
  background-color: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  .Bonus {
    &-colVisuel,
    &-content {
      flex: 0 0 50%;
      padding: 32px 48px;
    }
    &-colVisuel {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 50%;
      justify-content: center;
    }
    &-iconBorder {
      align-self: center;
      border: 2px solid ${color("border-black")};
      border-radius: 50px;
      display: inline-block;
      margin: 16px;
      padding: 12px;
    }
    &-icon {
      display: block;
    }
    &-title {
      ${font("headline1")}
      color: ${color("primary")};
      font-size: 4.8rem;
      line-height: 0.5;
      margin: 16px;
      text-align: center;
    }
    &-x2 {
      ${font("headline1")}
      color: ${color("primary")};
      font-size: 6.4rem;
      display: block;
    }
    &-content {
      color: ${color("primary")};
      font-weight: ${theme("font-weight-bold")};
      flex: 1;
      margin: 0;
    }
  }

  ${context("for-desktop")`
    .Bonus {
      &-colVisuel,
      &-content {
        padding: 24px;
      }
    }
  `}

  ${context("for-tablet-portrait")`
    padding: 16px;
    .Bonus {
      &-colVisuel {
        flex: 0 0 100%;
      }
      &-content {
        flex: 0 0 100%;
        text-align: center;
      }
    }
  `}
`;
