import Popup from "reactjs-popup";
import { styled, color, font, theme, z } from "../util/style.js";
import Icon from "./Icon.jsx";

export default styled(function({ title, actions, children, ...props }) {
  return (
    <Popup modal closeOnDocumentClick lockScroll {...props}>
      {(close) => (
        <>
          <header>
            {title || ""}
            <button onClick={close}>
              <Icon
                size={16}
                name="close"
                variant="white"
                hoverVariant="grey"
              />
            </button>
          </header>
          <main>{children}</main>
          {!!actions && <footer>{actions}</footer>}
        </>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlay")};
    z-index: ${z("popin")};
  }

  &-content {
    ${theme("content-box")}
    ${font("paragraph")}
    position: relative;
    max-width: 400px;
    overflow: hidden;

    header,
    footer {
      padding: 10px 16px;
    }
    main {
      padding: 16px;
    }

    header {
      background: ${color("primary")};
      color: white;
      button {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 40px;
        height: 40px;
        ${Icon} {
          display: block;
          margin: auto;
        }
      }
    }

    footer {
      display: flex;
      justify-content: flex-end;
      grid-gap: 12px;
    }
  }
`;
