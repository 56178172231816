import { Helmet } from "react-helmet";

import ScrollToTop from "../components/ScrollToTop.jsx";
import SideWidgets from "../components/side-widgets/SideWidgets.jsx";
import { paths } from "../services/router.js";
import { color, ifelse, styled, z } from "../util/style.js";
import Footer from "./common/Footer.jsx";
import Header from "./common/Header.jsx";

const FullHeight = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  ${ifelse("white")`
    background-color: white;
  ``
    background-color: ${color("bg-page")}
  `}
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  overflow: hidden;
`;

export default function LoggedInTemplate({
  title,
  white,
  mini,
  children,
  ...props
}) {
  return (
    <>
      <ScrollToTop excludedPaths={[paths.faq, paths.posts]} />
      {Boolean(title) && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <FullHeight white={white}>
        <SideWidgets />
        <Header />
        <Main id="main" {...props}>
          {children}
        </Main>
        <Footer mini={mini} />
      </FullHeight>
    </>
  );
}
