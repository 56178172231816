import { NavLink } from "react-router-dom";
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import _omit from "lodash/omit";
import { transformLink } from "../../common/links.js";

export default function Link({
  href,
  to,
  exact,
  alt,
  children,
  activeClassName,
  className,
  external,
  onClick,
  disabled,
  ...props
}) {
  const commonProps = {
    alt,
    "aria-label": alt,
    className,
    onClick,
    children,
    ..._omit(props, ["gold", "emphased", "ghost"]),
  };
  const navProps = { exact, activeClassName };

  let url = href || to;

  if (disabled) {
    return <span {...commonProps} />;
  }

  url = transformLink(url);

  if (!url) return <a {...commonProps} />;

  if (typeof url !== "string")
    return <NavLink to={url} {...navProps} {...commonProps} />;

  if (external || url.indexOf("://") !== -1)
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        {...commonProps}
      />
    );

  if (url.indexOf("/export/") === 0)
    return (
      <a
        href={(process.env.WEBSITE_URI_MANAGERS || "").replace(/\/$/, "") + url}
        download
        {...commonProps}
      />
    );

  if (url.indexOf("/files/") === 0 || url.indexOf("/media/") === 0)
    return (
      <a
        href={(process.env.CDN_URI || "").replace(/\/$/, "") + url}
        download
        {...commonProps}
      />
    );

  return <NavLink to={url} {...navProps} {...commonProps} />;
}
