import _pick from "lodash/pick";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";

import { format } from "../../common/date.js";
import {
  Controller,
  Form,
  FormCheckbox,
  FormField,
  FormInput,
  FormInputAppend,
  FormLabel,
  FormRadio,
  FormSelect,
  FormTextarea,
  FormUpload,
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../components/forms.jsx";
import InterventionBudgetHeader from "../components/InterventionBudgetHeader.jsx";
import InterventionsRequestsStatHeader from "../components/InterventionsRequestsStatHeader.jsx";
import { Col, Row } from "../components/layout.jsx";
import Steps from "../components/Steps.jsx";
import { Button, TextButton } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import {
  useInterventionRequest,
  useInterventionsStats,
} from "../services/interventions.js";
import { useInterventionVouchers } from "../services/interventionVouchers.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, font, styled, theme } from "../util/style.js";

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
  hr {
    margin: 32px 0;
  }
`;

const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const PanelTitle = styled.div`
  ${font("headline5")}
  text-align: center;
`;

const PanelSubtitle = styled.div`
  ${font("paragraph")}
  text-align: center;
`;

const Title = styled.div`
  ${font("headline5")}
  text-align: center;
  margin-bottom: 42px;
`;

const Buttons = styled.div`
  padding: 0 ${theme("padding-container")};
  display: flex;
  justify-content: space-between;
`;

const GridProducts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px auto;
  grid-column-gap: 16px;
`;

function useInterventionForm(isEliteProPlus, maxAmount) {
  return useForm({
    mode: "onBlur",
    defaultValues: {
      products: [
        {
          reference: undefined,
          serialNumber: undefined,
          quantity: 1,
        },
      ],
    },
    resolver: yupResolver(
      Yup.object().shape({
        kind: Yup.string()
          .nullable()
          .oneOf(
            [
              "diagnostic",
              "repair",
              "expertise",
              "commissioning",
              "maintenance",
            ],
            "Veuillez choisir une nature"
          )
          .required("Veuillez choisir une nature"),
        context: Yup.string()
          .nullable()
          .oneOf(
            ["cec", "ordered", "warranty", "quotation"],
            "Veuillez choisir un contexte"
          )
          .required("Veuillez choisir un contexte"),
        referenceOrder: Yup.string()
          .nullable()
          .when("context", {
            is: "ordered",
            then: (schema) =>
              schema.required("Veuillez indiquer le n° de commande"),
          }),
        referenceWarranty: Yup.string()
          .nullable()
          .when("context", {
            is: "warranty",
            then: (schema) =>
              schema.required("Veuillez indiquer le n° de ticket CEC"),
          }),
        referenceCec: Yup.string()
          .nullable()
          .when("context", {
            is: "cec",
            then: (schema) =>
              schema.required("Veuillez indiquer le n° de ticket CEC"),
          }),
        details: Yup.string()
          .nullable()
          .required("Veuillez décrire la demande"),
        products: Yup.array()
          .nullable()
          .of(
            Yup.object({
              reference: Yup.string()
                .nullable()
                .required("Référence obligatoire"),
              serialNumber: Yup.string()
                .nullable()
                .required("Numéro obligatoire"),
              quantity: Yup.number()
                .nullable()
                .transform((v, o) => (o === "" ? null : v))
                .min(0, "Obligatoire")
                .required("Obligatoire"),
            })
          )
          .min(1, "Veuillez ajouter au moins un produit"),
        locationKind: Yup.string()
          .nullable()
          .oneOf(
            ["residentiel", "tertiaire"],
            "Veuillez choisir un type de site"
          )
          .required("Veuillez choisir un type de site"),
        location: Yup.object({
          company: Yup.string()
            .nullable()
            .required("Veuillez indiquer un nom pour l'adresse"),
          street: Yup.string()
            .nullable()
            .required("Veuillez indiquer l'adresse"),
          postalCode: Yup.string().nullable().required("Obligatoire"),
          city: Yup.string().nullable().required("Veuillez indiquer la ville"),
        }),
        attachment: Yup.mixed()
          .nullable()
          .when("restrictedAccess", {
            is: true,
            then: (schema) =>
              schema.required("Veuillez sélectionner un fichier"),
          }),
        personInCharge: Yup.object({
          name: Yup.string().nullable().required("Veuillez renseigner le nom"),
          position: Yup.string()
            .nullable()
            .required("Veuillez renseigner ce champ"),
          phone: Yup.string()
            .nullable()
            .required("Veuillez renseigner le téléphone"),
          email: Yup.string()
            .nullable()
            .email("L'e-mail est invalide")
            .required("Veuillez renseigner l'email"),
        }),
        dateKind: Yup.string()
          .nullable()
          .oneOf(["date", "range", "none"], "Veuillez choisir une option")
          .required("Veuillez choisir une date"),
        date: Yup.date().transform((v) =>
          v instanceof Date && !isNaN(v) ? v : null
        ),
        time: Yup.string(),
        datetime: Yup.mixed()
          .nullable()
          .when(["dateKind", "date", "time"], {
            is: (dateKind, date, time) =>
              dateKind === "date" && (!date?.getTime() || !time),
            then: (schema) => schema.required("Veuillez choisir une date"),
          }),
        range: Yup.string()
          .nullable()
          .when("dateKind", {
            is: "range",
            then: (schema) =>
              schema.required("Veuillez indiquer la plage de dates"),
          }),
        ...(isEliteProPlus
          ? {
              useBudget: Yup.string()
                .nullable()
                .oneOf(
                  ["amount", "voucher", "no"],
                  "Veuillez choisir une option"
                )
                .required("Veuillez choisir une option"),
              voucherId: Yup.string()
                .nullable()
                .transform((v, o) => (o === "" ? null : v))
                .when("useBudget", {
                  is: "voucher",
                  then: (schema) =>
                    schema.required(
                      "Veuillez sélectionner un bon d'achat intervention"
                    ),
                }),
              amount: Yup.number()
                .nullable()
                .max(
                  maxAmount,
                  `Le montant ne peut pas être supérieur à ${maxAmount} €`
                )
                .transform((v, o) => (o === "" ? null : v))
                .when("useBudget", {
                  is: "amount",
                  then: (schema) =>
                    schema.required("Veuillez choisir un budget à utiliser"),
                }),
            }
          : {}),
      })
    ),
  });
}

export default function Interventions() {
  const [stats] = useInterventionsStats();

  const { isEliteProPlus, company } = useSelfMetaData();

  const STEPS = isEliteProPlus ? 6 : 5;
  const [currentStep, setCurrentStep] = useState(1); // 1-6

  const budgetInterventions = company?.budgets?.interventions;

  const topScroll = useRef();
  const scrollToFormTop = useCallback(() => {
    if (!topScroll) return;
    topScroll.current.scrollIntoView();
  }, [topScroll]);

  const interventionAction = useInterventionRequest();

  const [success, setSuccess] = useState(false);

  const form = useInterventionForm(
    isEliteProPlus,
    budgetInterventions?.totalBalance?.amount || 0
  );
  const { reset, trigger } = form;

  const [onRequest, { loading: loadingIntervention }] = useFormAction(
    form,
    interventionAction,
    {
      transformData: (data) => ({
        ..._pick(data, [
          "kind",
          "context",
          "locationKind",
          "location",
          "locationDetails",
          "restrictedAccess",
          "products",
          "details",
        ]),
        internalReference:
          data.context === "warranty"
            ? data.referenceWarranty
            : data.context === "cec"
            ? data.referenceCec
            : data.context === "ordered"
            ? data.referenceOrder
            : undefined,
        attachments: data.attachment ? [data.attachment] : undefined,
        personInChargeName: data.personInCharge?.name || undefined,
        personInChargePosition: data.personInCharge?.position || undefined,
        personInChargePhone: data.personInCharge?.phone || undefined,
        personInChargeEmail: data.personInCharge?.email || undefined,
        voucherId: data.useBudget === "voucher" ? data.voucherId : undefined,
        amount: data.useBudget === "amount" ? data.amount : undefined,
        date:
          data.dateKind === "date"
            ? "Le " + format(data.date) + " - " + data.time
            : data.dateKind === "range"
            ? data.range
            : "Au plus tôt",
      }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre demande n'a pas pu être envoyée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setSuccess(true);
        reset({});
        scrollToFormTop();
      },
    }
  );

  const next = useCallback(async () => {
    let valid = true;
    // validate current step before moving to next
    switch (currentStep) {
      case 1:
        valid = await trigger([
          "kind",
          "context",
          "referenceCec",
          "referenceOrder",
          "referenceWarranty",
          "details",
        ]);
        break;
      case 2:
        valid = await trigger(["products"]);
        break;
      case 3:
        valid = await trigger([
          "locationKind",
          "location",
          "restrictedAccess",
          "attachment",
          "locationDetails",
        ]);
        break;
      case 4:
        valid = await trigger(["personInCharge"]);
        break;
      case 5:
        valid = await trigger(["dateKind", "datetime", "range"]);
        break;
      case 6:
        valid = await trigger(["useBudget", "amount", "voucherId"]);
        break;
    }

    if (valid) {
      setCurrentStep((step) => step + 1);
      scrollToFormTop();
    }
  }, [currentStep, trigger, scrollToFormTop]);

  const previous = useCallback(() => {
    setCurrentStep((step) => step - 1);
    scrollToFormTop();
  }, [scrollToFormTop]);

  return (
    <LoggedInTemplate title="Demandes d'intervention">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Demande d'intervention"
      />
      <MainContent>
        <Headline title="Mes demandes" />
        <Container>
          <InterventionsRequestsStatHeader stats={stats} />
          {Boolean(isEliteProPlus) && (
            <InterventionBudgetHeader budget={budgetInterventions} />
          )}
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  {isEliteProPlus ? (
                    <p>
                      En tant que client ELITE PRO+, vous disposez d'un{" "}
                      <strong>budget de 1000 €/an</strong> pour des
                      interventions Daikin : diagnostic, dépannage, expertise,
                      mise en service ou encore maintenance.
                    </p>
                  ) : (
                    <p>
                      En tant que client ELITE PRO, vous pouvez demander une
                      intervention Daikin : diagnostic, dépannage, expertise,
                      mise en service ou encore maintenance.
                    </p>
                  )}
                  <center>
                    <Button to={paths.interventions} ghost>
                      Retour à l'historique
                    </Button>
                  </center>
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />

              <Title>
                Pour vos demandes d'intervention, remplissez le formulaire
              </Title>
              <Steps max={STEPS} current={currentStep} />

              <Form onSubmit={onRequest} loading={loadingIntervention}>
                <Panel>
                  {success ? (
                    <div>
                      <strong>La demande a été envoyée</strong> et sera traitée
                      par les équipes DAIKIN dans les plus brefs délais. Vous
                      pouvez annuler à tout moment votre demande dans votre
                      historique des demandes.
                    </div>
                  ) : (
                    <StepContent form={form} step={currentStep} />
                  )}
                </Panel>
                <Buttons>
                  {Boolean(!success) && (
                    <>
                      {currentStep === 1 ? (
                        <Button type="button" ghost to={paths.interventions}>
                          Annuler
                        </Button>
                      ) : (
                        <Button type="button" ghost onClick={previous}>
                          Précédent
                        </Button>
                      )}
                      {currentStep < STEPS ? (
                        <Button type="button" onClick={next}>
                          Suivant
                        </Button>
                      ) : (
                        <Button type="submit">J'envoie ma demande</Button>
                      )}
                    </>
                  )}
                </Buttons>
              </Form>
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}

function StepContent({ form, step }) {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = form;

  const [interventionVouchersResult] = useInterventionVouchers(
    { status: "nonValidated" },
    { limit: 99 }
  );
  const interventionVouchersOptions = useMemo(
    function () {
      return (interventionVouchersResult?.edges || []).map(
        ({ node: { id, voucherCode, reference, amount } }) => ({
          value: id,
          label: `${voucherCode} - ${reference} (${amount} €)`,
        })
      );
    },
    [interventionVouchersResult]
  );

  const {
    fields: products,
    append: appendProduct,
    remove: removeProduct,
  } = useFieldArray({
    control,
    name: "products",
    shouldUnregister: true,
  });

  const [context, dateKind, useBudget, restrictedAccess] = watch([
    "context",
    "dateKind",
    "useBudget",
    "restrictedAccess",
  ]);

  if (step === 1)
    return (
      <>
        <PanelTitle>La prestation</PanelTitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />
        <FormField
          label="Nature de la prestation"
          layout="row"
          name="kind"
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <>
              <FormRadio {...register(name)} value="diagnostic" {...childProps}>
                Diagnostic
              </FormRadio>
              <FormRadio {...register(name)} value="repair" {...childProps}>
                Dépannage
              </FormRadio>
              <FormRadio {...register(name)} value="expertise" {...childProps}>
                Expertise
              </FormRadio>
              <FormRadio
                {...register(name)}
                value="commissioning"
                {...childProps}
              >
                Mise en service
              </FormRadio>
              <FormRadio
                {...register(name)}
                value="maintenance"
                {...childProps}
              >
                Maintenance
              </FormRadio>
            </>
          )}
        </FormField>
        <FormField
          label="Contexte"
          name="context"
          errors={errors}
          layout="column"
        >
          {({ name, ...childProps }) => (
            <>
              <FormRadio {...register(name)} value="ordered" {...childProps}>
                <span>J'ai déjà commandé l'intervention</span>
                {Boolean(context === "ordered") && (
                  <FormField name="referenceOrder" errors={errors}>
                    {({ name, ...childProps }) => (
                      <FormInput
                        placeholder="N° de commande Daikin"
                        {...register(name)}
                        {...childProps}
                        style={{ maxWidth: 300 }}
                      />
                    )}
                  </FormField>
                )}
              </FormRadio>
              <FormRadio {...register(name)} value="warranty" {...childProps}>
                <span>Ma demande concerne un produit sous garantie</span>
                {Boolean(context === "warranty") && (
                  <FormField name="referenceWarranty" errors={errors}>
                    {({ name, ...childProps }) => (
                      <FormInput
                        placeholder="N° de ticket CEC de l'intervention"
                        {...register(name)}
                        {...childProps}
                        style={{ maxWidth: 300 }}
                      />
                    )}
                  </FormField>
                )}
              </FormRadio>
              <FormRadio {...register(name)} value="cec" {...childProps}>
                <span>J'ai un ticket CEC</span>
                {Boolean(context === "cec") && (
                  <FormField name="referenceCec" errors={errors}>
                    {({ name, ...childProps }) => (
                      <FormInput
                        placeholder="N° de ticket CEC"
                        {...register(name)}
                        {...childProps}
                        style={{ maxWidth: 300 }}
                      />
                    )}
                  </FormField>
                )}
              </FormRadio>
              <FormRadio {...register(name)} value="quotation" {...childProps}>
                Je fais une demande de devis
              </FormRadio>
            </>
          )}
        </FormField>
        <FormField
          label="Détaillez le motif de votre demande d'intervention"
          name="details"
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <FormTextarea
              {...register(name)}
              {...childProps}
              placeholder="Ex : description de la panne, etc."
            />
          )}
        </FormField>
      </>
    );

  if (step === 2)
    return (
      <>
        <PanelTitle>Le matériel</PanelTitle>
        <PanelSubtitle>
          Spécifiez les informations sur le matériel lié à l'intervention.
        </PanelSubtitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />
        <FormField name="products" errors={errors}>
          <GridProducts>
            <div>
              <FormLabel>Référence</FormLabel>
            </div>
            <div>
              <FormLabel>Numéro de série</FormLabel>
            </div>
            <div>
              <FormLabel>Quantité</FormLabel>
            </div>
            <div />
            {products.map((field, index) => (
              <Fragment key={field.id}>
                <div>
                  <FormField
                    name={`products.${index}.reference`}
                    errors={errors}
                  >
                    {({ name, ...childProps }) => (
                      <FormInput
                        {...register(name)}
                        {...childProps}
                        defaultValue={field.reference}
                      />
                    )}
                  </FormField>
                </div>
                <div>
                  <FormField
                    name={`products.${index}.serialNumber`}
                    errors={errors}
                  >
                    {({ name, ...childProps }) => (
                      <FormInput
                        {...register(name)}
                        {...childProps}
                        defaultValue={field.serialNumber}
                      />
                    )}
                  </FormField>
                </div>
                <div>
                  <FormField
                    name={`products.${index}.quantity`}
                    errors={errors}
                  >
                    {({ name, ...childProps }) => (
                      <FormInput
                        type="number"
                        min={0}
                        {...register(name)}
                        {...childProps}
                        defaultValue={field.quantity}
                      />
                    )}
                  </FormField>
                </div>
                <div>
                  <TextButton
                    type="button"
                    icon="trash"
                    onClick={() => removeProduct(index)}
                  />
                </div>
              </Fragment>
            ))}
          </GridProducts>
        </FormField>
        <TextButton
          type="button"
          icon="plus-line"
          onClick={() =>
            appendProduct({
              reference: undefined,
              serialNumber: undefined,
              quantity: 1,
            })
          }
        >
          Ajouter une ligne de matériel
        </TextButton>
      </>
    );

  if (step === 3)
    return (
      <>
        <PanelTitle>Le site d'intervention</PanelTitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />

        <FormField
          label="Type de site"
          layout="row"
          name="locationKind"
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <>
              <FormRadio
                {...register(name)}
                value="residentiel"
                {...childProps}
              >
                Résidentiel
              </FormRadio>
              <FormRadio {...register(name)} value="tertiaire" {...childProps}>
                Tertiaire
              </FormRadio>
            </>
          )}
        </FormField>

        <FormField label="Nom du site" name="location.company" errors={errors}>
          {({ name, ...childProps }) => (
            <FormInput {...register(name)} {...childProps} />
          )}
        </FormField>

        <FormField
          label="Adresse postale"
          name="location.street"
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <FormInput {...register(name)} {...childProps} />
          )}
        </FormField>

        <Row>
          <Col span={4}>
            <FormField
              label="Code postal"
              name="location.postalCode"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
          <Col span={8}>
            <FormField label="Ville" name="location.city" errors={errors}>
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
        </Row>

        <FormField name="restrictedAccess" errors={errors}>
          {({ name, ...childProps }) => (
            <FormCheckbox {...register(name)} {...childProps}>
              <span>
                Une autorisation spécifique est requise pour l'accès au site.
              </span>
              {Boolean(restrictedAccess) && (
                <FormField name="attachment" errors={errors}>
                  {({ name, ...childProps }) => (
                    <Controller
                      control={control}
                      name={name}
                      render={({ field }) => (
                        <FormUpload
                          {...field}
                          {...childProps}
                          placeholder="Sélectionnez le fichier à envoyer"
                        />
                      )}
                    />
                  )}
                </FormField>
              )}
            </FormCheckbox>
          )}
        </FormField>

        <FormField
          label="Remarques utiles à l'intervention"
          name="locationDetails"
          errors={errors}
        >
          {({ name, ...childProps }) => (
            <FormTextarea
              {...register(name)}
              {...childProps}
              placeholder="Ex : digicode, personnes à prévenir, conditions spéciales d'accès..."
            />
          )}
        </FormField>
      </>
    );

  if (step === 4)
    return (
      <>
        <PanelTitle>Le responsable sur site</PanelTitle>
        <PanelSubtitle>
          Renseignez les coordonnées d'une personne joignable pour
          l'intervention.
        </PanelSubtitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />

        <Row>
          <Col span={6}>
            <FormField
              name="personInCharge.name"
              label="Nom du responsable"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
          <Col span={6}>
            <FormField
              name="personInCharge.position"
              label="Fonction / Poste occupé"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <FormField
              name="personInCharge.email"
              label="Adresse e-mail"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput type="email" {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
          <Col span={6}>
            <FormField
              name="personInCharge.phone"
              label="Téléphone portable"
              errors={errors}
            >
              {({ name, ...childProps }) => (
                <FormInput {...register(name)} {...childProps} />
              )}
            </FormField>
          </Col>
        </Row>
      </>
    );

  if (step === 5)
    return (
      <>
        <PanelTitle>La date d'intervention</PanelTitle>
        <PanelSubtitle>Indiquez vos préférences.</PanelSubtitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />

        <FormField
          label="Date souhaitée"
          name="dateKind"
          errors={errors}
          layout="column"
        >
          {({ name, ...childProps }) => (
            <>
              <FormRadio {...register(name)} value="date" {...childProps}>
                <span>J'ai une date précise</span>
                {Boolean(dateKind === "date") && (
                  <FormField layout="row" name="datetime" errors={errors}>
                    {({ name, ...childProps }) => (
                      <>
                        <span>
                          <FormInput
                            type="date"
                            {...register("date")}
                            {...childProps}
                            style={{ maxWidth: 200 }}
                            placeholder="Date"
                          />
                        </span>
                        <span>
                          <FormInput
                            {...register("time")}
                            {...childProps}
                            style={{ maxWidth: 100 }}
                            placeholder="Ex : 9h"
                          />
                        </span>
                      </>
                    )}
                  </FormField>
                )}
              </FormRadio>
              <FormRadio {...register(name)} value="range" {...childProps}>
                <span>Je renseigne une plage de dates</span>
                {Boolean(dateKind === "range") && (
                  <FormField name="range" errors={errors}>
                    {({ name, ...childProps }) => (
                      <FormInput
                        {...register(name)}
                        {...childProps}
                        style={{ maxWidth: 300 }}
                        placeholder="Plage de dates"
                      />
                    )}
                  </FormField>
                )}
              </FormRadio>
              <FormRadio {...register(name)} value="none" {...childProps}>
                Proposez-moi une date au plus tôt
              </FormRadio>
            </>
          )}
        </FormField>
      </>
    );

  if (step === 6)
    return (
      <>
        <PanelTitle>Votre budget ELITE PRO+</PanelTitle>
        <PanelSubtitle>
          Bénéficiez d'une remise sur votre intervention en utilisant votre
          budget.
        </PanelSubtitle>
        <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
        <hr />

        <FormField name="useBudget" errors={errors} layout="column">
          {({ name, ...childProps }) => (
            <>
              <FormRadio {...register(name)} value="no" {...childProps}>
                Je ne souhaite pas utiliser mon budget
              </FormRadio>
              <FormRadio {...register(name)} value="amount" {...childProps}>
                <span>Je souhaite utiliser mon budget</span>
              </FormRadio>
              {useBudget === "amount" && (
                <FormField name="amount" errors={errors}>
                  {({ name, ...childProps }) => (
                    <FormFieldRadioContent>
                      <span>... pour un montant de{" : "}</span>
                      <span>
                        <FormInput
                          type="number"
                          {...register(name)}
                          {...childProps}
                          style={{ width: "auto", maxWidth: 200 }}
                        />
                      </span>
                      <FormInputAppend>€</FormInputAppend>
                    </FormFieldRadioContent>
                  )}
                </FormField>
              )}
              <FormRadio {...register(name)} value="voucher" {...childProps}>
                <span>Je souhaite utiliser un bon d'achat intervention</span>
              </FormRadio>
              {useBudget === "voucher" && (
                <FormField name="voucherId" errors={errors}>
                  {({ name, ...childProps }) => (
                    <Controller
                      name={name}
                      control={control}
                      render={({ field }) => (
                        <FormFieldRadioContent>
                          <FormSelect
                            {...field}
                            {...childProps}
                            placeholder="Bon d'achat intervention"
                            options={interventionVouchersOptions}
                          />
                        </FormFieldRadioContent>
                      )}
                    />
                  )}
                </FormField>
              )}
            </>
          )}
        </FormField>
      </>
    );
}

const FormFieldRadioContent = styled.div`
  padding-left: 32px;
`;
