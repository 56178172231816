import { format } from "../../common/date.js";
import { color, styled, theme, z } from "../util/style.js";
import Popup from "./Popup.jsx";
import { Button } from "./typography.jsx";

export default styled(function ({ status, session, onClose, ...props }) {
  if (status !== "subscription-expired") return null;
  return (
    <Popup
      {...props}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
      lockScroll
    >
      {() => (
        <>
          <header>Avant d'entrer dans le programme DAIKIN ELITE</header>
          <main>
            Votre accès à DAIKIN ELITE a expiré
            {session.subscriptionExpirationDate
              ? ` depuis le ${format(session.subscriptionExpirationDate)}`
              : ""}{" "}
            et le contrat pour la nouvelle année ne nous est pas encore parvenu.
            Si vous avez récemment envoyé votre contrat DAIKIN ELITE complété et
            signé, veuillez patienter quelques jours, vous recevrez
            prochainement un e-mailing de bienvenue avec vos accès. Si vos accès
            ne sont pas opérationnels, veuillez contacter votre commercial
            Daikin.
          </main>
          <footer>
            <Button onClick={onClose}>Accéder au site</Button>
          </footer>
        </>
      )}
    </Popup>
  );
})`
  &-overlay {
    background-color: ${color("overlay")};
    z-index: ${z("popin")};
  }

  &-content {
    ${theme("content-box")}
    ${theme("big-border")}
    max-width: 600px;
    padding: 24px 48px;
    width: 90%;
    text-align: center;

    header {
      border-bottom: 2px solid ${color("primary")};
      font-weight: ${theme("font-weight-bold")};
      display: inline-block;
      line-height: 1.4;
      padding-bottom: 8px;
      text-align: center;
      margin-bottom: 24px;
    }

    footer {
      margin-top: 24px;
    }
  }
`;
