import _sortBy from "lodash/sortBy";

const HOTLINE_CODES =
  process.env.HOTLINE_CODES || "0000,2022-01-01T00:00:00.000+01:00";

const codesWithDate = [];

const codes = HOTLINE_CODES.split(",");
let maximumFromDate = new Date("2000-01-01T00:00:00.000");
for (let c = 0; c < codes.length; c += 2) {
  const code = codes[c];
  let fromDate = maximumFromDate;
  if (c + 1 < codes.length) {
    fromDate = new Date(codes[c + 1]);
  }
  codesWithDate.push({ code, fromDate });
}
const codesWithDateSorted = _sortBy(
  codesWithDate,
  ({ fromDate }) => +fromDate
).reverse();

export function getCurrentCode() {
  const now = new Date();
  return codesWithDateSorted.find(({ fromDate }) => fromDate < now).code;
}

export function getNextCodeAndDate() {
  const now = new Date();
  return [...codesWithDateSorted]
    .reverse()
    .find(({ fromDate }) => fromDate > now);
}
