import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import _toPairs from "lodash/toPairs";
import { useCallback, useMemo, useState } from "react";

import { paginatedGql, toStandardResult } from "../util/graphql.js";
import { challengeSchema } from "./challenges.js";

const quizSchema = `
  ${challengeSchema}
  introduction
  image {
    id
    name
    version
  }
  url
  document {
    id
    name
    version
  }
`;

export function useQuizzes(pagination) {
  const result = useQuery(
    gql`
      query GET_QUIZZES($pagination: PaginationInput) {
        private {
          challenges {
            quizzes: findQuizzes(
              pagination: $pagination
            ) {
              ${paginatedGql(quizSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.challenges.quizzes");
}

export function useQuiz(quizAlias) {
  const result = useQuery(
    gql`
      query GET_QUIZ($alias: ID!) {
        private {
          challenges {
            quiz: fetchQuizByAlias(alias: $alias) {
              ${quizSchema}
              conclusionSuccess
              conclusionFailure
              questions {
                id
                text
                image {
                  id
                  name
                  version
                }
                answers {
                  id
                  text
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        alias: quizAlias,
      },
    }
  );

  return toStandardResult(result, "private.challenges.quiz");
}

export function useQuizSubmit(quiz) {
  const [mutation, result] = useMutation(
    gql`
      mutation ($id: ID!, $answersByQuestion: [QuizPrivateInput]!) {
        private {
          challenges {
            submitQuizAnswers(id: $id, answers: $answersByQuestion) {
              success
              mistakes
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  return [
    (answers) =>
      mutation({
        variables: {
          id: quiz.id,
          answersByQuestion: _map(
            _toPairs(_groupBy(answers, "question")),
            ([question, answers]) => ({
              question,
              answers: _map(answers, "answer"),
            })
          ),
        },
      }),
    ...toStandardResult(result, "private.challenges.submitQuizAnswers"),
  ];
}

export function useQuizNavigation(
  quiz,
  {
    question: initialQuestion = null,
    number: initialNumber = 0,
    showStart: initialShowStart = true,
    showEnd: initialShowEnd = false,
  } = {}
) {
  const [
    { question: currentQuestion, number, showStart, showEnd },
    setQuizState,
  ] = useState({
    question: initialQuestion,
    number: initialNumber,
    showStart: initialShowStart,
    showEnd: initialShowEnd,
  });
  const [answers, setAnswers] = useState([]);

  const questions = useMemo(() => quiz?.questions || [], [quiz]);

  const next = useCallback(
    function () {
      setQuizState((state) => {
        const nextState = { showStart: false };
        const nextNumber = number + 1;

        if (nextNumber <= questions.length) {
          nextState.number = nextNumber;
          nextState.question = questions[nextNumber - 1];
        } else {
          nextState.number = 0;
          nextState.question = null;
          nextState.showEnd = true;
        }

        return {
          ...state,
          ...nextState,
        };
      });
    },
    [questions, number]
  );

  const previous = useCallback(
    function () {
      setQuizState((state) => {
        const nextState = { showEnd: false };
        const nextNumber = number - 1;

        if (number - 1 > 0) {
          nextState.number = nextNumber;
          nextState.question = questions[nextNumber - 1];
        } else {
          nextState.number = 0;
          nextState.question = null;
          nextState.showStart = true;
        }

        return {
          ...state,
          ...nextState,
        };
      });
    },
    [questions, number]
  );

  function restart() {
    setQuizState({
      question: initialQuestion,
      number: initialNumber,
      showStart: initialShowStart,
      showEnd: initialShowEnd,
    });
    setAnswers([]);
  }

  const hasAnswer = useCallback(
    function (answer, question = currentQuestion.id) {
      return answers.some(
        ({ answer: existingAnswer, question: existingQuestion }) =>
          existingAnswer === answer && existingQuestion === question
      );
    },
    [answers, currentQuestion]
  );

  const atLeastOneAnswer = useCallback(
    function (question = currentQuestion.id) {
      return answers.some(
        ({ question: existingQuestion }) => existingQuestion === question
      );
    },
    [answers, currentQuestion]
  );

  function addAnswer(answer, question = currentQuestion.id) {
    setAnswers((answers) => [...answers, { answer, question }]);
  }

  function removeAnswer(answer, question = currentQuestion.id) {
    setAnswers((answers) => [
      ...answers.filter(
        ({ answer: existingAnswer, question: existingQuestion }) =>
          existingAnswer !== answer || existingQuestion !== question
      ),
    ]);
  }

  return {
    number,
    question: currentQuestion,
    isLastQuestion: quiz && questions && number === questions.length,
    showStart,
    showEnd,

    next,
    previous,
    restart,

    answers,
    atLeastOneAnswer,
    hasAnswer,
    addAnswer,
    removeAnswer,
  };
}
