import { useState } from "react";
import sanitizeHtml from "sanitize-html";

import { formatCurrency } from "../../common/currency.js";
import { format } from "../../common/date.js";
import {
  useArchiveWorkforceParticipationRequest,
  useCancelWorkforceParticipationRequest,
} from "../services/participations.js";
import { paths } from "../services/router.js";
import { getUrl } from "../util/medias.js";
import { context, styled } from "../util/style.js";
import ConfirmationPopup from "./ConfirmationPopup.jsx";
import { useForm, useFormAction } from "./forms.jsx";
import { Col, Row } from "./layout.jsx";
import {
  Request,
  RequestFooter,
  RequestHeader,
  RequestHeaderItem,
  RequestHeaderLine,
  RequestSection,
} from "./requests.jsx";
import { Button, TextButton } from "./typography.jsx";

const status = {
  pending: "En cours",
  accepted: "Validée",
  incomplete: "À compléter",
  declined: "Refusée",
  waitingForInvoice: "Facture en attente",
  invoiceChecking: "Vérification de la facture",
  cancelled: "Annulée",
  expired: "Expiré",
};

function html(text, options = {}) {
  return sanitizeHtml((text || "").replace(/\n/g, "<br />"), {
    allowedTags: ["br", "b", "i", "em", "strong", "a", "span", "div"],
    allowedAttributes: {
      a: ["href", "target", "style"],
      div: ["style"],
      span: ["style"],
    },
    ...options,
  });
}

export default styled(function ParticipationRequestItem({
  request,
  onUpdate,
  className,
}) {
  const [openCancel, setOpenCancel] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const form = useForm();

  const cancelAction = useCancelWorkforceParticipationRequest(request.id);
  const [onCancel, { loading: loadingCancel }] = useFormAction(
    form,
    cancelAction,
    {
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "La demande n'a pas pu être annulée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setOpenCancel(false);
        onUpdate();
      },
    }
  );

  const archiveAction = useArchiveWorkforceParticipationRequest(request.id);
  const [onArchive, { loading: loadingArchive }] = useFormAction(
    form,
    archiveAction,
    {
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "La demande n'a pas pu être archivée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setOpenArchive(false);
        onUpdate();
      },
    }
  );

  // Différence entre la dernière modification et le jour J, millisecondes transformées en jours
  const lastUpdate =
    (new Date() - new Date(request.statusUpdatedAt)) / 1000 / 60 / 60 / 24;

  return (
    <Request
      className={className}
      highlight={lastUpdate >= 53 && lastUpdate < 60}
    >
      <ConfirmationPopup
        title="Annulation de demande"
        open={Boolean(openCancel)}
        onClose={() => setOpenCancel(false)}
        actions={
          <>
            <Button mini onClick={() => setOpenCancel(false)} ghost>
              Annuler
            </Button>
            <Button mini onClick={onCancel}>
              Confirmer
            </Button>
          </>
        }
      >
        Voulez-vous réellement annuler cette demande ? Cette action est
        irréversible.
      </ConfirmationPopup>
      <ConfirmationPopup
        title="Demande archivée"
        open={Boolean(openArchive)}
        onClose={() => setOpenArchive(false)}
        actions={
          <>
            <Button mini onClick={() => setOpenArchive(false)} ghost>
              Annuler
            </Button>
            <Button mini onClick={onArchive}>
              Confirmer
            </Button>
          </>
        }
      >
        Voulez-vous réellement archiver cette demande ? Cette action est
        irréversible.
      </ConfirmationPopup>
      <RequestHeader>
        <RequestHeaderLine>
          <RequestHeaderItem strong grey={lastUpdate >= 60}>
            {request.reference ? request.reference + " - " : ""}
            {request.piece?.kind === "refrigerating"
              ? "Frigorifique"
              : "Non frigorifique"}
          </RequestHeaderItem>
          <RequestHeaderItem
            strong
            warning={
              (request.status === "waitingForInvoice" ||
                request.status === "invoiceChecking") &&
              lastUpdate < 60
            }
            primary={
              (request.status === "pending" ||
                request.status === "incomplete") &&
              lastUpdate < 60
            }
            success={request.status === "accepted" && lastUpdate < 60}
          >
            {status[request.status]}
          </RequestHeaderItem>
        </RequestHeaderLine>
        <RequestHeaderLine>
          <RequestHeaderItem small>
            {request.piece?.reference} - Demande du {format(request.createdAt)}
          </RequestHeaderItem>
        </RequestHeaderLine>
      </RequestHeader>

      <RequestSection>
        <div>
          Produit concerné{" : "}
          {request.product?.reference} - {request.product?.serial}
        </div>
        {request.invoice?.reference && (
          <div>
            Référence à rappeler{" : "}
            {request.invoice?.reference}
          </div>
        )}
        {(request.status === "waitingForInvoice" ||
          request.status === "accepted" ||
          request.status === "invoiceChecking") && (
          <div>
            Montant{" : "}
            <strong>{formatCurrency(request.amount, false)}</strong>
          </div>
        )}
      </RequestSection>

      {Boolean(request.details) && (
        <RequestSection>
          <div>
            <strong>Commentaire Daikin</strong>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: html(request.details) }}
          ></div>
        </RequestSection>
      )}

      <RequestFooter>
        <Row center>
          <Col span={6} sm={12}>
            {Boolean(request.status === "invoiceChecking") && (
              <div>La facture est en cours de validation.</div>
            )}
            {Boolean(
              (request.status === "pending" ||
                request.status === "incomplete" ||
                request.status === "waitingForInvoice") &&
                lastUpdate < 60
            ) && (
              <TextButton
                onClick={() => setOpenCancel(true)}
                icon="close-line"
                disabled={loadingCancel}
                mini
              >
                Annuler ma demande
              </TextButton>
            )}
            {Boolean(
              request.status === "accepted" ||
                request.status === "declined" ||
                request.status === "cancelled" ||
                lastUpdate >= 60
            ) && (
              <TextButton
                onClick={() => setOpenArchive(true)}
                icon="archive"
                disabled={loadingArchive}
                mini
              >
                Archiver ma demande
              </TextButton>
            )}
          </Col>
          <Col span={6} sm={12}>
            {request.status === "incomplete" && (
              <Button
                to={paths.participationEdit.replace(":id", request.id)}
                ghost
                mini
              >
                Compléter la demande
              </Button>
            )}
            {Boolean(
              request.status === "waitingForInvoice" && lastUpdate < 60
            ) ? (
              <Button
                to={paths.participationInvoice + "?r=" + request.id}
                ghost
                mini
              >
                Envoyer la facture
              </Button>
            ) : (
              Boolean(request.invoice?.file) && (
                <TextButton
                  to={getUrl("invoice", request.invoice?.file, "download")}
                  icon="download"
                  external
                  mini
                >
                  Télécharger la facture
                </TextButton>
              )
            )}
          </Col>
        </Row>
      </RequestFooter>
    </Request>
  );
})``;
