export const roles = [
  {
    id: "manager",
    label: "Gérant(e)",
  },
  {
    id: "stores",
    label: "Responsable boutiques",
  },
  {
    id: "training",
    label: "Responsable formations",
  },
  {
    id: "technical",
    label: "Responsable technique",
  },
];

export const rolesPrivate = [
  {
    id: "manager",
    label: "Gérant(e)",
  },
  {
    id: "stores",
    label: "Accès boutiques (cadeaux et accessoires)",
  },
  {
    id: "training",
    label: "Accès formations",
  },
  {
    id: "technical",
    label: "Accès service technique",
  },
];
