import { useCallback, useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import { Prompt } from "react-router-dom";

import ContentLoading from "./components/ContentLoading.jsx";
import GdprDataPopup from "./components/GdprDataPopup.jsx";
import UserAcceptancePopup from "./components/UserAcceptancePopup.jsx";
import WarningPopup from "./components/WarningPopup.jsx";
import { Routes } from "./router.jsx";
import { useUserSession } from "./services/auth.js";
import { usePermissions } from "./services/permissions.js";
import { paths } from "./services/router.js";
import Root from "./templates/Root.jsx";

// const GDPR_POPUP_LOCALSTORAGE_KEY = "gdpr-popup";
const GDRP_REQUIRED = "gdpr-required";

// const isClient = typeof window !== "undefined";

function App({ userConfirmation }) {
  const [sessionData, , { loading }] = useUserSession();
  const [hasPermission] = usePermissions();

  const [openWarning, setOpenWarning] = useState(sessionData?.status !== "ok");
  // const lastGdrpCloseTime =
  //   (isClient && window.localStorage.getItem(GDPR_POPUP_LOCALSTORAGE_KEY)) || 0;
  // const [openGdprData, setOpenGdprData] = useState(
  //   isClient &&
  //     !!sessionData &&
  //     sessionData.status === "ok" &&
  //     !sessionData.gdprDataFilled &&
  //     lastGdrpCloseTime < +new Date() - 24 * 60 * 60 * 1000 // On affiche seulement si les informations sont incomplètes ET que ça fait + de 24h qu'on n'a pas affiché la popup
  // );
  const [openGdprDataRequired, setOpendGdprDataRequired] = useState(false);

  // const onCloseUpdateSelfData = useCallback(() => {
  //   if (isClient)
  //     window.localStorage.setItem(GDPR_POPUP_LOCALSTORAGE_KEY, +new Date());
  //   setOpenGdprData(false);
  // }, []);
  const onCloseUpdateSelfDataRequired = useCallback(() => {
    userConfirmation.callback(false);
    setOpendGdprDataRequired(false);
  }, [userConfirmation]);
  const onSuccessUpdateSelfDataRequired = useCallback(() => {
    userConfirmation.callback(true);
    setOpendGdprDataRequired(false);
  }, [userConfirmation]);

  useEffect(() => {
    if (!userConfirmation) return;
    if (userConfirmation?.message === GDRP_REQUIRED) {
      return setOpendGdprDataRequired(true);
    }
    return userConfirmation?.callback(
      window.confirm(userConfirmation?.message)
    );
  }, [userConfirmation]);

  if (loading) {
    return (
      <Root>
        <ContentLoading />
      </Root>
    );
  }

  return (
    <Root>
      <Routes />

      {Boolean(sessionData?.status && sessionData.status !== "ok") &&
        (sessionData.status === "datausage-not-validated" ? (
          <UserAcceptancePopup open={openWarning} />
        ) : (
          <WarningPopup
            open={openWarning}
            session={sessionData}
            status={sessionData.status}
            onClose={() => setOpenWarning(false)}
          />
        ))}
      {hasPermission("stores:gifts") && (
        <>
          {/* <GdprDataPopup
            open={openGdprData}
            onClose={onCloseUpdateSelfData}
            onSubmit={onCloseUpdateSelfData}
          /> */}
          <Prompt
            when={!sessionData?.gdprDataFilled}
            message={(location) => {
              if (location.pathname === paths.gifts) {
                return GDRP_REQUIRED;
              }
              return true;
            }}
          />
          <GdprDataPopup
            open={openGdprDataRequired}
            required
            onClose={onCloseUpdateSelfDataRequired}
            onSubmit={onSuccessUpdateSelfDataRequired}
          />
        </>
      )}
    </Root>
  );
}

export default hot(App);
