import { Redirect, useParams } from "react-router-dom";

import { Button, Paragraph, Title } from "../components/typography.jsx";
import { useSessionData } from "../services/auth.js";
import { paths } from "../services/router.js";
import LoggedOutTemplate from "../templates/LoggedOutTemplate.jsx";
import { color, context, font, styled, theme } from "../util/style.js";

const Box = styled.div`
  ${theme("content-box")}
  margin: auto;
  max-width: 640px;
  width: 100%;
  padding: 24px 48px;
  text-align: center;

  ${context("for-tablet-portrait")`
    padding: 16px;
  `}

  ${Title} {
    ${font("headline4")}
    color : ${color("primary")};
  }
`;

const MESSAGES = {
  unauthorized:
    "Vous n'avez pas accès à la plateforme DAIKIN ELITE. Veuillez contacter votre commercial Daikin.",
  "no-company":
    "Vous n'avez pas accès à la plateforme DAIKIN ELITE. Veuillez contacter votre commercial Daikin.",
  "no-company-archived":
    "Vous n'avez plus accès à la plateforme DAIKIN ELITE. Veuillez contacter votre commercial Daikin.",
  "no-company-category":
    "Votre accès à la plateforme DAIKIN ELITE n'a pas été validé. Veuillez contacter votre commercial Daikin.",
  "no-company-subscription-expiration-date":
    "Votre accès à la plateforme DAIKIN ELITE n'a pas été validé, votre contrat ne nous est pas encore parvenu. Pour toute question, veuillez contacter votre commercial Daikin.",
};

export default function Login() {
  const sessionData = useSessionData();
  const { code } = useParams();

  if (Boolean(sessionData)) return <Redirect to={paths.home} />;

  return (
    <LoggedOutTemplate title="Connexion">
      <Box>
        <Title>Accès non autorisé</Title>
        <Paragraph>{MESSAGES[code] || MESSAGES.unauthorized}</Paragraph>
        <Button to="/">Retour à l'accueil</Button>
      </Box>
    </LoggedOutTemplate>
  );
}
