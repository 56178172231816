import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import {
  paginatedGql,
  tilesFragment,
  toStandardResult,
} from "../util/graphql.js";

const postSchema = `
  id
  title
  date
  image {
    id
    name
    version
  }
  thumbnail {
    id
    name
    version
  }
  summary
  category
  alias
`;

export function usePosts({ category }, pagination) {
  const result = useQuery(
    gql`
      query GET_POSTS($category: String, $pagination: PaginationInput) {
        private {
          posts {
            posts: findPosts(
              filter: { category: $category }
              pagination: $pagination
            ) {
              ${paginatedGql(postSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        category: category === "all" ? undefined : category,
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.posts.posts");
}

export function usePost(postAlias) {
  const result = useQuery(
    gql`
      query GET_POST($alias: ID!) {
        private {
          posts {
            post: fetchPostByAlias(alias: $alias) {
              ${postSchema}
              contents {
                ${tilesFragment()}
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        alias: postAlias,
      },
    }
  );

  return toStandardResult(result, "private.posts.post");
}
