import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { paginatedGql, toStandardResult } from "../util/graphql.js";

export const challengeSchema = `
  id
  title
  button {
    url
    label
  }
  thumbnail {
    id
    name
    version
  }
  summary
  alias
  attempted
  bonus
  multiple
  isPlayable
  playable { from until }
`;

export function useChallenges(pagination = { after: 0, limit: 100 }) {
  const result = useQuery(
    gql`
      query GET_CHALLENGES($pagination: PaginationInput) {
        private {
          challenges {
            list: findChallenges(
              pagination: $pagination
            ) {
              ${paginatedGql(challengeSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.challenges.list");
}
