import { formatCurrency } from "../../common/currency.js";
import { paths } from "../services/router.js";
import { color, context, eq, is, prop, styled, theme } from "../util/style.js";
import { Col, Row } from "./layout.jsx";
import { Button } from "./typography.jsx";

const Label = styled.div`
  white-space: nowrap;
  ${is("strong")`
    font-weight: bold;
  `}
`;

const BudgetCol = styled(Col)`
  color: white;
  ${eq("align", "center")`
    text-align: center;
  `}
`;

const IndicatorCol = styled(BudgetCol)`
  ${context("for-tablet-landscape")`
    text-align: center;
    margin-bottom: 32px;
    ${Label} {
      margin-bottom: 8px;
    }
  `}
`;

const Legend = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  background-color: ${prop("color")};
  vertical-align: middle;
  border-radius: 100%;
`;

const BudgetIndicator = styled(function ({ budget, colors, className }) {
  return (
    <div className={className}>
      {Boolean(budget.reserved > 0) && (
        <div
          className="BudgetIndicator-bar"
          style={{
            backgroundColor: colors.reserved,
            width:
              Math.min(
                100,
                Math.max(
                  0,
                  ((budget.reserved + budget.expenses) * 100) / budget.total
                )
              ) + "%",
          }}
        />
      )}
      {Boolean(-budget.expenses > 0) && (
        <div
          className="BudgetIndicator-bar"
          style={{
            backgroundColor: colors.expenses,
            width:
              Math.min(
                100,
                Math.max(0, (-budget.expenses * 100) / budget.total)
              ) + "%",
          }}
        />
      )}
    </div>
  );
})`
  width: 100%;
  background: white;
  border-radius: 28px;
  border: 3px solid white;
  position: relative;
  height: 28px;

  .BudgetIndicator-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 22px;
    min-width: 22px;
  }
`;

export default styled(function BudgetHeader({
  budget: rawBudget,
  budgetButton,
  className,
}) {
  const colors = {
    expenses: "#0189df",
    reserved: "#67c6dd",
  };

  const budget = {
    total: rawBudget?.totalCredits?.amount || 0,
    expenses: rawBudget?.totalExpenses?.amount || 0,
    balance: rawBudget?.totalBalance?.amount || 0,
    reserved: rawBudget?.totalReserved?.amount || 0,
  };

  return (
    <div className={className}>
      <div className="BudgetHeader-container">
        <Row>
          <IndicatorCol span={3} md={12}>
            <Label strong>Votre budget</Label>
            <BudgetIndicator budget={budget} colors={colors} />
          </IndicatorCol>
          <BudgetCol span={2} md={3} align="center">
            <Label strong>{formatCurrency(budget.total, false)}</Label>
            <Label>Total</Label>
          </BudgetCol>
          <BudgetCol span={2} md={3} align="center">
            <Label strong>
              {formatCurrency(
                budget.expenses === 0 ? 0 : -budget.expenses,
                false
              )}{" "}
              <Legend color={colors.expenses} />
            </Label>
            <Label>Consommés</Label>
          </BudgetCol>
          <BudgetCol span={2} md={3} align="center">
            <Label strong>{formatCurrency(budget.balance, false)}</Label>
            <Label>Restants</Label>
          </BudgetCol>
          {Boolean(budgetButton || budgetButton !== null) && (
            <BudgetCol span={2} md={3} align="center">
              {budgetButton || (
                <Button to={paths.interventionVouchers}>
                  Utiliser mon budget
                </Button>
              )}
            </BudgetCol>
          )}
        </Row>
      </div>
    </div>
  );
})`
  background: ${color("bg-header2")};
  border-radius: ${theme("border-radius")};
  margin-bottom: 72px;
  padding: 48px;

  .BudgetHeader-container {
    max-width: 660px;
    margin: auto;
  }

  ${context("for-tablet-portrait")`
    padding: 32px;
    margin: 30px 0;
  `}
`;
