import { useParams } from "react-router-dom";
import _keyBy from "lodash/keyBy";
import { format as formatDate } from "../../common/date.js";
import { usePosts } from "../services/posts.js";
import Container from "../templates/common/Container.jsx";
import { color, font, styled, context, theme } from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";
import Pagination, { usePagination } from "./Pagination.jsx";
import { paths } from "../services/router.js";
import { useMemo } from "react";
import PostCategories from "./PostCategories.jsx";
import { categories } from "../../common/categories.js";
import ContentLoading from "./ContentLoading.jsx";
import { useRef } from "react";
import { getMediaUrl } from "../util/medias.js";

const DEFAULT_LIMIT = 10;

const categoriesByKey = _keyBy(categories, "id");

const Post = styled(function({
  to,
  image,
  title,
  date,
  category,
  content,
  className,
}) {
  return (
    <div className={className}>
      <div className="Post-image">
        {Boolean(image) && (
          <img src={getMediaUrl(image, "post-thumbnail")} alt={title} />
        )}
      </div>
      <div className="Post-container">
        {Boolean(categoriesByKey[category]) && (
          <div className="Post-tags">
            <span className="Post-tag">{categoriesByKey[category]?.title}</span>
          </div>
        )}
        <Link className="Post-title" to={to}>
          {title}
        </Link>
        <div className="Post-date">{formatDate(date, "dd MMMM yyyy")}</div>
        <div className="Post-content">{content}</div>

        <div className="Post-bottomActions">
          <div>
            <Link className="Post-seeMore" to={to}>
              Lire la suite
              <Icon name="arrow-next" size={18} variant="blue" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
})`
  position: relative;
  padding: 24px;
  border-radius: ${theme("border-radius")};
  background: white;
  display: flex;
  grid-gap: 32px;
  align-items: flex-start;

  & + & {
    margin-top: 32px;
  }

  .Post {
    &-container {
      flex: 1;
    }
    &-image {
      background: ${color("white-grey2")};
      img {
        display: block;
        width: 220px;
        height: 220px;
      }
    }
    &-title {
      ${font("headline5")}
      color: ${color("primary")};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-tags {
      display: flex;
      grid-gap: 2px;
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    &-date {
      ${font("small")}
      color: ${color("grey-text")};
    }
    &-content {
      margin-top: 24px;
      overflow: hidden;
      margin-bottom: 48px;
    }
    &-tag {
      ${font("mini")}
      display: inline-block;
      background: ${color("grey-light")};
      color: white;
      padding: 2px 6px;
    }
    &-bottomActions {
      position: absolute;
      bottom: 24px;
      right: 24px;
      display: flex;
      grid-gap: 8px;
      justify-content: flex-end;
    }
    &-seeMore {
      ${Icon} {
        vertical-align: middle;
        margin-left: 12px;
        position: relative;
        left: 0;
        transition: left 0.3s ease;
      }
      &:hover {
        --icon-hover: 1;
        ${Icon} {
          left: 3px;
        }
      }
    }
  }


  ${context("for-desktop")`
    .Post {
      &-image img {
        width: 110px;
        height: 110px;
      }
    }
  `}

  ${context("for-tablet-landscape")`
    .Post {
      &-image img {
        width: 220px;
        height: 220px;
      }
    }
  `}

  ${context("for-tablet-portrait")`
    .Post {
      &-image {
        display: none;
      }
    }
  `}
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

export default styled(function PostsSection({ className }) {
  const { category } = useParams();
  const topScroll = useRef();

  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [postsResult, { ready, loading }] = usePosts({ category }, pagination);

  const posts = useMemo(
    () => (postsResult?.edges || []).map(({ node }) => node),
    [postsResult]
  );

  return (
    <>
      <Container className={className}>
        <div className="PostsSection-categories">
          <PostCategories />
        </div>
        <div className="PostsSection-content">
          <div className="PostsSection-listPosts">
            <ScrollRef ref={topScroll} />
            {loading ? (
              <ContentLoading />
            ) : posts?.length ? (
              (posts || []).map((post) => (
                <Post
                  key={post.id}
                  to={`${paths.posts}/${post.category || "all"}/${post.alias}`}
                  image={post.thumbnail}
                  title={post.title}
                  category={post.category}
                  date={post.date}
                  content={post.summary}
                />
              ))
            ) : (
              <Panel>
                <h3>Aucun résultat</h3>
                <div>
                  {category
                    ? "Aucun article dans cette catégorie."
                    : "Aucun article disponible pour le moment."}
                </div>
              </Panel>
            )}
            {Boolean(ready && posts?.length) && (
              <Pagination result={postsResult} pagination={pagination} />
            )}
          </div>
        </div>
      </Container>
    </>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  grid-gap: 56px;

  .PostsSection {
    &-categories {
      flex: 1;
    }
    &-content {
      width: 60%;
      display: block;
    }
  }

  ${context("for-tablet-landscape")`
    flex-direction: column;
    grid-gap: 32px;
    align-items: stretch;
    .PostsSection {
      &-content {
        width: 100%;
      }
    }
  `}
`;
