import { transformTile } from "../../common/links.js";
import ReactTilesRenderer from "../../common/modules/tiles/renderers/ReactTilesRenderer.jsx";
import { is, styled } from "../util/style.js";

export default styled(function Tiles({ className, tiles }) {
  const flatTiles = (tiles || []).map(({ data, ...meta }) => ({
    ...meta,
    ...data,
  }));
  return (
    <div className={className}>
      <ReactTilesRenderer tiles={flatTiles} options={{ transformTile }} />
    </div>
  );
})``;
