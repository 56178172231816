import { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";

import { colors } from "../../templates/theme.jsx";
import { color, font, is, prop, styled } from "../../util/style.js";

const COLORS = [
  "#0D97E2",
  "#3E3E3E",
  "#DF5601",
  "#758389",
  "#409600",
  "#646464",
  "#C2AC6D",
];

const MIN_REQUIRED_OPTION = 3;

function displayPercent(value, digits = 2) {
  return new Intl.NumberFormat("fr", {
    style: "percent",
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(value || 0);
}

const RepartitionBarLabels = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 16px;
`;
const TotalLabel = styled.span`
  color: ${color("blue")};
  font: ${font("strong")};
`;

const colorOfLeftValue = ({ value }) =>
  value > 0 ? colors.orange : value < 0 ? colors.red : colors.green;

const LeftLabel = styled.span`
  background-color: ${colorOfLeftValue};
  color: white;
  font: ${font("strong")};
  position: relative;
  padding: 4px 0.5em;
  z-index: 0;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: -6px;
    right: 0;

    border: 6px solid ${colorOfLeftValue};
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
`;

const Bar = styled.div`
  background-color: ${color("white-grey2")};
  border-radius: 11px;
  display: flex;
  justify-content: flex-start;
  padding: 1px;
  width: 100%;
  height: 22px;
`;

const BarValue = styled(function ({ isLast, isFirst, value, color, ...props }) {
  return <div {...props} />;
})`
  width: ${({ value }) => value * 100}%;
  background-color: ${prop("color")};
  height: 20px;
  transition: 0.5s width;
  ${is("isFirst")`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  `}
  ${is("isLast")`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  `}
`;

const RepartitionBar = styled(function ({ className, answers, value }) {
  const total = (value || []).reduce(
    (total, { value }) => total + (value || 0),
    0
  );
  const left = 1 - total;

  const orderedOptionsValued = answers
    .map(({ reference }) =>
      (value || []).find(({ option }) => option === reference)
    )
    .filter(Boolean);

  return (
    <div className={className}>
      <RepartitionBarLabels>
        <TotalLabel>{displayPercent(total, 0)}</TotalLabel>
        <LeftLabel value={left}>{displayPercent(left, 0)}</LeftLabel>
      </RepartitionBarLabels>
      <Bar>
        {(orderedOptionsValued || []).map(({ value }, index) => (
          <BarValue
            key={index}
            isFirst={index === 0}
            isLast={index === orderedOptionsValued.length - 1}
            value={value || 0}
            color={COLORS[index % COLORS.length]}
          />
        ))}
      </Bar>
    </div>
  );
})`
  width: 100%;
`;

const AnswersList = styled.div``;

const Bullet = styled.div`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: ${prop("color")};
`;

const AnswerLabel = styled.span`
  flex: 1;
`;

const AnswerInputContainer = styled.div`
  position: relative;
`;

const AnswerInput = styled.input.attrs({
  type: "number",
  min: 0,
  max: 100,
  step: 1,
})`
  border: 0;
  color: ${color("grey-text")};
  font: ${font("paragraph")};
  width: 80px;
  padding: 12px;
  margin-left: 12px;
  font-size: 16px;
  padding-right: 30px;
  text-align: right;
`;

const AnswerInputLabel = styled.div`
  color: ${color("grey-text")};
  font: ${font("paragraph")};
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
  pointer-events: none;
`;

const DeleteButton = styled.button`
  padding: 10px;
  margin-left: 10px;
  background: none;
  border: 0;
  cursor: pointer;
  > img {
    width: 16px;
  }
`;

const Answer = styled(function ({
  text,
  onChange,
  onRemove,
  value,
  index,
  className,
}) {
  return (
    <div className={className}>
      <Bullet color={COLORS[index % COLORS.length]} />
      <AnswerLabel>{text}</AnswerLabel>
      <AnswerInputContainer>
        <AnswerInput
          defaultValue={Math.round((value || 0) * 100)}
          onChange={(evt) =>
            onChange &&
            onChange(Number(evt.nativeEvent.target.value || 0) / 100)
          }
        />
        <AnswerInputLabel>%</AnswerInputLabel>
      </AnswerInputContainer>
      {!!onRemove && <DeleteButton onClick={onRemove}>Retirer</DeleteButton>}
    </div>
  );
})`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const AnswerSelector = styled.div`
  margin-top: 12px;
`;

export default styled(function PercentageQuestion({
  value,
  onChange,
  answers,
  className,
}) {
  const [displayedAnswers, setDisplayedAnswers] = useState([]);
  const selectInputRef = useRef();

  const getAnswerWeight = useCallback(
    ({ reference }) => {
      const weightedAnswer = (value || []).find(
        ({ option }) => option === reference
      );

      return (weightedAnswer && weightedAnswer.value) || 0;
    },
    [value]
  );

  useEffect(() => {
    if (answers.length <= MIN_REQUIRED_OPTION) setDisplayedAnswers(answers);
    // On n'affiche que les réponses avec un poids, sinon
    else setDisplayedAnswers(answers.filter(getAnswerWeight));
  }, [answers, getAnswerWeight]);

  return (
    <div className={className}>
      <RepartitionBar answers={displayedAnswers} value={value} />

      <AnswersList>
        {displayedAnswers.map((answer, index) => (
          <Answer
            key={answer.id}
            text={answer.text}
            index={index}
            value={getAnswerWeight(answer)}
            onChange={(weight) =>
              onChange &&
              onChange([
                ...(value || []).filter(
                  ({ option }) => option !== answer.reference
                ),
                { option: answer.reference, value: weight },
              ])
            }
            onRemove={
              answers.length <= MIN_REQUIRED_OPTION
                ? null
                : () => {
                    onChange &&
                      onChange(
                        (value || []).filter(
                          ({ option }) => option !== answer.reference
                        )
                      );
                    setDisplayedAnswers(
                      displayedAnswers.filter(
                        ({ reference }) => reference === answer.reference
                      )
                    );
                  }
            }
          />
        ))}
      </AnswersList>

      {answers.length !== displayedAnswers.length && (
        <AnswerSelector>
          <Select
            ref={selectInputRef}
            placeholder="Ajoutez une réponse..."
            options={answers
              .filter((answer) => displayedAnswers.indexOf(answer) === -1)
              .map((answer) => ({
                value: answer.reference,
                label: answer.text,
                answer,
              }))}
            onChange={(option) => {
              if (option) {
                setDisplayedAnswers([...displayedAnswers, option.answer]);
                setTimeout(() => selectInputRef.current.select.clearValue(), 0);
              }
            }}
          />
        </AnswerSelector>
      )}
    </div>
  );
})``;
