import { Fragment } from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import Container from "../templates/common/Container.jsx";
import { color, font, styled, is, context, theme } from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";

const Category = styled(function({ title, url, to, themes, className }) {
  const routeMatch = useRouteMatch(to);
  const active = to && routeMatch?.isExact;

  const Component = to ? Link : "div";

  return (
    <div className={className + (active ? " Category--active" : "")}>
      <Component to={to} className="Category-title">
        {title}
      </Component>
      {Boolean(themes?.length > 0) && (
        <ul className="Category-summary">
          {themes.map((child, index) => {
            const hasQuestions = child.questions?.length;
            const Component = hasQuestions ? Link : "div";
            return (
              <li key={"subcategory-" + index}>
                <Component
                  {...(hasQuestions ? { to: `${url}/${child.id}` } : {})}
                >
                  {child.title}
                </Component>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
})`
  .Category {
    &-title {
      ${font("headline5")}
      color: ${color("text-dark")};
      margin: 0;
      text-decoration: none;
      transition: color 0.3s ease;
    }
    
    &-summary {
      padding-left: 16px;
    }
  }
  ${is("to")`
    .Category-title {
      text-decoration: underline;
    }
    &.Category--active .Category-title, .Category-title:hover {
      color: ${color("primary")};
      transition: color 0.3s ease;
    }
  `}
`;

const Question = styled(function({ to, question, date, answer, className }) {
  const routeMatch = useRouteMatch(to);
  const active = to && routeMatch?.isExact;

  return (
    <div className={className + (active ? " Question--active" : "")}>
      <div className="Question-wrapper">
        <Link className="Question-question" to={to}>
          <span className="Question-label">{question}</span>
          <span className="Question-open">
            <Icon
              className="Question-openIcon"
              name="arrow-down"
              variant="grey"
              hoverVariant="white"
              size={16}
            />
          </span>
        </Link>
        <div className="Question-answerContainer">
          <span className="Question-answerDate">{date}</span>
          <p className="Question-answer">{answer}</p>
        </div>
      </div>
    </div>
  );
})`
  position: relative;
  
  .Question {
    &-wrapper {
      padding: 24px;
      margin-left: 56px;
      border-radius: ${theme("border-radius")};
    }
    &-question,
    &-answerContainer {
      position: relative;
      z-index: 1;
    }
    &-question {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-decoration: none;
      transition: color 0.3s ease;
      width: 100%;
    }
    &-label {
      align-items: center;
      ${font("headline5")}
      color: ${color("text-dark")};
      display: flex;
      flex: 1;
      text-align: left;
    }
    &-open {
      align-items: center;
      border: 1px solid ${color("disabled")};
      display: inline-flex;
      justify-content: center;
      margin-left: 16px;
      height: 40px;
      transition: all 0.3s ease;
      width: 40px;
    }
    &-openIcon {
      display: block;
      transform: rotate(0deg);
      transition: transform 0.3s ease;
    }
    &-answerContainer {
      display: none;
      margin-top: 32px;
    }
    &-answerDate {
      ${font("small")}
      display: block;
      margin: 32px 0;
      opacity: 0.6;
    }
    &-answer {
      margin: 32px 0 0;
    }
  }
  &:hover,
  &.Question--active {
    .Question-label {
      color: ${color("primary")};
      transition: color 0.3s ease;
    }
    .Question-open {
      background-color: ${color("primary")};
      border-color: ${color("primary")};
      transition: all 0.3s ease;
    }
    .Question-openIcon {
      &::after {
        opacity: 1;
        transition: color 0.3s ease;
      }
    }
  }
  &.Question--active {
    .Question-wrapper {
      background: white;
    }
    &::before {
      opacity: 1;
    }
    .Question-answerContainer {
      display: block;
    }
    .Question-openIcon {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
    }
  }

  ${context("for-tablet-portrait")`
    &::before {
      width: auto;
      left: -32px;
      right: -32px;
    }
    .Question-wrapper {
      margin-left: 0;
    }
  `}
`;

const BackButton = styled(function({ to, className }) {
  return (
    <Link to={to} className={className} aria-label="Retourner en arrière">
      <Icon name="arrow-top" size={30} variant="black" hoverVariant="blue" />
    </Link>
  );
})`
  align-items: center;
  border: 2px solid ${color("icon-black")};
  border-radius: 50px;
  justify-content: center;
  height: 50px;
  transition: border-color 0.3s ease;
  width: 50px;
  position: relative;
  display: none;
  transform: rotate(-90deg);

  &:hover {
    border-color: ${color("primary")};
    transition: border-color 0.3s ease;
    --icon-hover: 1;
  }

  ${context("for-tablet-portrait")`
    display: inline-flex;
  `}
`;

export default styled(function QuestionsSection({ title, content, className }) {
  const { path, url } = useRouteMatch();
  const routeMatch = useRouteMatch(path);
  const onHome = routeMatch?.isExact;

  return (
    <>
      <Container className={className}>
        <div
          className={
            "QuestionsSection-categories" +
            (onHome ? " QuestionsSection-categories--active" : "")
          }
        >
          <div className="QuestionsSection-categories-container">
            <h2 className="QuestionsSection-title">{title}</h2>

            <div className="QuestionsSection-categories-list">
              {content.map((category, index) => (
                <Category
                  key={"category-" + index}
                  {...(category.questions?.length
                    ? { to: `${url}/${category.id}` }
                    : {})}
                  url={`${url}/${category.id}`}
                  title={category.title}
                  themes={category.themes}
                />
              ))}
            </div>
          </div>
        </div>

        <div
          className={
            "QuestionsSection-content" +
            (!onHome ? " QuestionsSection-content--active" : "")
          }
        >
          {!onHome && (
            <div className="QuestionsSection-backContainer">
              <BackButton to={url} />
            </div>
          )}

          <div className="QuestionsSection-listQuestions">
            {content.map((category) => (
              <Fragment key={"theme-" + category.id}>
                {(category.themes || []).map((theme) => (
                  <Fragment key={"theme-" + category.id + "-" + theme.id}>
                    <Route
                      path={`${path}/${category.id}/${theme.id}`}
                      component={() =>
                        (
                          theme.questions || []
                        ).map(({ id, question, date, answer }) => (
                          <Question
                            key={
                              "question-" +
                              category.id +
                              "-" +
                              theme.id +
                              "-" +
                              id
                            }
                            to={`${url}/${category.id}/${theme.id}/${id}`}
                            question={question}
                            date={date}
                            answer={answer}
                          />
                        ))
                      }
                    />
                    <Route
                      path={`${path}/${category.id}/${theme.id}`}
                      exact
                      component={() => (
                        <Redirect
                          to={`${url}/${category.id}/${theme.id}/${theme.questions?.[0]?.id}`}
                        />
                      )}
                    />
                  </Fragment>
                ))}
                <Route
                  key={"category-" + category.id}
                  path={`${path}/${category.id}`}
                  component={() =>
                    (
                      category.questions || []
                    ).map(({ id, question, date, answer }) => (
                      <Question
                        key={"question-" + category.id + "-" + id}
                        to={`${url}/${category.id}/${id}`}
                        question={question}
                        date={date}
                        answer={answer}
                      />
                    ))
                  }
                />
                <Route
                  key={"category-" + category.id + "-default"}
                  path={`${path}/${category.id}`}
                  exact
                  component={() => (
                    <Redirect
                      to={
                        category.questions?.length
                          ? `${url}/${category.id}/${category.questions?.[0]?.id}`
                          : `${url}/${category.id}/${category.themes?.[0]?.id}/${category.themes?.[0]?.questions?.[0]?.id}`
                      }
                    />
                  )}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
})`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 0;

  .QuestionsSection {
    &-categories {
      ${theme("content-box")}
      position: relative;
      width: 460px;
      &-container {
        padding: 24px;
      }
      &-list {
        padding: 0 40px;
        & > ${Category} {
          margin: 40px 0;
        }
      }
    }
    &-content {
      flex: 1;
    }

    &-title {
      ${font("headline3")}
      color: ${color("grey-text")};
      margin: 0;
      padding: 24px 40px;
      background: ${color("bg-page")};
    }
    
    &-backContainer {
      display: none;
    }
  }

  ${context("for-tablet-portrait")`
    .QuestionsSection {
      &-content, &-categories {
        display: none;
        width: auto;
        flex: 1;

        &--active {
          display: block;
        }
      }
      &-backContainer {
        padding-left: 24px;
        display: block;
        margin-bottom: 24px; 
      }

      &-title {
        ${font("headline4")}
        color: ${color("primary")};
        margin: 0;
      }
    }
  `}
`;
