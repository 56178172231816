import { context, is, css, styled } from "../util/style.js";

const colsConfiguration = {
  main: {
    span: 7,
    lg: 12,
  },
  aside: {
    span: 5,
    lg: 12,
  },
};

function cssFlex(field) {
  return ({ kind, fixed, ...props }) => {
    const nFromKind = kind && colsConfiguration[kind][field];
    const nFromProps = props[field];

    const n = nFromProps || nFromKind;

    if (!n) return ``;

    const size = (n / 12) * 100;

    return n === "hidden"
      ? css`
          display: none;
        `
      : css`
          display: block;
          flex: 1 0 calc(${size}% - 15px);
          width: ${size}%;
          ${Boolean(fixed)
            ? css`
                max-width: ${size}%;
              `
            : ``}
        `;
  };
}

function cssOffset(field) {
  return ({ kind, fixed, ...props }) => {
    const nFromKind = kind && colsConfiguration[kind][field];
    const nFromProps = props[field];

    const n = nFromProps || nFromKind;

    if (!n) return ``;

    const size = (n / 12) * 100;

    return css`
      margin-left: ${size}%;
    `;
  };
}
export const Col = styled.div`

  ${cssFlex("span")}
  ${cssOffset("offset")}

  ${context("for-desktop")`
    ${cssFlex("lg")}
    ${cssOffset("lg-offset")}
  `}

  ${context("for-tablet-landscape")`
    ${cssFlex("md")}
    ${cssOffset("md-offset")}
  `}

  ${context("for-phone")`
    ${cssFlex("sm")}
    ${cssOffset("sm-offset")}
  `}

  ${context("for-small-phone")`
    ${cssFlex("xs")}
    ${cssOffset("xs-offset")}
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 15px;

  ${is("center")`
    align-items: center;
  `}
`;
