import { format as formatDate } from "../../common/date.js";
import heartOutline from "../icons/heart-outline.svg";
import heartFilled from "../icons/heart.svg";
import Container from "../templates/common/Container.jsx";
import { getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";

const ContestParticipationImage = styled.div`
  background: ${color("white-grey2")};
  img {
    border-radius: ${theme("border-radius")};
    display: block;
    width: 100%;
    height: auto;
  }
`;

const ContestParticipationContent = styled.div``;

const ContestParticipationLikes = styled.div`
  border-bottom: 1px solid ${color("grey-text")};
  line-height: 36px;
  text-align: right;
  img {
    vertical-align: middle;
  }
`;

const ContestParticipationLikeButton = styled.button`
  ${font("strong")}
  color: ${color("primary")};
  font-size: 2rem;
  ${is("active")`
    color: ${color("secondary")};
  `}
`;

const ContestParticipationDate = styled.p`
  color: ${color("grey-text")};
  margin: 0;
  padding: 0;
`;

const ContestParticipationTitle = styled(Link)`
  ${font("headline5")}
  color: ${color("text")};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ContestParticipationActions = styled.div`
  a {
    color: ${color("primary")};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const ContestParticipation = styled(function ({
  to,
  title,
  description,
  date,
  likesCount,
  file,
  userHasLiked,
  company,
  meta,
  onLike,
  onUnlike,
  className,
}) {
  return (
    <div className={className}>
      <ContestParticipationImage>
        {Boolean(file) && (
          <img
            src={getMediaUrl(file, "contest-participation-thumbnail")}
            alt={description || title || "Aucune description"}
          />
        )}
      </ContestParticipationImage>
      <ContestParticipationContent>
        <ContestParticipationLikes>
          <ContestParticipationLikeButton
            active={userHasLiked}
            onClick={userHasLiked ? onUnlike : onLike}
          >
            {likesCount}{" "}
            <img
              src={userHasLiked ? heartFilled : heartOutline}
              width="24"
              height="24"
              alt={userHasLiked ? "Je n'aime plus" : "J'aime"}
            />
          </ContestParticipationLikeButton>
        </ContestParticipationLikes>
        <ContestParticipationDate>
          {formatDate(date, "dd MMMM yyyy")}
        </ContestParticipationDate>
        <ContestParticipationTitle to={to}>
          {company?.name || title || "Sans titre"}
        </ContestParticipationTitle>
        <ContestParticipationActions>
          <Link className="seeMore" to={to}>
            Voir le projet
          </Link>
        </ContestParticipationActions>
      </ContestParticipationContent>
    </div>
  );
})`
  position: relative;
  margin-top: 32px;
  width: 260px;

  ${context("for-desktop")`
    width: 280px;
  `}

  ${context("for-tablet-landscape")`
    width: 320px;
  `}

  ${context("for-tablet-portrait")`
    width: auto;
  `}
`;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;

export default styled(function ContestParticipations({
  className,
  list,
  to,
  onLike,
  onUnlike,
}) {
  return (
    <div className={className}>
      {list?.length ? (
        (list || []).map(({ cursor: index, node: contestParticipation }) => (
          <ContestParticipation
            key={contestParticipation.id}
            {...contestParticipation}
            to={`${to}/view/${index - 1}`}
            onLike={() => onLike(contestParticipation.id)}
            onUnlike={() => onUnlike(contestParticipation.id)}
          />
        ))
      ) : (
        <h3>Aucune participation pour le moment</h3>
      )}
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  grid-gap: 24px;
  margin-top: 64px;
  padding: 32px 0;
  position: relative;
  z-index: 0;

  h3 {
    text-align: center;
    flex: 1;
  }

  ${context("for-desktop")`
    grid-gap: 32px;
  `}

  ${context("for-tablet-landscape")`
    grid-gap: 46px;
  `}

  ${context("for-tablet-portrait")`
    flex-direction: column;
    align-items: stretch;
  `}
`;
