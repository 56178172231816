export default function ReactButtonTile({ tile, options, className }) {
  return (
    <div
      className={`${
        className || ""
      } tile-container tile-button tile--typography`}
    >
      <a
        className="button"
        href={tile.link}
        target={tile.linkTarget}
        rel="noreferer noopener"
        title={tile.linkTitle}
      >
        {tile.text}
      </a>
    </div>
  );
}
