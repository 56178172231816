import Icon from "../../components/Icon.jsx";
import logoDaikin from "../../img/logo_daikin_white.png";
import {
  color,
  context,
  eq,
  font,
  is,
  isnot,
  styled,
  theme,
} from "../../util/style.js";
import Container from "./Container.jsx";

const ToTheTop = styled(function({ onClick, className }) {
  return (
    <button
      onClick={onClick}
      className={className}
      aria-label="Retourner en haut de la page"
    >
      <Icon name="arrow-top" size={30} variant="black" hoverVariant="blue" />
    </button>
  );
})`
  align-items: center;
  background-color: ${color("bg-page")};
  border: 2px solid ${color("icon-black")};
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  height: 50px;
  transition: border-color 0.3s ease;
  width: 50px;
  position: relative;

  &:hover {
    border-color: ${color("primary")};
    transition: border-color 0.3s ease;
    --icon-hover: 1;
  }
`;

export const MainContent = styled(function({ children, className }) {
  return (
    <div className={className}>
      <div className="MainContent-topBar">
        <Container className="MainContent-topBar-container" />
      </div>
      <div className="MainContent-main">{children}</div>
      <div className="MainContent-toTheTop">
        <ToTheTop
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>
      <div className="MainContent-bottomBar">
        <Container className="MainContent-bottomBar-container">
          <img
            src={logoDaikin}
            alt="Logo Daikin"
            className="MainContent-bottomBar-logo"
          />
        </Container>
      </div>
    </div>
  );
})`
  ${theme("big-border-top")}
  padding: 180px 0;
  position: relative;

  .MainContent {
    &-topBar,
    &-bottomBar {
      position: absolute;
      width: 100%;
      &-container {
        position: relative;
        &::before {
          content: "";
          border-radius: 50px;
          display: block;
          height: 600px;
          position: absolute;
          transform: rotate(-5deg);
          width: 3000px;
          z-index: -1;
        }
      }
    }

    &-main {
      position: relative;
    }

    &-topBar {
      top: 0;
      z-index: 0;
      overflow: hidden;
      height: 100vh;
      &-container {
        &::before {
          ${color("gradient-blue-topBar")}
          right: 30px;
          top: -420px;
        }
      }
    }

    &-bottomBar {
      bottom: 0;
      z-index: 0;
      text-align: right;
      overflow: hidden;
      pointer-events: none;
      &-container {
        height: 340px;
        &::before {
          ${color("gradient-blue-bottomBar")}
          left: 30px;
          top: 170px;
        }
      }
      &-logo {
        bottom: 40px;
        max-width: 180px;
        position: absolute;
        right: ${theme("padding-container")};
      }
    }

    &-toTheTop {
      position: relative;
      z-index: 1;
      margin-left: auto;
      margin-top: 56px;
      margin-right: 56px;
      width: 50px;
      text-align: right;
      ${context("for-tablet-landscape")`
        padding: 0 16px;
      `}
    }
  }

  ${context("for-tablet-landscape")`
    padding: 160px 0;
    .MainContent {
      &-bottomBar {
        &-logo {
          max-width: 120px;
        }
      }
    }
  `}

  ${context("for-tablet-portrait")`
    .MainContent {
      &-bottomBar {
        &-logo {
          bottom: 24px;
        }
      }
    }
  `}
  
  ${context("for-phone")`
    ${theme("big-border-top")}
    border-size: 5px;
    padding: 80px 0;

    .MainContent {
      &-topBar {
        &-container {
          &::before {
            top: -450px;
          }
        }
      }
      &-footerBar {
        &-logo {
          bottom: 16px;
          max-width: 88px;
        }
      }
    }
  `}

  ${is("extendedTop")`
    padding-top: 0;
    .MainContent-topBar-container {
      position: static;
      &::before {
        border-radius: 230px;
        right: 0;
        top: -230px;
      }
    }

    ${context("for-tablet-landscape")`
      .MainContent-topBar-container {
        &::before {
          right: 30px;
          top: -420px;
        }
      }
    `}

    ${context("for-phone")`
      .MainContent-topBar-container {
        &::before {
          top: -450px;
        }
      }
    `}
  `}
`;

export const ImageHeader = styled(function({
  image,
  image2x,
  icon,
  label,
  className,
}) {
  return (
    <section className={className}>
      <div className="ImageHeader-pageName">
        {Boolean(icon) && (
          <span className="ImageHeader-iconBorder">
            <Icon
              className="hide-mobile"
              name={icon}
              size={56}
              variant="white"
            />
            <Icon
              className="only-mobile hide-phone"
              name={icon}
              size={32}
              variant="white"
            />
            <Icon
              className="only-phone"
              name={icon}
              size={24}
              variant="white"
            />
          </span>
        )}
        {Boolean(label) && (
          <h1 className="ImageHeader-pageName-name">{label}</h1>
        )}
      </div>
      <img
        src={image}
        {...((image2x && { srcSet: `${image2x} 2x` }) || {})}
        alt=""
        className="ImageHeader-img"
      />
    </section>
  );
})`
  position: relative;
  ${Icon} {
    display: block;
  }
  .ImageHeader {
    aspect-ratio: 7/1;
    &-pageName {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      position: absolute;
      width: 100%;
      &-name {
        ${font("headline2")}
        color: white;
        margin: 0;
      }
    }
    &-iconBorder {
      border: 2px solid white;
      border-radius: 100px;
      margin-right: 16px;
      padding: 8px;
      ${is("noBorder")`
        border: 0;
        padding: 0;
      `}
    }
    &-img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ${context("for-tablet-landscape")`
    .ImageHeader {
      &-iconBorder {
        margin-right: 8px;
      }
      &-pageName {
        &-name {
          ${font("headline5")}
          color: white;
        }
      }
      &-img {
        aspect-ratio: 4/1;
      }
    }
  `}

  ${context("for-phone")`
    .ImageHeader {
      &-iconBorder {
        padding: 4px;
      }
    }
  `}
`;

export const VideoHeader = styled(function({ image, video, className }) {
  return (
    <section className={className}>
      <Container>
        <video controls poster={image}>
          <source src={video} type="video/mp4" />
        </video>
      </Container>
    </section>
  );
})`
  background: ${color("bg-dark")};
  position: relative;
  video {
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
    cursor: pointer;
  }

  ${context("for-tablet-portrait")`
    ${Container} {
      padding: 0;
    }
  `}
`;

export const TwoColumns = styled(function({ aside, className, children }) {
  return (
    <div className={className}>
      <div className="TwoColumns-aside">{aside}</div>
      <div className="TwoColumns-content">{children}</div>
    </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  margin: 56px -48px;

  .TwoColumns {
    &-aside, &-content {
      align-self: flex-start;
      flex: 0 0 50%;
      padding-left: 48px;
      padding-right: 48px;

      img {
        display: block;
      }
    }
    &-aside img + img {
      margin-top: 28px;
    }
    &-hotline {
      margin: 32px 0;
    }
  }
  ${eq("align", "right")`
    .TwoColumns {
      &-aside {
        order: 1;
      }
      &-content {
        order: 0;
      }
    }
  `}

  ${context("for-desktop")`
    .TwoColumns {
      margin: 40px -24px;
      &-aside, &-content {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  `}

  ${context("for-tablet-landscape")`
    margin: 0;
    .TwoColumns {
      margin: 40px 0;
      &-aside, &-content {
        padding-left: 0;
        padding-right: 0;
      }
      &-aside {
        flex: 0 0 100%;
        margin-bottom: 24px;
        max-width: 100%;            
      }
      &-content {
        flex: 0 0 100%;
      }
    }
    ${eq("align", "right")`
    .TwoColumns {
      &-aside {
        order: 0;
      }
      &-content {
        order: 1;
      }
    }
    `}
  `}
`;

export const Headline = styled(function({ title, className, children }) {
  return (
    <Container className={className}>
      <h1>{title}</h1>
      <p>{children}</p>
    </Container>
  );
})`
  text-align: center;
  margin-bottom: 80px;
  h1 {
    ${font("headline1")}
    ${isnot("big")`
      font-size: 4.6rem;
    `}
  }
  p {
    ${font("paragraph")}
    font-size: 2rem;
    font-weight: bold;
  }
  &-iconBorder {
    border: 2px solid ${color("border-blue")};
    border-radius: 100px;
    padding: 8px;
  }

  ${context("for-tablet-landscape")`
    h1 {
      ${font("headline3")}
      color: ${color("primary")};
    }
    p {
      ${font("paragraph")}
    }
  `}
`;

export const PanelInfos = styled(function({
  label,
  icon,
  image,
  image2x,
  className,
}) {
  return (
    <div className={className}>
      <div className="PanelInfos-header">
        {Boolean(icon) && <Icon size={80} variant="blue" name={icon} />}
        <h2 className="PanelInfos-title">{label}</h2>
      </div>
      {Boolean(image) && (
        <img
          src={image}
          srcSet={`${image2x} 2x`}
          alt=""
          className="PanelInfos-img"
        />
      )}
    </div>
  );
})`
  ${theme("big-border")}
  border-radius: ${theme("border-radius")};
  background-color: white;
  display: flex;
  flex-direction: column;

  .PanelInfos {
    &-header {
      align-items: center;
      background-color: ${color("bg-light")};
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 24px;
      padding: 16px;
      text-align: center;
      ${Icon} {
        margin: 16px;
      }
    }
    &-title {
      ${font("headline2")}
      color: ${color("text-dark")};
      margin: 16px;
      opacity: 0.6;
      flex: 1;
    }
    &-img {
      display: block;
      margin-bottom: 24px;
      width: 100%;
    }
    &-icon {
      margin: 16px;
    }
  }

  ${context("for-desktop")`
    .PanelInfos {
      &-title {
        ${font("headline4")}
        color: ${color("text-dark")};
      }
    }
  `}
`;

export const Section = styled(function({ className, children }) {
  return (
    <div className={className}>
      <div className="Section-diagonal" />
      {children}
    </div>
  );
})`
  background-color: white;
  position: relative;
  z-index: 0;
  &::after {
    background-color: white;
    bottom: -500px;
    content: "";
    display: block;
    left: 0;
    height: 500px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .Section-diagonal {
    border-style: solid;
    border-width: 0 100vw 90px 0;
    border-color: transparent ${color("bg-page")} white transparent;
    transform: scale(1.0001);
    margin-bottom: 56px;

    ${context("for-tablet-landscape")`
      margin-bottom: 32px;
    `}
  }
`;
