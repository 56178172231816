import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";

export const voucherSchema = `
  id
  voucherCode
  companyCode
  amount
  expirationDate
  used
  usedAt
  downloaded
  downloadedAt
`;

export function useVouchers() {
  const result = useQuery(
    gql`
      query{
        private {
          vouchers {
            vouchers : fetchVouchers{
              ${voucherSchema}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {},
    }
  );
  return toStandardResult(result, "private.vouchers.vouchers");
}

export function useExpiredVouchers() {
  const result = useQuery(
    gql`
      query{
        private {
          vouchers {
            vouchers : fetchExpiredVouchers{
              ${voucherSchema}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {},
    }
  );
  return toStandardResult(result, "private.vouchers.vouchers");
}

export function useUpdateDownloadedVoucher(voucherCode) {
  const [mutation, result] = useMutation(
    gql`
      mutation($voucherCode: String!) {
        private {
          vouchers {
            voucher: updateDownloadedVoucher(voucherCode: $voucherCode) {
              id
              downloaded
              downloadedAt
            }
          }
        }
      }
    `,
    { variables: { voucherCode } }
  );

  return [
    () => mutation({}),
    ...toStandardResult(result, "private.vouchers.voucher"),
  ];
}
