import {
  color,
  context,
  font,
  is,
  isnot,
  styled,
  theme,
} from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";

export const Title = styled.h1``;
export const Subtitle = styled.h2``;
export const SectionTitle = styled.h5``;

export const Paragraph = styled.p`
  ${is("justify")`
    text-align: justify
  `}
`;

export const List = styled.ul`
  padding-left: 48px;
  margin: 0 0 16px;
`;
export const ListItem = styled.li``;

export const Button = styled.button.attrs((props) => ({
  as: props.disabled ? "span" : props.to ? Link : props.as,
  to: props.disabled ? null : props.to,
  onClick: props.disabled ? null : props.onClick,
}))`
  ${color("gradient-blue-button")}
  ${font("paragraph")}
  border-radius: 50px;
  color: white;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: inline-block;
  font-weight: ${theme("font-weight-semi")};
  text-align: center;
  text-transform: uppercase;
  padding: 16px 48px;
  text-decoration: none;
  transition: all 0.3s !important;
  white-space: nowrap;

  ${is("gold")`
    ${color("gradient-gold-button")};
  `}
  ${is("grey")`
    filter: grayscale(100%);
  `}
  ${is("disabled")`
    filter: grayscale(100%);
    opacity: 0.67;
  `}

  ${isnot("disabled")`
    &:hover {
      background-position: right center;
      color: white;
    }
  `}

  ${is("ghost")`
    background: transparent;
    color: ${color("grey-light")};
    border: 1px solid ${color("grey-light")};
    ${isnot("disabled")`
      &:hover {
        color: ${color("blue")};
        border: 1px solid ${color("blue")};
      }
    `}
  `}

  ${is("mini")`
    ${font("small")};
    padding: 16px 32px;
  `}

  ${context("for-phone")`
    ${font("small")};
    padding: 16px 32px;
  `}
`;

export const TextButton = styled(function ({
  icon,
  className,
  children,
  mini,
  ...props
}) {
  const BaseComponent = props.to ? Link : "button";

  return (
    <BaseComponent className={className} {...props}>
      <span className="TextButton-icon">
        <Icon
          name={icon || "close"}
          size={mini ? 20 : 24}
          variant="black"
          hoverVariant="blue"
        />
      </span>
      {Boolean(children) && <span className="TextButton-text">{children}</span>}
    </BaseComponent>
  );
})`
  ${font("paragraph")};
  color: ${color("grey-light")};
  cursor: pointer;
  display: inline-flex;
  transition: all 0.3s;
  white-space: nowrap;
  align-items: center;
  text-decoration: none;

  ${is("disabled")`
    filter: grayscale(100%);
    opacity: 0.67;
  `}

  &:hover {
    color: ${color("blue")};
    .TextButton-icon {
      border-color: ${color("blue")};
      --icon-hover: 1;
    }
  }

  .TextButton-icon {
    display: block;
    border: 2px solid ${color("black")};
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 6px;
    transition: border-color 0.3s;
  }

  .TextButton-icon + .TextButton-text {
    margin-left: 10px;
  }

  ${is("mini")`
    .TextButton-icon {
      width: 30px;
      height: 30px;
      padding: 3px;
    }
  `}
`;
export const ButtonArrowAround = styled(function ({
  className,
  buttonAs,
  ...props
}) {
  return (
    <div className={className}>
      <Icon name="arrow-next" size={32} variant="grey" hoverVariant="blue" />
      <Button as={buttonAs} {...props} />
      <Icon name="arrow-prev" size={32} variant="grey" hoverVariant="blue" />
    </div>
  );
})`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${Button} {
    margin: 0 32px;
  }

  &:hover {
    --icon-hover: 1;
  }

  ${context("for-tablet-landscape")`
    ${Button} {
      margin: 0 16px;
    }
  `}
`;
