import { useEffect } from "react";

export * as Yup from "yup";
export { useForm, useWatch, Controller } from "react-hook-form";
export { yupResolver } from "@hookform/resolvers/yup";

export function hasErrors(errors) {
  return !!errors && !!errors.length;
}

export function filterErrorMessages(errors) {
  return (errors || []).map(({ message }) => message).filter((x) => !!x);
}

export function filterBadUserInputErrors(errors, path = null) {
  return (errors || [])
    .map(({ extensions: { code, errors } = {} }) =>
      code !== "BAD_USER_INPUT"
        ? []
        : path
        ? errors.filter((error) => error.path === path)
        : errors
    )
    .filter((x) => !!x && x.length)
    .reduce((all, errors) => [...all, ...(errors || [])], []);
}

export function useFormAction(
  form,
  action,
  { onError, onSuccess, transformData = (data) => data } = {}
) {
  const [fct, result, { loading, called, graphQLErrors, error }] = action;

  useEffect(
    function() {
      if (loading) return form.clearErrors();
      if (!called) return;

      filterErrorMessages(graphQLErrors).forEach((err) =>
        onError
          ? onError(err?.message ? err : { message: err }, form.setError)
          : form.setError("_global", err?.message ? err : { message: err })
      );
      filterBadUserInputErrors(graphQLErrors).forEach((err) =>
        onError
          ? onError(err?.message ? err : { message: err }, form.setError)
          : form.setError(err.path, err?.message ? err : { message: err })
      );
      if (result === false || error) {
        onError
          ? onError(error, form.setError)
          : form.setError(
              "_global",
              error?.message ||
                "Votre action n'a pas pu être réalisée. Veuillez réessayer plus tard."
            );
      }
      if (hasErrors(graphQLErrors) || error || !result) return;

      if (onSuccess) onSuccess(result);
    },
    [loading, result, graphQLErrors]
  );

  return [form.handleSubmit((data) => fct(transformData(data))), action[2]];
}
