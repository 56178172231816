import React from "react";
import styled from "styled-components";

export default styled(function HtmlText({ className, html }) {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
})`
  p + p,
  ul + p,
  p + ul,
  ul + ul {
    margin-top: 8px;
  }
  ul {
    list-style: initial;
    padding-left: 20px;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  u {
    text-decoration: underline;
  }
  pre {
    white-space: pre-wrap;
    font-family: monospace;
  }
`;
