export function formatAmount(amount, round = false) {
  return amount !== undefined
    ? Intl.NumberFormat("fr-FR", {
        ...(round
          ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
          : { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      }).format(amount)
    : undefined;
}

export function formatAmountForExcel(amount, round = false) {
  return amount !== undefined ? +parseFloat(amount).toFixed(round) : "";
}

export function formatRevenue(revenue, round = false, showSymbol = true) {
  const amount = round ? Math.round(revenue / 1000) : revenue / 1000;

  return revenue !== undefined
    ? Intl.NumberFormat("fr-FR").format(amount) + (showSymbol ? "K€" : "K")
    : undefined;
}

export function formatCurrency(amount, round = false) {
  return amount !== undefined
    ? Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        ...(round
          ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
          : { maximumFractionDigits: 2 }),
      }).format(amount)
    : undefined;
}
