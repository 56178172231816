import { useRef, useState } from "react";
import { Redirect, useParams } from "react-router-dom";

import ContestParticipationCreateForm from "../components/ContestParticipationCreateForm.jsx";
import {
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../components/forms.jsx";
import { usePagination } from "../components/Pagination.jsx";
import header from "../img/challenge.jpg";
import {
  useContest,
  useContestParticipationSubmit,
} from "../services/contests.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

const DEFAULT_LIMIT = 10;

function useContestParticipationForm(initialData = {}) {
  return useForm({
    mode: "onBlur",
    defaultValues: initialData,
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string(),
        description: Yup.string(),
      })
    ),
  });
}

export default function ContestDetails() {
  const { challenge: contestAlias } = useParams();

  const [hasSubmittedSuccess, setHasSubmittedSuccess] = useState(false);
  const topScroll = useRef();
  const pagination = usePagination(DEFAULT_LIMIT, topScroll.current);
  const [contest, { ready }] = useContest(contestAlias, pagination);

  const form = useContestParticipationForm();

  const contestParticipationSubmitAction =
    useContestParticipationSubmit(contest);
  const [, contestParticipationSubmitResult] = contestParticipationSubmitAction;

  const challengeTitle = contest ? contest.title : "Concours";

  const [onSubmit, { loading: submitLoading }] = useFormAction(
    form,
    contestParticipationSubmitAction,
    {
      transformData: (rawData) => {
        const {
          installationType,
          installationTypeOther,
          productType,
          city,
          ...data
        } = rawData;
        return {
          ...data,
          meta: [
            {
              reference: "installationType",
              label: "Type d'installation",
              value: installationType,
            },
            {
              reference: "installationTypeOther",
              label: "Autre type d'installation",
              value: installationTypeOther,
            },
            {
              reference: "productType",
              label: "Type de produit installé",
              value: productType,
            },
            {
              reference: "city",
              label: "Ville",
              value: city,
            },
          ],
        };
      },
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Votre participation n'a pas pu être envoyée. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess: () => {
        setHasSubmittedSuccess(true);
      },
    }
  );

  if (ready && (!contest || !contest.isPlayable))
    return <Redirect to={paths.challenges} />;

  return (
    <LoggedInTemplate title={challengeTitle}>
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline title={challengeTitle} />
        <Container>
          {contest?.multiple || !contest?.attempted ? (
            <ContestParticipationCreateForm
              contest={contest}
              onSubmit={onSubmit}
              loading={!ready || submitLoading}
              form={form}
              success={hasSubmittedSuccess}
              detailsUrl={paths.challenges + "/contest/" + contestAlias}
            />
          ) : (
            <div>Vous avez déjà participé</div>
          )}
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
