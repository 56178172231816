import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { color, context, font, ifelse, styled, theme } from "../util/style.js";
import Icon from "./Icon.jsx";
import Link from "./Link.jsx";

const PanelTab = styled(function({ icon, title, to, className }) {
  const routeMatch = useRouteMatch(to);
  const active = to && routeMatch?.isExact;
  return (
    <Link
      to={to}
      className={className + (active ? " PanelTab--active" : "")}
      alt={title}
    >
      <div className="PanelTab-icon-border">
        <Icon size={26} name={icon} variant="white" hoverVariant="black" />
      </div>
      <span className="PanelTab-title">{title}</span>
    </Link>
  );
})`
  ${ifelse("even")`
    ${color("gradient-blue-tab-reversed")};
  ``
    ${color("gradient-blue-tab")};
  `}

  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: all 0.3s ease;
  padding: 24px 16px;
  position: relative;
  text-decoration: none;
  ${Icon} {
    display: block;
  }
  &::before {
    background-color: white;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s ease;
    width: 100%;
  }
  .PanelTab {
    &-title,
    &-icon-border {
      position: relative;
      z-index: 9;
    }
    &-title {
      ${font("headline5")};
      color: white;
      margin-left: 8px;
    }
    &-icon-border {
      border: 2px solid white;
      border-radius: 50px;
      padding: 4px;
    }
  }

  &:hover,
  &.PanelTab--active {
    --icon-hover: 1;
    &::before {
      opacity: 1;
      transition: all 0.3s ease;
    }
    .PanelTab-title {
      color: ${color("primary")};
    }
    .PanelTab-icon-border {
      border-color: ${color("icon-black")};
    }
  }

  ${context("for-desktop")`
    padding: 16px 8px;
    .PanelTab {
      &-title {
        ${font("paragraph")}
        color: white;
        line-height: 1.4;
        font-weight: ${theme("font-weight-bold")};
      }
    }
  `}

  ${context("for-tablet-landscape")`
    padding: 12px;
    .PanelTab {
      &-title {
        display: none;
      }
    }
  `}
  ${context("for-phone")`
    padding: 8px;
  `}
`;

export const PanelAction = styled.div`
  border-top: 1px solid ${color("border")};
  margin-top: 56px;
  padding-top: 56px;
`;

export default styled(function Panel({ pages = [], className }) {
  let { path, url } = useRouteMatch();

  return (
    <div className={className}>
      <ul className="Panel-header">
        {pages.map((page, index) => (
          <li className="Panel-header-item" key={"tab-" + page.id}>
            <PanelTab
              to={`${url}/${page.id}`}
              title={page.title}
              icon={page.icon}
              even={index % 2 === 0}
            />
          </li>
        ))}
      </ul>
      <div className="Panel-content">
        <Switch>
          {pages.map((page) => (
            <Route
              key={"page-" + page.id}
              path={`${path}/${page.id}`}
              component={page.component}
            />
          ))}
          {Boolean(pages?.length) && (
            <Route path={`${path}/`}>
              <Redirect to={`${url}/${pages[0].id}`} />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
})`
  ${theme("big-border")}
  ${theme("box-shadow")}
  background-color: white;
  border-radius: ${theme("border-radius")};
  overflow: hidden;
  .Panel {
    &-header {
      display: flex;
      list-style-type: none;
      &-item {
        flex: 1;
      }
    }
    &-content {
      padding: 72px;
      &-intro {
        margin-bottom: 80px;
      }
    }
  }

  ${context("for-tablet-landscape")`
    .Panel {
      &-content {
        padding: 56px;
        &-intro {
          margin-bottom: 40px;
        }
      }
    }
  `}

  ${context("for-phone")`
    .Panel {
      &-content {
        padding: 32px 16px;
      }
    }
  `}
`;
