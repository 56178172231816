import React from "react";
import styled from "styled-components";

import { color, context, font } from "../util/style.js";

export default styled.p`
  color: ${color("grey-text")};
  font-family: ${font("primary")};
  font-size: 14px;
  line-height: 20px;
  padding-top: 16px;
`;
