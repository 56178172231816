import _keyBy from "lodash/keyBy.js";
import { useEffect, useState } from "react";

import { format as formatDate } from "../../../common/date.js";
import { roles } from "../../../common/labels.js";
import { paths } from "../../services/router.js";
import { useSelf, useValidateUsage } from "../../services/self.js";
import { color, font, is, styled, theme } from "../../util/style.js";
import ConfirmationPopup from "../ConfirmationPopup.jsx";
import {
  Form,
  FormCheckbox,
  FormField,
  FormInput,
  Yup,
  useForm,
  useFormAction,
  yupResolver,
} from "../forms.jsx";
import Link from "../Link.jsx";
import { ButtonArrowAround } from "../typography.jsx";
import {
  Data,
  Group,
  Primary,
  Section,
  Subtitle,
  Title,
  TitleHelp,
} from "./Components.jsx";

const rolesByKey = _keyBy(roles, "value");

export default function WidgetAccount({ close, onUpdateSelfData }) {
  const [self] = useSelf();

  return (
    <>
      <Section>
        <Title>Mon compte société</Title>
        <Subtitle>
          {Boolean(self?.company) && (
            <>
              <Primary>
                {[
                  self?.company?.name || "Inconnue",
                  rolesByKey[self?.company?.category]?.label,
                ]
                  .filter(Boolean)
                  .join(" - ")}
              </Primary>
            </>
          )}
        </Subtitle>
        <AddressesForms self={self} />
      </Section>

      <Section>
        <Title>Mon compte utilisateur</Title>
        <Group label={`${self?.firstname || ""} ${self?.lastname || ""}`} big>
          <Data label="E-mail : ">
            {self?.email ? (
              <a href={"mailto:" + self.email}>{self?.email}</a>
            ) : (
              "-"
            )}
          </Data>
          <Data label="Téléphone : ">
            {self?.phone ? (
              <a href={"tel:" + self.phone}>{self?.phone}</a>
            ) : (
              "-"
            )}
          </Data>
          <Data label="Informations RGPD : " block>
            {[
              [
                "Date de naissance",
                self?.birthDate
                  ? formatDate(self?.birthDate, "dd MMMM yyyy")
                  : "",
              ],
              ["Ville de naissance", self?.cityOfBirth],
              ["Nationalité", self?.nationality],
              ["N° de Sécurité Sociale", self?.socialNumber],
            ]
              .filter(([, value]) => !!value)
              .map(([label, value]) => (
                <div key={label}>
                  {label} : {value}
                </div>
              )) || <span>-</span>}
            <div>
              <Link onClick={onUpdateSelfData}>Modifier mes informations</Link>
            </div>
          </Data>
        </Group>
        <AccountForm self={self} />
      </Section>

      <hr />

      <Section>
        <TitleHelp>
          Vous souhaitez modifier ou supprimer votre compte ?
        </TitleHelp>
        <p>
          Effectuez votre demande en cliquant sur le bouton ci-dessous. L’équipe
          Daikin se charge de traiter votre demande dans les meilleurs délais.
        </p>
        <center>
          <ButtonArrowAround to={paths.contact} onClick={close}>
            J'envoie ma demande
          </ButtonArrowAround>
        </center>
      </Section>
    </>
  );
}

function useAccountForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(Yup.object().shape({})),
  });
}
function AccountForm({ self }) {
  const [success, setSuccess] = useState(false);

  const form = useAccountForm();
  const {
    register,
    reset,
    formState: { errors },
  } = form;

  const validateAction = useValidateUsage();

  const [onValidate, { loading: loadingValidate }] = useFormAction(
    form,
    validateAction,
    {
      transformData: (data) => ({ optIn: data.optIn }),
      onError: (err, setError) => {
        setError(
          err?.path || "_global",
          err || {
            message:
              "Vos préférences n'ont pas pu être enregistrées. Veuillez réessayer plus tard.",
          }
        );
      },
      onSuccess() {
        setSuccess(true);
      },
    }
  );

  useEffect(() => {
    reset({ optIn: self?.optIn?.validated });
  }, [self?.optIn, reset]);

  return (
    <>
      <ConfirmationPopup
        title="Préférences"
        open={Boolean(success)}
        onClose={() => setSuccess(false)}
      >
        Vos préférences ont bien été mises à jour.
      </ConfirmationPopup>
      <UserForm onSubmit={onValidate} loading={loadingValidate} errors={errors}>
        <Group>
          <FormField errors={errors} name="optIn">
            {({ name, ...childProps }) => (
              <FormCheckbox {...register(name)} {...childProps}>
                Je souhaite recevoir les communications liées au programme
                DAIKIN ELITE
              </FormCheckbox>
            )}
          </FormField>
        </Group>
        <FormField>
          <ButtonArrowAround type="submit">J'enregistre</ButtonArrowAround>
        </FormField>
      </UserForm>
    </>
  );
}

function useAddressForm() {
  return useForm({
    mode: "onTouched",
    resolver: yupResolver(Yup.object().shape({})),
  });
}

function AddressesForms({ self }) {
  return (
    <>
      <Group label="Adresse de facturation">
        <AddressForm address={self?.company?.billingAddresses?.[0]} />
      </Group>
      <Group label="Adresse de livraison">
        <AddressForm address={self?.company?.shippingAddresses?.[0]} />
      </Group>
    </>
  );
}

function AddressForm({ address, loading, onSubmit }) {
  const [editing, setEditing] = useState(false);

  const form = useAddressForm();

  const {
    register,
    reset,
    formState: { errors },
  } = form;

  useEffect(() => {
    reset({ ...address });
  }, [address, reset]);

  return editing ? (
    <Form onSubmit={onSubmit} loading={loading} errors={errors}>
      <FormField inline label="Rue : " name="street">
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <FormField inline label="Code postal : " name="postalCode">
        {({ name, ...childProps }) => (
          <FormInput
            {...register(name)}
            {...childProps}
            style={{ width: 100 }}
          />
        )}
      </FormField>
      <FormField inline label="Ville : " name="city">
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <p style={{ textAlign: "right" }}>
        <TextButton type="submit">Sauvegarder l'adresse</TextButton>
        <TextButton type="button" secondary onClick={() => setEditing(false)}>
          Annuler
        </TextButton>
      </p>
    </Form>
  ) : (
    <>
      {Boolean(address?.street || address?.city) ? (
        <p>
          {address?.street || ""}
          <br />
          {address?.postalCode || ""} {address?.city || ""}
        </p>
      ) : (
        <p>Aucune adresse enregistrée</p>
      )}
      <p>
        {/* Désactivé pour le moment, service GQL à faire
        <TextButton onClick={() => setEditing(true)}>
          Modifier l'adresse
        </TextButton>
        */}
      </p>
    </>
  );
}

const UserForm = styled(Form)`
  margin-top: 24px;
`;

const TextButton = styled.button`
  ${font("small")}
  color: ${color("text-dark")};
  font-weight: ${theme("font-weight-semi")};
  text-decoration: underline;
  transition: color 0.3s ease;

  ${is("secondary")`
    font-weight: ${theme("font-weight-regular")};
  `}

  &:hover {
    color: ${color("primary")};
    text-decoration: none;
  }

  & + & {
    margin-left: 16px;
  }
`;
