import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import _mapValues from "lodash/mapValues";
import _omit from "lodash/omit";
import _toPairs from "lodash/toPairs";
import { useMemo } from "react";

import { Yup } from "../components/forms.jsx";
import { paginatedGql, toStandardResult } from "../util/graphql.js";
import { challengeSchema } from "./challenges.js";
import useLocalStorage from "./useLocaleStorage.js";

const pollSchema = `
  ${challengeSchema}
  introduction
  image {
    id
    name
    version
  }
  document {
    id
    name
    version
  }
`;

export function usePolls(pagination) {
  const result = useQuery(
    gql`
      query GET_POLLS($pagination: PaginationInput) {
        private {
          challenges {
            polls: findPolls(
              pagination: $pagination
            ) {
              ${paginatedGql(pollSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      variables: {
        pagination,
      },
    }
  );

  return toStandardResult(result, "private.challenges.polls");
}

export function usePoll(pollAlias) {
  const result = useQuery(
    gql`
      query GET_POLL($alias: ID!) {
        private {
          challenges {
            poll: fetchPollByAlias(alias: $alias) {
              ${pollSchema}
              conclusion
              questions {
                id
                kind
                reference
                optional
                text
                image {
                  id
                  name
                  version
                }
                conditions {
                  kind
                  questionReference
                  answerReference
                }
                answers {
                  id
                  reference
                  text
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        alias: pollAlias,
      },
    }
  );

  return toStandardResult(result, "private.challenges.poll");
}

export function usePollSubmit(poll) {
  const [mutation, result] = useMutation(
    gql`
      mutation ($id: ID!, $answers: [PollAnswerPrivateInput]!) {
        private {
          challenges {
            submitPollAnswers(id: $id, answers: $answers) {
              id
              bonus
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  return [
    (answers) =>
      mutation({
        variables: {
          id: poll.id,
          answers: Object.keys(answers).map((question) => ({
            ...answers[question],
            question,
          })),
        },
      }),
    ...toStandardResult(result, "private.challenges.submitPollAnswers"),
  ];
}

const INTRODUCTION_INDEX = -1;

function getSchemaValidatorForQuestion(questions, answers) {
  const questionsEnabled = questions
    .map((question, index) => ({ ...question, index }))
    .filter(questionMeetsConditions(questions, answers));
  return Yup.object(
    questionsEnabled.reduce((all, { id, kind, optional, index }) => {
      let validator = null;
      const required = optional ? "optional" : "required";
      const requiredError = `La question ${
        index + 1
      } n'a pas de réponse valide`;
      switch (kind) {
        case "radio":
        case "list":
        case "rating":
        case "satisfaction":
        case "yesno":
          validator = Yup.object({
            option: Yup.string().nullable()[required](requiredError),
          });
          break;
        case "checkbox":
          validator = Yup.object({
            options: Yup.array(Yup.string())
              [required](requiredError)
              .min(
                optional ? 0 : 1,
                `La question ${index + 1} doit avoir au moins une réponse`
              ),
          });
          break;
        case "percentage":
          validator = Yup.object({
            optionsValued: Yup.array(
              Yup.object({
                option: Yup.string()[required](requiredError),
                value: Yup.number(),
              })
            )
              [required]()
              .test(
                "sum-100-percent",
                `La question ${index + 1} doit avoir une somme à 100%`,
                (value) => {
                  return (
                    optional || // pas obligatoire
                    (value || []).reduce(
                      (total, { value }) => total + value,
                      0
                    ) === 1
                  );
                }
              )
              .min(
                optional ? 0 : 1,
                `La question ${index + 1} doit avoir au moins une réponse`
              ),
          });
          break;
        case "file":
          validator = Yup.object({
            file: Yup.mixed()[required](requiredError),
          });
          break;
        case "number":
          validator = Yup.object({
            valueAsNumber: Yup.number()[required](requiredError),
          });
          break;
        case "text":
        default:
          validator = Yup.object({
            value: Yup.string().nullable()[required](requiredError),
          });
          break;
      }
      return {
        ...all,
        [id]: validator[required](requiredError),
      };
    }, {})
  );
}

function atLeastOneValue(answer) {
  return (
    Boolean(answer) &&
    typeof answer === "object" &&
    (answer?.value?.trim() ||
      answer?.valueAsNumber === 0 ||
      answer?.valueAsNumber ||
      answer?.options?.length ||
      answer?.option ||
      answer?.text?.trim() ||
      answer?.file ||
      (answer?.optionsValued || []).reduce(
        (total, { value }) => total + (value || 0),
        0
      ) === 1)
  );
}

function questionMeetsConditions(questions, answers) {
  const checkCondition = function ({
    answerReference: answerReferenceRaw,
    questionReference,
    kind,
  }) {
    const question = questions.find(
      ({ reference }) => reference === questionReference
    );
    const answerRaw = answers[question.id];
    const value = getAnswerValue(answerRaw, question);

    if (!question) return false;

    // Si optionnel et pas de réponse, la condition est ok
    if (kind === "neq" && question.optional && !atLeastOneValue(answerRaw))
      return true;

    if (kind === "has") return atLeastOneValue(answerRaw);
    if (kind === "nhas") return !atLeastOneValue(answerRaw);

    // On vérifie si au moins une référence satisfait la condition (OR)
    const answerReferences = answerReferenceRaw
      ? answerReferenceRaw.split(";")
      : [];
    return answerReferences.some((answerReference) => {
      // On récupère toutes les réponses à la question qui correspondent aux critères donnés
      const answersToMatchCondition = question.answers.filter(
        ({ reference }) => {
          switch (kind) {
            case "lt":
              return +reference < +answerReference;
            case "lte":
              return +reference <= +answerReference;
            case "gt":
              return +reference > +answerReference;
            case "gte":
              return +reference >= +answerReference;
            case "neq":
              return reference !== answerReference;
            default:
            case "eq":
              return reference === answerReference;
          }
        }
      );

      // Si aucune réponse ne correspond
      if (!answersToMatchCondition.length) return false;

      return answersToMatchCondition.some(
        ({ reference }) => value === reference
      );
    });
  };

  return function (question) {
    if (!question?.conditions?.length) return true;
    // On check si toutes les conditions sont satisfaites (AND)
    return question.conditions.every(checkCondition);
  };
}

function getAnswerValue(answerRaw, question) {
  let value = null;
  if (answerRaw && question) {
    switch (question.kind) {
      case "radio":
      case "list":
      case "rating":
      case "satisfaction":
      case "yesno":
        value = answerRaw.option;
        break;
      case "checkbox":
        value = answerRaw.options;
        break;
      case "percentage":
        value = answerRaw.optionsValued;
        break;
      case "file":
        value = answerRaw.file;
        break;
      case "number":
        value = answerRaw.valueAsNumber;
        break;
      case "text":
      default:
        value = answerRaw.value;
        break;
    }
  }
  return value;
}

function generateAnswerFromValue(value, question) {
  let answer = null;
  switch (question.kind) {
    case "radio":
    case "list":
    case "rating":
    case "satisfaction":
    case "yesno":
      answer = { option: value };
      break;
    case "checkbox":
      answer = { options: value };
      break;
    case "percentage":
      answer = { optionsValued: value };
      break;
    case "file":
      answer = { file: value };
      break;
    case "number":
      answer = { valueAsNumber: Number(value) };
      break;
    case "text":
    default:
      answer = { value };
      break;
  }
  return answer;
}

function transformNavigationStateBeforeStoringValue({
  answers = {},
  ...values
} = {}) {
  return {
    ...values,
    answers: _mapValues(answers, (value) => _omit(value, ["file"])),
  };
}

export function usePollNavigation(poll) {
  const [{ currentIndex, answers }, setNavigationState, removeNavigationState] =
    useLocalStorage(
      `poll-answers-${poll.id}`,
      {
        currentIndex: INTRODUCTION_INDEX,
        answers: {
          // [question.id]: { option: xxx } | { options: [xxx, yyy, zzz] } | { text: xxx } | {...}
        },
      },
      transformNavigationStateBeforeStoringValue
    );

  const questions = poll?.questions || [];
  const currentQuestion =
    currentIndex !== INTRODUCTION_INDEX ? questions[currentIndex] : null;
  const currentAnswer = currentQuestion
    ? getAnswerValue(answers[currentQuestion.id] || {}, currentQuestion)
    : null;
  const [
    questionsEnabled,
    nextIndex,
    previousIndex,
    errors,
    validAnswers,
    errorsSoFar,
    validAnswersSoFar,
  ] = useMemo(
    function () {
      const questionsEnabled = questions.map(
        questionMeetsConditions(questions, answers)
      );

      const firstIndexEnabledAfterCurrent = questionsEnabled
        .slice(currentIndex + 1)
        .findIndex(Boolean);
      const nextIndex =
        firstIndexEnabledAfterCurrent >= 0
          ? firstIndexEnabledAfterCurrent + currentIndex + 1
          : -1; // on rajoute l'index de la question courante car on a pris le tableau slicé

      const firstQuestionsEnabled = questionsEnabled.slice(0, currentIndex);
      firstQuestionsEnabled.reverse();
      const firstIndexEnabledBeforeCurrent =
        firstQuestionsEnabled.findIndex(Boolean);
      const previousIndex =
        firstIndexEnabledBeforeCurrent >= 0
          ? currentIndex - firstIndexEnabledBeforeCurrent - 1
          : -1; // on enlève car le tableau est à l'envers

      const schemaValidator = getSchemaValidatorForQuestion(questions, answers);
      let errors = null,
        validAnswers = null;
      try {
        validAnswers = schemaValidator.validateSync(answers, {
          abortEarly: false,
          stripUnknown: true,
        });
      } catch (err) {
        errors = err;
      }

      const schemaValidatorSoFar = getSchemaValidatorForQuestion(
        questions.slice(0, currentIndex + 1),
        answers
      );
      let errorsSoFar = null,
        validAnswersSoFar = null;
      try {
        validAnswersSoFar = schemaValidatorSoFar.validateSync(answers, {
          abortEarly: false,
          stripUnknown: true,
        });
      } catch (err) {
        errorsSoFar = err;
      }

      return [
        questionsEnabled,
        nextIndex,
        previousIndex,
        errors,
        validAnswers,
        errorsSoFar,
        validAnswersSoFar,
      ];
    },
    [questions, answers, currentAnswer, currentIndex]
  );
  const nextQuestion = nextIndex >= 0 ? questions[nextIndex] : null;
  const previousQuestion = previousIndex >= 0 ? questions[previousIndex] : null;
  const canGoNext =
    Boolean(nextQuestion) && Boolean(validAnswersSoFar) && !errorsSoFar;
  const canSend = Boolean(validAnswers) && !errors;

  return [
    {
      errorsSoFar,
      validAnswersSoFar,
      errors,
      canGoNext,
      canSend,
      currentIndex,
      currentQuestion,
      currentAnswer,
      nextIndex,
      nextQuestion,
      previousIndex,
      previousQuestion,
      totalQuestions: questions.length,
      questionsEnabled,
      answers: validAnswers,
    },
    useMemo(
      function () {
        return {
          setAnswer(value) {
            const answer = generateAnswerFromValue(value, currentQuestion);
            setNavigationState((state) => ({
              ...state,
              answers: { ...state.answers, [currentQuestion.id]: answer },
            }));
          },
          start() {
            setNavigationState((state) => ({
              ...state,
              currentIndex: 0,
            }));
          },
          next() {
            setNavigationState((state) => ({
              ...state,
              currentIndex: nextIndex,
            }));
          },
          previous() {
            setNavigationState((state) => ({
              ...state,
              currentIndex: previousIndex,
            }));
          },
          restart() {
            setNavigationState({
              currentIndex: INTRODUCTION_INDEX,
              answers: {},
            });
          },
          close() {
            removeNavigationState();
          },
        };
      },
      [
        currentQuestion?.kind,
        currentQuestion?.id,
        setNavigationState,
        removeNavigationState,
        nextIndex,
        previousIndex,
      ]
    ),
  ];

  //

  //

  //

  // const [
  //   { question: currentQuestion, number, showStart, showEnd },
  //   setQuizState,
  // ] = useState({
  //   question: initialQuestion,
  //   number: initialNumber,
  //   showStart: initialShowStart,
  //   showEnd: initialShowEnd,
  // });
  // const [answers, setAnswers] = useState([]);

  // const next = useCallback(
  //   function () {
  //     setQuizState((state) => {
  //       const nextState = { showStart: false };
  //       const nextNumber = number + 1;

  //       if (nextNumber <= questions.length) {
  //         nextState.number = nextNumber;
  //         nextState.question = questions[nextNumber - 1];
  //       } else {
  //         nextState.number = 0;
  //         nextState.question = null;
  //         nextState.showEnd = true;
  //       }

  //       return {
  //         ...state,
  //         ...nextState,
  //       };
  //     });
  //   },
  //   [questions, number]
  // );

  // const previous = useCallback(
  //   function () {
  //     setQuizState((state) => {
  //       const nextState = { showEnd: false };
  //       const nextNumber = number - 1;

  //       if (number - 1 > 0) {
  //         nextState.number = nextNumber;
  //         nextState.question = questions[nextNumber - 1];
  //       } else {
  //         nextState.number = 0;
  //         nextState.question = null;
  //         nextState.showStart = true;
  //       }

  //       return {
  //         ...state,
  //         ...nextState,
  //       };
  //     });
  //   },
  //   [questions, number]
  // );

  // function restart() {
  //   setQuizState({
  //     question: initialQuestion,
  //     number: initialNumber,
  //     showStart: initialShowStart,
  //     showEnd: initialShowEnd,
  //   });
  //   setAnswers([]);
  // }

  // const hasAnswer = useCallback(
  //   function (answer, question = currentQuestion.id) {
  //     return answers.some(
  //       ({ answer: existingAnswer, question: existingQuestion }) =>
  //         existingAnswer === answer && existingQuestion === question
  //     );
  //   },
  //   [answers, currentQuestion]
  // );

  // const atLeastOneAnswer = useCallback(
  //   function (question = currentQuestion.id) {
  //     return answers.some(
  //       ({ question: existingQuestion }) => existingQuestion === question
  //     );
  //   },
  //   [answers, currentQuestion]
  // );

  // function addAnswer(answer, question = currentQuestion.id) {
  //   setAnswers((answers) => [...answers, { answer, question }]);
  // }

  // function removeAnswer(answer, question = currentQuestion.id) {
  //   setAnswers((answers) => [
  //     ...answers.filter(
  //       ({ answer: existingAnswer, question: existingQuestion }) =>
  //         existingAnswer !== answer || existingQuestion !== question
  //     ),
  //   ]);
  // }

  // return {
  //   number,
  //   question: currentQuestion,
  //   isLastQuestion: quiz && questions && number === questions.length,
  //   showStart,
  //   showEnd,

  //   next,
  //   previous,
  //   restart,

  //   answers,
  //   atLeastOneAnswer,
  //   hasAnswer,
  //   addAnswer,
  //   removeAnswer,
  // };
}
