import { useEffect, useMemo, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";

import ContentLoading from "../components/ContentLoading.jsx";
import ContestParticipation from "../components/ContestParticipation.jsx";
import { usePagination } from "../components/Pagination.jsx";
import header from "../img/challenge.jpg";
import {
  useContest,
  useContestParticipationLike,
  useContestParticipationUnlike,
} from "../services/contests.js";
import { paths } from "../services/router.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { DEFAULT_LIMIT } from "./ContestDetails.jsx";

export default function ContestParticipationsDetails() {
  const { challenge: contestAlias, index: participationIndex } = useParams();

  const pagination = usePagination(1);
  const [contest, { ready, refetch: reload }] = useContest(contestAlias, {
    ...pagination,
    after: participationIndex,
  });

  const [doLike, resultLike, { loading: likeLoading }] =
    useContestParticipationLike(contest);
  const [doUnlike, resultUnlike, { loading: unlikeLoading }] =
    useContestParticipationUnlike(contest);

  const contestParticipation = useMemo(
    () =>
      contest?.participations?.edges
        ? contest?.participations?.edges[0]?.node
        : null,
    [contest]
  );

  // Rafraîchit la participation après like/unlike
  useEffect(
    function () {
      if (likeLoading || unlikeLoading) return;
      reload();
    },
    [likeLoading, unlikeLoading, resultLike, resultUnlike, reload]
  );

  if (ready && (!contest || !contest.isPlayable))
    return <Redirect to={paths.challenges} />;

  return (
    <LoggedInTemplate title={contest?.title || "Concours"}>
      <ImageHeader image={header} icon="trophy-line" label="Challenges" />
      <MainContent>
        <Headline
          title={
            contestParticipation?.company?.name ||
            contestParticipation?.title ||
            "Sans titre"
          }
        >
          {contestParticipation?.meta
            ?.filter(
              ({ value, reference }) =>
                reference !== "installationType" || value !== "Autre"
            )
            .map(({ value }) => value)
            .filter(Boolean)
            .join(" - ")}
        </Headline>
        <Container>
          {contestParticipation ? (
            <ContestParticipation
              {...contestParticipation}
              onLike={() => doLike(contestParticipation.id)}
              onUnlike={() => doUnlike(contestParticipation.id)}
              backUrl={
                paths.challenges +
                "/contest/" +
                contestAlias +
                "?p=" +
                (1 + Math.floor(participationIndex / DEFAULT_LIMIT))
              }
            />
          ) : (
            <ContentLoading />
          )}
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
