import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";
import { useSessionData } from "./auth.js";

const opalLogin = `opalLogin { username password }`;
const budgetGql = `
  totalCredits {
    amount
    expiresAt
  }
  totalExpenses {
    amount
    expiresAt
  }
  totalBalance {
    amount
    expiresAt
  }
  totalReserved {
    amount
    expiresAt
    lastUpdatedAt
  }
`;

export function useTrainingSelfData() {
  const sessionData = useSessionData();

  const result = useQuery(
    gql`
      query {
        private {
          self {
            company {
              id
              name
              ${opalLogin}
              sites {
                id
                name
                ${opalLogin}
              }
              budgets {
                formations {
                  ${budgetGql}
                }
              }
            }
          }
        }
      }
    `,
    {
      skip: !sessionData,
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.self");
}
