import { paths } from "../services/router.js";
import { font, styled, theme } from "../util/style.js";
import {
  Form,
  FormField,
  FormInput,
  FormRadio,
  Yup,
  useForm,
  yupResolver,
} from "./forms.jsx";
import Icon from "./Icon.jsx";
import { Col, Row } from "./layout.jsx";
import { Button } from "./typography.jsx";

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
  hr {
    margin: 32px 0;
  }
`;

const PanelTitle = styled.div`
  ${font("headline5")}
  text-align: center;
`;

const PanelSubtitle = styled.div`
  ${font("paragraph")}
  text-align: center;
`;

const Buttons = styled.div`
  padding: 0 ${theme("padding-container")};
  display: flex;
  justify-content: space-between;
`;

export function useParticipationForm(initialData) {
  return useForm({
    mode: "onBlur",
    defaultValues: initialData? {
      pieceKind: initialData.piece?.kind,
      productReference: initialData.product?.reference,
      productSerial: initialData.product?.serial,
      pieceReference: initialData.piece?.reference,
      reference: initialData.reference,
      invoicePieceReference: initialData.invoicePieceReference,
    }: {},
    resolver: yupResolver(
      Yup.object().shape({
        pieceKind: Yup.string()
          .nullable()
          .oneOf(["refrigerating", "nonRefrigerating"], "Type de pièce requis")
          .required("Type de pièce requis"),
        productReference: Yup.string()
          .nullable()
          .required("Référence requise"),
        productSerial: Yup.string()
          .nullable()
          .required("Numéro de série requis"),
        pieceReference: Yup.string()
          .nullable()
          .required("Référence requise"),
        reference: Yup.string()
          .nullable()
          .required("Référence interne ou un nom de client requis"),
        invoicePieceReference: Yup.string()
          .nullable()
          .required("Numéro de facture ou bon de livraison requis"),
      })
    ),
  });
}

export default function ParticipationRequestForm({successMessage, form, ...props}) {

  return (
    <Form {...props}>
      <Panel>
        {successMessage ||
          <CheckingContent />
        }
      </Panel>
      {!successMessage && (
        <Panel>
          <FormContent form={form} />
        </Panel>
      )}
      <Buttons>
        {!successMessage && (
          <>
            <Button type="button" ghost to={paths.participation}>
              Annuler
            </Button>
            <Button type="submit">J'envoie ma demande</Button>
          </>
        )}
      </Buttons>
    </Form>
  );
}

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  margin: 6px 0px;
  display: flex;
  align-items: first baseline;
  ${Icon} {
    margin-right: 8px;
  }
`;

const VerifContent = styled.span`
  flex: 1;
`;

function CheckingContent() {
  return (
    <>
      <PanelTitle>
        Pour bénéficier de votre forfait main d’œuvre ELITE, vérifiez que votre
        demande est éligible
      </PanelTitle>
      <hr />
      <List>
        <ListItem>
          <Icon name="check" variant="blue" size={16} />
          <VerifContent>
            Le technicien ayant réalisé l’intervention{" "}
            <strong>a suivi une formation relative aux produits</strong> Daikin
            concernés par la demande
          </VerifContent>
        </ListItem>
        <ListItem>
          <Icon name="check" variant="blue" size={16} />
          <VerifContent>
            Vous n’avez pas{" "}
            <strong>
              déjà bénéficié de votre participation sur ce même produit
            </strong>{" "}
            (la participation n’est valable qu’une fois sur un même produit et
            n’est pas cumulable sur 2 pièces)
          </VerifContent>
        </ListItem>
        <ListItem>
          <Icon name="check" variant="blue" size={16} />
          <VerifContent>
            <strong>Votre demande est adressée dans les 3 mois maximum</strong>{" "}
            qui suivent la commande de la pièce pour garantie
          </VerifContent>
        </ListItem>
        <ListItem>
          <Icon name="check" variant="blue" size={16} />
          <VerifContent>
            Vous n’avez pas bénéficié d’une{" "}
            <strong>mise en service Daikin</strong> sur ce produit
          </VerifContent>
        </ListItem>
      </List>
    </>
  );
}

function FormContent({ form }) {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <>
      <PanelTitle>Produit & pièce concernés par votre demande</PanelTitle>
      <PanelSubtitle>Tous les champs sont obligatoires.</PanelSubtitle>
      <hr />
      <FormField
        label="Type de pièce"
        layout="row"
        name="pieceKind"
        errors={errors}
      >
        {({ name, ...childProps }) => (
          <>
            <FormRadio
              {...register(name)}
              value="refrigerating"
              {...childProps}
            >
              Pièce frigorifique
            </FormRadio>
            <FormRadio
              {...register(name)}
              value="nonRefrigerating"
              {...childProps}
            >
              Autre pièce
            </FormRadio>
          </>
        )}
      </FormField>
      <FormField
        label="Référence interne / nom du client"
        name="reference"
        errors={errors}
        info="Retrouvez plus facilement votre demande avec cette référence"
      >
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <FormField
        label="Numéro de facture ou bon de livraison de la pièce détachée"
        name="invoicePieceReference"
        errors={errors}
      >
        {({ name, ...childProps }) => (
          <FormInput {...register(name)} {...childProps} />
        )}
      </FormField>
      <Row>
        <Col span={6}>
          <FormField
            label="Référence produit"
            name="productReference"
            errors={errors}
          >
            {({ name, ...childProps }) => (
              <FormInput {...register(name)} {...childProps} />
            )}
          </FormField>
        </Col>
        <Col span={6}>
          <FormField
            label="Numéro de série du produit"
            name="productSerial"
            errors={errors}
          >
            {({ name, ...childProps }) => (
              <FormInput {...register(name)} {...childProps} />
            )}
          </FormField>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <FormField
            label="Référence de pièce remplacée"
            name="pieceReference"
            errors={errors}
            info="Le numéro est sur l'étiquette"
          >
            {({ name, ...childProps }) => (
              <FormInput {...register(name)} {...childProps} />
            )}
          </FormField>
        </Col>
        <Col span={6}></Col>
      </Row>
    </>
  );
}
