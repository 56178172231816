import sanitizeHtml from "sanitize-html";

import { format as formatDate } from "../../common/date.js";
import heartOutline from "../icons/heart-outline.svg";
import heartFilled from "../icons/heart.svg";
import { getMediaUrl } from "../util/medias.js";
import { color, context, font, is, styled, theme } from "../util/style.js";
import HtmlText from "./HtmlText.jsx";
import { Button } from "./typography.jsx";

export function html(text, options = {}) {
  return sanitizeHtml(nl2br(text) || "", {
    allowedTags: ['br'],
    allowedAttributes: {},
    ...options,
  });
}
function nl2br(txt) {
  return (txt || "").replace(/\n/g, "<br />");
}

const ContestParticipationImage = styled.div`
  ${theme("content-box")}
  border-bottom: 0;
  padding: 25px;
  width: 530px;
  img {
    border-radius: ${theme("border-radius")};
    display: block;
    width: 100%;
    height: auto;
  }

  ${context("for-tablet-landscape")`
    width: 320px;
  `}

  ${context("for-tablet-portrait")`
    width: auto;
  `}
`;

const ContestParticipationContent = styled.div`
  flex: 1;
`;

const ContestParticipationLikes = styled.div`
  border-bottom: 1px solid ${color("grey-text")};
  line-height: 36px;
  text-align: right;
  img {
    vertical-align: middle;
  }
`;

const ContestParticipationLikeButton = styled.button`
  ${font("strong")}
  color: ${color("primary")};
  font-size: 2rem;
  ${is("active")`
    color: ${color("secondary")};
  `}
`;

const ContestParticipationDate = styled.p`
  color: ${color("grey-text")};
  margin: 0;
  padding: 0;
`;

const ContestParticipationTitle = styled.div`
  ${font("strong")}
  color: ${color("text")};
`;

const ContestParticipationDescription = styled.div`
  ${font("paragraph")}
`;

const ContestParticipationActions = styled.div`
  margin-top: 32px;
`;

export default styled(function ContestParticipation ({
  title,
  description,
  date,
  likesCount,
  file,
  userHasLiked,
  company,
  meta,
  onLike,
  onUnlike,
  backUrl,
  className,
}) {
  return (
    <div className={className}>
      <ContestParticipationImage>
        {Boolean(file) && (
          <img
            src={getMediaUrl(file, "contest-participation-image")}
            alt={description || title || "Aucune description"}
          />
        )}
      </ContestParticipationImage>
      <ContestParticipationContent>
        <ContestParticipationLikes>
          <ContestParticipationLikeButton
            active={userHasLiked}
            onClick={userHasLiked ? onUnlike : onLike}
          >
            {likesCount}{" "}
            <img
              src={userHasLiked ? heartFilled : heartOutline}
              width="24"
              height="24"
              alt={userHasLiked ? "Je n'aime plus" : "J'aime"}
            />
          </ContestParticipationLikeButton>
        </ContestParticipationLikes>
        <ContestParticipationDate>
          {formatDate(date, "dd MMMM yyyy")}
        </ContestParticipationDate>
        {Boolean(title) && <ContestParticipationTitle>{title}</ContestParticipationTitle>}
        {Boolean(description) && <ContestParticipationDescription>
          <HtmlText html={html(description)} />
        </ContestParticipationDescription>}
        <ContestParticipationActions>
          <Button to={backUrl}>Revenir à la liste</Button>
        </ContestParticipationActions>
      </ContestParticipationContent>
      </div>
  );
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  grid-gap: 56px;

  ${context("for-tablet-portrait")`
    width: auto;
  `}
`;
