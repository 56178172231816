import { getCurrentCode } from "../../../common/hotline.js";
import { usePermissions } from "../../services/permissions.js";
import { paths } from "../../services/router.js";
import { useSelfMetaData } from "../../services/self.js";
import { Form } from "../forms.jsx";
import HotlineNumber from "../HotlineNumber.jsx";
import Tooltip, {
  ArchivedTooltipContent,
  DisabledTooltipContent,
  ExpiredTooltipContent,
} from "../Tooltip.jsx";
import { ButtonArrowAround } from "../typography.jsx";
import { Data, Group, Primary, Section, Title } from "./Components.jsx";

export default function WidgetDaikin({ close }) {
  const [hasPermission] = usePermissions();
  const { company, isArchived, isSubscriptionExpired } = useSelfMetaData();

  const agency = company?.agency;
  const salesPerson = company?.salesPerson;

  return (
    <>
      <Form>
        <Section>
          <Title tabIndex="0">Mes contacts DAIKIN</Title>
          <Group label="Agence Daikin" big>
            {Boolean(agency) ? (
              <>
                {Boolean(agency.name) && (
                  <Data label="Nom :">{agency.name}</Data>
                )}
                <Data label="E-mail :">
                  {agency?.email ? (
                    <a href={"mailto:" + agency.email}>{agency?.email}</a>
                  ) : (
                    "Non disponible"
                  )}
                </Data>
                <Data label="Téléphone :">
                  {agency?.phone ? (
                    <a href={"tel:" + agency.phone}>{agency?.phone}</a>
                  ) : (
                    "Non disponible"
                  )}
                </Data>
                {Boolean(agency.address?.street || agency.address?.city) && (
                  <Data label="Adresse :">
                    {agency.address?.street}
                    <br />
                    {agency.address?.postalCode} {agency.address?.city}
                  </Data>
                )}
              </>
            ) : (
              <p>Aucune agence rattachée</p>
            )}
          </Group>
          <Group label="Mon commercial" big>
            {Boolean(
              salesPerson?.firstname ||
                salesPerson?.lastname ||
                salesPerson?.email ||
                salesPerson?.phone
            ) ? (
              <>
                {(salesPerson?.firstname || salesPerson?.lastname) && (
                  <Data>
                    {`${salesPerson.firstname || ""} ${
                      salesPerson.lastname || ""
                    }`.trim()}
                  </Data>
                )}
                <Data label="E-mail :">
                  {salesPerson?.email ? (
                    <a href={"mailto:" + salesPerson?.email}>
                      {salesPerson?.email}
                    </a>
                  ) : (
                    "Non disponible"
                  )}
                </Data>
                <Data label="Téléphone :">
                  {salesPerson?.phone ? (
                    <a href={"tel:" + salesPerson?.phone}>
                      {salesPerson?.phone}
                    </a>
                  ) : (
                    "Non disponible"
                  )}
                </Data>
              </>
            ) : (
              <p>Aucun commercial associé</p>
            )}
          </Group>
        </Section>
        <hr />
        {hasPermission("technical:hotline") ? (
          <>
            <Section>
              <Title>Hotline technique</Title>
              <Data>
                Accès prioritaire à la hotline technique avec le code{" "}
                <Primary>{getCurrentCode()}</Primary>
              </Data>
              <HotlineNumber />
            </Section>
            <hr />
          </>
        ) : (
          <>
            <Section>
              <Title>Hotline technique</Title>
              <Data>
                Accès prioritaire à la hotline technique avec le code{" "}
                <Primary>XXXX</Primary>
              </Data>
              <Tooltip
                align="left"
                content={
                  isArchived ? (
                    <ArchivedTooltipContent />
                  ) : isSubscriptionExpired ? (
                    <ExpiredTooltipContent />
                  ) : (
                    <DisabledTooltipContent />
                  )
                }
              />
              <HotlineNumber />
            </Section>
            <hr />
          </>
        )}
        <Section>
          <Title>Une question ?</Title>
          <center>
            <ButtonArrowAround to={paths.contact} onClick={close}>
              Contactez-nous
            </ButtonArrowAround>
          </center>
        </Section>
      </Form>
    </>
  );
}
