import { useCallback, useEffect } from "react";
import { useState } from "react";

import { colors } from "../templates/theme.jsx";
import { context, css, font, styled } from "../util/style.js";

const TooltipBubble = styled.div`
  position: ${({ float }) => (float ? "absolute" : "relative")};
  margin: 16px auto;
  ${({ float }) =>
    float
      ? css`
          max-width: 300px;
        `
      : ""};
  width: 100%;

  &::before {
    height: 0;
    content: "";
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent ${({ color }) => color} transparent;
    border-style: solid;
    left: ${({ align }) =>
      align === "left" ? "24px" : align === "right" ? "auto" : "50%"};
    right: ${({ align }) => (align === "right" ? "24px" : "auto")};
    margin: 0 -8px;
    position: absolute;
    top: -8px;
    width: 0;
  }
`;
const TooltipBubbleContent = styled.div`
  background: ${({ color }) => color};
  border-radius: 8px;
`;

export default styled(function Tooltip({
  className,
  content,
  children,
  float = false,
  align = "center",
  color = colors.red,
  show = true,
  ...props
}) {
  const [visible, setVisible] = useState(Boolean(show));
  const [isOver, setIsOver] = useState(false);
  const [hideTimeout] = useState({});
  const hide = useCallback(() => {
    if (isOver) return;
    setVisible(false);
  }, [isOver]);
  useEffect(
    function () {
      window.clearTimeout(hideTimeout.current);
      if (show || isOver) setVisible(true);
      else hideTimeout.current = window.setTimeout(hide, 250);
    },
    [show, hide, hideTimeout]
  );
  return (
    <div
      className={`${className} tooltip--${visible ? "visible" : "hidden"}`}
      onMouseEnter={useCallback(() => {
        setIsOver(true);
        window.clearTimeout(hideTimeout.current);
      }, [hideTimeout])}
      onMouseLeave={useCallback(() => {
        setIsOver(false);
        hideTimeout.current = window.setTimeout(hide, 250);
      }, [hide, hideTimeout])}
      {...props}
    >
      {children}
      <TooltipBubble color={color} float={Boolean(float)} align={align}>
        <TooltipBubbleContent color={color}>{content}</TooltipBubbleContent>
      </TooltipBubble>
    </div>
  );
})`
  transition: all 0.25s ease;
  display: flex;
  justify-content: ${({ align }) =>
    align === "left"
      ? "flex-start"
      : align === "right"
      ? "flex-end"
      : "center"};
  position: relative;
  width: 100%;

  &.tooltip--visible {
    opacity: 1;
    top: 0;
  }
  &.tooltip--hidden {
    opacity: 0;
    top: -16px;
  }
`;

export const CustomTooltipContent = styled(function CustomTooltipContent({
  text,
  ...props
}) {
  return (
    <div {...props}>
      <p>{text}</p>
    </div>
  );
})`
  padding: 16px;

  p {
    color: white !important;
    font-size: 1.6rem !important;
    line-height: 1.2 !important;
    margin: 0 0 0.25em 0 !important;
    text-align: center !important;
  }
`;
export const DisabledTooltipContent = (props) => (
  <CustomTooltipContent
    text="Vous n'avez pas accès à cette rubrique"
    {...props}
  />
);
export const ArchivedTooltipContent = (props) => (
  <CustomTooltipContent
    text="Cette fonctionnalité a été désactivée car vous n'êtes plus éligible au
contrat DAIKIN ELITE."
    {...props}
  />
);
export const ExpiredTooltipContent = styled(function ExpiredTooltipContent(
  props
) {
  return (
    <div {...props}>
      <p>
        Cette fonctionnalité sera de nouveau disponible lorsque le contrat
        DAIKIN ELITE nous sera retourné signé
        <span className="small">
          (NB : Il faut compter environ 2 semaines entre l’envoi du contrat
          signé et l’activation de ces fonctionnalités et de vos avantages)
        </span>
      </p>
    </div>
  );
})`
  padding: 16px;

  p {
    color: white !important;
    font-size: 1.2rem !important;
    line-height: 1.2 !important;
    margin: 0 0 0.25em 0 !important;
    text-align: center !important;
    .small {
      display: block;
      opacity: 0.75;
    }
  }
`;
export const TrainingTooltipContent = styled(function TrainingTooltipContent({
  className,
}) {
  return (
    <div className={className}>
      <p>
        <strong>
          Ce budget ne tient pas compte des commandes de formations en attente
          de validation.
        </strong>
      </p>
      <p>
        Pour plus d'informations, veuillez contacter le centre de formations
        (tel : <a href="tel:0437722204">04 37 72 22 04</a> ou{" "}
        <a href="mailto:serviceformations@daikin.fr">
          serviceformations@daikin.fr
        </a>
        )
      </p>
    </div>
  );
})`
  padding: 16px;

  p {
    color: white !important;
    font-size: 1.2rem !important;
    line-height: 1.2 !important;
    margin: 0 0 0.25em 0 !important;
    text-align: left !important;
    a {
      color: white;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
