import _sortBy from "lodash/sortBy";
import { useState } from "react";

import { styled, theme } from "../../util/style.js";
import starFull from "./images/star-full.svg";
import star from "./images/star.svg";

const Star = styled(function ({ className, selected, alt, ...props }) {
  return (
    <div className={className} {...props}>
      <img src={selected ? starFull : star} alt={alt} />
    </div>
  );
})`
  padding: 0 8px;
  cursor: pointer;
  width: 64px;
  img {
    width: 100%;
    max-width: 80px;
  }
`;

export default styled(function RatingQuestion({
  className,
  onChange,
  value,
  answers,
}) {
  const selectedReference = (
    answers.find(({ reference }) => reference === value) || { reference: "0" }
  ).reference;

  const [hoverReference, setHoverReference] = useState(selectedReference);

  return (
    <div className={className}>
      {_sortBy(answers, "reference").map(({ reference, text }) => (
        <Star
          key={reference}
          alt={`Note : ${text}`}
          selected={+hoverReference >= +reference}
          onClick={() => onChange(value === reference ? null : reference)}
          onMouseOver={() => setHoverReference(reference)}
          onMouseOut={() => setHoverReference(selectedReference)}
        />
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  margin: 10px -8px;
  justify-content: flex-start;
`;
