import {
  color,
  context,
  font,
  is,
  prop,
  styled,
  theme,
} from "../../util/style.js";

export const Section = styled.div`
  margin: 56px 0;
  ${context("for-tablet-landscape")`
    margin: 32px 0;
  `}
`;

export const Title = styled.h2`
  ${font("headline2")}
  color: ${color("title-special")};
  margin-bottom: 24px;
  margin-top: 0;
  small {
    ${font("headline5")}
    display: block;
  }
  ${context("for-tablet-landscape")`
    ${font("headline4")}
    small {
      ${font("paragraph")}
    }
  `}
`;

export const Group = styled(function ({ label, children, className }) {
  return (
    <div className={className}>
      {Boolean(label) && <div className="Group-label">{label}</div>}
      {children}
    </div>
  );
})`
  & + & {
    margin-top: 40px;
  }

  ${is("label")`
    .Group-label {
      display: block;
      margin: 8px 0;
      font-weight: ${theme("font-weight-bold")};
      ${is("big")`
        ${font("headline4")}
        color: ${color("primary")};
      `}
    }
  `}

  ${context("for-tablet-landscape")`
    ${font("small")}
  `}
`;

export const Subtitle = styled.div`
  ${font("headline4")}
  color: ${color("text-dark")};
  margin: 16px 0;
  text-transform: uppercase;

  ${context("for-tablet-landscape")`
    ${font("headline5")}
  `}
`;

export const Primary = styled.span`
  color: ${color("primary")};
`;

export const Data = styled(function ({ label, block, children, className }) {
  return (
    <div className={className}>
      {Boolean(label) && <label className="Data-label">{label}</label>}
      {children}
    </div>
  );
})`
  margin: 8px 0;
  word-break: break-all;

  ${is("big")` {
    ${font("headline4")}
    color: ${color("primary")};
  `}

  ${is("label")`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .Data-label {
      font-weight: ${theme("font-weight-bold")};
      margin-right: 8px;
    }
  `}

  ${is("block")`
    display: block;
  `}

  ${context("for-tablet-landscape")`  
    ${font("paragraph")}
  `}
`;

export const TitleHelp = styled.h3`
  color: ${color("primary")};
  text-align: left;
`;

export const Badge = styled.div`
  ${font("headline2")}
  color : white;
  background-color: ${color("primary")};
  border-radius: 50px;
  display: inline-block;
  padding: 2px 48px;
`;
