import { Redirect, Route } from "react-router-dom";

export default function HttpStatusRoute({
  statusCode,
  url,
  children,
  ...props
}) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = parseInt(statusCode || "", 10) || 200;
          staticContext.url = url;
        } else if (url) {
          if (url.indexOf("://") !== -1 && typeof window !== "undefined")
            window.location.replace(url);
          else return <Redirect to={url} />;
        }
        return children;
      }}
      {...props}
    />
  );
}
