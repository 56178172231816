import { useRef } from "react";
import { Redirect } from "react-router-dom";

import DisabledHelp from "../components/DisabledHelp.jsx";
import InterventionBudgetHeader from "../components/InterventionBudgetHeader.jsx";
import InterventionVoucherItem from "../components/InterventionVoucherItem.jsx";
import { Col, Row } from "../components/layout.jsx";
import { usePagination } from "../components/Pagination.jsx";
import { Button } from "../components/typography.jsx";
import header from "../img/intervention_bandeau.jpg";
import header2x from "../img/intervention_bandeau@2x.jpg";
import { useInterventionVouchers } from "../services/interventionVouchers.js";
import { paths } from "../services/router.js";
import { useSelfMetaData } from "../services/self.js";
import {
  Headline,
  ImageHeader,
  MainContent,
} from "../templates/common/Components.jsx";
import Container from "../templates/common/Container.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";
import { color, styled, theme } from "../util/style.js";

const DEFAULT_LIMIT = 100;

const Panel = styled.div`
  background: white;
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
  margin-bottom: 36px;
`;
const GreyContent = styled.div`
  background: ${color("bg-light")};
  border-radius: ${theme("border-radius")};
  padding: ${theme("padding-container")};
`;

const InterventionVouchersList = styled.div`
  ${InterventionVoucherItem} + ${InterventionVoucherItem} {
    margin-top: 30px;
  }
`;

const ScrollRef = styled.div`
  position: relative;
  top: -100px;
`;

export default function InterventionVouchers() {
  const { isEliteProPlus, company, sessionData } = useSelfMetaData();
  const { subscriptionExpirationDate } = sessionData;

  const isExpired = new Date(subscriptionExpirationDate) < new Date();

  const budgetInterventions = company?.budgets?.interventions;

  const topScroll = useRef();
  const pagination = usePagination(DEFAULT_LIMIT);
  const [interventionVouchersResult, { ready, refetch }] =
    useInterventionVouchers({}, pagination);

  const interventionVouchers = (interventionVouchersResult?.edges || []).map(
    ({ node }) => node
  );

  if (company && !isEliteProPlus) return <Redirect to={paths.interventions} />;

  return (
    <LoggedInTemplate title="Bons d'achat intervention">
      <ImageHeader
        image={header}
        image2x={header2x}
        icon="gear-phone"
        label="Bons d'achat intervention"
      />
      <MainContent>
        <Headline title="Mes bons d'achat intervention" />
        <Container>
          {Boolean(isEliteProPlus) && (
            <InterventionBudgetHeader
              budget={budgetInterventions}
              budgetButton={null}
            />
          )}
          <Row>
            <Col kind="aside">
              <Panel>
                <GreyContent>
                  <p>
                    Les bons d'achat intervention vous servent à utiliser votre
                    budget Daikin ELITE PRO+. Créez un bon du montant que vous
                    désirez, téléchargez-le et{" "}
                    <strong>ajoutez-le à votre demande</strong> d'intervention
                    Daikin pour bénéficier d'une remise du montant choisi sur
                    votre offre.
                  </p>

                  <p>
                    <center>
                      <Button
                        to={paths.interventionVoucherCreate}
                        disabled={
                          budgetInterventions?.totalBalance?.amount <= 0 ||
                          isExpired
                        }
                      >
                        Je créé un bon
                      </Button>
                    </center>
                  </p>

                  <p>
                    <center>
                      <Button to={paths.interventions} ghost>
                        Retour aux interventions
                      </Button>
                    </center>
                  </p>
                  {Boolean(isExpired) && (
                    <DisabledHelp>
                      L'accès aux bons d'achat intervention sera de nouveau
                      disponible lorsque le contrat DAIKIN ELITE 2023 nous sera
                      retourné signé (NB : Il faut compter environ 2 semaines
                      entre l’envoi du contrat signé et l’activation de ces
                      fonctionnalités et de vos avantages).
                    </DisabledHelp>
                  )}
                </GreyContent>
              </Panel>
            </Col>
            <Col kind="main">
              <ScrollRef ref={topScroll} />
              <InterventionVouchersList>
                {interventionVouchers.length ? (
                  interventionVouchers.map((interventionVoucher) => (
                    <InterventionVoucherItem
                      {...interventionVoucher}
                      key={interventionVoucher.id}
                      onUpdate={refetch}
                    />
                  ))
                ) : (
                  <Panel>
                    <h3>Aucun bon d'achat intervention</h3>
                    <div>
                      Vous n'avez pas créé de bon d'achat intervention pour
                      cette année.
                    </div>
                  </Panel>
                )}
              </InterventionVouchersList>
            </Col>
          </Row>
        </Container>
      </MainContent>
    </LoggedInTemplate>
  );
}
