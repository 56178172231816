export default function ReactSeparatorTile({ tile, options, className }) {
  return (
    <div
      className={`${className ||
        ""} tile-container tile-separator tile--typography`}
    >
      <hr />
    </div>
  );
}
