import { styled } from "../util/style.js";
import { Button } from "../components/typography.jsx";
import LoggedInTemplate from "../templates/LoggedInTemplate.jsx";

export default function Soon() {
  return (
    <LoggedInTemplate title="En construction">
      <Container>
        <Title>Page indisponible</Title>
        <Text>
          Désolé, la page demandée est en cours de construction et sera bientôt
          disponible
        </Text>
        <Button to="/">Retour à l'accueil</Button>
      </Container>
    </LoggedInTemplate>
  );
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 344px;
  padding: 56px 12px;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 24px;
`;

const Text = styled.p`
  margin-bottom: 40px;
`;
