import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { toStandardResult } from "../util/graphql.js";
import { challengeSchema } from "./challenges.js";

const externalChallengeSchema = `
  ${challengeSchema}
`;

export function useExternalChallenge(challengeAlias) {
  const result = useQuery(
    gql`
      query GET_EXTERNAL_CHALLENGE($alias: ID!) {
        private {
          challenges {
            externalChallenge: fetchExternalChallengeByAlias(alias: $alias) {
              ${externalChallengeSchema}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        alias: challengeAlias,
      },
    }
  );

  return toStandardResult(result, "private.challenges.externalChallenge");
}
