import { styled, theme, context } from "../../util/style.js";

export default styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme("document-width-default")};
  padding-left: ${theme("padding-container")};
  padding-right: ${theme("padding-container")};
  width: 100%;
  ${context("for-desktop")`
    max-width: ${theme("document-width-desktop")};
  `}
  ${context("for-tablet-landscape")`
    max-width: ${theme("document-width-tablet-landscape")};
  `}
  ${context("for-tablet-portrait")`
    max-width: ${theme("document-width-tablet-mobile")};
  `}
  ${context("for-phone")`
    padding-left: ${theme("padding-container-mobile")};
    padding-right: ${theme("padding-container-mobile")};
  `}
`;
