import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ excludedPaths }) {
  const { pathname } = useLocation();
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (excludedPaths?.some((path) => pathname.startsWith(path))) return;
    setTimeout(() => window.scrollTo({ top: 0 }));
  }, [pathname]);
  return null;
}
