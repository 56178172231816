import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback } from "react";

import { paginatedGql, toStandardResult } from "../util/graphql.js";

const participationSchema = `
  id
  reference
  amount
  piece {
    reference
    kind
  }
  product {
    reference
    serial
  }
  status
  statusUpdatedAt
  details
  invoice {
    reference
    file {
      id
      version
      name
    }
  }
  archived
  archivedAt
  createdAt
  updatedAt
`;

export function useSendWorkforceParticipationRequest() {
  const [mutation, result] = useMutation(
    gql`
      mutation SEND_PARTICIPATION_REQUEST(
        $data: WorkforceParticipationRequestPrivateInput!
      ) {
        private {
          workforceParticipationRequests {
            success: createWorkforceParticipationRequest(data: $data) {
              id
            }
          }
        }
      }
    `
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(
      result,
      "private.workforceParticipationRequests.success"
    ),
  ];
}

export function useUpdateWorkforceParticipationRequest(id) {
  const [mutation, result] = useMutation(
    gql`
      mutation UPDATE_PARTICIPATION_REQUEST(
        $id: ID!
        $data: WorkforceParticipationRequestPrivateInput!
      ) {
        private {
          workforceParticipationRequests {
            success: updateWorkforceParticipationRequest(id: $id, data: $data) {
              id
            }
          }
        }
      }
    `
  );
  return [
    useCallback((data) => mutation({ variables: { id, data } }), [id, mutation]),
    ...toStandardResult(
      result,
      "private.workforceParticipationRequests.success"
    ),
  ];
}

export function useWorkforceParticipationRequestsStats() {
  const result = useQuery(
    gql`
      query GET_STATS {
        private {
          workforceParticipationRequests {
            countByStatus: getWorkforceParticipationRequestCountByStatus {
              status
              count
            }
            archived: getArchivedWorkforceParticipationRequestCount
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  return toStandardResult(result, "private.workforceParticipationRequests", {
    transformData: (data) =>
      data && {
        archived: data.archived,
        ...(data.countByStatus || []).reduce(
          (stats, { status, count }) => ({ ...stats, [status]: count }),
          {}
        ),
      },
  });
}

export function useCancelWorkforceParticipationRequest(id) {
  const [mutation, result] = useMutation(
    gql`
      mutation CANCEL_PARTICIPATION_REQUEST($id: ID!) {
        private {
          workforceParticipationRequests {
            success: cancelWorkforceParticipationRequest(id: $id) {
              id
              status
            }
          }
        }
      }
    `,
    { variables: { id } }
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(
      result,
      "private.workforceParticipationRequests.success"
    ),
  ];
}

export function useArchiveWorkforceParticipationRequest(id) {
  const [mutation, result] = useMutation(
    gql`
      mutation ARCHIVE_PARTICIPATION_REQUEST($id: ID!) {
        private {
          workforceParticipationRequests {
            success: archiveWorkforceParticipationRequest(id: $id) {
              id
              status
              archived
              archivedAt
            }
          }
        }
      }
    `,
    { variables: { id } }
  );
  return [
    (data) => mutation({ variables: { data } }),
    ...toStandardResult(
      result,
      "private.workforceParticipationRequests.success"
    ),
  ];
}

export function useWorkforceParticipationRequests({ status }, pagination) {
  const result = useQuery(
    gql`
      query GET_PARTICIPATIONS($status: WorkforceParticipationRequestStatusEnum, $pagination: PaginationInput) {
        private {
          workforceParticipationRequests {
            participations: fetchWorkforceParticipationRequests(
              filter: { status: $status }
              pagination: $pagination
            ) {
              ${paginatedGql(participationSchema)}
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        status,
        pagination,
      },
    }
  );

  return toStandardResult(
    result,
    "private.workforceParticipationRequests.participations"
  );
}

export function useUploadInvoiceWorkforceParticipationRequest() {
  const [mutation, result] = useMutation(
    gql`
      mutation SEND_PARTICIPATION_REQUEST($requests: [ID]!, $invoice: Upload!) {
        private {
          workforceParticipationRequests {
            success: uploadInvoice(
              worforceParticipationRequests: $requests
              invoice: $invoice
            ) {
              id
            }
          }
        }
      }
    `
  );
  return [
    (data) =>
      mutation({
        variables: { requests: data.requests, invoice: data.invoice },
      }),
    ...toStandardResult(result, "private.workforceParticipationRequests.success"),
  ];
}

export function useWorkforceParticipationRequest(participationId) {
  const result = useQuery(
    gql`
      query GET_PARTICIPATION($id: ID!) {
        private {
          workforceParticipationRequests {
            workforceParticipationRequest: fetchWorkforceParticipationRequestById(id: $id) {
              ${participationSchema}
              invoicePieceReference
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        id: participationId,
      },
    }
  );

  return toStandardResult(result, "private.workforceParticipationRequests.workforceParticipationRequest");
}
